import React from 'react';
import { IssueCardTypes } from '@writercolab/types';
import { IssueLabel } from '@writercolab/ui-molecules';
import styles from './styles.module.css';
import { rswitch } from '../../../utils/rswitch';

const Suggestion = ({ correction }: { correction?: string[] }) => (
  <>
    {correction?.map((item, index) => (
      <IssueLabel key={`item${index}`} correction style={{ fontSize: 12, marginTop: 7 }}>
        {item}
      </IssueLabel>
    ))}
  </>
);

const Options$ = ({
  cardType,
  onApplySuggestion,
  correction,
}: {
  cardType: IssueCardTypes;
  onApplySuggestion?: (correction: string) => void | Promise<unknown>;
  correction?: string[];
}) => (
  <div className={styles.optionsContainer}>
    {rswitch(cardType, {
      [IssueCardTypes.ACCEPT_ALL_CHANGES]: (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className={styles.acceptAllButton}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            onApplySuggestion?.(correction ? correction[0] : '');
          }}
          role="button"
          tabIndex={-1}
        >
          Accept suggestion
        </div>
      ),
      [IssueCardTypes.SUGGESTION]: <Suggestion correction={correction} />,
      default: <></>,
    })}
  </div>
);

export const Options = React.memo(Options$);
