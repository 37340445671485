import { nanoid } from 'nanoid/non-secure';
import { useRef } from 'react';

const LENGTH = 8;
const PREFIX = 'w';

/**
 *
 * @param prefix optionally provide a prefix
 * @example
 * const id = useId('my-prefix');
 * console.log(id); // my-prefix-rn8ObqDx
 */
export const useId = (prefix = PREFIX) => useRef(`${prefix}-${nanoid(LENGTH)}`).current;
