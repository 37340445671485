
const digest = 'bc7d63925f98925a758034d066f25ef4e6d3e6d4487dc35b50d5a84cb72a09be';
const css = `.styles-module__9rwBRHI {
  margin: 12px 0;
}

.styles-module__9rwBRHI:last-child {
  margin: 12px 0 0;
}
`;

export default {"issueCard":"styles-module__9rwBRHI"};
export { css, digest };
  