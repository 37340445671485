import type { ReactElement } from 'react';
import React from 'react';
import cx from 'clsx';

import styles from './styles.module.css';

import { DotLoader } from '../MiscElements';

/**
 * @deprecated use WDS
 */
export interface IButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement & HTMLAnchorElement>, 'content' | 'ref' | 'size'> {
  className?: string;
  testId?: string;
  navTo?: string;
  navTarget?: string;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  size?: SizeTypes;
  type?: ButtonTypes;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  danger?: boolean;
  round?: boolean;
  isLoading?: boolean;
  isGenerating?: boolean;
  content?: string | ReactElement;
  squared?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  disabledClassName?: string;
  fullWidth?: boolean;
}

/**
 * @deprecated use WDS
 */
export enum SizeTypes {
  /** XS = height-20 font-10 */
  XXS = 'xxs',
  /** XS = height-28 font-12 */
  XS = 'xs',
  /** SMALL = height-32 font-13 */
  SMALL = 'small',
  /** MEDIUM = height-40 font-14 */
  MEDIUM = 'medium',
  /** LARGE = height-44 font-15 */
  LARGE = 'large',
  /** XXL = height-52 font-15 */
  XXL = 'xxl',
  /** XXL = height-60 font-16 */
  XXXL = 'xxxl',
}

/**
 * @deprecated use WDS
 */
export enum ButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  BLACK = 'black',
  TRANSPARENT = 'transparent',
  BORDERED = 'bordered',
  TRANSPARENT_HOVER = 'transparentHover',
  TRANSPARENT_LINK = 'transparentLink',
  LINK = 'link',
  GRAY = 'gray',
  BLUE2 = 'blue2',
  BLUE3 = 'blue3',
  MAUVE = 'mauve',
  PURPLE = 'purple',
  PURPLE_2 = 'purple2',
  PURPLE_3 = 'purple3',
  GREEN = 'green',
  YELLOW = 'yellow',
  PINK = 'pink',
  GRAY_NU = 'graynu',
}

/**
 * @deprecated use WDS
 */
export const Button: React.FC<IButtonProps> = React.forwardRef<HTMLButtonElement & HTMLAnchorElement, IButtonProps>(
  (
    {
      className,
      disabledClassName,
      testId,
      children,
      onClick,
      style,
      content = '',
      icon = null,
      size = SizeTypes.MEDIUM,
      type = ButtonTypes.DEFAULT,
      disabled = false,
      round = false,
      squared = false,
      danger = false,
      isLoading = false,
      isGenerating = false,
      htmlType = 'button' as IButtonProps['htmlType'],
      navTarget,
      navTo,
      fullWidth,
      ...props
    },
    ref,
  ) => {
    const elClass: string = cx(className, styles.button, styles[type], styles[size], {
      [disabledClassName ?? styles.disabled]: disabled,
      [styles.round]: round,
      [styles.squared]: squared,
      [styles.danger]: danger,
      [styles.loading]: isLoading,
      [styles.generating]: isGenerating,
    });

    const _onDisabledClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    if (navTo) {
      return (
        <a
          ref={ref}
          className={elClass}
          data-testid={testId}
          href={navTo}
          target={navTarget}
          style={style}
          onClick={isLoading || disabled ? _onDisabledClick : onClick}
          type={htmlType}
          {...props}
        >
          {isLoading && <DotLoader className={styles.dotLoader} />}
          {icon}
          {isLoading ? null : children || <span className={styles.content}>{content}</span>}
        </a>
      );
    }

    return (
      <button
        ref={ref}
        className={elClass}
        data-testid={testId}
        style={fullWidth ? { width: '100%', ...style } : style}
        onClick={isLoading || disabled ? _onDisabledClick : onClick}
        type={htmlType}
        {...props}
      >
        {isLoading && <DotLoader compact={round || squared} />}
        {icon}
        {isLoading ? null : children || <span className={styles.content}>{content}</span>}
      </button>
    );
  },
);

Button.displayName = 'Button';
