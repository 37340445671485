import type { cmudict } from '@writercolab/cmudict';
import { cleanFromNewLines } from './cleanFromNewLines';
import { getSentenceCount } from './getSentenceCount';
import { getSyllablesPerWord } from './getSyllablesPerWord';
import { getWords } from './getWords';
import { normalizeAbbr } from './normalizeAbbr';
import { removeUnicodeSymbols } from './removeUnicodeSymbols';

/**
 * Interface for the object returned by the `calculateGrade` method of the FleschKincaidService.
 * Contains statistics for the input text.
 * @interface
 * @property {number} characterCount - The number of characters in the input text.
 * @property {number} wordCount - The number of words in the input text.
 * @property {number} sentenceCount - The number of sentences in the input text.
 * @property {number} grade - The calculated Flesch-Kincaid reading grade level of the input text.
 */
export interface IContentStats {
  /**
   * The number of characters in the input text.
   * @type {number}
   */
  characterCount: number;
  /**
   * The number of words in the input text.
   * @type {number}
   */
  wordCount: number;
  /**
   * The number of sentences in the input text.
   * @type {number}
   */
  sentenceCount: number;
  /**
   * The calculated Flesch-Kincaid reading grade level of the input text.
   * @type {number}
   */
  grade: number;
}

/**
 * Interface for FleschKincaidService
 */
export interface IFleschKincaidService {
  /**
   * Calculates Flesch-Kincaid Reading Ease rate for a given text.
   * @param {string} text The input text to calculate the Flesch-Kincaid Reading Ease rate.
   * @returns {number} The calculated Flesch-Kincaid Reading Ease rate for the given text.
   */
  calculateRate(text: string, avgSentenceLength?: number): number;
  /**
   * Calculates Flesch-Kincaid Grade Level and other content stats for a given text.
   * @param {string} text The input text to calculate the Flesch-Kincaid Grade Level and other stats.
   * @param {RegExp | null} ignoreListRegex Optional regular expression to remove words from the text before calculating the Flesch-Kincaid Grade Level.
   * @returns {IContentStats} The calculated Flesch-Kincaid Grade Level and other content stats for the given text.
   */
  calculateGrade(text: string, ignoreListRegex?: RegExp | null): IContentStats;
}

/**
 * Service to calculate Flesch-Kincaid readability scores.
 *
 * @implements {IFleschKincaidService}
 */
export class FleschKincaidService implements IFleschKincaidService {
  constructor(private readonly sylDict: typeof cmudict) {
    // pass
  }

  /**
   * Calculates the number of words in the given text.
   * @param {string} text The input text to calculate the number of words.
   * @returns {number} The calculated number of words in the given text.
   */
  static words(text: string) {
    return getWords(text).length;
  }

  /**
   * Calculates the number of sentences in the given text.
   * @param {string} text The input text to calculate the number of sentences.
   * @returns {number} The calculated number of sentences in the given text.
   */

  sentences(text: string) {
    const nText = normalizeAbbr(text);

    return getSentenceCount(nText);
  }

  /**
   * Calculates the number of syllables per word in the given text and words.
   * @param {string} text The input text to calculate the number of syllables per word.
   * @param {string[]} words The words in the given text to calculate the number of syllables per word.
   * @returns {number} The calculated number of syllables per word in the given text and words.
   */
  static syllablesPerWord(text: string, sylDict: typeof cmudict, words = getWords(text)) {
    return getSyllablesPerWord(words, sylDict);
  }

  /**
   * Calculates the average number of words per sentence in the given text.
   * @param {string} text The input text to calculate the average number of words per sentence.
   * @returns {number} The calculated average number of words per sentence in the given text.
   */
  private wordsPerSentence(text: string) {
    return FleschKincaidService.words(text) / this.sentences(text);
  }

  /**
   * Calculates Flesch-Kincaid Reading Ease rate for a given text.
   * @param {string} text The input text to calculate the Flesch-Kincaid Reading Ease rate.
   * @returns {number} The calculated Flesch-Kincaid Reading Ease rate for the given text.
   */
  calculateRate(text: string): number {
    return (
      206.835 - 1.015 * this.wordsPerSentence(text) - 84.6 * FleschKincaidService.syllablesPerWord(text, this.sylDict)
    );
  }

  /**
   * Calculates the Flesch-Kincaid grade level of a given text.
   *
   * @param {string} text - The input text.
   * @param {RegExp | null} [ignoreListRegex=null] - A regular expression for any patterns to ignore when calculating the grade level.
   * @returns {IContentStats} - An object containing statistics about the text's content.
   */
  calculateGrade(text: string, ignoreListRegex?: RegExp | null): IContentStats {
    const textWihtoutUnicode = removeUnicodeSymbols(text);
    const textCleanNewLines = cleanFromNewLines(textWihtoutUnicode);

    const _text = ignoreListRegex ? textCleanNewLines.toLowerCase().replaceAll(ignoreListRegex, '') : textCleanNewLines;

    const characterCount = _text.length;
    const wordCount = FleschKincaidService.words(_text);
    // https://writerai.atlassian.net/browse/WA-2296
    // sentences will be calculated over the pristine text, we need all the line breaks
    const sentenceCount = this.sentences(text);
    const words = getWords(_text);
    const wordsPerSentence = words.length / sentenceCount;
    const syllablesPerWord = FleschKincaidService.syllablesPerWord(text, this.sylDict, words);

    let grade = 0.39 * wordsPerSentence + 11.8 * syllablesPerWord - 15.59;

    if (grade < 0) {
      grade = 0;
    }

    grade = Math.round(grade * 10) / 10;

    return { characterCount, wordCount, sentenceCount, grade };
  }
}
