import type { DependencyList, RefObject } from 'react';
import { useEffect } from 'react';

export const useResizeObserver = (
  elementRef: RefObject<HTMLDivElement>,
  onResize: (size: { width: number; height: number }) => void,
  deps: DependencyList = [],
) => {
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        const { width, height } = entries[0].target.getBoundingClientRect();

        onResize({
          width,
          height,
        });
      });
    });

    elementRef.current && observer.observe(elementRef.current);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
