/**
 * Stops the propagation of the given event.
 *
 * This function can be used to prevent an event from bubbling up the DOM tree.
 *
 * @template T - A type that extends an object with a `stopPropagation` method.
 * @param {MouseEvent | T} e - The event whose propagation should be stopped.
 */
export function stopEventPropagation<T extends { stopPropagation(): void }>(e: MouseEvent | T) {
  e.stopPropagation();
}
