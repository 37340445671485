import { type ReactNode } from 'react';
import { Enum } from '@writercolab/utils';

export const NotificationQueueItemType = new Enum(
  'basic',
  'delete',
  'error',
  'offline',
  'alert',
  'loading',
  'custom',
  'success',
);

export const RoutingType = new Enum('push', 'replace', 'newTab');
export const StorageType = new Enum('local', 'session');
export const StorageMethod = new Enum('get', 'set', 'delete');
export const AnalyticsType = new Enum('track', 'identify', 'page');

export type TNotificationQueueItem = {
  message: string | ReactNode;
  title?: string;
  type: typeof NotificationQueueItemType.type;
  closable?: boolean;
};

export type TEventQueueItem<T, R = unknown> = {
  type: T;
  params?: R;
};
