import React from 'react';

import cx from 'clsx';
import { Enum } from '@writercolab/utils';
import type { ReactNode } from 'react';
import styles from './NavLinkGroup.module.css';
import { Text } from '../typography/Text/Text';

export const TNavLinkGroupVariant = new Enum('default', 'caps', 'largeTitle', 'largeBlackTitle');

interface INavLinkGroupProps {
  className?: string;
  title?: string;
  variant?: typeof TNavLinkGroupVariant.type;
  dataAnchor?: string;
  children?: ReactNode;
}

export const NavLinkGroup: React.FC<INavLinkGroupProps> = ({
  className,
  variant = TNavLinkGroupVariant.enum.default,
  title,
  dataAnchor,
  children,
}) => (
  <div data-anchor={dataAnchor} className={cx(className, styles.container)}>
    {title && (
      <div className={styles.title}>
        <Text
          className={cx({
            [styles.titleBlackLarge]: variant === TNavLinkGroupVariant.enum.largeBlackTitle,
            [styles.titleLarge]: variant === TNavLinkGroupVariant.enum.largeTitle,
            [styles.titleDefault]: variant === TNavLinkGroupVariant.enum.default,
            [styles.titleCaps]: variant === TNavLinkGroupVariant.enum.caps,
          })}
        >
          {title}
        </Text>
      </div>
    )}
    <div className={styles.linkContainer}>{children}</div>
  </div>
);
