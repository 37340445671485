import { Quill } from 'react-quill';
import { IssueCategory, IssueType } from '@writercolab/common-utils';
import { QA_TEXTHIGHLIGHT_FORMAT_NAME, QL_TEXTHIGHLIGHT_CLASS_PREFIX } from "../constants";

const Inline = Quill.import('blots/inline');

export class TextHighlightFormat extends Inline {
  // Remember, there is:
  // super.formats
  // super.domNode

  static blotName = QA_TEXTHIGHLIGHT_FORMAT_NAME;
  static className = QL_TEXTHIGHLIGHT_CLASS_PREFIX;
  static tagName = 'span';

  // constructor(scroll: any, domNode: HTMLElement, issue: IIssue) {
  //   super(scroll, domNode, issue);
  // }

  static create(issue) {
    if (!this.isIssueUseful(TextHighlightFormat.getIssueCategoryId(issue))) {
      return super.create(false);
    }

    const node = super.create(issue);
    this.setNodeConfigurations(node, issue);

    return node;
  }

  static formats(domNode, scroll) {
    const data = domNode.getAttribute('data-value');

    // Data is not useful
    if (!this.isIssueUseful(data)) {
      return super.formats(domNode, scroll);
    }
    // Useful
    else {
      return data;
    }
  }

  /** See: https://github.com/quilljs/parchment#example */
  formats() {
    const formats = super.formats();
    const value = TextHighlightFormat.formats(this.domNode, this.scroll);

    // Only defined if the value is correct/acceptable.
    if (TextHighlightFormat.isIssueUseful(value)) {
      formats[QA_TEXTHIGHLIGHT_FORMAT_NAME] = TextHighlightFormat.formats(this.domNode, this.scroll);
    }

    // Return this blot's formats
    return formats;
  }

  format(format, issue) {
    const oldValue = TextHighlightFormat.formats(this.domNode, this.scroll);

    if (!issue) {
      super.format(format, false);

      return;
    }

    // Remove
    if (
      !format ||
      format !== TextHighlightFormat.blotName ||
      !TextHighlightFormat.isIssueUseful(TextHighlightFormat.getIssueCategoryId(issue))
    ) {
      // False = Remove this format
      super.format(format, false);
    }
    // Add
    else {
      const node = this.domNode;
      TextHighlightFormat.setNodeConfigurations(node, issue, oldValue);
    }
  }

  /* To prevent the node from accidentally being built in different ways. */
  static setNodeConfigurations(node, issue, oldValue) {
    // Remove previous highlight.
    if (oldValue && this.isIssueUseful(oldValue)) {
      node.classList.remove(`${QL_TEXTHIGHLIGHT_CLASS_PREFIX}-${oldValue}`);
      node.classList.remove(`issue-${issue.issueId}`);
      node.classList.remove('single-letter');
    }

    node.classList.add(`${QL_TEXTHIGHLIGHT_CLASS_PREFIX}-${TextHighlightFormat.getIssueCategoryId(issue)}`);
    node.classList.add(`issue-${issue.issueId}`);
    node.style = `border-bottom: 2px solid ${issue.sidebarCategory?.color}`;

    if (issue.length === 1) {
      node.classList.add('single-letter');
    }

    node.setAttribute('data-id', issue.issueId);
    node.setAttribute('data-category', TextHighlightFormat.getIssueCategoryId(issue));

    const dataIds = node.getAttribute('data-value') ? node.getAttribute('data-value').split(',') : [];
    dataIds.push(`${issue.issueId}`);
    node.setAttribute('data-value', dataIds.join(','));
  }

  static getIssueCategoryId(issue) {
    if (!issue) {
      return '';
    }

    if (IssueType.DICTIONARY !== issue.issueType &&
      ![IssueCategory.Claim, IssueCategory.Quote].includes(issue.category)) {
      return issue.sidebarCategory ? issue.sidebarCategory?.id.toString() : '';
    }

    return issue.category;
  }

  static isIssueUseful(value) {
    return !!value;
  }
}
