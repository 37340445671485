import React from 'react';
import cx from 'clsx';
import type { IIssuePlagiarismMatches } from '@writercolab/types';
import { PLAGIARISM_MIN_MATCH_PERCENT } from '@writercolab/ui-sidebar-models';
import styles from './styles.module.css';
import { PLAGIARISM_DEFAULT_SIMILARITY, PLAGIARISM_MATCHES_LIMIT } from './constants';

interface IPlagiarismProps {
  matches?: Array<IIssuePlagiarismMatches>;
}

export const Plagiarism: React.FC<IPlagiarismProps> = ({ matches }) => {
  if (!matches) return null;

  return (
    <div className={cx(styles.plagiarismContainer)}>
      {matches
        .filter(match => match.similarity >= PLAGIARISM_MIN_MATCH_PERCENT)
        .slice(0, PLAGIARISM_MATCHES_LIMIT)
        .sort((a, b) => b.similarity - a.similarity)
        .map((match, index) => (
          <div key={`${match.url}-${index}`} className={styles.matchContainer}>
            <p className={styles.matchPercentHolder}>
              <span className={styles.matchPercentHighlight}>{match.similarity || PLAGIARISM_DEFAULT_SIMILARITY}%</span>{' '}
              match
            </p>
            <p className={styles.matchDescription} title={match.title}>
              {match.title}
            </p>
            <p className={styles.matchLinkHolder}>
              <a className={styles.matchLink} href={match.url} target="_blank" title={match.url}>
                {match.url}
              </a>
            </p>
          </div>
        ))}
    </div>
  );
};
