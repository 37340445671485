import { createAtomSubscriber } from '@writercolab/mobx';
import { E_INTEGRATION_TYPE, IssuesPipeline, SidebarViewMode } from '@writercolab/types';
import { autorun } from 'mobx';
import type { IIssuesModelParams, SidebarModel } from '@writercolab/ui-sidebar-models';
import { UICardsPanelModel } from '../CardsPanel';
import { UICategoriesPanelModel } from '../CategoriesPanel';
import { UIIssuesListModel } from '../IssuesList';
import { UIVisibleIssuesListModel } from '../VisibleIssuesList';
import { UIDocumentStatsModel } from '../DocumentStats';

export interface UISidebarModelOptions {
  model: SidebarModel;
  modelUICardsPanelModel?: UICardsPanelModel;
  modelUICategoriesPanelModel?: UICategoriesPanelModel;
  modelUIDocumentStats?: UIDocumentStatsModel;
  modelUIIssuesListModel?: UIIssuesListModel;
  modelVisibleIssuesList?: UIVisibleIssuesListModel;
}

export class UISidebarModel {
  readonly model: SidebarModel;
  readonly modelUICardsPanelModel: UICardsPanelModel;
  readonly modelUICategoriesPanelModel: UICategoriesPanelModel;
  readonly modelUIDocumentStats: UIDocumentStatsModel;
  readonly modelUIIssuesListModel: UIIssuesListModel;
  readonly modelVisibleIssuesList: UIVisibleIssuesListModel;

  constructor({
    model,
    modelUICardsPanelModel,
    modelUICategoriesPanelModel,
    modelUIDocumentStats,
    modelUIIssuesListModel,
    modelVisibleIssuesList,
  }: UISidebarModelOptions) {
    this.modelUICategoriesPanelModel = modelUICategoriesPanelModel ?? new UICategoriesPanelModel(model);
    this.modelVisibleIssuesList =
      modelVisibleIssuesList ??
      new UIVisibleIssuesListModel({
        sidebarModel: model,
      });
    this.modelUIIssuesListModel =
      modelUIIssuesListModel ??
      new UIIssuesListModel({
        model,
        modelVisibleIssuesList: this.modelVisibleIssuesList,
        analytics: model.analytics,
      });
    this.modelUICardsPanelModel =
      modelUICardsPanelModel ??
      new UICardsPanelModel({
        model,
        modelUICardsPanelModel: this.modelUIIssuesListModel,
      });
    this.modelUIDocumentStats = modelUIDocumentStats ?? new UIDocumentStatsModel({ model });
    this.model = model;
  }

  get isScoreHidden() {
    const { sidebarState, documentStats } = this.model;

    return sidebarState.isEmptyState || !documentStats.score.available;
  }

  get isPreviewMode() {
    return this.model.sidebarState.isPreviewMode;
  }

  readonly onSidebarModeChange = () => {
    this.model.eventBus.emit('onSidebarModeChange', SidebarViewMode.OPEN);
  };

  readonly onSidebarContainerResize = (width: number) => {
    this.model.size.setSidebarWidth(width);
  };

  get isLoading() {
    return this.model.sidebarState.isLoading;
  }

  get scoreValue() {
    return this.model.documentStats.score.value;
  }

  get isMedium() {
    return this.model.size.isMedium;
  }

  get isSmall() {
    return this.model.size.isSmall;
  }

  get isStandard() {
    return this.model.size.isStandard;
  }

  get isStatsCollapsed() {
    return ([E_INTEGRATION_TYPE.enum.WORD_PLUGIN, E_INTEGRATION_TYPE.enum.OUTLOOK_PLUGIN] as string[]).includes(
      this.model.integrationType,
    );
  }

  get statsAreVisible() {
    return this.model.sidebarState.statsAreVisible;
  }

  useReactiveData() {
    this.atom.reportObserved();

    return this;
  }

  private readonly atom = createAtomSubscriber('atom', () =>
    autorun(() => {
      const { $initialPipeline, collaboration, setPipeline, sidebarState, categories, documentId } = this.model;

      if (!documentId) {
        return;
      }

      if ($initialPipeline.status === 'pending') {
        return;
      }

      if (collaboration.isCollaborative) {
        setPipeline(IssuesPipeline.FULL);

        return;
      }

      if (sidebarState.enforcedPipeline) {
        setPipeline(sidebarState.enforcedPipeline);

        return;
      }

      if (categories?.isPlagiarismCategorySelected) {
        setPipeline(IssuesPipeline.PLAGIARISM);
      } else {
        setPipeline(IssuesPipeline.BASIC);
      }
    }),
  );
}
