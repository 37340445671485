import { UserAccountStatus, type IUserProfile } from '@writercolab/types';
import { LocalStorageKey } from '../services/localStorage/localStorageService';
import type { IOrganization } from '../types';

export const isUserOrgCreator = (org: IOrganization, profile: IUserProfile): boolean =>
  org.createdUserId === profile.id;

export const setUserLastVisitOrgId = (userProfileId: number, orgId: number, organizations: IOrganization[] | null) => {
  if (organizations && organizations.find(org => org.id === orgId)) {
    const lastSelectedOrgIds = localStorage.getItem(LocalStorageKey.lastSelectedOrgIds);

    if (lastSelectedOrgIds) {
      const entries = JSON.parse(lastSelectedOrgIds);
      const existing = entries.find(entry => entry.userId === userProfileId);

      if (existing) {
        existing.orgId = orgId;
      } else {
        entries.push({ userId: userProfileId, orgId });
      }

      localStorage.setItem(LocalStorageKey.lastSelectedOrgIds, JSON.stringify(entries));
    } else {
      localStorage.setItem(LocalStorageKey.lastSelectedOrgIds, JSON.stringify([{ userId: userProfileId, orgId }]));
    }
  }
};

export const getUserLastVisitOrgId = (userProfileId: number, organizations: IOrganization[] | null): number | null => {
  let selectedOrgId = null;
  const lastSelectedOrgIds = localStorage.getItem(LocalStorageKey.lastSelectedOrgIds);

  if (lastSelectedOrgIds) {
    try {
      const lastSelectedOrg = JSON.parse(lastSelectedOrgIds).find(entry => entry.userId === userProfileId);

      if (lastSelectedOrg?.orgId) {
        selectedOrgId = lastSelectedOrg.orgId;

        if (!organizations || !organizations.find(org => org.id === selectedOrgId)) {
          return null;
        }
      }
    } catch {
      return selectedOrgId;
    }
  }

  return selectedOrgId;
};

export const isUserEmailUnconfirmed = (userProfile?: IUserProfile | null): boolean | undefined =>
  !userProfile ? undefined : userProfile.accountStatus === UserAccountStatus.INVITED;

export const isUserInvited = (userProfile: IUserProfile): boolean =>
  userProfile.invited && userProfile.accountStatus === UserAccountStatus.INVITED;
