
const digest = 'bea885d094d212e7f91f0b01bd0b9d21a5588b99fcd4cfbc71f40118ad53360e';
const css = `.styles-module__yT8LGis {
  word-break: break-word;
}

.styles-module__Xag0n96 {
  position: absolute;
  left: 0;
  width: 100%;
  height: 26px;
}
`;

export default {"correctionLabel":"styles-module__yT8LGis","correctionLabelShadow":"styles-module__Xag0n96"};
export { css, digest };
  