
const digest = '2b8dd35600b648ef1c219e37e8c6dc0d31db36bb9236283f17321da5d6bd7523';
const css = `.styles-module__nEBAeVR {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  user-select: none;
}

.styles-module__nEBAeVR:hover {
  text-decoration: none;
}

.styles-module__cgorCSO {
  padding: 0 0 2px;
}

.styles-module__EVydqoB {
}

.styles-module__eqIkuJw {
  padding: 0 14px;
}

.styles-module__Tj-vaQv {
  min-width: 120px;
}

.styles-module__9gUExhk {
  display: flex;
  margin-right: 15px;
  flex-shrink: 0;
}

.styles-module__VzDMPXu {
  display: flex;
  align-items: center;
  flex: 1;
}

.styles-module__8mEqvxz {
  display: flex;
  flex-shrink: 0;
}

.styles-module__LC0XZ1m::before {
  position: absolute;
  z-index: 0;
  left: 19px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: var(--writer-blue-5);
  content: '';
}

.styles-module__kcUo27T {
  position: relative;
  top: -3px;
  margin: 0;
}

.styles-module__mn0tI0K {
  display: flex;
  max-width: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.styles-module__ij6vb-T {
  min-width: 113px;
}

.styles-module__mn0tI0K p {
  padding: 0 3px;
  margin-left: 1px;
}

.styles-module__CBTkgHT {
  border-radius: 4px;
}

.styles-module__CBTkgHT .styles-module__9gUExhk {
  cursor: pointer;
}

.styles-module__CBTkgHT:hover {
  cursor: pointer;
}

.styles-module__CBTkgHT:hover p {
  cursor: pointer;
}

/* Blue Hover */
.styles-module__DO55Hvd:hover {
  background: var(--writer-blue-1) !important;
}

.styles-module__DO55Hvd.styles-module__-eWkqLz {
  background: var(--writer-blue-2) !important;
}

.styles-module__DO55Hvd.styles-module__-eWkqLz:hover .styles-module__mn0tI0K p {
  color: var(--classic-black) !important;
}

/* Dark Blue Hover */
.styles-module__9Opx1F0:hover {
  background: var(--writer-blue-2) !important;
}

.styles-module__9Opx1F0.styles-module__-eWkqLz {
  background: var(--writer-blue-3) !important;
}

.styles-module__9Opx1F0.styles-module__-eWkqLz .styles-module__mn0tI0K p {
  color: var(--classic-black) !important;
}

.styles-module__9Opx1F0.styles-module__-eWkqLz:hover .styles-module__mn0tI0K p {
  color: var(--classic-black) !important;
}

/* Transparent Blue Hover */
.styles-module__-BYeULa:hover .styles-module__mn0tI0K p,
.styles-module__-BYeULa.styles-module__-eWkqLz .styles-module__mn0tI0K p {
  color: var(--writer-blue-5) !important;
}

.styles-module__-BYeULa:hover .styles-module__9gUExhk svg path {
  fill: var(--writer-blue-5) !important;
}

/* Grey Hover */
.styles-module__4mvSIwQ:hover {
  background: var(--classic-grey-1) !important;
}

.styles-module__4mvSIwQ.styles-module__-eWkqLz {
  background: var(--writer-graynu-2);
}

.styles-module__4mvSIwQ.styles-module__-eWkqLz:hover .styles-module__mn0tI0K p {
  color: var(--classic-black) !important;
}

.styles-module__96Tl97e {
  opacity: 0.2;
}

.styles-module__96Tl97e:hover {
  background: transparent !important;
}

.styles-module__rf0MBmu {
}

.styles-module__rf0MBmu .styles-module__mn0tI0K {
}

.styles-module__rf0MBmu .styles-module__mn0tI0K:hover {
  cursor: default;
}
`;

export default {"container":"styles-module__nEBAeVR","containerIconSmall":"styles-module__cgorCSO","containerLabelEmpty":"styles-module__EVydqoB","tooltipTitle":"styles-module__eqIkuJw","lockReasonTag":"styles-module__Tj-vaQv","containerIcon":"styles-module__9gUExhk","iconDescription":"styles-module__VzDMPXu","containerTrailingIcon":"styles-module__8mEqvxz","iconIndicator":"styles-module__LC0XZ1m","containerLockIcon":"styles-module__kcUo27T","containerDescription":"styles-module__mn0tI0K","containerCtaButton":"styles-module__ij6vb-T","hover":"styles-module__CBTkgHT","hoverBlue":"styles-module__DO55Hvd","active":"styles-module__-eWkqLz","hoverDarkBlue":"styles-module__9Opx1F0","hoverTransparentBlue":"styles-module__-BYeULa","hoverGrey":"styles-module__4mvSIwQ","disabled":"styles-module__96Tl97e","locked":"styles-module__rf0MBmu"};
export { css, digest };
  