import type { IComputedValue } from 'mobx';
import { computed } from 'mobx';
import { getLogger } from '@writercolab/utils';
import { BaseService } from './BaseService';
import { SUBSCRIPTION } from './const';
import { Subscriber } from '../Subscriber';

const logger = getLogger('Service', '@writercolab/mobx');

export abstract class ConstService<
  TContext extends Record<string, BaseService | undefined> = never,
  TContextNames extends keyof TContext = never,
> extends BaseService<TContext, TContextNames> {
  static accessor<T extends ConstService>(service: T): IComputedValue<T> {
    const subscriber = new Subscriber({
      getId: () => true,
      subscribe: () => {
        logger.debug(this.name, 'start');
        const cancel = service[SUBSCRIPTION]();

        return () => {
          logger.debug(this.name, 'stop');
          cancel();
        };
      },
    });

    return computed(() => {
      // eslint-disable-next-line no-unused-expressions
      subscriber.data;

      return service;
    });
  }
}
