import React from 'react';
import { IssueCardTypes } from '@writercolab/types';
import { Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';
import { IssueLabel, TextDiff } from '@writercolab/ui-molecules';
import styles from './styles.module.css';

import { Correction } from '../Correction';
import { rswitch } from '../../../utils/rswitch';

interface IIssueHolderProps {
  cardType: IssueCardTypes;
  header?: string;
  highlight: string;
  subHighlight?: string;
  showDiff?: boolean;
  correction: string[];
  onApplySuggestion: (replacement: string) => Promise<unknown>;
}

const IssueHolder$ = ({
  cardType,
  header,
  showDiff,
  highlight,
  subHighlight,
  correction,
  onApplySuggestion,
}: IIssueHolderProps) => (
  <div className={styles.issueHolder}>
    {rswitch(cardType, {
      [IssueCardTypes.ACCEPT_ALL_CHANGES]: (
        <IssueLabel className={styles.acceptAllChangesLabel} style={{ marginTop: 7 }}>
          <div>
            <Text style={{ marginBottom: 4 }} variant={TextSize.XL} bold>
              {header}
            </Text>
          </div>
          {!!correction.length && <Icon name={IconVariant.ARROW_RIGHT_ALT} />}
          {/* eslint-disable-next-line no-nested-ternary */}
          {correction ? (
            showDiff ? (
              <TextDiff className={styles.textDiff} firstString={highlight} secondString={correction[0]} />
            ) : (
              correction[0]
            )
          ) : (
            highlight
          )}
        </IssueLabel>
      ),
      [IssueCardTypes.CORRECTION]: (
        <Correction highlight={highlight} correction={correction} onApplySuggestion={onApplySuggestion} />
      ),
      [IssueCardTypes.STRIKETHROUGH_HIGHLIGHT]: (
        <IssueLabel error strikethrough style={{ marginTop: 7 }} onClick={() => onApplySuggestion('')}>
          {highlight}
        </IssueLabel>
      ),
      default: (
        <IssueLabel style={{ marginTop: 7 }}>
          {header || highlight} <span className={styles.subHighlight}>{subHighlight}</span>
        </IssueLabel>
      ),
    })}
  </div>
);

export const IssueHolder = React.memo(IssueHolder$);
