import type { DocumentStatsModel, DocumentStatTypes, SidebarModel } from '@writercolab/ui-sidebar-models';

export type TDocumentStatsModel = Pick<
  DocumentStatsModel,
  | 'contentMetrics'
  | 'readingTimeReadable'
  | 'fleschKincaidScoreValueReadable'
  | 'isFleschKincaidScoreAvailable'
  | 'gradeColor'
  | 'avgSentenceLength'
>;

export class UIDocumentStatsModel {
  private readonly documentStats: TDocumentStatsModel;
  public readonly defaultOption!: typeof DocumentStatTypes.type | undefined;
  public readonly onDocumentStatOptionChange!: SidebarModel['onDocumentStatOptionChange'];

  constructor(
    opts:
      | { model: SidebarModel }
      | {
          documentStats: TDocumentStatsModel;
          getDefaultStatItem: () => typeof DocumentStatTypes.type | undefined;
          onDocumentStatOptionChange: SidebarModel['onDocumentStatOptionChange'];
        },
  ) {
    this.documentStats = 'model' in opts ? opts.model.documentStats : opts.documentStats;

    Object.defineProperties(this, {
      defaultOption: {
        enumerable: true,
        configurable: true,
        get: () => ('model' in opts ? opts.model?.defaultStatItem : opts.getDefaultStatItem()),
      },
      onDocumentStatOptionChange: {
        enumerable: true,
        configurable: true,
        get: () => ('model' in opts ? opts.model.onDocumentStatOptionChange : opts.onDocumentStatOptionChange),
      },
    });
  }

  get characterCount() {
    return this.documentStats.contentMetrics?.characterCount ?? 0;
  }

  get wordCount() {
    return this.documentStats.contentMetrics?.wordCount ?? 0;
  }

  get avgSentenceLength() {
    return this.documentStats.avgSentenceLength;
  }

  get readingTimeReadable() {
    return this.documentStats.readingTimeReadable;
  }

  get fleschKincaidScoreValueReadable() {
    return this.documentStats.fleschKincaidScoreValueReadable;
  }

  get isFleschKincaidScoreAvailable() {
    return this.documentStats.isFleschKincaidScoreAvailable;
  }

  get gradeColor() {
    return this.documentStats.gradeColor;
  }
}
