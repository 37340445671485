import { BaseError } from '@writercolab/errors';
import { getLogger } from './logger';

const logger = getLogger('lock', '@writercolab/utils');

/**
 * The type TMaybeArray represents a value that can either be a single instance of type T or an array of instances of type T.
 * @template T - The type of value that can either be a single instance or an array of instances.
 */
type TMaybeArray<T> = T | T[];

/**
 * Options for creating a lock function.
 */
export interface ILockOptions {
  /** A callback that will be called when a lock is acquired. */
  onStart?(counter: number): void;

  /** A callback that will be called when a lock is released. */
  onEnd?(counter: number, data: unknown | BaseError): void;

  /** A factory function that can be used to create a custom locker object. */
  buildLocker?(): {
    get(): number;
    set(val: number): void;
  };

  /** A function that determines whether a lock can be acquired. */
  access?(locker: number): boolean;

  /** A name for the lock. */
  name?: string;

  /** A list of dependencies that must be locked before this lock can be acquired. */
  depLocks?: TMaybeArray<(exec: () => Promise<void>) => Promise<void>>;
}

/** A counter for generating unique lock names. */
let ID = 0;

/**
 * Creates a new lock function.
 * @param opts - The options for the lock function.
 * @returns A function that can be used to lock access to a critical section of code.
 */
export function lock(opts?: ILockOptions) {
  const name = opts?.name ?? `lock-${ID++}`;
  logger.debug(`build lock: ${name}`);
  const locker =
    opts?.buildLocker?.() ??
    (() => {
      let counter = 0;

      return {
        get: () => counter,
        set: (val: number) => {
          counter = val;
        },
      };
    })();

  const mutateLock = (acquire: boolean) => {
    logger.debug(`${name} ${acquire ? 'acquire' : 'release'} lock`);
    const val = locker.get();
    const nextVal = val + (acquire ? 1 : -1);
    locker.set(nextVal);

    return nextVal;
  };

  const tryLock = () => {
    const counter = locker.get();
    const hasAccess = opts?.access ? opts.access(counter) : counter === 0;

    if (!hasAccess) {
      logger.warn(`${name} - access denied`);
      throw new BaseError(`Access denied: ${counter} (${name})`);
    }

    return mutateLock(true);
  };

  // eslint-disable-next-line no-nested-ternary
  const locks = opts?.depLocks ? (Array.isArray(opts.depLocks) ? opts.depLocks : [opts.depLocks]) : [];

  const runLocks = () => {
    if (!locks.length) {
      return () => {
        // pass
      };
    }

    let fn: () => void | undefined;

    const defer = new Promise<void>(resolve => {
      fn = resolve;
    });

    locks.forEach(cb => cb(() => defer));

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return () => fn!();
  };

  return async <T>(exec: () => T | Promise<T>) => {
    const lockCounter = tryLock();
    const cancel = runLocks();

    try {
      opts?.onStart?.(lockCounter);
      const ret = await exec();
      const unlockCounter = mutateLock(false);
      opts?.onEnd?.(unlockCounter, ret);
      cancel();

      return ret;
    } catch (err) {
      logger.error(`${name}`, err);
      const error = new BaseError(err);

      const unlockCounter = mutateLock(false);
      opts?.onEnd?.(unlockCounter, error);
      cancel();

      throw error;
    }
  };
}
