import { createAtomSubscriber, Subscriber } from '@writercolab/mobx';
import type { IIssue } from '@writercolab/types';
import { createFactory, getLogger } from '@writercolab/utils';
import { computed, makeObservable, comparer, reaction } from 'mobx';
import type { Virtualizer } from '@tanstack/react-virtual';
import type { SidebarModel } from '@writercolab/ui-sidebar-models';
import { UIIssueCardModel } from '../IssueCard/UIIssueCardModel';

const logger = getLogger('UIVisibleIssuesListModel', 'ui-sidebar');

type TVirtualizer = Pick<Virtualizer<HTMLDivElement, Element>, 'scrollToIndex'>;

export interface IUIVisibleIssuesListModelOptions {
  sidebarModel: SidebarModel;
}

export class UIVisibleIssuesListModel {
  private readonly sidebarModel: SidebarModel;

  constructor({ sidebarModel }: IUIVisibleIssuesListModelOptions) {
    this.sidebarModel = sidebarModel;
    logger.debug('constructor');

    makeObservable(this, {
      list: computed({ equals: comparer.shallow }),
      scrollNoticedMarkers: computed.struct,
    });
  }

  /**
   * Gets the list of visible issues in the sidebar.
   * @returns An array of items created using the factory.
   */
  get list() {
    return this.sidebarModel.issues?.visibleSidebarIssues.map(item => this.factory.create(item));
  }

  /**
   * Returns an object indicating whether there are previous or next scroll noticed markers.
   *
   * @returns An object with properties `hasPrev` and `hasNext` indicating the presence of previous and next scroll noticed markers respectively.
   */
  get scrollNoticedMarkers() {
    const { issues } = this.sidebarModel;

    if (!issues) {
      return {
        hasPrev: false,
        hasNext: false,
      };
    }

    const { visibleSidebarIssues, currentSidebarIssues } = issues;

    if (visibleSidebarIssues === currentSidebarIssues || !currentSidebarIssues.length) {
      return {
        hasPrev: false,
        hasNext: false,
      };
    }

    if (!visibleSidebarIssues.length) {
      return {
        hasPrev: true,
        hasNext: true,
      };
    }

    // We need grab first and last issue ids from the current sidebar issues
    // and check if they are in the visible issues map
    // visibleIssuesMap - contains all issues that are visible in the content editor + 2 pages down
    const firstIssue = currentSidebarIssues[0];
    const lastIssue = currentSidebarIssues[currentSidebarIssues.length - 1];

    const firstVIssue = visibleSidebarIssues[0];
    const lastVIssue = visibleSidebarIssues[visibleSidebarIssues.length - 1];

    /*
    r:  first       last
    v:    first last
    */

    const isVFirst = firstIssue.from === firstVIssue.from;
    const isVLast = lastVIssue.until === lastIssue.until;

    return {
      hasPrev: !isVFirst,
      hasNext: !isVLast,
    };
  }

  /**
   * Sets the virtualizer for the visible issues list and reports that the observable has been observed.
   * @param virtualizer The virtualizer to be set for the visible issues list.
   */
  readonly useController = (virtualizer?: TVirtualizer) => {
    this.atom.reportObserved();
    this.virtualizer = virtualizer;
  };

  private virtualizer: TVirtualizer | undefined;

  private readonly factory = createFactory<UIIssueCardModel, IIssue>({
    create: issue => new UIIssueCardModel(issue, this.sidebarModel),
    recreate: (inst, params) => inst.recreate(params),
  });

  private atom = createAtomSubscriber('atomScroller', () =>
    reaction(
      () => {
        const { issues } = this.sidebarModel;

        if (!issues) {
          return { issueId: undefined, index: -1 };
        }

        const { visibleSidebarIssues, selectedIssue } = issues;

        const issueId = selectedIssue?.issueId;
        const index = issueId ? visibleSidebarIssues.findIndex(item => item.issueId === issueId) : -1;

        return { issueId, index };
      },
      ({ index, issueId }, { issueId: pIssueId }) => {
        if (index < 0) {
          return;
        }

        logger.debug('scrollToIndex', index, issueId);
        const { virtualizer } = this;

        if (virtualizer) {
          virtualizer.scrollToIndex(index, { align: 'center', behavior: issueId === pIssueId ? 'auto' : 'smooth' });

          // EXT-3147 we use internal api for recalculation during scrolling
          if ('notify' in virtualizer && virtualizer.notify instanceof Function) {
            try {
              virtualizer.notify(false, false);
            } catch {
              // pass
            }
          }
        }
      },
      {
        name: 'atomScroller',
        equals: comparer.structural,
        delay: 100,
      },
    ),
  );
}
