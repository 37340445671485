import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export type TGetDraftsPaginatedResponse =
  components['schemas']['model_PaginatedResult_template_dto_FindDraftWithUserResponse'];

export type TGetDraftsResponse = components['schemas']['template_dto_FindDraftWithUserResponse'];

export type TGetDraftsPaginatedRequest = components['schemas']['template_dto_FindDraftsRequest'];

export type TDraftDetailsResponse = components['schemas']['template_dto_DraftResponse'];

export type TDraftsExportQueryParams = {
  search?: string;
  documentId?: string;
  templateId?: string;
  sortField?: 'creationTime' | 'title' | 'body';
  sortOrder?: 'asc' | 'desc';
  draftIds?: number[];
};

export type TGetDraftQueryParams = {
  search: string;
  documentId: string;
  templateIds: string[];
  sortField?: 'creationTime' | 'title' | 'body';
  sortOrder?: 'asc' | 'desc';
  offset: number;
  limit: number;
};

export type TDraftMediaFileResponse = components['schemas']['media_dto_MediaFileResponse'];

export const EDraftDownloadFormats = new Enum('csv', 'xlsx');

export type TDraftDownloadFormats = typeof EDraftDownloadFormats.type;

export const EDraftTemplateMediaActionId = new Enum('downloadFile', 'openMediaURL');

export type TDraftTemplateMediaActionId = typeof EDraftTemplateMediaActionId.type;

export const EDraftInputKnownNames = new Enum('eventName', 'participants', 'fileId');

export type TDraftInputKnownNames = typeof EDraftInputKnownNames.type;

export interface IDraftInput {
  name: TDraftInputKnownNames;
  value: string[];
}

export interface IDraftDetailsWithInputs extends TDraftDetailsResponse {
  inputs: IDraftInput[];
  media?: TDraftMediaFileResponse;
  voiceName?: string | null;
  reWritePromptName?: string | null;
}
