
const digest = 'c56d030603ec45adeac30d4a20dcdf26470a715e8d9fbf835591ad48544e5440';
const css = `.styles-module__LA7sxCR {
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
  padding-top: 12px;
}

.styles-module__hnVvERS {
  padding-left: 10px !important;
}

.styles-module__SHKMyp7 path {
  fill: var(--classic-grey-3);
}

.styles-module__qbSWMJn {
  position: absolute;
  left: -17px;
}

.styles-module__qbSWMJn path {
  fill: var(--classic-grey-3);
}
`;

export default {"container":"styles-module__LA7sxCR","headerMini":"styles-module__hnVvERS","headerIcon":"styles-module__SHKMyp7","snippetsCardHeaderIcon":"styles-module__qbSWMJn"};
export { css, digest };
  