export interface ISnippetTag {
  tag: string;
}

// snippet skeleton for create action
export interface ISnippetCreate {
  id?: string;
  reference?: string;
  shortcut?: string;
  description?: string;
  snippet: string;
  tags?: ISnippetTag[];
}

export const SNIPPETS_LOAD_LIMIT = 50;
export const SNIPPET_SHORTCUT_PREFIX = 'w.';
