import { secondsFromNow } from './secondsFromNow';

export const COLLABORATION_ACTIVE_SECONDS = 30;
export const COLLABORATION_LOCK_SECONDS = 5;

export const viewerIsTyping = (lastTyping?: number) =>
  !!lastTyping && secondsFromNow(lastTyping) <= COLLABORATION_LOCK_SECONDS;

export const viewerIsActive = (lastViewed?: number) =>
  !!lastViewed && secondsFromNow(lastViewed) <= COLLABORATION_ACTIVE_SECONDS;
