import { CategoryType, type TAnalyticsActivityTypeBuilder } from '@writercolab/types';

export const fromParamConfig = {
  [CategoryType.COMPLIANCE]: 'sidebar_compliance',
  [CategoryType.PLAGIARISM]: 'sidebar_plagiarism',
  [CategoryType.CLARITY]: 'sidebar_clarity',
  [CategoryType.INCLUSIVITY]: 'sidebar_inclusivity',
  [CategoryType.STYLE]: 'sidebar_style',
  [CategoryType.TERMS]: 'sidebar_terms',
  [CategoryType.DELIVERY]: 'sidebar_delivery',
} as const;

export const UISidebarAnalyticsActivity = {
  startTeamTrial: '[Billing v2] Clicked Start Team Trial', // when user clicks Start Team Trial
  purchasePro: '[Billing v2] Clicked Purchase Pro Now', // when user clicks Purchase pro
} as const;

export type TUISidebarAnalyticsParams = TAnalyticsActivityTypeBuilder<
  typeof UISidebarAnalyticsActivity,
  {
    [UISidebarAnalyticsActivity.startTeamTrial]: {
      clicked_from: (typeof fromParamConfig)[keyof typeof fromParamConfig];
    };
    [UISidebarAnalyticsActivity.purchasePro]: {
      clicked_from: (typeof fromParamConfig)[keyof typeof fromParamConfig];
    };
  }
>;
