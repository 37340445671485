import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ButtonTypes,
  DotLoader,
  Dropdown,
  Icon,
  IconVariant,
  Logo,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';
import { BillingProduct } from '@writercolab/common-utils';

import { changeTeam, changeOrg } from "../../redux/auth/actions";
import { findObject, filterTeams, findTeam } from "../../utils/utils";
import API from "../../utils/api";
import { languageSelectOptions } from "./languagesList";

import purplePaint from "../../assets/images/purplePaint.png";
import styles from "./style.module.scss";

const SignOutModal = ({
  dispatch,
  logOut,
  currentWorkspace,
  workspaces = [],
  currentOrganization,
  organizations = [],
  changeModal,
  documentId,
  personaId,
  subscription,
}) => {
  const [choosenTeam, setTeam] = useState(currentWorkspace?.id);
  const [choosenOrg, setOrg] = useState(currentOrganization.id);
  const [languageCode, setCurrentLanguageCode] = useState(undefined);
  const [isLoadingTeams, setIsLoadingTeams] = useState(false);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);
  const [isFreeOrProUser, setIsFreeOrProUser] = useState(undefined);
  const axiosAbortControllerSubscriptionRef = useRef();

  useEffect(() => {
    setTeam(currentWorkspace?.id);
  }, [currentWorkspace?.id]);

  useEffect(() => {
    setIsLoadingTeams(false);
  }, [workspaces]);

  const languagesList = useMemo(
    () => languageSelectOptions.map(option => ({ ...option, active: option.id === languageCode })),
    [languageCode],
  );

  const onLanguageChange = useCallback(
    async (id) => {
      setCurrentLanguageCode(id);
      await API.updateLanguage(id, currentOrganization.id, currentWorkspace?.id);
      await API.refreshAnalyzedContent(currentOrganization.id, currentWorkspace?.id, personaId, documentId);
    },
    [currentOrganization.id, currentWorkspace?.id, personaId, documentId],
  );

  const { selectedLanguageName, selectedLanguageClassName } = useMemo(() => {
    const language = languageSelectOptions.find(option => option.id === languageCode);
    const selectedLanguageName = language?.name;
    const selectedLanguageClassName = language?.className;

    return { selectedLanguageName, selectedLanguageClassName };
  }, [languageCode]);

  const logoLink = useMemo(() => (
    `https://${process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_HOST : window.env.REACT_APP_API_HOST}/organization/${currentOrganization.id}/team/${currentWorkspace?.id}`
  ), [currentOrganization.id, currentWorkspace?.id]);

  const organizationTeams = filterTeams(workspaces, choosenOrg);

  const onOrgsItemSelect = (id) => {
    if (+id !== +currentOrganization.id) {
      setIsLoadingTeams(true);
      setCurrentLanguageCode(undefined);
      const formattedId = +id;
      setOrg(formattedId);
      dispatch(changeOrg(findObject(organizations, formattedId)));
      setTeam('');
    }
  };

  useEffect(() => {
    if (!subscription) return;
    setIsLoadingSubscription(true);
    axiosAbortControllerSubscriptionRef.current?.abort();
    axiosAbortControllerSubscriptionRef.current = new AbortController();
    API.getSubscription(currentOrganization.id, { signal: axiosAbortControllerSubscriptionRef.current.signal })
      .then((res) => {
        const productName = res.data.productName;
        setIsFreeOrProUser(productName === BillingProduct.FREE || productName === BillingProduct.PRO);
      })
      .catch(() => {
        setIsFreeOrProUser(true);
      })
      .finally(() => {
        setIsLoadingSubscription(false);
      });

    API.fetchWorkspacesNew(currentOrganization.id)
      .then(({ data }) => {
        const teamInfo = data.find(d => String(d.id) === String(currentWorkspace?.id));

        API.getSuggestionSettings(currentOrganization.id, teamInfo.styleguide.styleguideId)
          .then((res) => {
            const categories = res.data?.categories;
            const languageCategoryValue = categories?.find(category => category.category === 'spell-checker')?.meta?.language;
            setCurrentLanguageCode(languageCategoryValue || 'en-us');
          })
          .catch(() => setCurrentLanguageCode('en-us'));
      })
      .catch(() => setCurrentLanguageCode('en-us'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace?.id, currentOrganization.id]);

  const onTeamsItemSelect = (id) => {
    const formattedId = +id;
    setTeam(formattedId);
    dispatch(changeTeam(findTeam(workspaces, formattedId)));
  };

  const LanguageDropdownTrigger = ({ languageName, languageClassName }) => (
    <div className={`${styles.languageSelectTrigger} ${styles.triggerFlag} ${languageClassName}`}>
      <div>
        <Text color={TextColor.BLACK} variant={TextSize.S}>
          {languageName}
        </Text>
      </div>
      <div className={styles.selectTriggerIcon}>
        <Icon name={IconVariant.ARROW_DOWN} />
      </div>
    </div>
  );

  const orgOptions = useMemo(() => organizations.map(org => ({
    ...org,
    active: org.id === choosenOrg,
  })) || [], [organizations, choosenOrg]);

  const teamOptions = useMemo(() => organizationTeams?.map(team => ({
    ...team,
    active: team.id === choosenTeam,
  })) || [], [organizationTeams, choosenTeam]);

  const showLanguagePicker = !!isFreeOrProUser && !!subscription;
  const showOrgPicker = (!isFreeOrProUser || organizations.length > 1) && !!subscription;

  const isLoadingSelectors = isFreeOrProUser === undefined && !!subscription;

  return (
    <React.Fragment>
      <div className={styles.wrapper__background} onClick={changeModal} />
      <div data-aos="fade-up" className={styles.wrapper__modal}>
        <div className={styles.modal__top}>
          <a target="_blank" rel="noreferrer" href={logoLink}>
            <Logo />
          </a>
          <img src={purplePaint} alt="purplePaint" draggable={false} />
        </div>
        {isLoadingSelectors ? (
          <DotLoader className={styles.modal__selectorsLoading} />
        ) : (
          <div className={styles.modal__main}>
            {showOrgPicker && (
              <InputGroup
                dropdownOptions={orgOptions}
                handleChangeInput={onOrgsItemSelect}
                highlightActiveOption
                id='orgs'
                inputType={InputTypes.SELECT}
                label='ORG'
                placeholder='Select org'
                value={findObject(organizations, choosenOrg)?.name}
              />
            )}
            {!showLanguagePicker && !!subscription && (
              <InputGroup
                className={`${styles.teamOptionsList}${(!((organizationTeams || []).length) || isLoadingTeams || languageCode === undefined) ? ` ${styles.teamOptionsListHidden}` : ''}`}
                dropdownOptions={teamOptions}
                handleChangeInput={onTeamsItemSelect}
                highlightActiveOption
                id='teams'
                inputType={InputTypes.SELECT}
                label='TEAM'
                placeholder='Select team'
                value={findTeam(workspaces, choosenTeam)?.name}
              />
            )}
            {showLanguagePicker && (
              <div className={`${styles.languageOptionsList}${(isLoadingSubscription || languageCode === undefined) ? ` ${styles.languageOptionsListHidden}` : ''}`}>
                <Text variant={TextSize.XS} upperCase medium className={styles.languageLabel}>Language</Text>
                <Dropdown
                  className={styles.languageDropdown}
                  containerClassName={styles.languageDropdown}
                  triggerClassName={styles.dropdownTrigger}
                  trigger={
                    <LanguageDropdownTrigger
                      languageName={selectedLanguageName}
                      languageClassName={selectedLanguageClassName}
                    />
                  }
                  options={languagesList}
                  onPrimaryOptionClickAction={onLanguageChange}
                />
              </div>
            )}
          </div>
        )}
        <div className={styles.modal__bottom}>
          <Button type={ButtonTypes.TRANSPARENT} onClick={logOut} className={styles.signOutBtn}>Sign out</Button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    workspaces: auth.workspaces,
    currentWorkspace: auth.currentWorkspace,
    organizations: auth.organizations,
    currentOrganization: auth.currentOrganization,
    documentId: auth.documentId,
    personaId: auth.personaId,
    subscription: auth.subscription,
  };
};

export default connect(mapStateToProps)(SignOutModal);
