import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isUserEmailUnconfirmed } from "@writercolab/common-utils";
import API from "../../utils/api";
import { getUserData, updateProfileData } from "../../redux/auth/actions";

export const useEmailConfirmation = (authToken) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showEmailConfirmationLock, setShowEmailConfirmationLock] = useState(false);
  const [emailRecheckingActive, setEmailRecheckingActive] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const res = await API.profile();

      if (res?.status === 200) {
        if (isUserEmailUnconfirmed(res.data)) {
          setShowEmailConfirmationLock(true);
          setEmailRecheckingActive(true);
        }

        dispatch(updateProfileData({ ...res.data }));
      }
      setIsLoading(false);
    })();
  }, [dispatch]);

  useEffect(() => {
    if (emailRecheckingActive) {
      const intervalId = setInterval(() => {
        API.profile()
          .then((res) => {
            if (res?.status === 200) {
              if (isUserEmailUnconfirmed(res.data)) {
                setShowEmailConfirmationLock(true);
              } else {
                setShowEmailConfirmationLock(false);
                setEmailRecheckingActive(false);
                dispatch(getUserData(false, authToken));
              }

              dispatch(updateProfileData({ ...res.data }));
              setIsLoading(false);
            }
          })
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [dispatch, emailRecheckingActive]);

  return [showEmailConfirmationLock, isLoading];
};
