
const digest = '98f1ac1265b5d11e3bd648b73fd64e45d65a831861d4bb1fc7f47efcd9b095aa';
const css = `.DocumentStats-module__O3QcB98 {
  margin: 0 var(--sidebar-horizontal-spacing) 0 0;
}

.DocumentStats-module__TjI5e58 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  margin: 3px 0;
}

.DocumentStats-module__e-vkbsC {
  padding: 6px 0;
  border-top: var(--gray-border);
  border-bottom: var(--gray-border);
}

.DocumentStats-module__127K1vd {
  min-width: 34px;
  text-align: center;
}
`;

export default {"container":"DocumentStats-module__O3QcB98","statItem":"DocumentStats-module__TjI5e58","baseStatsContainer":"DocumentStats-module__e-vkbsC","value":"DocumentStats-module__127K1vd"};
export { css, digest };
  