
const digest = '284e1514569d5584d660991121555471bffe7d304ed8b85b431ebca38dcc0e32';
const css = `.Popover-module__mhWKoKc {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  user-select: none;
}

.Popover-module__Nfacs4K {
  display: flex;
  align-items: center;
}

.Popover-module__L4QBu3I {
  position: absolute;
  z-index: 99999;
  overflow: hidden auto;
  padding: 8px 0;
  border: var(--gray-border);
  border-radius: 4px;
  animation: Popover-module__j5-mVQ1 0.3s forwards;
  background: var(--classic-white);
  box-shadow: var(--menu-box-shadow);
  opacity: 0;

  &.Popover-module__sZJVo-G {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &.Popover-module__MXu3-xR {
    top: -100%;
    left: 100%;
  }

  &.Popover-module__phJBu5V {
    top: -100%;
    right: 100%;
  }

  &.Popover-module__INNWP3m {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &.Popover-module__iQR-xhI {
    bottom: 100%;
    right: 0;
  }

  &.Popover-module__SGCo4A2 {
    bottom: 100%;
    left: 0;
  }

  &.Popover-module__0qNBjYi {
    top: 100%;
    right: 0;
  }

  &.Popover-module__DmeM9kW {
    top: 100%;
    left: 0;
  }
}

.Popover-module__emHi5pV {
  width: 16px;
  text-align: center;
}

.Popover-module__0UEn7L0 {
  transform: rotate(180deg);
}

@keyframes Popover-module__j5-mVQ1 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
`;

export default {"container":"Popover-module__mhWKoKc","trigger":"Popover-module__Nfacs4K","popover":"Popover-module__L4QBu3I","fadeIn":"Popover-module__j5-mVQ1","top":"Popover-module__sZJVo-G","right":"Popover-module__MXu3-xR","left":"Popover-module__phJBu5V","bottom":"Popover-module__INNWP3m","topLeft":"Popover-module__iQR-xhI","topRight":"Popover-module__SGCo4A2","bottomLeft":"Popover-module__0qNBjYi","bottomRight":"Popover-module__DmeM9kW","icon":"Popover-module__emHi5pV","iconInverted":"Popover-module__0UEn7L0"};
export { css, digest };
  