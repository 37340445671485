import { makeObservable, computed } from 'mobx';
import type { TSubscriptionLimit } from '@writercolab/types';

import { PromisedModel } from '@writercolab/mobx';
import type { components, RequestServiceInitialize } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export const TSubscriptionLimitType = new Enum(
  'contentCheck',
  'coWrite',
  'reWrite',
  'domain',
  'transcript',
  'team',
  'user',
  'graph',
  'voice',
  'AIStudioGraph',
  'AIStudioVoice',
  'AIStudioCredit',
  'AIStudioUsage',
  'AIStudioMonthlyBudget',
  'AIStudioNotificationThreshold',
);

export interface ILimitsModelParams {
  api: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: number | undefined;
}

export class LimitsModel {
  constructor(private opts: ILimitsModelParams) {
    makeObservable(this, {
      contentCheck: computed,
      coWrite: computed,
      reWrite: computed,
      domain: computed,
      transcript: computed,
      team: computed,
      user: computed,
      graph: computed,
      voice: computed,
      aiStudioGraph: computed,
      aiStudioVoice: computed,
      aiStudioCredit: computed,
      aiStudioUsage: computed,
      aiStudioMonthlyBudget: computed,
      aiStudioNotificationThreshold: computed,
      isLoaded: computed,
    });
  }

  private readonly $subscriptionLimits: PromisedModel<TSubscriptionLimit[] | undefined> = new PromisedModel({
    name: '$subscriptionLimits',
    load: async () =>
      this.opts.api
        .get('/api/billing/organizations/{organizationId}/subscription/limit', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
            },
          },
        })
        .then(r => r.data),
  });

  private readonly $contentCheckLimit = new PromisedModel({
    name: '$contentCheckLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.contentCheck),
  });

  private readonly $coWriteLimit = new PromisedModel({
    name: '$coWriteLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.coWrite),
  });

  private readonly $reWriteLimit = new PromisedModel({
    name: '$reWriteLimit',
    load: async () => this.makeUserLimitsRequest(TSubscriptionLimitType.enum.reWrite),
  });

  private readonly $domainLimit = new PromisedModel({
    name: '$domainLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.domain),
  });

  private readonly $transcriptLimit = new PromisedModel({
    name: '$transcriptLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.transcript),
  });

  private readonly $teamLimit = new PromisedModel({
    name: '$teamLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.team),
  });

  private readonly $userLimit = new PromisedModel({
    name: '$userLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.user),
  });

  private readonly $graphLimit = new PromisedModel({
    name: '$graphLimit',
    load: async () => this.makeTeamLimitsRequest(TSubscriptionLimitType.enum.graph),
  });

  private readonly $voiceLimit = new PromisedModel({
    name: '$voiceLimit',
    load: async () => this.makeTeamLimitsRequest(TSubscriptionLimitType.enum.voice),
  });

  private readonly $aiStudioGraphLimit = new PromisedModel({
    name: '$aiStudioGraphLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.AIStudioGraph),
  });

  private readonly $aiStudioVoiceLimit = new PromisedModel({
    name: '$aiStudioVoiceLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.AIStudioVoice),
  });

  private readonly $aiStudioCreditLimit = new PromisedModel({
    name: '$aiStudioCreditLimit',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.AIStudioCredit),
  });

  private readonly $aiStudioUsage = new PromisedModel({
    name: '$aiStudioUsage',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.AIStudioUsage),
  });

  private readonly $aiStudioMonthlyBudget = new PromisedModel({
    name: '$aiStudioMonthlyBudget',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.AIStudioMonthlyBudget),
  });

  private readonly $aiStudioNotificationThreshold = new PromisedModel({
    name: '$aiStudioNotificationThreshold',
    load: async () => this.makeOrgLimitsRequest(TSubscriptionLimitType.enum.AIStudioNotificationThreshold),
  });

  get contentCheck() {
    return this.getLimit(TSubscriptionLimitType.enum.contentCheck, this.$contentCheckLimit.value);
  }

  get coWrite() {
    return this.getLimit(TSubscriptionLimitType.enum.coWrite, this.$coWriteLimit.value);
  }

  get reWrite() {
    return this.getLimit(TSubscriptionLimitType.enum.reWrite, this.$reWriteLimit.value);
  }

  get domain() {
    return this.getLimit(TSubscriptionLimitType.enum.domain, this.$domainLimit.value);
  }

  get transcript() {
    return this.getLimit(TSubscriptionLimitType.enum.transcript, this.$transcriptLimit.value);
  }

  get team() {
    return this.getLimit(TSubscriptionLimitType.enum.team, this.$teamLimit.value);
  }

  get user() {
    return this.getLimit(TSubscriptionLimitType.enum.user, this.$userLimit.value);
  }

  get graph() {
    return this.getLimit(TSubscriptionLimitType.enum.graph, this.$graphLimit.value);
  }

  get voice() {
    return this.getLimit(TSubscriptionLimitType.enum.voice, this.$voiceLimit.value);
  }

  get aiStudioGraph() {
    return this.getLimit(TSubscriptionLimitType.enum.AIStudioGraph, this.$aiStudioGraphLimit.value);
  }

  get aiStudioVoice() {
    return this.getLimit(TSubscriptionLimitType.enum.AIStudioVoice, this.$aiStudioVoiceLimit.value);
  }

  get aiStudioCredit() {
    return this.getLimit(TSubscriptionLimitType.enum.AIStudioCredit, this.$aiStudioCreditLimit.value);
  }

  get aiStudioUsage() {
    return this.getLimit(TSubscriptionLimitType.enum.AIStudioUsage, this.$aiStudioUsage.value);
  }

  get aiStudioMonthlyBudget() {
    return this.getLimit(TSubscriptionLimitType.enum.AIStudioMonthlyBudget, this.$aiStudioMonthlyBudget.value);
  }

  get aiStudioNotificationThreshold() {
    return this.getLimit(
      TSubscriptionLimitType.enum.AIStudioNotificationThreshold,
      this.$aiStudioNotificationThreshold.value,
    );
  }

  get isLoaded(): boolean {
    return !!this.$subscriptionLimits.value;
  }

  reloadSubscriptionLimits = async (): Promise<TSubscriptionLimit[] | undefined> => {
    this.$subscriptionLimits.reload();

    return this.$subscriptionLimits.promise;
  };

  private readonly H_RELOAD = TSubscriptionLimitType.hash({
    contentCheck: () => {
      this.$contentCheckLimit.reload();

      return this.$contentCheckLimit.promise;
    },
    coWrite: () => {
      this.$coWriteLimit.reload();

      return this.$coWriteLimit.promise;
    },
    reWrite: () => {
      this.$reWriteLimit.reload();

      return this.$reWriteLimit.promise;
    },
    domain: () => {
      this.$domainLimit.reload();

      return this.$domainLimit.promise;
    },
    transcript: () => {
      this.$transcriptLimit.reload();

      return this.$transcriptLimit.promise;
    },
    team: () => {
      this.$teamLimit.reload();

      return this.$teamLimit.promise;
    },
    user: () => {
      this.$userLimit.reload();

      return this.$userLimit.promise;
    },
    graph: () => {
      this.$graphLimit.reload();

      return this.$graphLimit.promise;
    },
    voice: () => {
      this.$voiceLimit.reload();

      return this.$voiceLimit.promise;
    },
    AIStudioGraph: () => {
      this.$aiStudioGraphLimit.reload();

      return this.$aiStudioGraphLimit.promise;
    },
    AIStudioVoice: () => {
      this.$aiStudioVoiceLimit.reload();

      return this.$aiStudioVoiceLimit.promise;
    },
    AIStudioCredit: () => {
      this.$aiStudioCreditLimit.reload();

      return this.$aiStudioCreditLimit.promise;
    },
    AIStudioUsage: () => {
      this.$aiStudioUsage.reload();

      return this.$aiStudioUsage.promise;
    },
    AIStudioMonthlyBudget: () => {
      this.$aiStudioMonthlyBudget.reload();

      return this.$aiStudioMonthlyBudget.promise;
    },
    AIStudioNotificationThreshold: () => {
      this.$aiStudioNotificationThreshold.reload();

      return this.$aiStudioNotificationThreshold.promise;
    },
  });

  reloadLimit = async (limitType: typeof TSubscriptionLimitType.type) => this.H_RELOAD[limitType]();

  private makeOrgLimitsRequest = async (
    creditType:
      | typeof TSubscriptionLimitType.enum.contentCheck
      | typeof TSubscriptionLimitType.enum.coWrite
      | typeof TSubscriptionLimitType.enum.domain
      | typeof TSubscriptionLimitType.enum.transcript
      | typeof TSubscriptionLimitType.enum.team
      | typeof TSubscriptionLimitType.enum.user
      | typeof TSubscriptionLimitType.enum.AIStudioGraph
      | typeof TSubscriptionLimitType.enum.AIStudioVoice
      | typeof TSubscriptionLimitType.enum.AIStudioCredit
      | typeof TSubscriptionLimitType.enum.AIStudioUsage
      | typeof TSubscriptionLimitType.enum.AIStudioMonthlyBudget
      | typeof TSubscriptionLimitType.enum.AIStudioNotificationThreshold,
  ) =>
    this.opts.api
      .get('/api/billing/organizations/{organizationId}/usage/{creditType}', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            creditType,
          },
        },
      })
      .then(r => r.data);

  private makeTeamLimitsRequest = async (
    creditType: typeof TSubscriptionLimitType.enum.graph | typeof TSubscriptionLimitType.enum.voice,
  ) => {
    const { organizationId, teamId } = this.opts;

    if (!teamId) {
      return undefined;
    }

    return this.opts.api
      .get('/api/billing/organizations/{organizationId}/team/{teamId}/usage/{creditType}', {
        params: {
          path: {
            organizationId,
            teamId,
            creditType,
          },
        },
      })
      .then(r => r.data);
  };

  private makeUserLimitsRequest = async (creditType: typeof TSubscriptionLimitType.enum.reWrite) =>
    this.opts.api
      .get('/api/billing/organizations/{organizationId}/usage/{creditType}/user', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            creditType,
          },
        },
      })
      .then(r => r.data);

  private getLimit(
    limitType: typeof TSubscriptionLimitType.type,
    usage?: components['schemas']['billing_model_UsageItem'],
  ) {
    if (!usage) {
      return undefined;
    }

    const usageForLimit = this.getUsageForLimit(limitType);
    const { value, limit } = usage;

    return {
      exceeded: value >= limit || !!usageForLimit,
      limit,
      value,
      blockedUntil: usageForLimit?.blockedUntil ?? undefined,
    };
  }

  private getUsageForLimit = (
    limitType: typeof TSubscriptionLimitType.type,
  ): components['schemas']['billing_model_ApiSubscriptionLimit'] | undefined => {
    const usageRecord = this.$subscriptionLimits.value;

    if (!usageRecord) {
      return undefined;
    }

    return usageRecord.find(l => l.type === limitType);
  };
}
