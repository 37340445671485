import { IssueCategory, IssueType } from "@writercolab/common-utils";

export const checkVisible = (top, cardRef) => {
  let isVisible = true;
  const editorHeight = window.innerHeight - 100;
  let invertHeight = 260;

  if (cardRef.current) {
    const { offsetHeight } = cardRef.current;

    if (offsetHeight < 70) {
      invertHeight = 90;
    } else if (offsetHeight < 180) {
      invertHeight = 210;
    }

    if (top + invertHeight >= editorHeight) {
      isVisible = 'invert';
    }
  }

  if (!top || top <= 50 || top >= editorHeight) {
    isVisible = false;
  }

  return isVisible;
};

export const getCardPosition = (selectedIssueId, cardRef, cardShow, isValidPosition, selectedIssue) => {
  const selectedSpan = document.querySelector(`span[data-id="${selectedIssueId}"]`);

  if (!selectedSpan || !cardShow) {
    return ({
      style: {
        display: 'none',
      },
    });
  }

  const { top, left, bottom } = selectedSpan.getBoundingClientRect();

  const visibility = checkVisible(top, cardRef);

  if (!visibility) {
    return ({
      style: {
        display: 'none',
      },
    });
  }

  if (selectedIssue && selectedIssue.suggestions && !(
    IssueType.DICTIONARY === selectedIssue.issueType ||
    [IssueCategory.Claim, IssueCategory.Quote, IssueCategory.BannedWords, IssueCategory.UseCarefully].includes(
      selectedIssue.category,
    )
  )) {
    if (selectedIssue.suggestions.length === 0) {
      return ({
        style: {
          display: 'none',
        },
      });
    }
  }

  if (!isValidPosition) {
    return ({
      style: {
        visibility: 'hidden',
      },
    });
  }

  if (cardRef.current) {
    const styleTop = visibility === 'invert' ? top - cardRef.current.offsetHeight : bottom;

    return ({
      style: {
        position: 'absolute',
        top: `${styleTop - 72}px`,
        left: `${left}px`,
        width: 'auto',
        zIndex: '5',
      },
    });
  }
};
