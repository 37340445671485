
const digest = '02ef94548f197b58657b3dc14efdefa10802cf9473f70fab620d184890026181';
const css = `.Text-module__GX1EnmP {
  margin: 0;
  cursor: inherit;
  font-weight: var(--font-default-weight);
  letter-spacing: 0;
}

.Text-module__j7rF8-m {
  font-family: var(--font-default);
}

.Text-module__K0kY5Je {
  font-size: var(--font-size-xs);
  font-style: normal;
  letter-spacing: 1px;
  line-height: 15px;
  text-align: left;
  text-transform: uppercase;
}

.Text-module__qXcptBB {
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: var(--font-medium-weight);
  letter-spacing: 1.3px;
  line-height: 15px;
  text-transform: uppercase;
}

.Text-module__NXK1q-v {
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: var(--font-medium-weight);
  letter-spacing: 1px;
  line-height: 12px;
  text-transform: uppercase;
}

.Text-module__FG5ROQ5 {
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: var(--font-medium-weight);
  letter-spacing: 1.3px;
  line-height: 12px;
  text-transform: uppercase;
}

.Text-module__A7vJrDZ {
  font-size: var(--font-size-xxxs);
}

.Text-module__X-JRYVT {
  font-size: var(--font-size-xxs);
}

.Text-module__emBwuH8 {
  font-size: var(--font-size-xs);
}

.Text-module__8NrY-4Z {
  font-size: var(--font-size-s);
}

.Text-module__58w22G5 {
  font-size: var(--font-size-m);
}

.Text-module__5SreR3z {
  font-size: var(--font-size-l);
}

.Text-module__LgLz2-P {
  font-size: var(--font-size-xl);
}

.Text-module__UjHjwkC {
  font-size: var(--font-size-xxl);
}

.Text-module__IYhrJGH {
  font-size: var(--font-size-xxxl);
}

.Text-module__bChvSN1 {
  font-size: var(--font-size-xxxxl);
}

.Text-module__9SHhDaR {
  font-size: var(--font-size-xxxxxl);
}

.Text-module__RTpZXEw {
  font-weight: var(--font-light-weight);
}

.Text-module__xgzn80u {
  font-weight: var(--font-medium-weight);
}

.Text-module__6HCLXDa {
  font-weight: var(--font-bold-weight);
}

.Text-module__ZwkjZJP {
  font-style: italic;
}

.Text-module__5HTxg6n {
  text-transform: uppercase;
}

.Text-module__7V5dYTA {
  color: var(--classic-black);
}

.Text-module__nGeRryu {
  color: var(--classic-white);
}

.Text-module__0mX0-VD {
  color: var(--writer-grey-1);
}

.Text-module__uNqiwXD {
  color: var(--writer-grey-2);
}

.Text-module__YdGhK-H {
  color: var(--classic-orange-3);
}

.Text-module__gGENxeF {
  color: var(--writer-grey-3);
}

.Text-module__Yt4-6Z0 {
  color: var(--classic-purple-3);
}

.Text-module__3TJ5cOF {
  color: var(--classic-grey-3);
}

.Text-module__cBjt1L1 {
  background-color: var(--classic-orange-0);
}

.Text-module__ill7Hl7 {
  border-bottom: var(--text-highlight-border-red);
  background-color: var(--classic-orange-1);
}

.Text-module__coInNfs {
  border-bottom: var(--text-highlight-border-green);
  background-color: var(--classic-green-1);
}

.Text-module__oaqz7l3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Text-module__vFCjbxk {
  display: inline;
}

.Text-module__SCCTrK2 {
  text-decoration: underline;
}

.Text-module__TuwBMsa {
  font-family: var(--font-monospace);
}

.Text-module__WIysn-v {
  font-family: var(--font-arial);
}

.Text-module__qph6U9F {
  background-color: var(--classic-green-1);
}

.Text-module__7-iWzIN {
  text-align: center;
}

.Text-module__3ekxGn7 {
  text-align: left;
}

.Text-module__5CrELYf {
  text-align: right;
}

.Text-module__-sxJ4Im {
  text-align: justify;
}
`;

export default {"container":"Text-module__GX1EnmP","faceDefault":"Text-module__j7rF8-m","textCaps":"Text-module__K0kY5Je","textSmallCaps":"Text-module__qXcptBB","textExtraSmallCaps":"Text-module__NXK1q-v","sSizeCaps":"Text-module__FG5ROQ5","textSizeXxxs":"Text-module__A7vJrDZ","textSizeXxs":"Text-module__X-JRYVT","textSizeXs":"Text-module__emBwuH8","textSizeS":"Text-module__8NrY-4Z","textSizeM":"Text-module__58w22G5","textSizeL":"Text-module__5SreR3z","textSizeXl":"Text-module__LgLz2-P","textSizeXxl":"Text-module__UjHjwkC","textSizeXxxl":"Text-module__IYhrJGH","textSizeXxxxl":"Text-module__bChvSN1","textSizeXxxxxl":"Text-module__9SHhDaR","textLight":"Text-module__RTpZXEw","textMedium":"Text-module__xgzn80u","textBold":"Text-module__6HCLXDa","textItalic":"Text-module__ZwkjZJP","textUppercase":"Text-module__5HTxg6n","colorBlack":"Text-module__7V5dYTA","colorWhite":"Text-module__nGeRryu","colorGrey":"Text-module__0mX0-VD","colorGrey2":"Text-module__uNqiwXD","colorOrange":"Text-module__YdGhK-H","colorGrey3":"Text-module__gGENxeF","colorPurple3":"Text-module__Yt4-6Z0","colorClassicGrey3":"Text-module__3TJ5cOF","highlightLightOrange":"Text-module__cBjt1L1","highlightRed":"Text-module__ill7Hl7","highlightGreen":"Text-module__coInNfs","ellipsisOverflow":"Text-module__oaqz7l3","inline":"Text-module__vFCjbxk","underline":"Text-module__SCCTrK2","faceMonospace":"Text-module__TuwBMsa","faceArial":"Text-module__WIysn-v","backgroundGreen":"Text-module__qph6U9F","center":"Text-module__7-iWzIN","left":"Text-module__3ekxGn7","right":"Text-module__5CrELYf","justify":"Text-module__-sxJ4Im"};
export { css, digest };
  