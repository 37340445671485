import isEqual from 'lodash/isEqual';
import { autorun, runInAction, when } from 'mobx';

export interface ITestOptions {
  format?: (obj: unknown) => unknown;
  eq?: typeof isEqual;
  timeout?: number;
}

export class TestProperties<TModel> {
  static mock<T>(obj: Partial<T>) {
    return obj as unknown as T;
  }

  static sleep(timeout: number) {
    return new Promise<void>(resolve => setTimeout(resolve, timeout));
  }

  private readonly timeout: number;

  private readonly eq: typeof isEqual;

  private readonly format: (obj: unknown) => unknown;

  constructor(
    public creator: () => TModel,
    opts: ITestOptions = {},
  ) {
    this.timeout = opts.timeout ?? 1000;
    this.eq = opts.eq ?? isEqual;
    this.format = opts.format ?? (obj => obj);
  }

  async checkProp<T extends keyof TModel>(
    prop: T,
    orig: TModel[T],
    val: TModel[T],
    mutator: (model: TModel, val: TModel[T]) => void,
    model = this.creator(),
  ): Promise<TModel> {
    let value: TModel[T] | undefined;

    const cancel = autorun(() => {
      value = model[prop];
    });

    if (!this.eq(value, orig)) {
      throw new Error(`invalid original value: ${this.format(value)} !== ${this.format(orig)}`);
    }

    const defer = when(
      () => {
        const modelVal = model[prop];

        return this.eq(modelVal, val);
      },
      { timeout: this.timeout },
    );

    runInAction(() => {
      mutator(model, val);
    });

    try {
      await defer;
      cancel();
    } catch (err) {
      cancel();
      throw new Error(`(timeout): invalid new value: ${this.format(value)} !== ${this.format(val)}`);
    }

    if (!this.eq(value, val)) {
      throw new Error(`invalid new value: ${this.format(value)} !== ${this.format(val)}`);
    }

    return model;
  }

  check<T extends keyof TModel>(prop: T, orig: TModel[T], val: TModel[T], model = this.creator()): Promise<TModel> {
    return this.checkProp<T>(
      prop,
      orig,
      val,
      (m, v) => {
        m[prop] = v;
      },
      model,
    );
  }

  async testAction({
    action,
    watcher,
    model = this.creator(),
  }: {
    action: (m: TModel) => void;
    watcher: (m: TModel) => boolean;
    model?: TModel | ((m: TModel) => void);
  }) {
    let m: TModel;

    if (model instanceof Function) {
      m = this.creator();
      model(m);
    } else {
      m = model;
    }

    const defer = when(() => watcher(m));
    action(m);

    await defer;

    return m;
  }
}
