import React from 'react';
import cx from 'clsx';
import type { DocumentStatTypes } from '@writercolab/ui-sidebar-models';
import styles from './styles.module.css';
import { StatItem } from '../StatItem';

export type TStatsDropdownOption = {
  label: string;
  value: number | string;
  id: typeof DocumentStatTypes.type;
};

export const StatsDropdown: React.FC<{
  className?: string;
  currentOptionId?: typeof DocumentStatTypes.type;
  dropdownOptions: TStatsDropdownOption[];
  onChange?: (id: typeof DocumentStatTypes.type) => void;
}> = ({ className, dropdownOptions, currentOptionId, onChange }) => (
  <div className={cx(className, styles.main)}>
    {dropdownOptions.map(item => (
      <StatItem
        key={item.id}
        label={item.label}
        value={item.value}
        checked={item.id === currentOptionId}
        onChange={() => onChange?.(item.id)}
      />
    ))}
  </div>
);
