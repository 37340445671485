import React from 'react';
import cx from 'clsx';
import { diffWordsWithSpace } from 'diff';

import styles from './styles.module.css';

export interface TextDiffProps {
  className?: string;
  firstString?: string;
  secondString?: string;
  addedClassName?: string;
  removedClassName?: string;
  testId?: string;
  testItemId?: string;
}

export const TextDiff = ({
  className,
  firstString = '',
  secondString = '',
  addedClassName = '',
  removedClassName = '',
  testId = 'text-diff',
  testItemId = 'text-diff-item',
}: TextDiffProps) => (
  <span className={cx(styles.container, className)} data-test-id={testId}>
    {diffWordsWithSpace(firstString, secondString).map((group, index) => {
      const { value, added, removed } = group;
      const hasDataTestId = added || (!added && !removed);

      return (
        <span
          key={`diff-${index}`}
          data-test-id={hasDataTestId ? testItemId : null}
          className={cx({
            [styles.added]: added,
            [styles.removed]: removed,
            [addedClassName]: added,
            [removedClassName]: removed,
          })}
        >
          {value}
        </span>
      );
    })}
  </span>
);
