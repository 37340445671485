
const digest = 'bd47ef99fd78b454abeadaecaef70e2536c50e1f678ab000c7ba764cfef9b50f';
const css = `.styles-module__h0DyaMq {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.styles-module__-z4SJsX {
  position: absolute;
  top: 7px;
  right: 0;
  left: 0;
  margin: auto;
  font-size: 18px;
  font-weight: 600;
}

.styles-module__lIq3SVd {
  visibility: hidden;
}

.styles-module__WO35O71 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  margin: auto;
  cursor: pointer;
  inset: 0;
  visibility: hidden;
}

.styles-module__D4feks6 {
  position: relative;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 2px solid var(--classic-grey-1);
  border-radius: 50%;
  border-top-color: var(--classic-blue-4);
  border-right-color: var(--classic-blue-4);
  animation: styles-module__18FmS3s 1s var(--spinner-animation-function) infinite;
}

.styles-module__atzl7wR {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: styles-module__18FmS3s;
  animation-timing-function: linear;
  visibility: visible;
}

.styles-module__WO35O71 i {
  vertical-align: top;
}

.styles-module__Geqmp7D:hover .styles-module__WO35O71 {
  visibility: visible;
}

.styles-module__Geqmp7D:hover .styles-module__-z4SJsX {
  visibility: hidden;
}

.styles-module__XS1jCZh {
  width: 40px;
  height: 40px;
}

@keyframes styles-module__18FmS3s {
  to {
    transform: rotate(360deg);
  }
}
`;

export default {"container":"styles-module__h0DyaMq","scoreText":"styles-module__-z4SJsX","scoreTextHidden":"styles-module__lIq3SVd","refreshButton":"styles-module__WO35O71","spinner":"styles-module__D4feks6","spin":"styles-module__18FmS3s","refreshAnimation":"styles-module__atzl7wR","containerClickable":"styles-module__Geqmp7D","circularProgressbar":"styles-module__XS1jCZh"};
export { css, digest };
  