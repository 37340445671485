import { Enum } from '@writercolab/utils';
import { IconVariant } from '../lib/Icon';

export const TAppDecorationId = new Enum(
  'ask_writer',
  'blog_post',
  'recaps',
  'ads',
  'social',
  'messaging',
  'blogs',
  'caseStudies',
  'lists',
  'errors',
  'faqs',
  'summaries',
  'supportArticles',
  'emails',
  'shortform',
  'longform',
  'image',
);

export const APP_DECORATION: { id: string; icon: IconVariant }[] = [
  {
    id: TAppDecorationId.enum.ask_writer,
    icon: IconVariant.ASKWRITER_SHORTCUT,
  },

  {
    id: TAppDecorationId.enum.blog_post,
    icon: IconVariant.BLOG_SHORTCUT,
  },
  {
    id: TAppDecorationId.enum.recaps,
    icon: IconVariant.RECAPS_SHORTCUT,
  },
  {
    id: TAppDecorationId.enum.shortform,
    icon: IconVariant.CONSOLE_APP_SHORT_FORM,
  },
  {
    id: TAppDecorationId.enum.ads,
    icon: IconVariant.CONSOLE_APP_ADS,
  },

  {
    id: TAppDecorationId.enum.social,
    icon: IconVariant.CONSOLE_APP_SOCIAL,
  },
  {
    id: TAppDecorationId.enum.messaging,
    icon: IconVariant.CONSOLE_APP_MESSAGING,
  },
  {
    id: TAppDecorationId.enum.blogs,
    icon: IconVariant.CONSOLE_APP_BLOGS,
  },
  {
    id: TAppDecorationId.enum.caseStudies,
    icon: IconVariant.CONSOLE_APP_CASE_STUDIES,
  },
  {
    id: TAppDecorationId.enum.lists,
    icon: IconVariant.CONSOLE_APP_LISTS,
  },
  {
    id: TAppDecorationId.enum.errors,
    icon: IconVariant.CONSOLE_APP_ERRORS,
  },
  {
    id: TAppDecorationId.enum.faqs,
    icon: IconVariant.CONSOLE_APP_FAQ,
  },
  {
    id: TAppDecorationId.enum.summaries,
    icon: IconVariant.CONSOLE_APP_SUMMARIES,
  },
  {
    id: TAppDecorationId.enum.supportArticles,
    icon: IconVariant.CONSOLE_APP_SUPPORT_ARTICLES,
  },
  {
    id: TAppDecorationId.enum.emails,
    icon: IconVariant.CONSOLE_APP_EMAILS,
  },
  {
    id: TAppDecorationId.enum.shortform,
    icon: IconVariant.CONSOLE_APP_SHORT_FORM,
  },
  {
    id: TAppDecorationId.enum.longform,
    icon: IconVariant.CONSOLE_APP_LONG_FORM,
  },
  {
    id: TAppDecorationId.enum.image,
    icon: IconVariant.CONSOLE_APP_IMAGE,
  },
];

export const APP_LIBRARY_CATEGORY_DECORATION: { id: string; color?: string; icon?: IconVariant }[] = [
  {
    id: 'advertising',
    color: '#AAEBFF',
  },
  {
    id: 'marketing',
    color: '#AA83FF',
  },
  {
    id: 'external-comms',
    color: '#E3FC49',
  },
  {
    id: 'sales',
    color: '#8BFF97',
  },
  {
    id: 'general',
    color: '#FD9EFF',
  },
  {
    id: 'product',
    color: '#476EF9',
  },
  {
    id: 'recruiting',
    color: '#AAEBFF',
  },
  {
    id: 'support',
    color: '#E4E9FF',
  },
  {
    id: 'design',
    color: '#FFD29D',
  },
  {
    id: 'brainstorming',
    icon: IconVariant.LIGHT_BULB,
  },
  {
    id: 'generating',
    icon: IconVariant.COMMANDS_WAND,
  },
  {
    id: 'summarizing',
    icon: IconVariant.SUMMARY,
  },
  {
    id: 'rewriting',
    icon: IconVariant.COMMANDS_STAR,
  },
  {
    id: 'analyzing',
    icon: IconVariant.CRYSTAL_BALL,
  },
  {
    id: 'transforming',
    icon: IconVariant.TRANSFORM,
  },
  {
    id: 'ads',
    icon: IconVariant.ADS_CONTENT_TYPE_BOLD,
  },
  {
    id: 'blogs',
    icon: IconVariant.BLOG_CONTENT_TYPE_BOLD,
  },
  {
    id: 'emails',
    icon: IconVariant.EMAIL_CONTENT_TYPE_BOLD,
  },
  {
    id: 'social',
    icon: IconVariant.ADS_CONTENT_TYPE_BOLD,
  },
  {
    id: 'shortform',
    icon: IconVariant.DOC_WITH_TWO_LINES,
  },
  {
    id: 'longform',
    icon: IconVariant.DOC_WITH_FIVE_LINES,
  },
  {
    id: 'healthcare',
    icon: IconVariant.CROSS_IN_CIRCLE,
  },
  {
    id: 'financial-services',
    icon: IconVariant.ABSTRACT_DOLLAR_SIGN,
  },
  {
    id: 'ecommerce',
    icon: IconVariant.ECOMMERCE,
  },
  {
    id: 'technology',
    icon: IconVariant.TECH,
  },
  {
    id: 'image',
    icon: IconVariant.IMAGE_CONTENT_TYPE,
  },
];
