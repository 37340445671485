import React from 'react';
import cx from 'clsx';

import styles from './styles.module.css';
import { Text, TextColor, TextSize } from '../typography/Text/Text';
import { Tooltip } from '../Tooltip';
import { Icon, IconVariant } from '../Icon';

/**
 * @deprecated use WDS
 */
export interface ILabelProps {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  /** Adds mark " *REQUIRED " to Label */
  required?: boolean;
  /** Adds text with some additional information to Label */
  additionalText?: string;
  /** Error text to show in label field if */
  errorText?: string;
  /** Info text to show in label field if */
  infoText?: string;
  /** Info text tooltip width */
  infoTextTooltipWidth?: number | string;
}

/**
 * @deprecated use WDS
 */
export const Label: React.FC<ILabelProps & React.AllHTMLAttributes<HTMLLabelElement>> = ({
  className,
  children,
  required = false,
  errorText = '',
  additionalText = '',
  infoText = '',
  infoTextTooltipWidth,
  ...props
}) => (
  <label className={cx(className, styles.styledLabel)} {...props}>
    <Text caps smallCaps variant={TextSize.XS}>
      {children}

      {infoText && (
        <Tooltip title={infoText} placement="top" tooltipWidth={infoTextTooltipWidth}>
          <div className={styles.tooltipContainer}>
            <Icon name={IconVariant.INFO_OUTLINED} />
          </div>
        </Tooltip>
      )}
    </Text>

    {!errorText && (
      <>
        {required && (
          <Text caps variant={TextSize.XS} className={styles.requiredMark}>
            *Required
          </Text>
        )}

        {additionalText && (
          <Text className={styles.additionalText} caps variant={TextSize.XS}>
            {additionalText}
          </Text>
        )}
      </>
    )}

    {errorText && (
      <Text className={styles.errorText} caps variant={TextSize.XS} color={TextColor.ORANGE}>
        {errorText}
      </Text>
    )}
  </label>
);

export default Label;
