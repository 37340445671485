import { getLogger } from '@writercolab/utils';
import { makeObservable, observable, runInAction } from 'mobx';
import { PromisedError } from '@writercolab/errors';

const logger = getLogger('PromiseData');

export class PromiseData<T> {
  value: T | undefined | PromisedError;

  status: 'pending' | 'fulfilled' | 'rejected' = 'pending';

  public readonly promise: Promise<T>;
  public readonly isEqualPromise: (promise: Promise<T>) => boolean;

  constructor(promise: Promise<T>, value: T | undefined | PromisedError = undefined) {
    this.value = value;
    this.isEqualPromise = aPromise => aPromise === promise;

    makeObservable(this, {
      value: observable.ref,
      status: observable,
    });

    this.promise = promise.then(
      data => {
        runInAction(() => {
          this.value = data;
          this.status = 'fulfilled';
        });

        return data;
      },
      err => {
        const wrapErr = new PromisedError(err);
        runInAction(() => {
          this.value = wrapErr;
          this.status = 'rejected';
        });

        throw wrapErr;
      },
    );

    this.promise.catch(err => {
      logger.warn(err);
    });
  }
}
