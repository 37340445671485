import React from 'react';
import { Icon, IconVariant, Text, TextSize, Button, ButtonTypes } from '@writercolab/ui-atoms';

import { ExpiredPanel } from '../ExpiredPanel';

interface IPlanCancelledProps {
  isOrgAdmin: boolean;
  onClickHref?: string;
}

export const PlanCancelled = ({ isOrgAdmin, onClickHref }: IPlanCancelledProps) => (
  <ExpiredPanel
    header="Your Team plan was cancelled"
    description={
      <>
        {isOrgAdmin && <Text variant={TextSize.L}>Resubscribe to keep using Writer.</Text>}
        {!isOrgAdmin && (
          <Text variant={TextSize.L}>Contact your org admin and ask them to select a plan to keep using Writer. </Text>
        )}
      </>
    }
  >
    {isOrgAdmin && (
      <Button type={ButtonTypes.PRIMARY} content="Resubscribe" navTo={onClickHref} navTarget="_blank"></Button>
    )}
    <ExpiredPanel.List>
      <Text variant={TextSize.M}>
        <Icon name={IconVariant.GREEN1_CHECKMARK} width={14} height={14} />
        Impactful writing
      </Text>
      <Text variant={TextSize.M}>
        <Icon name={IconVariant.GREEN1_CHECKMARK} width={14} height={14} />
        Team terms and snippets
      </Text>
      <Text variant={TextSize.M}>
        <Icon name={IconVariant.GREEN1_CHECKMARK} width={14} height={14} />
        Get suggestions anywhere
      </Text>
      <Text variant={TextSize.M}>
        <Icon name={IconVariant.GREEN1_CHECKMARK} width={14} height={14} />
        Just $18/user/month
      </Text>
    </ExpiredPanel.List>
  </ExpiredPanel>
);
