import React from 'react';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Score, Icon, IconVariant, SkeletonLoader, SkeletonLoaderType } from '@writercolab/ui-atoms';
import { Category as CategoryBase } from '@writercolab/ui-molecules';
import styles from './styles.module.css';
import { UICategoriesPanelModel } from './UICategoriesPanelModel';
import { Category } from '../Category';

export const NUMBER_OF_CATEGORIES = 8;

export { UICategoriesPanelModel };

export interface ICategoriesPanelProps {
  model: UICategoriesPanelModel;
}

export const CategoriesPanel = observer<ICategoriesPanelProps>(({ model }) => {
  const {
    isSelected,
    categoriesList,
    isSnippetsSection,
    isLoading,
    isStyleguideLoading,
    hideCategoriesHeader,
    hideSnippets,
    isStandard,
    isSmall,
    scoreValue,
    totalSidebarIssuesCount,
    hideScoreValue,
    hideCounter,
    onSelectAll,
    onSelectSnippets,
    onRefreshScoreClick,
  } = model;

  // eslint-disable-next-line no-nested-ternary
  const categories = isStyleguideLoading ? (
    <SkeletonLoader type={SkeletonLoaderType.CATEGORY_ITEM} repeat={NUMBER_OF_CATEGORIES} />
  ) : (
    <div>{categoriesList?.map(category => <Category key={category.id} model={category} />)}</div>
  );

  return (
    <div className={cx(styles.categoriesPanel, { [styles.miniPanel]: !isStandard })}>
      <div className={styles.header}>
        <div className={styles.scoreContainer}>
          <Score
            value={scoreValue}
            onClick={onRefreshScoreClick}
            hideScoreValue={hideScoreValue}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className={styles.categoriesList}>
        {hideCategoriesHeader ? null : (
          <CategoryBase
            isHeading
            isSmall={isSmall}
            label={isSmall ? 'All' : 'Suggestions'}
            hideCounter={hideCounter}
            value={totalSidebarIssuesCount}
            selected={isSelected}
            onClick={onSelectAll}
          />
        )}

        {categories}

        {hideSnippets ? null : (
          <CategoryBase
            isHeading
            isSmall={isSmall}
            label="Snippets"
            hideCounter
            selected={isSnippetsSection}
            onClick={onSelectSnippets}
            icon={<Icon name={IconVariant.LIGHTNING} />}
            withoutValue
          />
        )}
      </div>
    </div>
  );
});
