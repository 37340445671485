import React from 'react';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Icon, IconVariant } from '@writercolab/ui-atoms';
import { SelectedCategoryHeader } from '@writercolab/ui-molecules';
import styles from './styles.module.css';
import { UICardsPanelModel } from './UICardsPanelModel';
import { IssuesList } from '../IssuesList';
import { SnippetsList } from '../SnippetsList';

export { UICardsPanelModel };

export interface CardsPanelProps {
  model: UICardsPanelModel;
  isSnippets?: boolean;
}

export const CardsPanel = observer<CardsPanelProps>(({ model, isSnippets: $isSnippets }) => {
  const {
    modelUIIssuesListModel,
    modelSnippetsList,
    totalCardsCount,
    title,
    isStandard,
    isShowHeader,
    showTeamName,
    teamName,
    isLoading,
    color,
    subTitle,
  } = model;

  const isSnippets = $isSnippets ?? model.isSnippets;

  return (
    <div className={styles.container}>
      <SelectedCategoryHeader
        className={cx({ [styles.headerMini]: !isStandard })}
        showHeader={isShowHeader}
        showTeamName={showTeamName}
        teamName={teamName}
        label={title}
        count={totalCardsCount ?? 0}
        icon={isSnippets ? <Icon name={IconVariant.LIGHTNING} className={styles.snippetsCardHeaderIcon} /> : null}
        subTitle={subTitle}
        color={color}
        isLoading={isLoading}
      />
      {isSnippets ? <SnippetsList model={modelSnippetsList} /> : <IssuesList model={modelUIIssuesListModel} />}
    </div>
  );
});
