
const digest = '1cbcf6a3b7d7fae8b82158e420e30d031f076a78d453f9bd819ede2a2f0b164e';
const css = `.VisibleIssuesListContent-module__p81O67Y {
  max-height: 100%;
  flex: 1 1 auto;
  padding: 2px var(--sidebar-horizontal-spacing);
  -ms-overflow-style: none !important;
  overflow-y: scroll;
}

.VisibleIssuesListContent-module__p81O67Y::-webkit-scrollbar {
  width: 0;
}

.VisibleIssuesListContent-module__67FOrV8 {
  padding: 2px var(--sidebar-horizontal-spacing-extra-small);
}
`;

export default {"issuesList":"VisibleIssuesListContent-module__p81O67Y","issuesListWide":"VisibleIssuesListContent-module__67FOrV8"};
export { css, digest };
  