import type { RequestServiceInitialize } from '@writercolab/network';
import type { FirebaseApp } from 'firebase/app';
import { getAuth, setPersistence, browserSessionPersistence, signInWithCustomToken } from 'firebase/auth';
import { getLogger } from '@writercolab/utils';
import type { IFirebaseSettings } from '@writercolab/types';

const LOG = getLogger('FirebaseApi', 'firebase');

/**
 * Loads a user from Firebase.
 *
 * @param app - The Firebase app instance.
 * @param request - The request service initialize API.
 * @param settings - The Firebase settings.
 * @param organizationId - The ID of the organization.
 * @returns The loaded user.
 */
export async function loadUser(
  app: FirebaseApp,
  request: RequestServiceInitialize['api'],
  settings: IFirebaseSettings,
  organizationId: string | number,
) {
  const auth = getAuth(app);

  try {
    await setPersistence(auth, settings.firebaseStatePersistenceType || browserSessionPersistence);
    const { token } = await request
      .put('/api/auth/organization/{organizationId}/firebase/login', {
        params: {
          path: {
            organizationId: Number(organizationId),
          },
        },
      })
      .then(r => r.data);

    const response = await signInWithCustomToken(auth, token);

    LOG.info('Sign in to firebase ok. User: ', response.user);

    return response.user;
  } catch (error) {
    LOG.error('Error while firebaseSignInWPersistence', error);
  }

  return undefined;
}
