import type { RequestServiceInitialize } from '@writercolab/network';
import {
  EDraftDownloadFormats,
  type TDraftsExportQueryParams,
  type TGetDraftsPaginatedRequest,
  type TDraftDownloadFormats,
} from '@writercolab/types';

import { getLogger } from '@writercolab/utils';

export interface IDraftsApiModelOpts {
  requestService: RequestServiceInitialize['api'];
}

const logger = getLogger('DraftsApiModel');

export class DraftsApiModel {
  private readonly requestService: RequestServiceInitialize['api'];

  constructor(opts: IDraftsApiModelOpts) {
    this.requestService = opts.requestService;
  }

  loadDrafts = async (organizationId: number, teamId: number, body: TGetDraftsPaginatedRequest) => {
    try {
      const { data } = await this.requestService.put(
        '/api/template/organization/{organizationId}/team/{teamId}/draft/get',
        {
          params: {
            path: {
              organizationId,
              teamId,
            },
          },
          body,
        },
      );

      return data;
    } catch (error) {
      logger.error('Failed to load drafts', { error });

      throw error;
    }
  };

  loadDocumentDrafts = async (
    organizationId: number,
    teamId: number,
    documentId: string,
    query: Partial<{
      templateId: string;
      offset: number;
      limit: number;
    }> = {},
  ) => {
    try {
      const { data } = await this.requestService.get(
        '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}',
        {
          params: {
            path: {
              organizationId,
              teamId,
              documentId,
            },
            query,
          },
        },
      );

      return data;
    } catch (error) {
      logger.error('Failed to load document drafts', { error });

      throw error;
    }
  };

  loadDraft = async (organizationId: number, teamId: number, draftId: number) => {
    try {
      const { data } = await this.requestService.get(
        '/api/template/organization/{organizationId}/team/{teamId}/draft/{draftId}',
        {
          params: {
            path: {
              organizationId,
              teamId,
              draftId,
            },
          },
        },
      );

      return data;
    } catch (error) {
      logger.error('Failed to load draft', { draftId, error });

      throw error;
    }
  };

  deleteDrafts = async (organizationId: number, teamId: number, draftIds: number[]) => {
    try {
      await this.requestService.delete('/api/template/organization/{organizationId}/team/{teamId}/draft', {
        params: {
          path: {
            organizationId,
            teamId,
          },
          query: {
            draftIds,
          },
        },
      });
    } catch (error) {
      logger.error('Failed to delete drafts', { draftIds, error });

      throw error;
    }
  };

  exportDraftsAsCSV = async (organizationId: number, teamId: number, params: TDraftsExportQueryParams) => {
    try {
      const { data } = await this.requestService.get(
        '/api/template/organization/{organizationId}/team/{teamId}/draft/csv',
        {
          parseAs: 'blob',
          params: {
            path: {
              organizationId,
              teamId,
            },
            query: params,
          },
        },
      );

      return data;
    } catch (error) {
      logger.error('Failed to export drafts as CSV', { params, error });

      throw error;
    }
  };

  exportDraftsAsXLSX = async (organizationId: number, teamId: number, params: TDraftsExportQueryParams) => {
    try {
      const { data } = await this.requestService.get(
        '/api/template/organization/{organizationId}/team/{teamId}/draft/xlsx',
        {
          parseAs: 'blob',
          params: {
            path: {
              organizationId,
              teamId,
            },
            query: params,
          },
        },
      );

      return data;
    } catch (error) {
      logger.error('Failed to export drafts as XLSX', { params, error });

      throw error;
    }
  };

  exportDraftsByFormat = async (
    organizationId: number,
    teamId: number,
    params: TDraftsExportQueryParams,
    format: TDraftDownloadFormats,
  ) =>
    EDraftDownloadFormats.match(
      format,
      {
        csv: () => this.exportDraftsAsCSV(organizationId, teamId, params),
        xlsx: () => this.exportDraftsAsXLSX(organizationId, teamId, params),
      },
      null,
    );

  downloadDraftMediaFile = async (organizationId: number, teamId: number, fileId: string) => {
    try {
      const { data } = await this.requestService.get(
        '/api/media/organization/{organizationId}/team/{teamId}/file/{fileId}/download',
        {
          parseAs: 'blob',
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          params: {
            path: {
              organizationId,
              teamId,
              fileId,
            },
          },
        },
      );

      return data;
    } catch (error) {
      logger.error('Failed to download draft media file', { fileId, error });

      throw error;
    }
  };
}
