import type { FC, ReactNode } from 'react';
import React, { createContext, useContext } from 'react';
import { getModalsRootContainer } from '../utils';

const ModalContext = createContext(getModalsRootContainer);

export const useModalContext = () => useContext(ModalContext);

export const ModalContextProvider: FC<{
  getModalsRootContainer: typeof getModalsRootContainer;
  children?: ReactNode;
  // eslint-disable-next-line @eslint-react/no-unstable-context-value
}> = ({ children, getModalsRootContainer }) => (
  <ModalContext.Provider value={getModalsRootContainer}>{children}</ModalContext.Provider>
);
