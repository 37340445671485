import type { ReactNode } from 'react';
import type { IRephraseGrades } from '@writercolab/types';
import type { IRephraseSuggestionsResponse } from './Editor';

export interface SelectionArea {
  left: number;
  top: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

export interface IFloatingPosition {
  top: number;
  left: number;
  width: number;
  height: number;
  bottom: number;
}

export type RewriteModuleState = {
  showWidget: boolean;
  selectedText: string;
  selectedArea: SelectionArea;
  totalElementHeight: number;
  selectedRange: { index: number; length: number } | null;
  textContainerPosition: IFloatingPosition | undefined;
  isWidgetHidden: boolean;
};

export enum RewriteModuleAction {
  RESET = 'RESET',
  DEFAULT = 'DEFAULT',
}

export enum RewriteAction {
  RESET = 'RESET',
  DEFAULT = 'DEFAULT',
  SET_LOADING = 'SET_LOADING',
  SET_LOADING_MORE = 'SET_LOADING_MORE',
  SELECT_GRADE = 'SELECT_GRADE',
  SET_SUGGESTION_FOR_GRADE = 'SET_SUGGESTION_FOR_GRADE',
  SET_FETCH_ERROR = 'SET_FETCH_ERROR',
  SET_FETCH_ERROR_MORE = 'SET_FETCH_ERROR_MORE',
  SET_FETCH_ERROR_UNSAFE_CONTENT = 'SET_FETCH_ERROR_UNSAFE_CONTENT',
}

export type DefaultRewriteModuleAction = {
  type: RewriteModuleAction;
  payload?: Partial<RewriteModuleState>;
};

export type DefaultRewriteAction = {
  type: RewriteAction;
  payload?: Partial<RewriteState>;
};

export type SetSuggestionAction = {
  type: RewriteAction.SET_SUGGESTION_FOR_GRADE;
  payload: {
    grade: string;
  } & ISuggestionRequestState;
};

export type RewriteModuleStateAction = DefaultRewriteModuleAction;

export interface ISuggestionRequestState {
  isLoading: boolean;
  isLoadingMore: boolean;
  showMoreTimesLoaded: number;
  areNoMoreNewSuggestions: boolean;
  fetchShowMoreError: boolean;
  suggestions?: IRephraseSuggestionsResponse[];
}

export type RewriteStateAction = SetSuggestionAction | DefaultRewriteAction;

export interface RewriteState {
  selectedGrade: string;
  selectedGradeName: string;
  suggestions: Record<string, ISuggestionRequestState>;
  fetchError: boolean;
  fetchErrorUnsafeContent: boolean;
  grades: IRephraseGrades[];
  firstGrades: IRephraseGrades[];
  lastGrades: IRephraseGrades[];
  selectedSuggestions: ISuggestionRequestState | null;
}

export interface ILimitReachedInfo {
  title: string;
  description: ReactNode;
}
