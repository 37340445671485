import axios from 'axios';
import { CookieStorage } from 'cookie-storage';
import { AUTH_TOKEN_NAME } from '@writercolab/common-utils';

import { clientId } from "./constants";
import packageJson from "../../package.json";

const cookieStorage = new CookieStorage({
  secure: true,
  sameSite: 'None'
});

async function requestInterceptor(config) {
  const newConfig = config;

  newConfig.headers['x-client'] = clientId;
  newConfig.headers['x-client-ver'] = packageJson.version;

  const accessToken = cookieStorage.getItem('qToken') || localStorage.getItem('qToken');

  if (accessToken) {
    newConfig.headers[AUTH_TOKEN_NAME] = accessToken;
  }

  return newConfig;
}

const apiClient = axios.create({
  baseURL: `https://${process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_HOST : window.env.REACT_APP_API_HOST}/api`,
  headers: {
    Accept: 'application/json',
  },
});

apiClient.interceptors.request.use(requestInterceptor);

const API = {
  login: (username, password) => {
    return apiClient.put('/login', {
      username,
      password,
    })
  },
  profile: () => apiClient.get('/user/v2/profile'),
  getMyOrganization: () => {
    return apiClient.get(`/organization/v2/my`)
  },
  fetchWorkspacesNew: (orgId, config) => {
    return apiClient.get(`/organization/v2/${orgId}/team/my`, config)
  },
  getSubscription: orgId => {
    return apiClient.get(`/billing/organizations/${orgId}/subscription`)
  },
  getPermissions: () => apiClient.get('/auth/permission'),
  setDocumentContent: ({
    organizationId,
    workspaceId,
    personaId,
    documentId,
    content,
    clientId,
  }) => {
    apiClient.post(
      `/content/organization/${organizationId}/workspace/${workspaceId}/persona/${personaId}/document/${documentId}/content`,
      {
        clientId,
        content,
      },
    )
  },
  setDocumentFragment: ({
    organizationId,
    workspaceId,
    personaId,
    documentId,
    delta,
    clientId,
  }) => {
    return apiClient.post(
      `/content/organization/${organizationId}/workspace/${workspaceId}/persona/${personaId}/document/${documentId}/delta/fragmented`,
      {
        clientId,
        delta,
      },
    )
  },
  setDocumentDelta: ({
    organizationId,
    workspaceId,
    personaId,
    documentId,
    delta,
    clientId,
  }) => {
    return apiClient.post(
      `/content/organization/${organizationId}/workspace/${workspaceId}/persona/${personaId}/document/${documentId}/delta`,
      {
        clientId,
        delta,
      },
    )
  },
  createBuffer: () => apiClient.post('/auth/buffer'),
  readBuffer: (readKey) =>
    apiClient.put('/auth/buffer/get', {
      readKey,
    }),
  exchangeAuthCode: (codeVerifier, authorizationCode) =>
    apiClient.put('/auth/app/code/exchange', {
      code_verifier: codeVerifier,
      authorization_code: authorizationCode,
    }),
  invalidateToken: () => apiClient.put('/auth/token/user/invalidate'),
  updateLanguage: (languageCode, organizationId, teamId) => apiClient.put(`/styleguide/organization/${organizationId}/team/${teamId}/language`, { languageCode }),
  getSuggestionSettings: (organizationId, styleguideId) => apiClient.get(`/styleguide/organization/${organizationId}/styleguide/${styleguideId}`),
  refreshAnalyzedContent: (organizationId, workspaceId, personaId, documentId) => {
    const url = `/content/organization/${organizationId}/workspace/${workspaceId}/persona/${personaId}/document/${documentId}/content/refresh`;

    return apiClient.put(url);
  },
  getApplicationUsage: (organizationId) => apiClient.get(`/billing/organizations/${organizationId}/usage`),
};

export default API;
