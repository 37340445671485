import React, { useCallback } from 'react';
import cx from 'clsx';
import { useAlert, positions, Provider as AlertProvider, transitions } from 'react-alert';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { observer } from 'mobx-react-lite';
import { SidebarSearchBar, SkeletonLoader, SkeletonLoaderType } from '@writercolab/ui-atoms';
import { SnippetCard, EducationalBanner, EducationalBannerLocation, AlertTemplate } from '@writercolab/ui-molecules';
import { copyToClipboard, openNewTab } from '@writercolab/dom';
import styles from './styles.module.css';
import type { UISnippetsListModel } from './UISnippetsListModel';

export interface ISnippetsListProps {
  model: UISnippetsListModel;
  alignAlertToCenter?: boolean;
}

const alertBoxOptions = {
  timeout: 3000,
  transition: transitions.FADE,
};

const EmptyContainer = ({
  addNewSnippetUrl,
  documentationUrl = 'https://support.writer.com/article/72-adding-content-snippets',
}: {
  addNewSnippetUrl: string;
  documentationUrl?: string;
}) => {
  const _handleAddSnippetClick = useCallback(() => openNewTab(addNewSnippetUrl), [addNewSnippetUrl]);

  const _handleOpenDocumentation = useCallback(() => openNewTab(documentationUrl), [documentationUrl]);

  return (
    <div className={styles.containerBanner}>
      <EducationalBanner
        location={EducationalBannerLocation.SNIPPETS_SIDEBAR}
        onPrimaryActionClick={_handleAddSnippetClick}
        onSecondaryActionClick={_handleOpenDocumentation}
        closeButtonVisible={false}
        bannerClassName={styles.educationalBanner}
      />
    </div>
  );
};

const SnippetsContent = observer<ISnippetsListProps>(({ model }) => {
  const {
    snippets,
    searchValue,
    loading,
    hasNextPage,
    isEmpty,
    newSnippetUrl,
    onLoadMore,
    setKeyword,
    onSnippetClick,
  } = model;

  const alert = useAlert();

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const onSnippetClicked = useCallback(
    (id: string, snippet: string) => {
      onSnippetClick(id, snippet);

      if (model.hasEventSubscriber('onSnippetClicked')) {
        model.onSnippetClicked(snippet);
      } else {
        copyToClipboard({ html: snippet });
      }

      alert.show('Copied to clipboard');
    },
    [alert, model, onSnippetClick],
  );

  if (loading) {
    return <SkeletonLoader type={SkeletonLoaderType.ISSUE_CARD} repeat={9} style={{ marginTop: 10 }} />;
  }

  if (isEmpty) {
    return <EmptyContainer addNewSnippetUrl={newSnippetUrl ?? ''} />;
  }

  return (
    <div className={styles.container}>
      <SidebarSearchBar className={styles.searchbar} keyword={searchValue} setKeyword={setKeyword} />
      <div>
        {snippets?.value?.map(
          (item, index) =>
            item && (
              <SnippetCard
                id={item.id.toString()}
                snippet={item.snippet}
                key={`${item.shortcut}-${index}`}
                className={styles.snippetcard}
                shortcut={item.shortcut || ''}
                description={item.description || ''}
                tags={item.tags || []}
                onSnippetClick={onSnippetClicked}
              />
            ),
        )}
      </div>
      <div ref={sentryRef} />
      {snippets?.hasNext && (
        <div>
          <SkeletonLoader type={SkeletonLoaderType.ISSUE_CARD} />
        </div>
      )}
    </div>
  );
});

export const SnippetsList: React.FC<ISnippetsListProps> = ({ model }) => (
  <AlertProvider
    template={AlertTemplate}
    position={model.isAlertsCentered ? positions.BOTTOM_CENTER : positions.BOTTOM_RIGHT}
    offset={model.isAlertsCentered ? '10px' : '10px 238px 10px 10px'}
    {...alertBoxOptions}
  >
    <div className={cx(styles.scrollContainer, { [styles.scrollContainerWide]: model.isSmallPadding })}>
      <SnippetsContent model={model} />
    </div>
  </AlertProvider>
);
