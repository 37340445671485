
const digest = '621edb39b4dcf65b8847c604c92589fdcbbd9929b90cdf052495e1da9021dae5';
const css = `.styles-module__PyeOTFq {
  display: flex;
  min-width: 200px;
  box-sizing: border-box;
  flex-direction: column;
  padding: 8px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 2px 32px rgb(172 185 220 / 40%);

  :is(label) {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    cursor: pointer;
  }

  :is(input) {
    margin-right: 11px;
  }
}
`;

export default {"main":"styles-module__PyeOTFq"};
export { css, digest };
  