import { pushSortable } from './pushSortable';
import { binarySearch } from './binarySearch';
import { buildComparator } from './comparator';

export interface IMathingText<TSource extends ArrayLike<unknown>, TMatcher, TResult> {
  indexOf(source: TSource, matcher: TMatcher, index: number): number;
  length(matcher: TMatcher): number;
  createChunk(matcher: TMatcher, offset: number): TResult | undefined;
  offset(chunk: TResult): number;
  cmp: (a: TMatcher, b: TMatcher) => number;
  collision?: () => void;
  gap?(source: TSource, from: number, end: number, push: (c: TResult) => void): void;
}

// comparator for ranges
const cmpRange = buildComparator<[number, number], [number, number]>({
  gt: (a, b) => a[0] > b[0],
});

// eslint-disable-next-line no-nested-ternary
const cmpRangeWithPoint = ([s, e]: [number, number], v: number) => (v < s ? -1 : v > e ? 1 : 0);

const comparatorCmpAsc = buildComparator({
  gt: (a: number, b: number) => a > b,
});

export function matchingArray<TSource extends ArrayLike<unknown>, TMatcher, TResult>(
  source: TSource,
  matchers: TMatcher[],
  { indexOf, createChunk, offset, length, cmp, collision, gap }: IMathingText<TSource, TMatcher, TResult>,
) {
  const collisionHashIndex = new Map<number, TMatcher[]>();
  const chunks: TResult[] = [];
  const ranges: Array<[number, number]> = [];
  const addedSet = new Set<TMatcher>();

  const push = (item: TMatcher, index: number) => {
    const chunk = createChunk(item, index);

    if (chunk) {
      pushSortable(chunks, chunk, cmpChunk);
      pushSortable(ranges, [index, index + length(item) - 1], cmpRange);

      return true;
    }

    return false;
  };

  const cmpChunk = buildComparator<TResult, TResult>({
    gt: (a, b) => offset(a) > offset(b),
  });

  const isInRange = (val: number) => binarySearch(ranges, val, cmpRangeWithPoint) >= 0;

  // matching all entrances to source array
  // if we find only one this means that we find mathes
  // if zero this means that matcher is invalid
  // if more than once fill collision map for further resolving collisions
  matchers.forEach(matcher => {
    const nums: number[] = [];

    let cursor = 0;

    while (cursor >= 0 && cursor < source.length) {
      const start = indexOf(source, matcher, cursor);

      if (start < 0) {
        break;
      }

      nums.push(start);
      cursor = start + length(matcher);
    }

    if (nums.length === 1) {
      if (!push(matcher, nums[0])) {
        collisionHashIndex.set(nums[0], [matcher]);
      }
    } else if (nums.length > 1) {
      nums.forEach(num => {
        if (collisionHashIndex.has(num)) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const ref = collisionHashIndex.get(num)!;
          ref.push(matcher);
        } else {
          collisionHashIndex.set(num, [matcher]);
        }
      });
    }
  });

  // generate map of collision indexes which doens't includes in ranges
  const collisionKeys = Array.from(collisionHashIndex.keys()).filter(key => {
    if (isInRange(key)) {
      collisionHashIndex.delete(key);

      return false;
    }

    return true;
  });

  collisionKeys.sort(comparatorCmpAsc);

  // fixing collision
  collisionKeys.forEach(index => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const matcher = collisionHashIndex.get(index)!;

    if (matcher.length === 1) {
      const item = matcher[0];
      const isValid = !addedSet.has(item) && !isInRange(index);

      if (isValid) {
        if (push(item, index)) {
          addedSet.add(item);
        } else {
          return;
        }
      }

      collisionHashIndex.delete(index);
    } else {
      matcher.sort(cmp);
      const updateItems = matcher.filter(item => !addedSet.has(item));

      for (const item of updateItems) {
        if (!isInRange(index)) {
          if (push(item, index)) {
            addedSet.add(item);
            break;
          }
        }
      }

      collisionHashIndex.delete(index);
    }
  });

  // filling gaps in matching
  if (gap) {
    let prevRange: [number, number] | undefined;
    for (const range of ranges) {
      if (!prevRange) {
        if (range[0] !== 0) {
          gap(source, 0, range[0], chunk => pushSortable(chunks, chunk, cmpChunk));
        }
      } else if (prevRange[1] + 1 !== range[0]) {
        gap(source, prevRange[1] + 1, range[0], chunk => pushSortable(chunks, chunk, cmpChunk));
      }

      prevRange = range;
    }
  }

  // collision detector
  if (collision && collisionHashIndex.size) {
    collision();
  }

  return chunks;
}
