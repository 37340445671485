import { makeObservable, computed, observable, action, runInAction } from 'mobx';
import type { IssuesPipeline } from '@writercolab/types';
import { E_INTEGRATION_TYPE, BillingProduct, SharedQueryParam, SidebarViewMode } from '@writercolab/types';
import { getSnippetsPageUrl } from '@writercolab/dom';
import { calculateChecksumOverDelta } from '@writercolab/quill-delta-utils';
import type { AiAssistantSubscriptionModel } from '@writercolab/models';
import type { SidebarDataModel } from '../sidebarData';
import type { IssuesModel } from '../issues';
import type { SizeModel } from '../size';
import type { CategoriesModel } from '../categories';

export interface ISidebarStateModelParams {
  subscription: Pick<
    AiAssistantSubscriptionModel,
    'isFree' | 'isTeam' | 'isMultiTeam' | 'isEnterprise' | 'isCancelled' | 'isTrialExpired'
  >;
  size: Pick<SizeModel, 'isSmall' | 'isMedium' | 'isStandard'>;
  sidebarData: Pick<SidebarDataModel, 'issues' | 'styleguide' | 'contentHashes' | 'documentDelta' | 'documentContent'>;
  issues: () => Pick<IssuesModel, 'isEmptyState'> | undefined;
  categories: () => Pick<CategoriesModel, 'selectedCategory'> | undefined;
  documentId: () => string | undefined;
  organizationId: () => number | undefined;
  teamId: () => number | undefined;
  isTeamAdmin: () => boolean;

  integrationType: typeof E_INTEGRATION_TYPE.type;
  sidebarMode: () => SidebarViewMode | undefined;
  appRoot: string;
}

export class SidebarStateModel {
  constructor(private opts: ISidebarStateModelParams) {
    makeObservable(this, {
      enforcedPipeline: observable,

      statsAreVisible: computed,
      hideCategoriesHeader: computed,
      hideSnippets: computed,
      isLoading: computed,
      isStyleguideLoading: computed,
      isTeamPlanCancelled: computed,
      isEnterprisePlanCancelled: computed,
      isTeamTrialExpired: computed,
      isFreeCancelled: computed,
      isEmptyState: computed,
      isPreviewMode: computed,
      billingPageHref: computed,
      paidFeatureHref: computed,
      activateFreeHref: computed,
      newSnippetUrl: computed,
      showTeamName: computed,

      isNoIntegration: computed,
      isPipelineContentOutdated: computed,
      currentContentHash: computed,

      forceSidebarLoading: action.bound,
      setEnforcePipeline: action.bound,
    });
  }

  // by default the pipeline is being changed automatically
  // based on certain conditions (isPlagiarism, isCollaborative etc).
  // Enforced pipeline is the static pipeline that prevents automatic updates
  // it will stay static until setEnforcePipeline resets it
  enforcedPipeline: IssuesPipeline | undefined = undefined;

  private isForceLoaded = observable.box(false);

  setEnforcePipeline(pipeline?: IssuesPipeline) {
    this.enforcedPipeline = pipeline;
  }

  forceSidebarLoading(milliseconds = 4000) {
    this.isForceLoaded.set(true);

    setTimeout(() => runInAction(() => this.isForceLoaded.set(false)), milliseconds);
  }

  get isPipelineContentOutdated() {
    return this.currentContentHash !== this.opts.sidebarData.contentHashes.data?.claim;
  }

  get currentContentHash() {
    const currentDelta = this.opts.sidebarData.documentDelta.data;

    if (!currentDelta) {
      return undefined;
    }

    return calculateChecksumOverDelta(currentDelta);
  }

  private static IS_ALERTS_CENTERED: Array<typeof E_INTEGRATION_TYPE.type> = [
    E_INTEGRATION_TYPE.enum.CHROME_EXTENSION,
    E_INTEGRATION_TYPE.enum.FIGMA_PLUGIN,
    E_INTEGRATION_TYPE.enum.WORD_PLUGIN,
    E_INTEGRATION_TYPE.enum.OUTLOOK_PLUGIN,
  ];

  get isAlertsCentered() {
    return SidebarStateModel.IS_ALERTS_CENTERED.includes(this.opts.integrationType);
  }

  get isTeamAdmin() {
    return this.opts.isTeamAdmin();
  }

  get integrationType() {
    return this.opts.integrationType;
  }

  get isPreviewMode() {
    return this.opts.sidebarMode?.() === SidebarViewMode.PREVIEW;
  }

  get isNoIntegration() {
    return !this.opts.documentId();
  }

  get showTeamName() {
    return this.opts.subscription.isEnterprise;
  }

  get canWriteToTerms() {
    return this.opts.subscription.isFree && this.isTeamAdmin;
  }

  get statsAreVisible() {
    if (this.isLoading && (this.opts.size.isSmall || this.opts.size.isMedium)) {
      return false;
    }

    if (this.isTeamPlanCancelled || this.isTeamTrialExpired) {
      return false;
    }

    return true;
  }

  get hideCategoriesHeader() {
    return !this.opts.size.isStandard && this.isLoading;
  }

  get hideSnippets() {
    const { isNoIntegration, isEnterprisePlanCancelled, isTeamTrialExpired, isTeamPlanCancelled, isFreeCancelled } =
      this;

    if (isEnterprisePlanCancelled || isTeamTrialExpired || isTeamPlanCancelled || isFreeCancelled || isNoIntegration) {
      return true;
    }

    return !this.opts.size.isStandard && this.isLoading;
  }

  get isLoading() {
    const {
      isEmptyState,
      isNoIntegration,
      isEnterprisePlanCancelled,
      isTeamTrialExpired,
      isTeamPlanCancelled,
      isFreeCancelled,
    } = this;

    if (isEnterprisePlanCancelled || isTeamTrialExpired || isTeamPlanCancelled || isFreeCancelled) {
      return false;
    }

    // if we aren't sure about emptiness state, this means that we are still in the loading state
    if (isEmptyState === undefined && !isNoIntegration) {
      return true;
    }

    if (isNoIntegration) {
      return false;
    }

    const {
      issues,
      documentContent: { data: documentContentData },
    } = this.opts.sidebarData;

    return this.isForceLoaded.get() || !issues.data || this.isStyleguideLoading || documentContentData === undefined;
  }

  get isStyleguideLoading() {
    return !this.opts.sidebarData.styleguide.data;
  }

  get isTeamPlanCancelled() {
    return this.opts.subscription.isTeam && this.opts.subscription.isCancelled;
  }

  get isEnterprisePlanCancelled() {
    return this.opts.subscription.isEnterprise && this.opts.subscription.isCancelled;
  }

  get isTeamTrialExpired() {
    return this.opts.subscription.isTrialExpired;
  }

  get isFreeCancelled() {
    return this.opts.subscription.isFree && this.opts.subscription.isCancelled;
  }

  get isEmptyState() {
    const issues = this.opts.issues();

    if (issues === undefined) {
      return undefined;
    }

    const { isEmptyState } = issues;

    if (isEmptyState === undefined) {
      return undefined;
    }

    return (
      isEmptyState ||
      this.isTeamTrialExpired ||
      this.isTeamPlanCancelled ||
      this.isFreeCancelled ||
      this.isNoIntegration
    );
  }

  get newSnippetUrl() {
    const { opts } = this;
    const organizationId = opts.organizationId();
    const teamId = opts.teamId();

    if (organizationId === undefined || teamId === undefined) {
      return undefined;
    }

    return getSnippetsPageUrl(this.opts.appRoot, organizationId, teamId, {
      [SharedQueryParam.CREATE]: 'true',
    });
  }

  get billingPageHref() {
    const { opts } = this;

    const { appRoot } = opts;
    const organizationId = opts.organizationId();

    if (organizationId === undefined) {
      return undefined;
    }

    return `${appRoot.endsWith('/') ? appRoot : `${appRoot}/`}organization/${organizationId}/admin/billing`;
  }

  get paidFeatureHref() {
    return `${this.billingPageHref}?triggerUpdateTo=${this.opts.categories()?.selectedCategory?.billingProduct}`;
  }

  get activateFreeHref() {
    return `${this.billingPageHref}?triggerUpdateTo=${BillingProduct.FREE}`;
  }
}
