import React from 'react';
import cx from 'clsx';

export interface ColorDotProps {
  className?: string;
  color: string;
  size?: number;
}

export const ColorDot: React.FC<ColorDotProps> = ({ color, className, size = 8 }) => (
  <div
    style={{ width: `${size}px`, height: `${size}px`, borderRadius: '50%', backgroundColor: color }}
    className={cx(className)}
  ></div>
);
