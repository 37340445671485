import { type FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import type { IFirebaseConfig, IFirebaseSettings } from '@writercolab/types';

export const DEBUG_APP_CHECK_TOKEN = '39EBB9A6-EF20-4A98-BB83-5F31175EC131';

/**
 * Initializes a Firebase app with the provided configuration and settings.
 *
 * @param config - The Firebase configuration object.
 * @param settings - The Firebase settings object.
 * @param firebaseAppName - The name of the Firebase app (default: 'writer').
 * @returns The initialized Firebase app.
 */
export function initApp(config: IFirebaseConfig, settings: IFirebaseSettings, firebaseAppName = 'writer'): FirebaseApp {
  try {
    const app = getApp(firebaseAppName);

    return app;
  } catch (e) {
    const app = initializeApp(config, firebaseAppName);

    if (settings.useAppCheck && settings.recaptchaEnterpriseKey) {
      if (window.location.hostname === 'localhost') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = DEBUG_APP_CHECK_TOKEN;
      }

      initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(settings.recaptchaEnterpriseKey),
        isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh
      });
    }

    return app;
  }
}
