
const digest = '756f4cb7f6faf1bae2729e3fc949e9c1d95c5df1392da53e9a283a7fc15973d5';
const css = `.styles-module__QTzMgZK {
  max-width: 100%;
  box-shadow: var(--issue-card-collapsed-shadow);
  transition: var(--box-shadow-transition);
}

.styles-module__QTzMgZK:hover {
  box-shadow: var(--snippet-card-highlighted-shadow);
  cursor: pointer;
}

.styles-module__63B97pI {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 12px 16px 27px;
  background: var(--white);
}

.styles-module__QTzMgZK,
.styles-module__63B97pI {
  border-radius: 8px;
}

/* header */
.styles-module__JIImVrn {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 12px;
}

.styles-module__GL79Mf- {
  position: absolute;
  left: -17px;
}

.styles-module__GL79Mf- path {
  fill: var(--turquoise-chill);
}

.styles-module__aI-dv8x {
  overflow: hidden;
  color: var(--turquoise-chill);
  font-size: var(--font-size-m);
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* snippet */
.styles-module__wkD9EYm {
  display: flex;
  width: calc(100% + 12px);
  max-height: 230px;
  flex-direction: column;
  padding-right: 12px;
  overflow-y: scroll;
}

.styles-module__VoVpP1a {
  font-size: var(--font-size-m);
  font-weight: 600;
  line-height: 23px;
}

.styles-module__qUEgkgz {
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  font-size: 11px;
  line-height: 20px;
  white-space: pre-line;

  & > ol {
    padding: 0 0 0 22px;

    & li {
      &[data-list='ordered'] {
        list-style-type: decimal;
      }

      &[data-list='bullet'] {
        list-style-type: disc;
      }
    }
  }
}

.styles-module__qUEgkgz h1,
.styles-module__qUEgkgz h2,
.styles-module__qUEgkgz h3,
.styles-module__qUEgkgz h4,
.styles-module__qUEgkgz h5,
.styles-module__qUEgkgz h6,
.styles-module__qUEgkgz strong {
  font-weight: 600 !important;
}

/* description */
.styles-module__cVoA6TQ {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.styles-module__cVoA6TQ::before {
  position: absolute;
  left: 0;
  width: 100%;
  border-top: var(--gray-border);
  content: '';
}

.styles-module__SpyuAq2 {
  padding-top: 13px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.3px;
  line-height: 15px;
  text-transform: uppercase;
}

.styles-module__Ke5I7I8 {
  padding-top: 5px;
  color: var(--classic-grey-4);
  font-size: 11px;
  line-height: 20px;
  word-break: break-word;
}

.styles-module__gRm-ccB {
  display: flex;
  flex-direction: column;
}

.styles-module__-yES6pm {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin-top: 8px;
  gap: 5px;
}

.styles-module__-yES6pm > .styles-module__CTkFAmJ {
  padding: 2px 8px;
  border-radius: 13px;
  background: #c4d0fd;
  color: var(--classic-blue-4);
}

.styles-module__-yES6pm > .styles-module__CTkFAmJ.styles-module__-nznVWY {
  visibility: hidden;
}

.styles-module__-yES6pm > .styles-module__zGxFLaZ {
  color: var(--classic-blue-4);
}

.styles-module__WRn-bSZ {
  margin-top: 8px;
}

.styles-module__-nznVWY {
  display: none;
}

/* to highlight the parent on clicking child */
.styles-module__qUEgkgz:active::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--writer-green-4);
  border-radius: 8px;
  content: '';
}

.styles-module__FBzd282::-webkit-scrollbar {
  width: 15px;
}

.styles-module__FBzd282::-webkit-scrollbar-track {
  background: transparent;
}

.styles-module__FBzd282::-webkit-scrollbar-thumb {
  border: 5px solid var(--white);
  border-radius: 15px;
  background: var(--color-support-lightest);
}
`;

export default {"mainContainer":"styles-module__QTzMgZK","container":"styles-module__63B97pI","cardHeader":"styles-module__JIImVrn","cardHeaderIcon":"styles-module__GL79Mf-","cardTitle":"styles-module__aI-dv8x","snippetContainer":"styles-module__wkD9EYm","snippetTitle":"styles-module__VoVpP1a","snippetText":"styles-module__qUEgkgz","descriptionContainer":"styles-module__cVoA6TQ","descriptionLabel":"styles-module__SpyuAq2","descriptionText":"styles-module__Ke5I7I8","footer":"styles-module__gRm-ccB","tags":"styles-module__-yES6pm","tag":"styles-module__CTkFAmJ","hidden":"styles-module__-nznVWY","tagsLeft":"styles-module__zGxFLaZ","seemoreButton":"styles-module__WRn-bSZ","scrollbar":"styles-module__FBzd282"};
export { css, digest };
  