/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-restricted-syntax
import type { Dictionary } from 'lodash';

import type {
  CreatePaymentMethodResponse,
  IApplicationUsageStats,
  IBillingCalculation,
  IBillingContact,
  IBillingPaymentMethod,
  IBillingPlan,
  IInvoice,
  IPaymentMethod,
  ISetSubscription,
  ISubscription,
  ISubscriptionFeatureLock,
  ISubscriptionLimit,
} from '@writercolab/types';
import Request from '../../utils/requestUtils';
import { getLogger } from '../../logger';
import { formatAmount } from '../../utils/priceUtils';
import type { OrganizationId } from '../../types/Organization';

const LOG = getLogger('billing');

/**
 * @deprecated use @writercolab/network
 */
export const getAvailablePlans = async (orgId: OrganizationId | string) => {
  const { data } = await Request.getAxiosInstance().get<{ prices: IBillingPlan[] }>(
    `/billing/organizations/${orgId}/price`,
  );

  return data.prices;
};

/**
 * @deprecated use @writercolab/network
 */
export const getPaymentMethod = async (orgId: OrganizationId | string): Promise<IPaymentMethod | null> => {
  let paymentMethod;
  try {
    const response = await Request.getAxiosInstance().get<IPaymentMethod>(
      `/billing/organizations/${orgId}/paymentMethod`,
    );
    paymentMethod = response.data;
  } catch (e) {
    LOG.error(e);
  }

  return paymentMethod;
};

/**
 * @deprecated use @writercolab/network
 */
export const getBillingCustomer = async (orgId: OrganizationId | string) => {
  let data: IBillingContact | null = null;
  try {
    const response = await Request.getAxiosInstance().get<IBillingContact>(`/billing/organizations/${orgId}/customer`);
    data = response.data;
  } catch (e) {
    LOG.error(e);
  }

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const updateBillingCustomer = async (orgId: OrganizationId, email: string) => {
  let data: IBillingContact | null = null;
  try {
    const response = await Request.getAxiosInstance().put<IBillingContact>(`/billing/organizations/${orgId}/customer`, {
      email,
    });
    data = response.data;
  } catch (e) {
    LOG.error(e);
  }

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const getApplicationUsage = async (orgId: OrganizationId | string) => {
  let data = {} as IApplicationUsageStats;
  try {
    const response = await Request.getAxiosInstance().get<IApplicationUsageStats>(
      `/billing/organizations/${orgId}/usage`,
    );
    data = response.data;
  } catch (e) {
    LOG.error(e);
  }

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const getCurrentSubscription = async (orgId: OrganizationId | string) => {
  const { data } = await Request.getAxiosInstance().get<ISubscription>(`/billing/organizations/${orgId}/subscription`);

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const getCurrentSubscriptionLimit = async (orgId: OrganizationId | string) => {
  const { data } = await Request.getAxiosInstance().get<ISubscriptionLimit[]>(
    `/billing/organizations/${orgId}/subscription/limit`,
  );

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const getTeamFeatureLocks = async (orgId: OrganizationId | string, teamId: string) => {
  const { data } = await Request.getAxiosInstance().get<{ features: ISubscriptionFeatureLock[] }>(
    `/billing/organizations/${orgId}/teams/${teamId}/feature`,
  );

  return data.features;
};

/**
 * @deprecated use @writercolab/network
 */
export const calculate = async (
  orgId: OrganizationId | string,
  priceKey: IBillingPlan['key'],
  couponCode?: string,
  seats?: number,
): Promise<IBillingCalculation> => {
  const queryParams = new URLSearchParams();

  if (seats) {
    queryParams.append('seats', `${seats}`);
  }

  if (couponCode) {
    queryParams.append('code', couponCode);
  }

  const { data } = await Request.getAxiosInstance().get(
    `/billing/organizations/${orgId}/price/${priceKey}/calculate?${queryParams.toString()}`,
  );

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const getInvoiceList = async (orgId: OrganizationId | string): Promise<IInvoice[]> => {
  const { data } = await Request.getAxiosInstance().get(`/billing/organizations/${orgId}/invoice`);

  return data
    .filter(item => !!item.lines[0])
    .map(item => ({
      date: item.created,
      activity: item.lines[0]?.description,
      charge: formatAmount(item.amountDue),
      link: item.link,
    }));
};

/**
 * @deprecated use @writercolab/network
 */
export const updateSubscription = async (
  orgId: OrganizationId | string,
  priceKey: IBillingPlan['key'],
  seats?: number,
  couponCode?: string,
): Promise<ISetSubscription> => {
  const { data } = await Request.getAxiosInstance().put(`/billing/organizations/${orgId}/subscription`, {
    priceKey,
    code: couponCode,
    seats,
  });

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const startTrial = async (
  orgId: OrganizationId | string,
  priceKey?: IBillingPlan['key'],
): Promise<ISubscription> => {
  const params = priceKey ? `?priceKey=${priceKey}` : '';
  const { data } = await Request.getAxiosInstance().put(`/billing/organizations/${orgId}/subscription/trial${params}`);

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const cancelTrial = async (orgId: OrganizationId | string): Promise<ISubscription> => {
  const { data } = await Request.getAxiosInstance().delete(`/billing/organizations/${orgId}/subscription/trial`);

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const activateFreeSubscription = async (orgId: OrganizationId | string): Promise<ISubscription> => {
  const { data } = await Request.getAxiosInstance().post(`/billing/organizations/${orgId}/subscription/free`);

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const createSetupIntent = async (
  orgId: OrganizationId | string,
  paymentMethodId: IBillingPaymentMethod['paymentMethodId'],
): Promise<CreatePaymentMethodResponse> => {
  const result = {} as CreatePaymentMethodResponse;

  try {
    const { data } = await Request.getAxiosInstance().post(`/billing/organizations/${orgId}/setupIntent`, {
      paymentMethodId,
    });
    result.result = data;
  } catch (e) {
    result.error = new Error("There's a problem with your card. Update it or add a different card.");
    LOG.error(e);
  }

  return result;
};

/**
 * @deprecated use @writercolab/network
 */
export const setDefaultPaymentMethod = async (
  orgId: OrganizationId | string,
  paymentMethodId: IBillingPaymentMethod['paymentMethodId'],
): Promise<CreatePaymentMethodResponse> => {
  const result = {} as CreatePaymentMethodResponse;

  try {
    const { data } = await Request.getAxiosInstance().put(`/billing/organizations/${orgId}/paymentMethod`, {
      paymentMethodId,
    });
    result.result = data;
  } catch (e) {
    result.error = new Error("There's a problem with your card. Update it or add a different card.");
    LOG.error(e);
  }

  return result;
};

/**
 * @deprecated use @writercolab/network
 */
export const getIsEligibleForDiscount = async (orgId: OrganizationId | string): Promise<boolean> => {
  const { data } = await Request.getAxiosInstance().get(`/billing/organizations/${orgId}/discount`);

  return data.eligibleForOneMonthOffer;
};

/**
 * @deprecated use @writercolab/network
 */
export const applyForFreeMonthDiscount = async (orgId: OrganizationId | string): Promise<any> => {
  const { data } = await Request.getAxiosInstance().post(`/billing/organizations/${orgId}/discount`);

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const applyForFreeTeamWeeks = async (orgId: OrganizationId | string): Promise<any> => {
  const { data } = await Request.getAxiosInstance().post(`/billing/organizations/${orgId}/subscription/trial/restart`);

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const cancelSubscription = async (orgId: OrganizationId | string): Promise<any> => {
  const { data } = await Request.getAxiosInstance().delete(`billing/organizations/${orgId}/subscription`);

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const increaseSubscriptionSeatsAmount = async (
  orgId: OrganizationId | string,
  priceKey: IBillingPlan['key'],
  seatsNumber: number,
): Promise<ISetSubscription> => {
  const { data } = await Request.getAxiosInstance().put<ISetSubscription | ISetSubscription>(
    `billing/organizations/${orgId}/subscription`,
    {
      priceKey,
      seats: seatsNumber,
    },
  );

  return data;
};

/**
 * @deprecated use @writercolab/network
 */
export const saveCancellationSurvey = async (
  orgId: OrganizationId | string,
  reasons: Dictionary<string>,
  comments: string,
): Promise<any> => {
  const { data } = await Request.getAxiosInstance().put(`/billing/organizations/${orgId}/survey`, {
    survey: {
      ...reasons,
      comments,
    },
  });

  return data;
};
