/* eslint-disable no-restricted-syntax, @typescript-eslint/no-explicit-any */
import type { Dictionary } from 'lodash';
import { IntegrationType } from '@writercolab/types';
import type { IUserProfile, BillingStatus, IBillingPlan, ISubscription, UserRole } from '@writercolab/types';
import { toISOString } from '@writercolab/date-utils';
import LocalStorageService, { LocalStorageKey } from '../localStorage/localStorageService';
// TECHDEBT: WA-2649 Fix cycle dependencies within Analytics service

import { internalTrackActivity } from '../request';
import { getParamValue } from '../../utils';
import type { IOrganization } from '../../types/Organization';
import { SharedUtmQueryParam } from '../../types/shared';
import { getLogger } from '../../logger';

const LOG = getLogger('analytics');
type AnalyticEvents = any;
export type AnalyticsEventProperties = Record<string, any>;

export type TClickedFromParam =
  | 'trial_banner'
  | 'all_plans_widget'
  | 'suggestions'
  | 'terms'
  | 'snippets'
  | 'sidebar_terms'
  | 'sidebar_style'
  | 'sidebar_clarity'
  | 'sidebar_delivery'
  | 'sidebar_inclusivity'
  | 'user_activity_report'
  | 'admin_apps'
  | 'suggestions_report'
  | 'portal'
  | 'team_people'
  | 'sidebar_plagiarism'
  | 'sidebar_compliance'
  | 'SAML'
  | 'all_teams';

export enum GTMActivity {
  CUSTOM_FORM_SUBMIT = 'custom-form-submit',
  CUSTOM_PAGE_DETAILS = 'custom-page-details',
}

export enum GTMCategory {
  FORM_SUBMISSION = 'Form Submission',
}

export enum GTMActionLabel {
  EMAIL = 'Email',
  GOOGLE = 'Google',
  SAML_SSO = 'SAML SSO',
}

export interface GTMPageViewParams {
  title: string;
  url: string;
}

export interface GTMActivityParams {
  event: GTMActivity;
  userId: string;
  email: string | null;
  loggedIn?: string;
  planType?: string;
  isTrial?: string;
  eventLabel?: GTMActionLabel | string;
  eventLabel2?: string;
  eventAction?: string;
  org?: string;
  eventCategory?: GTMCategory;
}

interface IAnalytics {
  identify(
    user: IUserProfile,
    organization: IOrganization,
    subscription: ISubscription,
    role: UserRole,
    additionalInfoToPost: Dictionary<any>,
  ): IAnalytics;

  trackActivity(event: AnalyticEvents, properties?: Dictionary<any>): void;

  setIntegrationType(integrationType: IntegrationType): IAnalytics;

  setOrganizationId(organizationId: string): IAnalytics;

  setUserId(organizationId: string): IAnalytics;

  setUserEmail(organizationId: string): IAnalytics;

  trackSidebarActivity(event: AnalyticEvents, properties?: Dictionary<any>): void;

  trackInternalActivity(activity: AnalyticEvents, activityProperties?: Dictionary<any>): Promise<void>;

  setBillingPlanName(planName: IBillingPlan['productName']): IAnalytics;

  setBillingSubscriptionStatus(billingStatus: BillingStatus): IAnalytics;
}

/**
 * @deprecated use AnalyticsService from @writercolab/analytics
 */
export class Analytics implements IAnalytics {
  private integrationType: IntegrationType = IntegrationType.DEFAULT;

  private organizationId: string | number = '0';

  private userId = '0';

  private billingPlanName: IBillingPlan['productName'] | string = '';

  private billingSubscriptionStatus: BillingStatus | string = '';

  private userEmail = '';

  private static instance: Analytics = new Analytics();

  private static propertiesCollection: Dictionary<any> = {};

  private static buildUserId = (id: string | number): string => `segment-prefix-${id}`;

  static collectDynamicProperties = (): AnalyticsEventProperties =>
    LocalStorageService.getItem(LocalStorageKey.utmData) as AnalyticsEventProperties;

  static queryParamsDynamicProperties = (): AnalyticsEventProperties => {
    const queryParams = {};
    [
      SharedUtmQueryParam.CAMPAIGN,
      SharedUtmQueryParam.CONTENT,
      SharedUtmQueryParam.MEDIUM,
      SharedUtmQueryParam.SOURCE,
      SharedUtmQueryParam.TERM,
      SharedUtmQueryParam.UTM_REFERRER,
      SharedUtmQueryParam.REFERRER,
      SharedUtmQueryParam.PAGE_LABEL,
      SharedUtmQueryParam.GCLID,
    ].forEach(p => {
      const utmValue = getParamValue(p);

      if (utmValue) {
        queryParams[p] = utmValue;
      }
    });

    return queryParams;
  };

  static getInstance = (): Analytics => Analytics.instance;

  constructor() {
    if (Analytics.instance) {
      throw new Error('Already instantiated use getInstance()');
    }

    if (typeof window !== 'undefined') {
      (window as any).dataLayer = (window as any).dataLayer || [];
    }

    Analytics.instance = this;
  }

  page(pageTitle) {
    if (typeof window !== 'undefined') {
      (window as any).analytics?.page(pageTitle);
    }
  }

  private static buildUserAnalyticsPayload(
    user: IUserProfile,
    currentOrg: IOrganization,
    subscription: ISubscription,
    role?: UserRole,
  ): any {
    if (!user || !currentOrg) {
      return {};
    }

    const { billingPlanName, billingSubscriptionStatus } = Analytics.instance;
    const { email, id, lastName, firstName, lastOnlineTime, createdAt } = user;

    return {
      email,
      user_id: Analytics.buildUserId(id),
      org_id: currentOrg.id.toString(),
      company: currentOrg.name,
      customer_type: subscription.customerType,
      first_name: firstName,
      last_name: lastName,
      role,
      member_since: createdAt ? toISOString(createdAt) : '',
      last_seen: lastOnlineTime ? toISOString(lastOnlineTime) : '',
      account_status: subscription.status,
      plan_name: billingPlanName,
      subscription_plan: billingSubscriptionStatus,
    };
  }

  identify(
    user: IUserProfile,
    organization: IOrganization,
    subscription: ISubscription,
    role = '',
    additionalInfoToPost = {},
  ): IAnalytics {
    const segmentAnalytics = (window as any).analytics;

    if (!segmentAnalytics || !user) {
      return this;
    }

    const userData = Analytics.buildUserAnalyticsPayload(user, organization, subscription, role as UserRole);
    const userId = Analytics.buildUserId(user.id);

    Analytics.propertiesCollection = {
      ...userData,
      ...additionalInfoToPost,
    };

    segmentAnalytics.identify(userId, Analytics.propertiesCollection);

    const _appcues = (window as any).Appcues;

    if (_appcues) {
      _appcues.identify(userId, Analytics.propertiesCollection);
    }

    LOG.info('analytics identify called');

    return this;
  }

  /**
   * @deprecated please use @writercolab/analytics instead
   */
  trackActivity(activity: AnalyticEvents, activityProperties?: AnalyticsEventProperties): void {
    const dynamicEventProperties = Analytics.collectDynamicProperties();
    const queryParamsEventProperties = Analytics.queryParamsDynamicProperties();
    const eventProperties = {
      userId: this.userId,
      userEmail: this.userEmail,
      plan_name: this.billingPlanName,
      subscription_status: this.billingSubscriptionStatus,
      ...activityProperties,
      ...dynamicEventProperties,
      ...queryParamsEventProperties,
    };

    (window as any).analytics?.track(activity, eventProperties);
    LOG.info('trackActivity', activity);
  }

  /**
   * @deprecated please use @writercolab/analytics instead
   */
  // analytics is being sent through our BE via API request (similar to segment.io but proxied through BE)

  async trackInternalActivity(activity: AnalyticEvents, activityProperties?: AnalyticsEventProperties): Promise<void> {
    const dynamicEventProperties = Analytics.collectDynamicProperties();
    const queryParamsEventProperties = Analytics.queryParamsDynamicProperties();
    const eventProperties = { ...activityProperties, ...dynamicEventProperties, ...queryParamsEventProperties };

    if (!Analytics.propertiesCollection['org_id']) {
      return;
    }

    try {
      await internalTrackActivity(Analytics.propertiesCollection['org_id'], activity, eventProperties);
      LocalStorageService.removeItem(LocalStorageKey.utmData);
      LOG.info('trackInternalActivity', activity, eventProperties);
    } catch (e) {
      LOG.error(e);
    }
  }

  // for sidebar analytics
  setIntegrationType(integrationType: IntegrationType): IAnalytics {
    this.integrationType = integrationType;

    return this;
  }

  // for sidebar analytics
  setOrganizationId(organizationId: string | number): IAnalytics {
    this.organizationId = +organizationId;

    return this;
  }

  setBillingPlanName(planName: IBillingPlan['productName']): IAnalytics {
    this.billingPlanName = planName;

    return this;
  }

  setBillingSubscriptionStatus(billingStatus: BillingStatus): IAnalytics {
    this.billingSubscriptionStatus = billingStatus;

    return this;
  }

  setUserId(userId: string): IAnalytics {
    this.userId = userId;

    return this;
  }

  setUserEmail(userEmail: string): IAnalytics {
    this.userEmail = userEmail;

    return this;
  }

  /**
   * @deprecated please use @writercolab/analytics instead
   */
  async trackSidebarActivity(event: AnalyticEvents, properties?: Dictionary<any>) {
    const eventProperties = {
      ...properties,
      integration_type: this.integrationType,
      suggestion_ui: 'sidebar',
    };

    if (!this.organizationId) {
      return;
    }

    try {
      await internalTrackActivity(this.organizationId, event, eventProperties);
    } catch (e) {
      LOG.warn(e);
    }
  }

  /**
   * @deprecated please use @writercolab/analytics instead
   */
  async trackRewriteActivity(event: AnalyticEvents, properties?: Dictionary<any>) {
    const eventProperties = {
      ...properties,
      integration_type: this.integrationType,
      suggestion_ui: 'rewrite',
    };

    if (!this.organizationId) {
      return;
    }

    try {
      await internalTrackActivity(this.organizationId, event, eventProperties);
    } catch (e) {
      LOG.warn(e);
    }
  }

  async trackGtmPageView(params: any): Promise<void> {
    (window as any).dataLayer?.push({
      event: 'page_view',
      page: {
        url: params.url,
        title: params.title,
      },
    });
    LOG.info('trackGtmPageView', params);
  }

  trackGtmActivity(params: GTMActivityParams) {
    (window as any).dataLayer?.push(params);
    LOG.info('trackGtmActivity', params);
  }
}

export default Analytics;
