import type Delta from 'quill-delta';
import { Enum } from '@writercolab/utils';

export interface INavigatable {
  /** Route to navigate to */
  navTo?: string;
  /** set <a> target */
  navTarget?: string;
}

export enum SharedQueryParamStandardValue {
  positive = 'true',
  nagative = 'false',
}

export enum SharedUtmQueryParam {
  CAMPAIGN = 'utm_campaign',
  CONTENT = 'utm_content',
  MEDIUM = 'utm_medium',
  SOURCE = 'utm_source',
  TERM = 'utm_term',
  UTM_REFERRER = 'utm_referrer',
  REFERRER = 'referrer',
  PAGE_LABEL = 'page_label',
  REF = 'ref',
  GCLID = 'gclid',
}

export interface IDomElementOffset {
  top: number;
  left: number;
}

export interface IEditorParentRef {
  setEditorContent: (content: string) => void;
  pasteContentToTheEnd: (content: string) => void;
  isEditorContentEmpty: () => boolean;
  getEditorContent: () => Delta;
}

export interface IEnabledPrompt {
  promptId: string;
  enabled: boolean;
}

export const TUserStatusFilter = new Enum('active', 'invite_pending', 'approval_pending');
