import type { ReactNode } from 'react';
import React from 'react';
import cx from 'clsx';
import random from 'lodash/random';
import type { SkeletonProps } from 'react-loading-skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './styles.module.css';
import { MuiTableRow, MuiTableCell } from '../mui';
import { Icon, IconVariant } from '../Icon';
import { Logo } from '../Logo';

export enum SkeletonLoaderType {
  DEFAULT = 'default',
  ISSUE_CARD = 'issue_card',
  CATEGORY_ITEM = 'category_item',
  TEAM_STATS_SECTION = 'team_stats_section',
  YOUR_BILLING_PLAN_WIDGET = 'your_billing_plan_widget',
  GLOBAL_NAV = 'global_nav',
  DOCS_LIST = 'docs_list',
  SINGLE_SIGN_ON = 'single_sign_on',
  MAGIC_LINKS = 'magic_links',
  DRAFTS = 'drafts',
  CIRCLE = 'circle',
  FILE_BOX = 'file_box',
  CHAT_MESSAGE_SUB_QUESTION = 'chat_message_sub_question',
  CHAT_MESSAGES = 'chat_messages',
  CHAT_MESSAGE = 'chat_message',
}

export interface SkeletonLoaderProps extends SkeletonProps {
  type: SkeletonLoaderType;
  repeat?: number;
  style?: React.CSSProperties;
}

const repeatNTimes = (item: ReactNode, n: number) =>
  Array(n)
    .fill(true)
    // eslint-disable-next-line @eslint-react/no-array-index-key
    .map((_, i) => <span key={i}>{item}</span>);

const DocsListLoader: React.FC<{ count?: number; className?: string }> = ({ count = 1, className = '' }) => (
  <>
    {Array(count)
      .fill(true)
      .map((_, i) => (
        // eslint-disable-next-line @eslint-react/no-array-index-key
        <MuiTableRow className={cx(styles.docsRow, className)} key={i}>
          <MuiTableCell align="center" className={styles.docsIcon}>
            <Icon name={IconVariant.DOCUMENT} />
          </MuiTableCell>
          <MuiTableCell align="left">
            <Skeleton width="100%" height={10} />
          </MuiTableCell>
          <MuiTableCell align="left">
            <Skeleton width="100%" height={10} />
          </MuiTableCell>
          <MuiTableCell align="left">
            <Skeleton width="100%" height={10} />
          </MuiTableCell>
          <MuiTableCell align="left">
            <Skeleton width="100%" height={10} />
          </MuiTableCell>
          <MuiTableCell align="right">
            <Skeleton width="100%" height={10} />
          </MuiTableCell>
        </MuiTableRow>
      ))}
  </>
);

const GlobalNavLoader = () => (
  <div className={styles.menuItemMainBox}>
    <div className={styles.menuLogos}>
      <Skeleton circle height={60} width={60} />
    </div>
    <div className={styles.menuSwitcher}>
      <Skeleton width={88} height={5} className={styles.animatingLine} />
      <Skeleton width={120} height={10} className={styles.animatingLine} />
    </div>
    <div className={styles.menuItems}>
      <div className={styles.menuItem}>
        <Skeleton circle width={12} height={12} className={styles.animatingLine} />
        <Skeleton width={170} height={12} className={styles.animatingLine} />
      </div>
      <div className={styles.menuItem}>
        <Skeleton circle width={12} height={12} className={styles.animatingLine} />
        <Skeleton width={170} height={12} className={styles.animatingLine} />
      </div>
      <div className={styles.menuItem}>
        <Skeleton circle width={12} height={12} className={styles.animatingLine} />
        <Skeleton width={170} height={12} className={styles.animatingLine} />
      </div>
      <div className={styles.menuItem}>
        <Skeleton circle width={12} height={12} className={styles.animatingLine} />
        <Skeleton width={170} height={12} className={styles.animatingLine} />
      </div>
      <div className={styles.menuItem}>
        <Skeleton circle width={12} height={12} className={styles.animatingLine} />
        <Skeleton width={170} height={12} className={styles.animatingLine} />
      </div>
    </div>
  </div>
);

const IssueCardLoader: React.FC = () => (
  <div className={styles.issuecardMainBox}>
    <Skeleton circle height={8} width={8} />
    <Skeleton width={88} height={5} className={styles.animatingLine} />
  </div>
);

const CategoryItemLoader = () => (
  <div className={styles.categoryMainBox}>
    <Skeleton circle height={8} width={8} />
    <div>
      <Skeleton width={70} height={5} className={styles.animatingLine} />
      <Skeleton width={random(15, 60)} height={5} className={styles.animatingLine} />
    </div>
    <Skeleton width={10} height={5} className={styles.score} />
  </div>
);

const TeamStatsSection = () => (
  <div className={styles.teamStatsMainBox}>
    <div className={styles.mainAnimationBox}>
      <Skeleton width={22} height={22} circle />
      <Skeleton width={random(50, 99)} height={13} className={styles.animatingLine} />
    </div>
    <Skeleton width={random(50, 99)} height={13} style={{ marginTop: 18 }} />
    <div className={styles.statsBox}>
      {repeatNTimes(
        <div>
          <Skeleton width={63} height={13} />
          <br />
          <Skeleton width={25} height={13} />
        </div>,
        6,
      )}
    </div>
  </div>
);
const YourBillingPlanWidget = () => (
  <div className={styles.billingPlanMainBox}>
    <div className={styles.billingContainer}>
      <div className={styles.spaceBetween}>
        <Skeleton width={138} height={13} />
        <Skeleton width={54} height={13} />
      </div>
      <Skeleton width={random(70, 99)} height={13} className={styles.animatingLine} />
      <Skeleton width={random(70, 99)} height={13} className={styles.animatingLine} />
    </div>
    <div className={styles.billingContainer}>
      <Skeleton width={random(70, 99)} height={13} className={styles.animatingLine} />
      <div className={styles.spaceBetween}>
        <Skeleton width={45} height={9} className={styles.animatingLine} style={{ marginRight: 20 }} />
        <div>
          <Skeleton width="100%" height={4} className={styles.animatingLine} />
        </div>
      </div>
    </div>
    <Skeleton width={80} height={13} className={styles.managePlanButton} />
  </div>
);

const SingleSignOnLoader: React.FC<{ count?: number }> = ({ count = 1 }) => (
  <>
    {Array(count)
      .fill(true)
      .map((_, i) => (
        // eslint-disable-next-line @eslint-react/no-array-index-key
        <div className={styles.singleSignOnContainer} key={i}>
          <Skeleton width={180} height={20} />
          <Skeleton className={styles.headerLine} width="100%" height={2} />
          <div className={styles.identityWrapper}>
            <div className={styles.identitySection}>
              <Skeleton width={187} height={132} />
              <div className={styles.identityColumn}>
                <Skeleton width={120} height={24} />
                <Skeleton width={120} height={24} />
              </div>
            </div>
            <Skeleton className={styles.headerLine} width="100%" height={2} />
            <div className={styles.identitySection}>
              <Skeleton width={600} height={32} />
            </div>
            <Skeleton className={styles.headerLine} width="100%" height={2} />
            <div className={styles.identitySection}>
              <Skeleton width={600} height={32} />
            </div>
            <Skeleton className={styles.headerLine} width="100%" height={2} />
          </div>
        </div>
      ))}
  </>
);

const MagicLinksLoader: React.FC<{ count?: number }> = ({ count = 1 }) => (
  <>
    {Array(count)
      .fill(true)
      .map((_, i) => (
        // eslint-disable-next-line @eslint-react/no-array-index-key
        <div className={styles.magicLinks} key={i}>
          <Skeleton width={165} height={18} />
          <Skeleton className={styles.magicLinksText} width={193} height={17} />
          <Skeleton className={styles.magicLinksText} width={193} height={17} />
          <Skeleton className={styles.magicLinksText} width={119} height={17} />
          <Skeleton className={styles.magicLinksEnd} width={193} height={17} />
        </div>
      ))}
  </>
);

export const DraftsListLoader: React.FC<{ count?: number; className?: string }> = ({ count = 13, className = '' }) => (
  <>
    {Array(count)
      .fill(true)
      .map((_, i) => (
        // eslint-disable-next-line @eslint-react/no-array-index-key
        <div className={cx(styles.draftsRow, className)} key={i}>
          <div style={{ width: '51%' }}>
            <Skeleton width="100%" height={10} />
          </div>

          <div style={{ width: '25%' }}>
            <Skeleton width="100%" height={10} />
          </div>

          <div style={{ width: '10%' }}>
            <Skeleton width="100%" height={10} />
          </div>
        </div>
      ))}
  </>
);

const CircleLoader: React.FC = () => (
  <div className={styles.circleLoader}>
    <div className={styles.loaderContainerWrapper}>
      <div className={styles.loaderContainer}>
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

const FileBoxLoader = () => (
  <div className={styles.billingPlanMainBox}>
    <Skeleton width="60%" height={10} className={cx(styles.animatingLine, styles.centeredLine)} />
    <Skeleton width="30%" height={10} className={cx(styles.animatingLine, styles.centeredLine)} />
  </div>
);

const ChatMessageSubQuestionLoader = () => (
  <>
    <Skeleton width="100%" height={8} />
    <Skeleton width="100%" height={8} />
    <Skeleton width="100%" height={8} />
    <Skeleton width="60%" height={8} />
  </>
);

const ChatMessagesLoader = () => (
  <div className={styles.chatMessagesLoader}>
    <div className={styles.chatMessageLoaderContainer}>
      <div className={styles.chatMessageLoaderAvatar} />
      <div className={styles.chatMessageLoaderContent}>
        <Skeleton width="100%" height={12} />
      </div>
    </div>

    <div className={styles.chatMessageLoaderContainer}>
      <Logo className={styles.chatMessageLoaderAvatar} />

      <div className={cx(styles.chatMessageLoaderContent, styles.chatMessageLoaderContentLarge)}>
        <Skeleton width="100%" height={12} />
        <Skeleton width="100%" height={12} />
        <Skeleton width="100%" height={12} />
        <Skeleton width="100%" height={12} />
        <Skeleton width="100%" height={12} />
        <Skeleton width="60%" height={12} />
      </div>
    </div>
  </div>
);

const ChatMessageLoader = () => (
  <div>
    <Skeleton width="100%" count={100} height={12} />
    <Skeleton width="60%" height={12} />
  </div>
);

const Item = {
  [SkeletonLoaderType.ISSUE_CARD]: <IssueCardLoader />,
  [SkeletonLoaderType.CATEGORY_ITEM]: <CategoryItemLoader />,
  [SkeletonLoaderType.TEAM_STATS_SECTION]: <TeamStatsSection />,
  [SkeletonLoaderType.YOUR_BILLING_PLAN_WIDGET]: <YourBillingPlanWidget />,
  [SkeletonLoaderType.GLOBAL_NAV]: <GlobalNavLoader />,
  [SkeletonLoaderType.DOCS_LIST]: <DocsListLoader />,
  [SkeletonLoaderType.SINGLE_SIGN_ON]: <SingleSignOnLoader />,
  [SkeletonLoaderType.MAGIC_LINKS]: <MagicLinksLoader />,
  [SkeletonLoaderType.DRAFTS]: <DraftsListLoader />,
  [SkeletonLoaderType.CIRCLE]: <CircleLoader />,
  [SkeletonLoaderType.FILE_BOX]: <FileBoxLoader />,
  [SkeletonLoaderType.CHAT_MESSAGE_SUB_QUESTION]: <ChatMessageSubQuestionLoader />,
  [SkeletonLoaderType.CHAT_MESSAGES]: <ChatMessagesLoader />,
  [SkeletonLoaderType.CHAT_MESSAGE]: <ChatMessageLoader />,
};

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ type, repeat, className, style, ...props }) => {
  if (type === SkeletonLoaderType.DEFAULT) {
    // Documentation for props - https://github.com/dvtng/react-loading-skeleton?tab=readme-ov-file#skeleton-and-skeletontheme
    return <Skeleton className={className} style={style} {...props} />;
  } else if (type === SkeletonLoaderType.DOCS_LIST) {
    return <DocsListLoader className={className} count={repeat} />;
  } else {
    return (
      <div className={cx(className, styles.wrapper)} style={style}>
        {repeat ? repeatNTimes(Item[type], repeat) : Item[type]}
      </div>
    );
  }
};

export default SkeletonLoader;
