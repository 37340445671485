import { useState, useRef, useEffect, useCallback, type RefObject, type Dispatch, type SetStateAction } from 'react';

export function useVisible(initialIsVisible: boolean): {
  dropdownRef: RefObject<HTMLDivElement>;
  triggerRef: RefObject<HTMLDivElement>;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
} {
  const [isVisible, setIsVisible] = useState(initialIsVisible);

  useEffect(() => {
    setIsVisible(initialIsVisible);
  }, [initialIsVisible]);

  const triggerRef = useRef<HTMLDivElement>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!isVisible) {
        // skip if dropdown is not visible
        return;
      }

      if (!triggerRef?.current || !dropdownRef?.current) {
        // skip if dropdown is not initialized
        return;
      }

      if (event.composedPath().includes(triggerRef.current) || event.composedPath().includes(dropdownRef.current)) {
        // skip if click was inside dropdown elements
        return;
      }

      setIsVisible(false);
    },
    [isVisible],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return { dropdownRef, triggerRef, isVisible, setIsVisible };
}
