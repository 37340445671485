import type {
  E_INTEGRATION_TYPE,
  IIssue,
  IssueFlag,
  SidebarViewMode,
  TOrgTeamUserActivityParams,
} from '@writercolab/types';
import type { cmudict } from '@writercolab/cmudict';
import { CategoryType, IssuesPipeline, SNIPPET_SHORTCUT_PREFIX, SNIPPETS_LOAD_LIMIT } from '@writercolab/types';
import type { RequestServiceInitialize } from '@writercolab/network';
import { AnalyticsServiceBase, type IAnalyticsTrack, RequestAdapter } from '@writercolab/analytics';
import { action, comparer, computed, makeObservable, observable, runInAction } from 'mobx';
import { FleschKincaidService } from '@writercolab/text-utils';
import { AiAssistantSubscriptionModel } from '@writercolab/models';
import { createNanoEvents, type Emitter } from 'nanoevents';
import { PromisedModel } from '@writercolab/mobx';
import { isDocumentIsValid, requiredObject } from '@writercolab/utils';
import { createFirebaseClient } from '@writercolab/firebase';
import { SidebarStateModel } from './SidebarStateModel';
import { type IIssuesModelParams, IssuesModel } from '../issues';
import { SizeModel } from '../size';
import { DocumentStatsModel } from '../documentStats';
import { type ISnippetsModelParams, SnippetsModel } from '../snippets';
import type {
  IFirebaseConfig,
  IFirebaseSettings,
  ISidebarEvents,
  DocumentStatTypes,
  IIssuesModel,
} from '../utils/types';
import type { IApiParams, ISidebarClient } from '../sidebarApi';
import { createSidebarClient } from '../utils';
import { CategoriesModel, type ICategoriesModel, type ICategoriesModelOpts } from '../categories';
import { SidebarDataModel } from '../sidebarData';
import { CollaborationModel } from '../collaboration';
import { getLogger } from '../logger';
import { type TUISidebarModelsAnalyticsParams, UISidebarModelsAnalyticsActivity } from '../../analytics';

const LOG = getLogger('SidebarModel');

export type ISidebarModelParamsBase = Pick<
  IIssuesModelParams,
  'isIssueVisible' | 'onSelectionStrategy' | 'getLocalContent'
> & {
  requestService: RequestServiceInitialize;
  issues?: IIssuesModel;
  categories?: ICategoriesModel;
  eventBus?: Emitter<ISidebarEvents>;
  subscriptionModel?: AiAssistantSubscriptionModel;
  appRoot: string;
  documentId(): string | undefined;
  teamId(): number | undefined;
  organizationId(): number | undefined;
  personaId(): number | undefined;
  teamName(): string;
  sidebarMode?(): SidebarViewMode;
  width?(): number;
  defaultStatItem?(): typeof DocumentStatTypes.type;
  useDropdownPortal?(): boolean;
  isSnippetsCategoryHidden?(): boolean;
  isTeamAdmin(): boolean;
  isOrgAdmin(): boolean;
  integrationType: typeof E_INTEGRATION_TYPE.type;
  getCmudict: () => undefined | typeof cmudict; // import { cmudict } from '@writercolab/cmudict';
} & (
    | {
        analytics: IAnalyticsTrack<TUISidebarModelsAnalyticsParams, TOrgTeamUserActivityParams>;
      }
    | {
        userId(): number | undefined;
        userEmail(): string | undefined;
      }
  );

export type ISidebarModelParams =
  | (ISidebarModelParamsBase & {
      sidebarDataModel: SidebarDataModel;
      firebase?: never;
    })
  | (ISidebarModelParamsBase & {
      firebase: {
        config: IFirebaseConfig;
        settings: IFirebaseSettings;
      };
      sidebarDataModel?: never;
    });

/**
 * Represents the Sidebar Model.
 */
export class SidebarModel {
  constructor(private readonly opts: ISidebarModelParams) {
    const { firebase, requestService, sidebarDataModel, subscriptionModel } = opts;

    this.analytics =
      'analytics' in opts
        ? opts.analytics
        : new AnalyticsServiceBase([new RequestAdapter(requestService.api)], {
            integrationType: opts.integrationType,
          }).withContext({
            team_id: opts.teamId,
            organization_id: opts.organizationId,
            user_id: opts.userId,
            user_email: opts.userEmail,
          });
    this.eventBus = opts.eventBus ?? createNanoEvents<ISidebarEvents>();

    this.dataModel =
      sidebarDataModel ??
      new SidebarDataModel({
        getLocalContent: opts.getLocalContent,
        client: createFirebaseClient({
          config: firebase.config,
          settings: firebase.settings,
          request: requestService.api,
          params: {
            organizationId: () => this.organizationId,
            teamId: () => opts.teamId(),
            documentId: () => this.documentId,
            personaId: opts.personaId() ?? '',
            userId: () => undefined,
          },
        }),
      });

    this.documentStats = new DocumentStatsModel({
      sidebarData: this.dataModel,
      issues: () => this.issues,
      calculateGrade: (text, ignoreListRegex) => {
        const cmudict = this.opts.getCmudict();

        if (!cmudict) {
          return undefined;
        }

        return new FleschKincaidService(cmudict).calculateGrade(text, ignoreListRegex);
      },
    });

    this.$initialPipeline = new PromisedModel({
      name: '$initialPipeline',
      load: async () => {
        if (!this.documentId) {
          return undefined;
        }

        return this.dataModel.api?.getDocumentPipeline?.().then(this.initDocumentPipeline);
      },
    });

    this.subscription =
      subscriptionModel ??
      new AiAssistantSubscriptionModel({
        api: requestService.api,
        teamId: opts.teamId,
        organizationId: opts.organizationId,
      });

    this.size = new SizeModel(() => this.width);

    this.collaboration = new CollaborationModel({
      sidebarDataModel: this.dataModel,
    });

    this.sidebarState = new SidebarStateModel({
      sidebarData: this.dataModel,
      subscription: this.subscription,
      issues: () => this.issues,
      size: this.size,
      categories: () => this.categories,
      documentId: () => opts.documentId(),
      organizationId: () => opts.organizationId(),
      teamId: () => opts.teamId(),
      isTeamAdmin: () => opts.isTeamAdmin(),
      integrationType: opts.integrationType,
      sidebarMode: () => opts.sidebarMode?.(),
      appRoot: opts.appRoot,
    });

    makeObservable(this, {
      requestClient: computed,
      categories: computed,
      issues: computed,
      snippets: computed,

      pipeline: observable,

      organizationId: computed,
      documentId: computed,
      width: computed,
      teamId: computed,
      appRoot: computed,

      setPipeline: action.bound,
    });
  }

  private readonly $requestClientOpts = computed(
    () => {
      const { opts } = this;

      return requiredObject<IApiParams>({
        organizationId: opts.organizationId(),
        teamId: opts.teamId(),
        personaId: opts.personaId(),
        documentId: opts.documentId(),
        request: opts.requestService.api,
      });
    },
    { equals: comparer.structural },
  );

  /**
   * Gets the request client for the sidebar.
   * @returns The request client if available, otherwise undefined.
   */
  get requestClient(): ISidebarClient | undefined {
    const params = this.$requestClientOpts.get();

    return params ? createSidebarClient(params) : undefined;
  }

  private readonly $categoriesOpts = computed(() => {
    const { opts } = this;

    return requiredObject<Omit<ICategoriesModelOpts, 'getStyleGuide' | 'getSubscription'>>({
      organizationId: opts.organizationId(),
      teamId: opts.teamId(),
      appRoot: opts.appRoot,
    });
  });

  /**
   * Gets the categories model.
   * @returns The categories model if available, otherwise undefined.
   */
  get categories(): ICategoriesModel | undefined {
    const { opts } = this;

    if (opts.categories) {
      return opts.categories;
    }

    const p = this.$categoriesOpts.get();

    if (!p) {
      return undefined;
    }

    const params = {
      ...p,
      getStyleGuide: () => this.dataModel.styleguide.data,
      getSubscription: () => this.subscription.$subscription.value,
    };

    return new CategoriesModel(params);
  }

  private readonly $issuesOpts = computed(
    () => {
      const { opts } = this;

      return requiredObject<Pick<IIssuesModelParams, 'documentId' | 'categories'>>({
        documentId: opts.documentId(),
        categories: this.categories,
      });
    },
    { equals: comparer.structural },
  );

  /**
   * Gets the issues model.
   * @returns The issues model if available, otherwise undefined.
   */
  get issues(): IIssuesModel | undefined {
    const { opts } = this;

    if (opts.issues) {
      return opts.issues;
    }

    const params = this.$issuesOpts.get();

    if (!params) {
      return undefined;
    }

    return new IssuesModel({
      ...params,
      analytics: this.analytics,
      eventBus: this.eventBus,
      apiFlagSuggestionAsWrong: (issue: IIssue, state: IssueFlag, segment: string, comment?: string | null) =>
        this.requestClient?.api?.flagSuggestionAsWrong(issue, state, segment, comment) as Promise<void>,
      apiBulkFlagSuggestionsAsWrong: flagSuggestionsResults =>
        this.requestClient?.api?.flagSuggestionsAsWrong(flagSuggestionsResults) as Promise<void>,
      apiSuggestionComment: (issue: IIssue, comment: string) =>
        this.requestClient?.api?.suggestionComment(issue, comment) as Promise<void>,
      apiReportOnAcceptSuggestion: (replacement: string, issue: IIssue, segment: string) =>
        this.requestClient?.api?.reportOnAcceptSuggestion(replacement, issue, segment) as Promise<void>,
      apiBulkReportOnAcceptSuggestions: (replacement: string, issues: IIssue[], segment: string) =>
        this.requestClient?.api?.reportOnAcceptSuggestions(replacement, issues, segment) as Promise<void>,

      firebaseIssuesData: () => this.dataModel.issuesData,
      documentContentData: () => this.dataModel.documentContentData,
      onSelectionStrategy: this.opts.onSelectionStrategy,
      isIssueVisible: this.opts.isIssueVisible,
      getLocalContent: this.opts.getLocalContent,
    });
  }

  /**
   * Represents the document statistics model.
   */
  public readonly documentStats: DocumentStatsModel;

  private readonly $snippetsOpts = computed(
    () => {
      const { opts } = this;

      return requiredObject<ISnippetsModelParams>({
        api: opts.requestService.api,
        defaultLimit: SNIPPETS_LOAD_LIMIT,
        defaultPrefix: SNIPPET_SHORTCUT_PREFIX,
        teamId: opts.teamId(),
        organizationId: opts.organizationId(),
      });
    },
    { equals: comparer.structural },
  );

  /**
   * Gets the snippets model.
   * @returns The snippets model if available, otherwise undefined.
   */
  get snippets(): SnippetsModel | undefined {
    const params = this.$snippetsOpts.get();

    if (!params) {
      return undefined;
    }

    return new SnippetsModel(params);
  }

  /**
   * The analytics service used by the sidebar model.
   */
  public readonly analytics: IAnalyticsTrack<TUISidebarModelsAnalyticsParams, TOrgTeamUserActivityParams>;

  /**
   * Represents the Firebase model.
   */
  public readonly dataModel: SidebarDataModel;

  /**
   * Represents the subscription model for the sidebar.
   */
  public readonly subscription: AiAssistantSubscriptionModel;

  /**
   * Represents the size of the sidebar.
   */
  public readonly size: SizeModel;

  /**
   * Represents the state of the sidebar.
   */
  public readonly sidebarState: SidebarStateModel;

  /**
   * Represents the collaboration model for the sidebar.
   */
  public readonly collaboration: CollaborationModel;

  /**
   * Represents the event bus for the sidebar model.
   */
  public readonly eventBus: Emitter<ISidebarEvents>;

  $initialPipeline: PromisedModel<IssuesPipeline | undefined>;

  public pipeline: IssuesPipeline | undefined = undefined;

  get appRoot() {
    return this.opts.appRoot;
  }

  get width() {
    return this.opts.width?.();
  }

  get organizationId() {
    return this.opts.organizationId();
  }

  get documentId() {
    const docId = this.opts.documentId();

    if (isDocumentIsValid(docId)) {
      return docId;
    }

    return undefined;
  }

  get teamId() {
    return this.opts.teamId();
  }

  get teamName() {
    return this.opts.teamName();
  }

  get integrationType() {
    return this.opts.integrationType;
  }

  get defaultStatItem() {
    return this.opts.defaultStatItem?.();
  }

  get useDropdownPortal() {
    return this.opts.useDropdownPortal?.() ?? false;
  }

  get isSnippetsCategoryHidden() {
    return this.opts.isSnippetsCategoryHidden?.();
  }

  get isOrgAdmin() {
    return this.opts.isOrgAdmin();
  }

  private readonly initDocumentPipeline = (pipeline: IssuesPipeline | undefined) =>
    runInAction(() => {
      if (!pipeline || (pipeline === IssuesPipeline.FULL && !this.collaboration.isCollaborative)) {
        return this.setPipeline(IssuesPipeline.BASIC);
      } else if (pipeline === IssuesPipeline.PLAGIARISM) {
        this.categories?.setCategory(CategoryType.PLAGIARISM);

        return this.setPipeline(IssuesPipeline.PLAGIARISM);
      }

      return this.setPipeline(pipeline);
    });

  async setPipeline(pipeline: IssuesPipeline) {
    if (this.pipeline === pipeline) {
      return pipeline;
    }

    await this.requestClient?.api?.changeDocumentPipeline(pipeline);

    this.pipeline = pipeline;

    return this.pipeline;
  }

  readonly onSelectCategory = (categoryType: CategoryType) => {
    this.categories?.setCategory(categoryType);

    if (categoryType === CategoryType.PLAGIARISM) {
      this.sidebarState.forceSidebarLoading();
    }

    this.eventBus.emit('onSelectCategory', this.categories?.selectedCategory);

    this.analytics.track(UISidebarModelsAnalyticsActivity.filteredSuggestions, {
      filter_category: categoryType || CategoryType.ALL,
    });
  };

  readonly onRefreshScore = () => {
    this.sidebarState.forceSidebarLoading();
    this.requestClient?.api?.refreshScore();
    this.eventBus.emit('onRefreshScore');
  };

  readonly onRefreshScoreClick = () => {
    this.analytics.track(UISidebarModelsAnalyticsActivity.refreshScore, {});
    this.onRefreshScore();
  };

  readonly onSnippetClick = (id: string, snippet: string) => {
    this.requestClient?.api?.reportSnippetApply(id);

    this.analytics.track(UISidebarModelsAnalyticsActivity.snippetClicked, {
      id,
      snippet,
    });

    this.eventBus.emit('onSnippetClicked', snippet);
  };

  readonly onDocumentStatOptionChange = (statType: typeof DocumentStatTypes.type) => {
    this.eventBus.emit('onChangeStatOption', statType);
  };

  readonly hasEventSubscriber = (event: keyof ISidebarEvents) => !!this.eventBus.events[event]?.length;
}
