import { isLocalEnv } from '../environment/environment';
import { getLogger } from '../../logger';

const LOG = getLogger('localStorageService');

// TODO: merge this with useLocalStorage
export enum LocalStorageKey {
  utmData = 'utmData',
  chromeWindowVisible = 'chromeWindowVisible',
  setTeamTrial = 'setTeamTrial',
  usersLastTeam = 'user`s last team for orgs',
  lastSelectedOrgIds = 'lastSelectedOrgIds',
  triggerLogoutInAllTabsParams = 'trigger logout in all tabs params',
  educationalTermsModuleVisible = 'educationalTerms',
  educationalSnippetsModuleVisible = 'educationalSnippets',
  tutorialVisible = 'tutorial visible',
  usersLastDocument = 'users last document',
  usersRouteBeforeLogin = 'users last route before login',
  coWriteState = 'coWriteState',
  usersDashboardRouteBeforeLogin = 'users last dashboard route before login',
  coWriteNextState = 'coWriteNextState',
  coWriteCategoryNextState = 'coWriteCategoryNextState',
  jobsQueueLastActiveJob = 'jobsQueueLastActiveJob',
  coWriteBlogNextState = 'coWriteBlogNextState_1',
  seoBlogDraftUsed = 'seoBlogDraftUsed',
  lastRewritePrompt = 'lastRewritePrompt',
  lastDraftValues = 'lastDraftValues',
  lastDraft = 'lastDraft',
  chatMessageDocumentBootstrapContent = 'chatMessageDocumentBootstrapContent',
  askIntroductionModalHidden = 'askIntroductionModalHidden',
  askAutoWritePopupHidden = 'askAutoWritePopupHidden',
  queryParamsState = 'queryParamsState',
  onboardingShowcaseViewedVideos = 'onboardingShowcaseViewedVideos',
  onboardingShowcaseHidden = 'onboardingShowcaseHidden',
  onboardingState = 'onboardingState',
  inAppBackButtonState = 'inAppBackButtonState',
}

export default class LocalStorageService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static setItem(key: LocalStorageKey, value: any): void {
    if (isLocalEnv()) {
      LOG.info('local storage set: ', key, value);
    }

    window.localStorage.setItem(key, JSON.stringify(value));
  }

  public static getItem<T>(key: string, otherwise?: T): T | null {
    try {
      const data: string | null = window.localStorage.getItem(key);

      if (data !== null) {
        return JSON.parse(data);
      }

      if (otherwise) {
        return otherwise;
      }
    } catch (e) {
      LOG.error(e);
    }

    return null;
  }

  public static removeItem(key: LocalStorageKey): void {
    if (isLocalEnv()) {
      LOG.info('local storage remove: ', key);
    }

    window.localStorage.removeItem(key);
  }
}
