
const digest = 'a9ac9cd597da959f464251ad9790f4b9093d472df23e4f24bf4bb7e82269bff4';
const css = `.styles-module__GjhvnWr {
}

.styles-module__WbVXE22 {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.3px;
  line-height: 15px;
  text-transform: uppercase;
  user-select: none;
}

.styles-module__WbVXE22 > span:hover > .styles-module__Qclb4N4 {
  background-color: var(--writer-graynu-2);
}

.styles-module__Qclb4N4 {
  padding: 5.5px 3.5px 5.5px 4.5px;
  border-radius: 50%;
  margin: 2px 4px;
  background-color: var(--writer-graynu-1);
}

.styles-module__JCghgLQ {
  padding: 5.5px 4.5px 5.5px 3.5px;
  transform: rotate(180deg);
}

.styles-module__Qclb4N4 svg path {
  fill: var(--tabs-link-color);
}

.styles-module__3rUzm2s {
  color: var(--classic-grey-3);
}

.styles-module__z8DLdU6 {
  color: var(--classic-blue-4);
}

.styles-module__z8DLdU6 .styles-module__Qclb4N4 {
  transform: rotate(180deg);
}
`;

export default {"container":"styles-module__GjhvnWr","buttonContainer":"styles-module__WbVXE22","icon":"styles-module__Qclb4N4","arrowUp":"styles-module__JCghgLQ","seeMore":"styles-module__3rUzm2s","seeLess":"styles-module__z8DLdU6"};
export { css, digest };
  