import React from 'react';
import cx from 'clsx';
import { IconsCommonArrowRight } from '@writercolab/assets';
import { Text, TextSize } from '@writercolab/ui-atoms';

import styles from './FkChangeIndicator.module.css';

export interface IFkChangeIndicatorProps {
  className?: string;
  from: number;
  to: number;
}

export const FkChangeIndicator: React.FC<IFkChangeIndicatorProps> = ({ className, from, to }) => (
  <div className={cx(className, styles.fkChangeIndicator)}>
    <div className={cx(styles.label, styles.from)}>
      <Text variant={TextSize.M} bold>
        {from}
      </Text>
    </div>
    <IconsCommonArrowRight />
    <div className={cx(styles.label, styles.to)}>
      <Text variant={TextSize.M} bold>
        {to}
      </Text>
    </div>
  </div>
);
