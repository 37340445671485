import { computed } from 'mobx';

import { initializeFirestore } from 'firebase/firestore';

import { PromisedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize } from '@writercolab/network';

import type { SidebarDataClient, IFirebaseConfig, ISidebarDataParams, IFirebaseSettings } from '@writercolab/types';
import { FirebaseApi } from './FirebaseApi';
import { initApp } from './initApp';
import { loadUser } from './loadUser';

export interface IFirebaseClientOptions {
  config: IFirebaseConfig;
  settings: IFirebaseSettings;
  request: RequestServiceInitialize['api'];
  params: ISidebarDataParams;
}

export function createFirebaseClient({ config, request, settings, params }: IFirebaseClientOptions) {
  const app = initApp(config, settings);

  const user = new PromisedModel({
    name: 'user',
    load: async () => {
      const organizationId = params.organizationId();

      if (!organizationId) {
        return undefined;
      }

      return loadUser(app, request, settings, organizationId);
    },
  });

  const api = computed(() => {
    if (user.status !== 'fulfilled') {
      return undefined;
    }

    const firestore = initializeFirestore(app, settings.firestoreSettings || {}, config.databaseId);

    return new FirebaseApi(firestore, params);
  });

  const getParams = (): ISidebarDataParams => params;

  return Object.defineProperties({} as SidebarDataClient, {
    params: {
      enumerable: true,
      configurable: true,
      get: getParams,
    },
    user: {
      enumerable: true,
      configurable: true,
      get() {
        return user.value;
      },
    },
    api: {
      enumerable: true,
      configurable: true,
      get() {
        return api.get();
      },
    },
  });
}
