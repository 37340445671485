import { IssueCategory } from '@writercolab/common-utils';

export const TERM_SKIP_CATEGORIES = new Set([
  IssueCategory.CommonMistakes,
  IssueCategory.BannedWords,
  IssueCategory.UseCarefully,
  IssueCategory.PassiveVoice,
  IssueCategory.SentenceComplexity,
  IssueCategory.HealthyCommunication,
  IssueCategory.Plagiarism,
]);
