import { Enum } from './enum';

export const MsOfficeExtendedFileFormats = new Enum('docx', 'pptx', 'xlsx');
export type TMsOfficeExtendedFileFormats = typeof MsOfficeExtendedFileFormats.type;

export const convertBytesToMb = (bytes: number): number => bytes / 1024 / 1024;

export const isMsOfficeExtendedFileFormat = (format: string): boolean =>
  MsOfficeExtendedFileFormats.is(format.toLowerCase());

export const mapFileExtensions = (formats: string[]): string[] =>
  formats.flatMap(f => (isMsOfficeExtendedFileFormat(f) ? [f.slice(0, -1), f] : f));

export const isFileExceedingSizeLimit = (file: File, size: number): boolean => convertBytesToMb(file.size) > size;
