/**
 * Forms date into MMM DD, YYYY
 * Example: returns Oct 31, 1990
 * @param date
 * @return {string}
 */
export function getDateFormatMMMDDYYYY(date?: string | Date | null) {
  if (!date) {
    return '-';
  }

  const dateObj = new Date(date);
  const dateInfo = dateObj.toDateString().split(' '); // ["Fri","Sep","14","2018"]

  return `${dateInfo[1]} ${Number(dateInfo[2])}, ${dateInfo[3]}`;
}

/**
 * Forms date into MM/DD/YYYY
 * Example: returns 10/31/1990
 * @param date
 * @return {string}
 */
export function getDateFormatMMDDYYYY(text?: string | Date | number) {
  if (!text) {
    return '-';
  }

  const date = new Date(text);

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

/**
 * Forms date into MMDDYY
 * @param date
 * @return {string}
 */
export function getDateFormatMMDDYY(date: string) {
  if (!date) {
    return '-';
  }

  const dateObj = new Date(date);
  const dateInfo = dateObj.toDateString().split(' '); // ["Fri","Sep","14","2018"]

  return `${dateInfo[1]} ${Number(dateInfo[2])}, ${dateInfo[3]}`;
}

/**
 * Returns number of days based on epoch time
 * eg.: Date.now() - new Date(prevDate) => 15
 * @param datetime (in epoch)
 * @return {number}
 */
export const getNumberOfDays = (date: number): number => Math.ceil(date / 1000 / (3600 * 24));

/**
 * Returns updated date with added days
 * Eg. ("2021-02-16T16:09:44Z", 30) => Thu Mar 18 2021
 * @param date
 * @param daysToBeAdded
 * @return {String}
 */
export const addDaysToDate = (date: string, daysToBeAdded: number): string => {
  const _date = new Date(date);
  _date.setDate(_date.getDate() + daysToBeAdded);

  return _date.toDateString();
};

/**
 * Returns if the passed date is Today's or not
 * @param text
 * @return {boolean}
 */
export function isDateTodays(text: string) {
  const _date = new Date(text);
  const _todaysDate = new Date();

  return _todaysDate.toDateString() === _date.toDateString();
}

/**
 * Returns if the passed date is Yesterday's or not
 * @param text
 * @return {boolean}
 */
export function isDateYesterdays(text: string) {
  const _date = new Date(text);

  const _today = new Date();
  const _yesterday = new Date(_today);
  _yesterday.setDate(_yesterday.getDate() - 1);

  return _yesterday.toDateString() === _date.toDateString();
}

/**
 * Checks if the given date is within the past week.
 *
 * @param {string} inputDate - The date string to be checked.
 * @returns {boolean} True if the date is within the past week, false otherwise.
 */
export const isDatePastWeek = (inputDate: string): boolean => {
  const date = new Date(inputDate);
  const now = new Date();

  now.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  const diffInDays = Math.floor((now.getTime() - date.getTime()) / (1000 * 3600 * 24));

  return date <= now && diffInDays <= 7;
};

/**
 * Forms date into
 *    MMM DD, HH:MMam/pm
 * or if the date is same into
 *    HH:MMam/pm
 * Example: returns June 9, 10:50pm or 10:50pm
 * @param string
 * @return {string}
 */
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export function getDateTime(text: string, showYear?: boolean) {
  if (!text) {
    return '-';
  }

  const date = new Date(text);
  const _monthName = monthNames[date.getMonth()];
  const _date = date.getDate();
  let _hours = date.getHours();
  const _amOrPm = _hours > 11 ? 'pm' : 'am';

  _hours %= 12;
  _hours = _hours !== 0 ? _hours : 12; // the hour '0' should be '12'
  const _minutes = `0${date.getMinutes()}`.slice(-2);

  const _time = `${_hours}:${_minutes}${_amOrPm}`;
  const _year = showYear ? `${date.getFullYear()}, ` : '';

  return isDateTodays(text) ? `${_time}` : `${_monthName} ${_date}, ${_year}${_time}`;
}

export function getTimeOrDateWithYear(text: string) {
  if (!text) {
    return '-';
  }

  const date = new Date(text);
  const _monthName = monthNames[date.getMonth()];
  const _date = date.getDate();
  let _hours = date.getHours();
  const _amOrPm = _hours > 11 ? 'pm' : 'am';

  _hours %= 12;
  _hours = _hours !== 0 ? _hours : 12;
  const _minutes = `0${date.getMinutes()}`.slice(-2);
  const _time = `${_hours}:${_minutes}${_amOrPm}`;

  return isDateTodays(text) ? `${_time}` : `${_monthName} ${_date}, ${date.getFullYear()}`;
}

export function getTimeOrDate(text: string): string {
  if (!text) {
    return '-';
  }

  const date = new Date(text);
  const _monthName = monthNames[date.getMonth()];
  const _date = date.getDate();
  let _hours = date.getHours();
  const _amOrPm = _hours > 11 ? 'pm' : 'am';

  _hours %= 12;
  _hours = _hours !== 0 ? _hours : 12;
  const _minutes = `0${date.getMinutes()}`.slice(-2);
  const _time = `${_hours}:${_minutes}${_amOrPm}`;

  return isDateTodays(text) ? `${_time}` : `${_monthName} ${_date}`;
}

export function timeSince(date: number) {
  const seconds = Math.floor((+new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years`;
  }

  interval = seconds / 2592000;

  if (interval > 1) {
    return `${Math.floor(interval)} months`;
  }

  interval = seconds / 86400;

  if (interval > 1) {
    return `${Math.floor(interval)} days`;
  }

  interval = seconds / 3600;

  if (interval > 1) {
    return `${Math.floor(interval)} hours`;
  }

  interval = seconds / 60;

  if (interval > 1) {
    return `${Math.floor(interval)} mins`;
  }

  return `${Math.floor(seconds)} sec`;
}

export function toISOString(date: string): string {
  return new Date(date).toISOString();
}

/**
 * Forms date into MMM, YYYY
 * Example: returns Oct, 1990
 * @param date
 * @return {string}
 */
export function getDateFormatMMMYYYY(date?: string | Date | null) {
  if (!date) {
    return '-';
  }

  const dateObj = new Date(date);
  const dateInfo = dateObj.toDateString().split(' '); // ["Fri","Sep","14","2018"]

  return `${dateInfo[1]}, ${dateInfo[3]}`;
}

/**
 * Forms date into
 *    MMM DD, HH:MMam/pm
 * or if the date is same into
 *    HH:MMam/pm
 * Example: returns June 9, 10:50pm or 10:50pm
 * @param string
 * @return {string}
 */
export const fullMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export function getFullDateFormatWithTime(text: string | null) {
  if (!text) {
    return '-';
  }

  const date = new Date(text);
  const _monthName = fullMonthNames[date.getMonth()];
  const _date = date.getDate();
  let _hours = date.getHours();
  const _amOrPm = _hours > 11 ? 'pm' : 'am';

  _hours %= 12;
  _hours = _hours !== 0 ? _hours : 12;
  const _minutes = `0${date.getMinutes()}`.slice(-2);
  const _time = `${_hours}:${_minutes}${_amOrPm}`;
  const _year = `${date.getFullYear()}`;

  return `${_monthName} ${_date}, ${_year}, ${_time}`;
}

export function getDateFormatMMMMYYYY(text?: string | Date | number) {
  if (!text) {
    return '-';
  }

  const date = new Date(text);

  return `${fullMonthNames[date.getMonth()]} ${date.getFullYear()}`;
}

export function getDateFormatMMMMDDYYYY(text?: string | Date | number) {
  if (!text) {
    return '-';
  }

  const date = new Date(text);

  return `${fullMonthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

/**
 * Forms relative date from the given date
 * Example: "Just now", "Today", "Yesterday", "Oct 31, 1990"
 * @param date
 * @param options
 * @param options.showJustNow - If true, returns "Just now" if applicable
 * @param options.showMinutesAgo - If true, returns "A few minutes ago" if applicable
 * @param options.showHoursAgo - If true, returns "A few hours ago" if applicable
 * @param options.showToday - If true, returns "Today" if applicable
 * @param options.showYesterday - If true, returns "Yesterday" if applicable
 * @param options.showTime - If true, returns date with time
 * @return {string}
 */
export function getRelativeDate(
  date: string | Date,
  options: {
    showJustNow?: boolean;
    showMinutesAgo?: boolean;
    showHoursAgo?: boolean;
    showToday?: boolean;
    showYesterday?: boolean;
    showTime?: boolean;
  } = {},
) {
  const _date = new Date(date);
  const _today = new Date();

  const diff = Math.abs(_today.getTime() - _date.getTime());
  const minMs = 60000;

  const { showJustNow, showMinutesAgo, showHoursAgo, showToday, showYesterday, showTime } = {
    showJustNow: true,
    showMinutesAgo: true,
    showHoursAgo: true,
    showToday: true,
    showYesterday: true,
    showTime: false,
    ...options,
  };

  if (showJustNow && diff < minMs) {
    return 'Just now';
  }

  if (showMinutesAgo && diff < minMs * 10) {
    return 'A few minutes ago';
  }

  if (showHoursAgo && diff < minMs * 60 * 3) {
    return 'A few hours ago';
  }

  if (showToday && _date.toDateString() === _today.toDateString()) {
    return 'Today';
  }

  const _yesterday = new Date(_today);
  _yesterday.setDate(_yesterday.getDate() - 1);

  if (showYesterday && _date.toDateString() === _yesterday.toDateString()) {
    return 'Yesterday';
  }

  const formattedDate = getDateFormatMMMDDYYYY(_date);

  if (showTime) {
    return `${formattedDate}, ${_date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;
  }

  return formattedDate;
}
