import type { components, operations, RequestServiceInitialize } from '@writercolab/network';
import type { IIssue, IIssueStatusPayload, IssuesPipeline } from '@writercolab/types';
import { IssueFlag } from '@writercolab/types';
import type { ISidebarApi, TApiParams } from './types';

export const createSidebarApi = (
  request: RequestServiceInitialize['api'],
  { organizationId, teamId, personaId, documentId }: TApiParams,
) => {
  const ret: ISidebarApi = {
    getWriterXAuthToken: () =>
      request
        .put('/api/auth/organization/{organizationId}/firebase/login', {
          params: {
            path: {
              organizationId,
            },
          },
        })
        .then(r => r.data),

    getSnippetsListRequest: (requestParams: operations['findAllSnippets']['parameters']['query']) =>
      request
        .get('/api/terminology/organization/{organizationId}/team/{teamId}/snippet', {
          params: {
            path: {
              organizationId,
              teamId,
            },
            query: requestParams,
          },
        })
        .then(r => r.data),

    reportOnAcceptSuggestion: async (replacement: string, issue: IIssue, segment: string) => {
      const issueWithStatus: IIssueStatusPayload = {
        ...issue,
        state: IssueFlag.ACCEPT,
        issueId: issue.issueId,
        stateDescription: 'ACCEPT',
      };

      const payload: components['schemas']['content_dto_LegacySetIssuesStatusRequest'] = {
        organizationId,
        workspaceId: teamId,
        documentId,
        segment,
        issues: [issueWithStatus],
      };

      const { data } = await request.post('/contentscore/organization/workspace/document/personaId/issues/set/status', {
        body: payload,
        params: {},
      });

      return data;
    },

    reportOnAcceptSuggestions: async (replacement: string, issues: IIssue[], segment: string) => {
      const issuesWithStatus = issues.map(issue => ({
        ...issue,
        state: IssueFlag.ACCEPT,
        issueId: issue.issueId,
        stateDescription: 'ACCEPT',
      }));

      const payload: components['schemas']['content_dto_LegacySetIssuesStatusRequest'] = {
        organizationId,
        workspaceId: teamId,
        documentId,
        segment,
        issues: issuesWithStatus,
      };

      const { data } = await request.post('/contentscore/organization/workspace/document/personaId/issues/set/status', {
        body: payload,
        params: {},
      });

      return data;
    },

    flagSuggestionAsWrong: async (issue: IIssue, state: IssueFlag, segment: string, comment?: string | null) => {
      const payload: components['schemas']['content_dto_SetIssueStatusRequest'] = {
        segment,
        state,
        stateDescription: comment || '',
        highlight: issue.highlight,
      };

      const res = await request.post(
        '/api/content/organization/{organizationId}/team/{teamId}/document/{documentId}/issue/{issueId}/status',
        {
          body: payload,
          params: {
            path: {
              organizationId,
              teamId,
              documentId,
              issueId: issue.issueId,
            },
          },
        },
      );

      return res.data;
    },

    flagSuggestionsAsWrong: async flagSuggestionsResults => {
      const issuesStatusChanges = flagSuggestionsResults.map(flagSuggestionResult => ({
        issueId: flagSuggestionResult.issue.issueId,
        segment: flagSuggestionResult.segment,
        state: flagSuggestionResult.state,
        stateDescription: flagSuggestionResult.comment || '',
        highlight: flagSuggestionResult.issue.highlight,
      }));
      const payload: components['schemas']['content_dto_SetIssuesStatusRequest'] = {
        issues: issuesStatusChanges as components['schemas']['content_dto_SetIssuesStatusRequest']['issues'],
      };

      const res = await request.post(
        '/api/content/organization/{organizationId}/team/{teamId}/document/{documentId}/issue/status',
        {
          body: payload,
          params: {
            path: {
              organizationId,
              teamId,
              documentId,
            },
          },
        },
      );

      return res.data;
    },

    suggestionComment: async (issue: IIssue, comment: string) => {
      const payload: components['schemas']['content_dto_UpdateIssueStatus'] = {
        stateDescription: comment,
      };

      const res = await request.put(
        '/api/content/organization/{organizationId}/team/{teamId}/document/{documentId}/issue/{issueId}/status',
        {
          body: payload,
          params: {
            path: {
              organizationId,
              teamId,
              issueId: issue.issueId,
              documentId,
            },
          },
        },
      );

      return res.data;
    },

    changeDocumentPipeline: async (pipelineId: IssuesPipeline) => {
      const res = await request.put(
        '/api/content/organization/{organizationId}/workspace/{workspaceId}/persona/{personaId}/document/{documentId}/content/pipeline',
        {
          params: {
            path: {
              organizationId,
              workspaceId: teamId,
              documentId,
              personaId,
            },
          },
          body: {
            pipelineId,
          },
        },
      );

      return res.data;
    },

    refreshScore: () =>
      request
        .put(
          '/api/content/organization/{organizationId}/workspace/{workspaceId}/persona/{personaId}/document/{documentId}/content/refresh',
          {
            params: {
              path: {
                organizationId,
                workspaceId: teamId,
                documentId,
                personaId,
              },
            },
          },
        )
        .then(r => r.data),

    reportSnippetApply: (snippetId: string) =>
      request
        .put('/api/terminology/organization/{organizationId}/team/{teamId}/snippet/{snippetId}/apply', {
          params: {
            path: {
              organizationId,
              teamId,
              snippetId,
            },
          },
        })
        .then(r => r.data),
  };

  return ret;
};
