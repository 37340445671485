import type { SuggestionCategoryType, IUserProfile } from '@writercolab/types';
import type { ReactNode } from 'react';

export interface ISuggestionSection<T> {
  category: string;
  enabled: boolean;
  meta: T;
}

export enum ReadabilityIndex {
  WRITER = 'writer',
  FLESCH_KINCAID = 'flesch-kincaid-grade-level',
}

export enum ReadabilityWriterType {
  SIMPLE = 'simple',
  GENERAL = 'general',
  ADVANCED = 'advanced',
  CUSTOM = 'custom',
}

export enum ReadabilitySentenceComplexity {
  DONT_CHECK = '0',
  GRADE_8 = '8',
  GRADE_12 = '12',
  GRADE_14 = '13',
}

export interface ISuggestionSettings {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categories: ISuggestionSection<any>[];
  teamId: number;
  modifiedUser: IUserProfile | null;
  createTime: string;
  updateTime: string;
}

export interface ISuggestionMetaRules {
  rules: string[];
}

export interface ISuggestionMetaSensitivity {
  age: boolean;
  disability: boolean;
  genderIdentity: boolean;
  raceEthnicityNationality: boolean;
  sexualOrientation: boolean;
  substanceUse: boolean;
}

export interface ISuggestionMetaClarity {
  wordiness: boolean;
  infinitive: boolean;
  passiveVoice: boolean;
  unclearReferences: boolean;
  vocabularySimplification: boolean;
}

export interface ISuggestionMetaSpellChecker {
  language: string;
}

export interface ISuggestionMetaMetaReadability {
  grade: number;
  ignore: string[];
  index: ReadabilityIndex;
  paragraphLength: string;
  sentenceComplexity: number;
  vocabulary: number;
  selected: ReadabilityWriterType;
}

export interface ISuggestionMetaInclusivity {
  useGenderInclusivePronouns: boolean;
  useGenderInclusiveNouns: boolean;
}

export interface ISuggestionMetaAcronym {
  commonAcronymCheck: boolean;
  defaultIgnore: boolean;
  myIgnore: boolean;
  ignore: string[];
  format?: string | null;
}

export interface ISuggestionMetaPlagiarism {
  ignore: string[];
}

export interface ISuggestionMetaEmoji {
  bannedEmojis: string[];
}

export interface StructureRule {
  dropdownValues: string[];
  examples: [];
  ruleId: string;
}

export interface ISuggestionExampleContents {
  id: string;
  content: ReactNode;
}

export interface ISuggestionExampleConfig {
  id: string;
  title: string;
  contents: ISuggestionExampleContents[];
}
