import type { IContentStats, FleschKincaidService } from '@writercolab/text-utils';
import { convertMinsToHrsMins, removeIgnoredTags } from '@writercolab/text-utils';
import { type IStyleguideCategory, SuggestionCategoryType } from '@writercolab/types';
import { Enum } from '@writercolab/utils';
import { makeObservable, computed } from 'mobx';
import sum from 'lodash/sum';
import type { SidebarDataModel } from '../sidebarData';
import type { IssuesModel } from '../issues';

export const AVERAGE_WORDS_PER_MINUTE = 265;

export const GradeScoreColor = new Enum('green', 'blue', 'purple', 'gray');

export interface IDocumentStatsModelParams {
  sidebarData: Pick<SidebarDataModel, 'styleguideData' | 'documentContentData'>;
  issues: () => Pick<IssuesModel, 'sidebarIssues'> | undefined;
  calculateGrade(text: string, ignoreListRegex?: RegExp | null): IContentStats | undefined;
}

export class DocumentStatsModel {
  private static FLESCH_KINCAID_MIN_WORDS_COUNT = 10;
  private readonly sidebarData: IDocumentStatsModelParams['sidebarData'];
  private readonly issues: IDocumentStatsModelParams['issues'];
  private readonly calculateGrade: Required<IDocumentStatsModelParams>['calculateGrade'];

  constructor({ sidebarData, issues, calculateGrade }: IDocumentStatsModelParams) {
    this.sidebarData = sidebarData;
    this.issues = issues;

    this.calculateGrade = calculateGrade;

    makeObservable(this, {
      readability: computed,
      ignoreReadabilityList: computed,
      contentMetrics: computed,
      contentWithoutIgnoredTags: computed,
      readingTime: computed,
      readingTimeReadable: computed,
      avgSentenceLength: computed,
      isFleschKincaidScoreAvailable: computed,
      fleschKincaidScore: computed,
      fleschKincaidScoreValueReadable: computed,
      gradeColor: computed,
      score: computed,
    });
  }

  get readability(): undefined | IStyleguideCategory {
    return this.sidebarData.styleguideData?.categories?.find(d => d.category === SuggestionCategoryType.READABILITY);
  }

  get ignoreReadabilityList() {
    return (
      !!this.readability && this.readability.enabled && this.readability.meta?.index === 'flesch-kincaid-grade-level'
    );
  }

  get ignoreListRegex() {
    if (!this.sidebarData.styleguideData || !this.ignoreReadabilityList || !this.readability?.meta) {
      return null;
    }

    const ignoreList = this.readability.meta.ignore as string[];
    // sort ignore list by length to match bigger terms first
    const _lowercasedIgnoreList = ignoreList?.sort((a, b) => b.length - a.length).map(word => word.toLowerCase()) || [];

    return new RegExp(_lowercasedIgnoreList.join('|'), 'g');
  }

  get contentWithoutIgnoredTags(): string | undefined {
    const content = this.sidebarData.documentContentData;

    return !content ? undefined : removeIgnoredTags(content);
  }

  get contentMetrics(): IContentStats | undefined {
    if (!this.sidebarData.styleguideData) {
      return undefined;
    }

    const { contentWithoutIgnoredTags } = this;

    if (!contentWithoutIgnoredTags) {
      return undefined;
    }

    return this.calculateGrade(contentWithoutIgnoredTags, this.ignoreListRegex);
  }

  get readingTime() {
    const { contentMetrics } = this;

    if (!contentMetrics) {
      return null;
    }

    return contentMetrics.wordCount > 0 ? Math.round(contentMetrics.wordCount / AVERAGE_WORDS_PER_MINUTE) : null;
  }

  get avgSentenceLength() {
    const { contentMetrics } = this;

    if (!contentMetrics) {
      return 0;
    }

    return contentMetrics.wordCount > 0 ? +(contentMetrics.wordCount / contentMetrics.sentenceCount).toFixed(1) : 0;
  }

  get fleschKincaidScore() {
    return this.contentMetrics?.grade;
  }

  get isFleschKincaidScoreAvailable() {
    if (!this.contentMetrics) {
      return false;
    }

    return this.contentMetrics?.wordCount >= DocumentStatsModel.FLESCH_KINCAID_MIN_WORDS_COUNT;
  }

  get fleschKincaidScoreValueReadable() {
    if (!this.fleschKincaidScore) {
      return undefined;
    }

    if (this.isFleschKincaidScoreAvailable) {
      return this.fleschKincaidScore > 14 ? '14+' : this.fleschKincaidScore.toFixed(1);
    } else {
      return '-';
    }
  }

  get readingTimeReadable() {
    if (this.readingTime === null) {
      return '';
    } else {
      return this.readingTime >= 1 ? convertMinsToHrsMins(this.readingTime) : '<1 min';
    }
  }

  get gradeColor() {
    if (!this.isFleschKincaidScoreAvailable || !this.fleschKincaidScore) {
      return GradeScoreColor.enum.gray;
    }

    if (this.fleschKincaidScore >= 0 && this.fleschKincaidScore <= 7) {
      return GradeScoreColor.enum.green;
    }

    if (this.fleschKincaidScore > 7 && this.fleschKincaidScore <= 9) {
      return GradeScoreColor.enum.blue;
    }

    if (this.fleschKincaidScore > 9) {
      return GradeScoreColor.enum.purple;
    }

    return GradeScoreColor.enum.gray;
  }

  get score() {
    const { contentMetrics } = this;

    if (!this.sidebarData.styleguideData || !contentMetrics) {
      return { value: 100, available: false };
    }

    if (contentMetrics.wordCount === 0) {
      return { value: 100, available: true };
    }

    const scoreSection = this.sidebarData.styleguideData.sections.filter(
      section => section.scoreEnabled && section.scoreWeight !== 0,
    );
    const available = scoreSection.length > 0;

    const categoryScores = scoreSection.map(category => {
      const factor = category.scoreWeight;

      const issuesInCategory =
        this.issues()?.sidebarIssues.filter(issue => category.categories.includes(issue.category)).length || 0;
      const normalizedDocumentLength = contentMetrics.wordCount / factor;
      const normalizedIssues = issuesInCategory + normalizedDocumentLength;
      const score = normalizedDocumentLength / normalizedIssues;

      return score * 100;
    });

    const value = Math.round(sum(categoryScores) / categoryScores.length) || 100;

    return { value, available };
  }
}
