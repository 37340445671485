/* eslint-disable @typescript-eslint/no-explicit-any */
import { Enum } from './enum';

/**
 * Information about a log message.
 */
export interface ILogInfo {
  /** The name of the logger. */
  name: string;

  /** The name of the module that the logger is associated with. */
  module: string;
}

/**
 * A function that logs a message.
 * @param namespace - The namespace to log the message under.
 * @param msg - The message to log.
 */
export interface ILog {
  (namespace: string): (...msg: any[]) => void;

  /** A function that formats a log message. */
  format?(info: ILogInfo, mode: string): string;
}

let MIDDLEWARE: ILog[] = [];

/**
 * Adds a new logger middleware function to the middleware array.
 * @param logger - The logger middleware function to add.
 * @returns True if the logger was added successfully, false otherwise.
 */
export function addMiddleware(logger: ILog) {
  if (MIDDLEWARE.includes(logger)) {
    return false;
  }

  MIDDLEWARE.push(logger);

  return true;
}

/**
 * Removes a logger middleware function from the middleware array.
 * @param logger - The logger middleware function to remove.
 */
export function removeMiddleware(logger: ILog) {
  const md = MIDDLEWARE.filter(md => md !== logger);

  if (md.length !== MIDDLEWARE.length) {
    MIDDLEWARE = md;
  }
}

/** The default log message formatting function. */
const defaultFormat: Required<ILog>['format'] = (info, mode) => {
  const namespace = info.module ? `${info.module}:${info.name}` : info.name;

  return `${mode.toUpperCase()}:${namespace} -`;
};

/**
 * Creates a new logger controller object.
 * @param info - Information about the logger.
 * @returns A new logger controller object.
 */
function createCtrl(info: ILogInfo) {
  const formatter = new Map<string, (msg: any[]) => void>();
  const format = (mode: string) => {
    const fn = formatter.get(mode);

    if (fn) {
      return fn;
    }

    const logs = MIDDLEWARE.map(md => {
      const namespace = (md.format ?? defaultFormat)(info, mode);

      return md(namespace);
    });
    const logsLen = logs.length;
    const fn2 = (msg: any[]) => {
      if (MIDDLEWARE.length !== logsLen) {
        formatter.delete(mode);
        format(mode)(msg);
      } else {
        logs.forEach(log => log(...msg));
      }
    };
    formatter.set(mode, fn2);

    return fn2;
  };

  /**
   * Logs a message in the specified mode.
   * @param mode - The mode to log the message in.
   * @param msg - The message to log.
   */
  const log = (mode: string, ...msg: any[]) => format(mode)(msg);

  return { log };
}

/** An enumeration of log message modes. */
const T_NAME = new Enum('trace', 'debug', 'log', 'info', 'warn', 'error');

/**
 * Creates a new logger object.
 * @param name - The name of the logger.
 * @param module - The name of the module that the logger is associated with.
 * @returns A new logger object.
 */
export function getLogger(name: string, module?: string) {
  const ctrl = createCtrl({ name, module: module ?? '' });

  const init = {} as Record<typeof T_NAME.type, (..._: any[]) => void> & {
    return: <T>(obj: T, ...args: any[]) => T;
  };

  for (const key of T_NAME) {
    init[key] = (...msg: any[]) => {
      init[key] = (...msg: any[]) => {
        ctrl.log(key, ...msg);
      };
      init[key](...msg);
    };
  }

  init.return = (obj, ...args) => {
    ctrl.log('return', ...args, obj);

    return obj;
  };

  return init;
}

/**
 * Creates a factory function for creating logger objects associated with a specific module.
 * @param module - The name of the module that the loggers created by this factory will be associated with.
 * @returns A factory function for creating logger objects.
 */
export function getLoggerFactory(module: string) {
  return (name: string) => getLogger(name, module);
}
