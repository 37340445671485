import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export type PreviewDocumentType = components['schemas']['media_model_FileType'];

export interface PreviewDocument {
  id: string;
  url: string;
  name: string;
  type: PreviewDocumentType;
  preConnected: boolean;
  scrapeUrl?: string | null;
}

export const DocumentFileTypes = new Enum<PreviewDocumentType>(
  'pdf',
  'txt',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'jpg',
  'png',
  'eml',
  'html',
  'url',
  'srt',
  'csv',
  'xls',
  'xlsx',
  'other',
  'mp3',
  'mp4',
);
