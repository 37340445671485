import UIParser from 'ua-parser-js';
import { E_CLIENT_ID, E_INTEGRATION_TYPE, H_INTEGRATION_TYPE_TO_CLIENT_ID } from '@writercolab/types';
import { Context } from './context';

/**
 * Represents the base structure for the system context.
 *
 * @typedef {Object} TSystemContextBase
 *
 * @property {string} integration_type - The type of integration.
 * @property {(typeof H_INTEGRATION_TYPE_TO_CLIENT_ID)[keyof typeof H_INTEGRATION_TYPE_TO_CLIENT_ID] | typeof E_CLIENT_ID.enum.Unknown} client_id - The client ID which can be one of the values from H_INTEGRATION_TYPE_TO_CLIENT_ID or Unknown from E_CLIENT_ID.enum.
 */
export type TSystemContextBase = {
  integration_type: string;
  client_id:
    | (typeof H_INTEGRATION_TYPE_TO_CLIENT_ID)[keyof typeof H_INTEGRATION_TYPE_TO_CLIENT_ID]
    | typeof E_CLIENT_ID.enum.Unknown;
};

/**
 * Represents the system context with additional properties.
 *
 * @typedef {TSystemContextBase} TSystemContextBase - The base type for system context.
 *
 * @property {string} 'x-system' - The name of the system.
 * @property {string} 'x-system-ver' - The version of the system.
 * @property {string} 'x-browser' - The name of the browser.
 * @property {string} 'x-browser-ver' - The version of the browser.
 * @property {string} 'x-domain' - The domain name.
 */
export type TSystemContext = TSystemContextBase & {
  'x-system': string;
  'x-system-ver': string;
  'x-browser': string;
  'x-browser-ver': string;
  'x-domain': string;
};

/**
 * Represents a system context that extends a generic context with additional system-related properties.
 *
 * @template TContext - A generic type extending a record with string keys and unknown values.
 *
 * @extends Context<TContext & TSystemContext>
 *
 * @param integrationType - The type of integration being used.
 * @param clientId - The client ID, defaults to a value derived from the integration type.
 * @param userAgent - The user agent string, defaults to the user agent of the current environment.
 * @param domain - The domain name, defaults to the hostname of the current environment.
 *
 * @remarks
 * This class is used to create a context that includes system-related information such as the operating system,
 * browser, and domain. It uses the `UIParser` to parse the user agent string and extract relevant information.
 *
 * @example
 * ```typescript
 * const systemContext = new SystemContext({
 *   integrationType: 'exampleIntegration',
 *   clientId: 'exampleClientId',
 *   userAgent: 'exampleUserAgent',
 *   domain: 'example.com'
 * });
 * ```
 *
 * @method static getClientId
 * @param integrationType - The type of integration.
 * @returns The client ID associated with the given integration type.
 */
export class SystemContext<TContext = {}> extends Context<TContext & TSystemContext> {
  constructor({
    integrationType,
    clientId = SystemContext.getClientId(integrationType),
    userAgent = (typeof window !== 'undefined' ? window : globalThis).navigator?.userAgent,
    domain = (typeof window !== 'undefined' ? window : globalThis).location.hostname,
  }: {
    clientId?: string;
    integrationType: string;
    userAgent?: string;
    domain?: string | (() => string);
  }) {
    const result = userAgent ? new UIParser(userAgent).getResult() : undefined;

    super({
      integration_type: integrationType,
      client_id: clientId,
      'x-system': result?.os.name,
      'x-system-ver': result?.os.version,
      'x-browser': result?.browser.name,
      'x-browser-ver': result?.browser.version,
      'x-domain': domain,
    } as TContext & TSystemContext);
  }

  /**
   * Retrieves the client ID based on the provided integration type.
   *
   * @param integrationType - The type of integration for which to get the client ID.
   * @returns The client ID corresponding to the given integration type, or `Unknown` if the integration type is not recognized.
   */
  static getClientId(integrationType: string): TSystemContextBase['client_id'] {
    return E_INTEGRATION_TYPE.is(integrationType)
      ? H_INTEGRATION_TYPE_TO_CLIENT_ID[integrationType]
      : E_CLIENT_ID.enum.Unknown;
  }
}
