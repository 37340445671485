import type { ReactNode } from 'react';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cx from 'clsx';
import type { DropdownActionOption, DropdownOption } from './types';
import { DropdownPlacement, ItemsTypes } from './types';

import styles from './styles.module.css';

import { useDropdownCoordinates } from './useDropdownCoordinates';
import { Text, TextSize } from '../typography/Text/Text';
import { Icon, IconVariant } from '../Icon';
import { HorizontalLine, LabelledTags } from '../MiscElements';
import { Tooltip } from '../Tooltip';
import { Tag, TagSize } from '../Tag';
import { Checkbox } from '../Checkbox';
import { useVisible } from '../../hooks/useVisible';
import { ColorDot } from '../ColorDot';

export * from './types';

/**
 * Please dont use this component for filter/sorting purposes.
 * For filter/sorting purposes use components from @writercolab/ui-molecules FilterHeader, SortingHeader
 */
export interface DropdownProps<T = string> {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Set inline css styles for select element */
  dropdownSelectStyles?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  /** Set dropdown container label */
  label?: string;
  /** Set additional css class for main container */
  containerClassName?: string;
  /** Set additional options css class */
  dropdownItemClassName?: string;
  /** Set additional css class for secondary options container */
  secondaryClassName?: string;
  /** Set additional css class for Dropdown container */
  dropDownContainerClassName?: string;
  /** Component on click on which the Dropdown list is opened */
  trigger: ReactNode;
  /** Static placement of Dropdown List */
  placement?: DropdownPlacement;
  /** Function to calculate placement */
  getPlacement?: (triggerRef: HTMLElement) => DropdownPlacement;
  /** Options for Dropdown List */
  options: DropdownOption<T>[];
  /** Set secondary action options for Dropdown List */
  secondaryOptions?: DropdownActionOption[];
  /** Set additional action options for Dropdown List */
  additionalOptions?: DropdownActionOption[];
  /** Type of List items. By default, (select type) items have "check mark" in the right. With "action" type this mark is absent */
  itemsType?: ItemsTypes;
  /** Set the handler to handle click event */
  onPrimaryOptionClickAction: (id: T) => void;
  /** Optional callback triggered when clicking on "trigger" component */
  onTriggerClickCallback?: () => void;
  /** Set additional highlight for action option */
  highlightActiveOption?: boolean;
  /** Enable options length transaction */
  truncated?: boolean;
  /** Enable options multiline display */
  multiLineOptions?: boolean;
  /** Doesn't show highlight color on hover */
  disableHighlightOnHover?: boolean;
  /** shows tooltip when hovering on trigger */
  tooltip?: string;
  /** shows Dropdown for tags */
  withTags?: boolean;
  /** className for the trigger */
  triggerClassName?: string;
  /** enable show on hover */
  showOnHover?: boolean;
  /** use checkbox inputs */
  useCheckbox?: boolean;
  /** hide popup on option click */
  hideOnCLick?: boolean;
  /** dropdown list will be appended to this DOM element via React.Portal */
  domContainer?: Element;
  /** Add additional description on bottom */
  description?: ReactNode;
  /** Add list header */
  header?: ReactNode;
  /** Test id to pass for unique element identification */
  testId?: string;
  /** Should update dropdown placement on getPlacement function change */
  shouldUpdatePlacementOnChange?: boolean;
}

interface RenderActionProps {
  options: DropdownActionOption[];
  optionSize?: TextSize;
  className?: string;
  separatorClassName?: string;
}

export const Dropdown = <T,>({
  placement = DropdownPlacement.BOTTOM_LEFT,
  getPlacement,
  itemsType = ItemsTypes.SELECT,
  label,
  truncated,
  multiLineOptions,
  trigger,
  options,
  onPrimaryOptionClickAction,
  onTriggerClickCallback,
  secondaryOptions,
  additionalOptions,
  highlightActiveOption,
  disableHighlightOnHover,
  tooltip,
  withTags,
  className,
  containerClassName,
  secondaryClassName,
  dropdownItemClassName,
  dropDownContainerClassName,
  triggerClassName,
  showOnHover = false,
  useCheckbox = false,
  hideOnCLick = true,
  domContainer,
  description,
  header,
  dropdownSelectStyles = {},
  testId,
  shouldUpdatePlacementOnChange = false,
  ...props
}: DropdownProps<T>) => {
  const [dropdownPlacement, setDropdownPlacement] = useState<DropdownPlacement>(placement);

  const [tooltipDisabled, setTooltipDisabled] = useState(!tooltip);
  const { triggerRef, dropdownRef, isVisible, setIsVisible } = useVisible(false);
  const { debouncedPortalCoords, onUpdatePortalCoords } = useDropdownCoordinates(
    triggerRef,
    dropdownPlacement,
    isVisible,
  );

  const updatePlacement = useCallback(() => {
    if (!triggerRef.current) {
      return;
    }

    const newPlacement = getPlacement?.(triggerRef.current);

    if (newPlacement && newPlacement !== dropdownPlacement) {
      setDropdownPlacement(newPlacement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlacement, dropdownPlacement]);

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (domContainer) {
      onUpdatePortalCoords();
    }

    if (getPlacement) {
      updatePlacement();
    }

    if (onTriggerClickCallback) {
      onTriggerClickCallback();
    }

    setIsVisible(!isVisible);
  };

  const onMouseOver = () => {
    if (showOnHover) {
      if (onTriggerClickCallback) {
        onTriggerClickCallback();
      }

      setIsVisible(true);
    }
  };

  const onMouseOut = () => showOnHover && setIsVisible(false);
  const elClass: string = cx(
    className,
    dropDownContainerClassName,
    styles.dropdownItemsContainer,
    styles[itemsType],
    styles[dropdownPlacement],
  );

  const renderDropdownPrimaryOptions = (actionFn: (id: T) => void) =>
    options?.map(
      ({
        name,
        id,
        active,
        icon,
        warning,
        color,
        action,
        description,
        optionLabel,
        optionLabelColor,
        customOptionName,
        className,
      }: DropdownOption<T>) => {
        const elClass = cx(styles.dropdownItem, dropdownItemClassName, className, {
          [styles.warning]: warning,
          [styles.highlight]: active && highlightActiveOption,
          [styles.withDescription]: !!description,
          [styles.truncated]: truncated,
          [styles.multiLine]: multiLineOptions,
          [styles.disableHover]: disableHighlightOnHover,
          [styles.withTags]: withTags,
          [styles.withCheckbox]: useCheckbox,
          [styles.withLabel]: !!optionLabel,
          [styles.centerIcon]: itemsType === ItemsTypes.ICON_INLINE,
        });

        const _handleClick = (id: T) => {
          // eslint-disable-next-line no-unused-expressions
          action ? action(id) : actionFn(id);

          if (hideOnCLick) {
            setIsVisible(false);
          }
        };

        return (
          <div
            aria-hidden
            className={elClass}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              _handleClick(id);
            }}
            key={`dropdown-value-${name}-${id}`}
            role="option"
            aria-selected={active}
          >
            {useCheckbox && (
              <div aria-hidden className={styles.dropdownItemCheckBox} onClick={e => e.stopPropagation()}>
                <Checkbox
                  checked={active}
                  onClick={() => _handleClick(id)}
                  className={styles.dropdownItemCheckBoxItem}
                />
              </div>
            )}
            {icon && icon}
            {color && <ColorDot color={color} className={cx(styles.dropdownItemIndicatorWrapper)} />}
            {withTags ? (
              <Tag size={TagSize.S}>{customOptionName || name}</Tag>
            ) : (
              <Text
                variant={TextSize.S}
                medium={active}
                className={cx({
                  [styles.withLabelText]: !!optionLabel,
                  [styles.textHidden]: itemsType === ItemsTypes.ICON_INLINE,
                  [styles.withWarning]: warning,
                })}
              >
                {customOptionName || name}
              </Text>
            )}
            {itemsType !== ItemsTypes.ACTION &&
              (withTags ? (
                <Icon
                  name={active ? IconVariant.BLUE_CHECKMARK : IconVariant.BLUE_UNCHECKEDMARK}
                  className={cx(styles.blueCheckIcon)}
                />
              ) : (
                <Icon
                  name={IconVariant.CHECK}
                  className={cx(styles.checkIcon, {
                    [styles.checkIconHidden]: !active,
                    [styles.textHidden]: itemsType === ItemsTypes.ICON_INLINE,
                  })}
                />
              ))}
            {optionLabel && <LabelledTags bgColor={optionLabelColor}>{optionLabel}</LabelledTags>}
            {description && (
              <Text variant={TextSize.XS} className={styles.description}>
                {description}
              </Text>
            )}
          </div>
        );
      },
    );

  const renderActionOptions = ({
    options,
    optionSize = TextSize.S,
    className,
    separatorClassName,
  }: RenderActionProps) => {
    if (options) {
      const getElClass = (highlighted = false) =>
        cx(styles.dropdownSecondaryContainer, secondaryClassName, styles.dropdownSecondaryItem, dropdownItemClassName, {
          [className ?? '']: highlighted,
        });

      const onActionClick = (action: () => void) => {
        setIsVisible(!isVisible);
        action();
      };

      return (
        <div>
          <HorizontalLine className={cx(styles.lineSeparator, separatorClassName)} />
          {options.map(({ id, action, name, highlighted }: DropdownActionOption) => (
            <div aria-hidden key={id} className={getElClass(highlighted)} onClick={() => onActionClick(action)}>
              <Text variant={optionSize}>{name}</Text>
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (shouldUpdatePlacementOnChange) {
      updatePlacement();
    }
  }, [shouldUpdatePlacementOnChange, updatePlacement]);

  useEffect(() => {
    if (tooltip?.length) {
      setTooltipDisabled(isVisible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const renderDropdownList = () =>
    isVisible ? (
      <div ref={dropdownRef} className={elClass} style={dropdownSelectStyles}>
        {label && (
          <div className={styles.dropdownContainerLabel}>
            <Text smallCaps>{label}</Text>
          </div>
        )}

        {header && <div className={styles.header}>{header}</div>}

        {renderDropdownPrimaryOptions(onPrimaryOptionClickAction)}

        {secondaryOptions &&
          renderActionOptions({
            options: secondaryOptions,
            separatorClassName: cx({
              [styles.separatorNoPrimaryOptions]: !options.length,
            }),
          })}

        {additionalOptions &&
          renderActionOptions({
            options: additionalOptions,
            className: cx(styles.dropdownAdditional),
          })}

        {description && <div className={styles.descriptionContainer}>{description}</div>}
      </div>
    ) : null;

  const renderDropdownListInPortal = useCallback(
    () =>
      domContainer &&
      debouncedPortalCoords &&
      createPortal(
        <div className={styles.portalContainer} style={debouncedPortalCoords}>
          {renderDropdownList()}
        </div>,
        domContainer,
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedPortalCoords, domContainer],
  );

  return (
    <div
      className={cx(containerClassName, styles.dropdownContainer, {
        [styles.noPrimaryOptions]: !options?.length,
      })}
      onFocus={onMouseOver}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseOut}
      onBlur={onMouseOut}
      data-testid={testId}
      role="listbox"
      tabIndex={0}
      {...props}
    >
      <Tooltip title={tooltip || ''} disabled={tooltipDisabled}>
        <div
          aria-hidden
          data-open={isVisible}
          onClick={onClick}
          className={cx(styles.dropdownTrigger, triggerClassName)}
          ref={triggerRef}
        >
          {trigger}
        </div>
      </Tooltip>

      {domContainer ? renderDropdownListInPortal() : renderDropdownList()}
    </div>
  );
};
