import React from 'react';
import { useMemo } from 'react';
import cx from 'clsx';
import type { IIssueCard, ISidebarCategory, IssueCategory, IssueType } from '@writercolab/types';
import { Text, TextSize } from '@writercolab/ui-atoms';

import { FkChangeIndicator, TextDiff } from '@writercolab/ui-molecules';
import styles from './styles.module.css';
import { Replacement } from './Replacement';

import { UIInlineIssueCardModel } from './UIInlineIssueCardModel';
import { Controls } from '../Controls';
import { Description } from '../Description';

export interface InlineIssueCardProps extends IIssueCard {
  sidebarCategory?: ISidebarCategory;
  issueType: IssueType;
  category: IssueCategory;
  isInverted?: boolean;
  useDropdownPortal?: boolean;
  useMarkIssueAsWrongComment?: boolean;
}

interface CategoryLabelProps {
  category?: string;
}

const CategoryLabel: React.FC<CategoryLabelProps> = ({ category }) =>
  category ? <div className={styles.category}>{category}</div> : null;

export const InlineIssueCard: React.FC<InlineIssueCardProps> = ({
  fleschKincaidScoreChange,
  correction,
  highlight,
  description,
  examples,
  learnMore,
  category,
  issueType,
  sidebarCategory,
  onApplySuggestion,
  onDeleteIssueClick,
  onLearnMoreClicked,
  onMarkIssueAsWrong,
  onMarkIssueAsWrongCommentStateChange,
  isInverted,
  useDropdownPortal = true,
  useMarkIssueAsWrongComment = true,
}) => {
  const viewModel = useMemo(
    () => new UIInlineIssueCardModel(category, issueType, sidebarCategory, correction),
    [correction, category, sidebarCategory, issueType],
  );

  if (viewModel.isMultilineCard) {
    return (
      <div
        className={cx(styles.mainContainer, styles.multiLine, {
          [styles.multiLineInverted]: isInverted,
        })}
      >
        <div className={styles.multiLineHeading}>
          <Text bold variant={TextSize.M}>
            {viewModel.isPassiveVoice ? 'Use active voice' : 'Simplify this sentence'}
          </Text>
          {fleschKincaidScoreChange && (
            <FkChangeIndicator
              className={styles.fkChangeIndicator}
              from={fleschKincaidScoreChange.from}
              to={fleschKincaidScoreChange.to}
            />
          )}
        </div>
        <div
          className={cx(styles.mainContainerBody, styles.bodyBordered, styles.multiLineDiff)}
          onClick={() => onApplySuggestion?.(viewModel.correctionsList[0])}
        >
          {viewModel.isPassiveVoice ? (
            <Text variant={TextSize.XL}>{viewModel.correctionsList[0]}</Text>
          ) : (
            <TextDiff
              firstString={highlight}
              secondString={viewModel.correctionsList[0]}
              addedClassName={styles.multiLineAdded}
            />
          )}
        </div>
        <div className={styles.multilineControlsContainer}>
          <CategoryLabel category={sidebarCategory?.label || category} />
          <Controls
            learnMore={learnMore}
            onLearnMoreClicked={onLearnMoreClicked}
            onMarkIssueAsWrong={onMarkIssueAsWrong}
            onMarkIssueAsWrongCommentStateChange={onMarkIssueAsWrongCommentStateChange}
            onDeleteIssueClick={onDeleteIssueClick}
            isInverted={isInverted}
            useDropdownPortal={useDropdownPortal}
            useMarkIssueAsWrongComment={useMarkIssueAsWrongComment}
            className={styles.multiLineControls}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(styles.mainContainer, {
        [styles.compactMode]: viewModel.isCompactMode,
        [styles.dontUseCard]: viewModel.isBannedWord || viewModel.isUseCarefully,
        [styles.mainContainerInverted]: isInverted,
        [styles.mainContainerMultiSuggestion]: viewModel.isMultipleSuggestions,
      })}
    >
      <div className={styles.mainContainerHero}>
        {!viewModel.hideReplacement && (
          <Replacement
            isCompactMode={viewModel.isCompactMode}
            isMultipleSuggestions={viewModel.isMultipleSuggestions}
            category={viewModel.showCategory ? sidebarCategory?.label : undefined}
            onApplySuggestion={onApplySuggestion}
            correction={viewModel.correctionsList}
            highlight={highlight || ''}
            isInverted={isInverted}
          />
        )}
      </div>
      <div
        className={cx(styles.mainContainerBody, {
          [styles.alignDescriptionWithControls]: !viewModel.isDescriptionActive,
          [styles.inlineLabel]: !isInverted,
        })}
      >
        {!viewModel.isCompactMode ? (
          <>
            <CategoryLabel category={sidebarCategory?.label || category} />
            {viewModel.isDescriptionActive && (
              <Description className={styles.description} description={description} examples={examples} />
            )}
          </>
        ) : null}
      </div>
      <div
        className={cx(styles.mainContainerControls, {
          [styles.alignDescriptionWithControls]: !viewModel.isDescriptionActive,
          [styles.inlineControls]: viewModel.isMultipleSuggestions && !(isInverted || viewModel.isDescriptionActive),
        })}
      >
        <Controls
          learnMore={learnMore}
          onLearnMoreClicked={onLearnMoreClicked}
          onMarkIssueAsWrong={onMarkIssueAsWrong}
          onMarkIssueAsWrongCommentStateChange={onMarkIssueAsWrongCommentStateChange}
          onDeleteIssueClick={onDeleteIssueClick}
          isInverted={isInverted}
          useDropdownPortal={useDropdownPortal}
          useMarkIssueAsWrongComment={useMarkIssueAsWrongComment}
          className={cx(styles.controls, {
            [styles.multipleSuggestionControls]: viewModel.isMultipleSuggestions,
          })}
        />
      </div>
    </div>
  );
};
