
const digest = 'dabd60d69a53643a915aec28c90483c885e9a02647c9e111547700481e6007c2';
const css = `.styles-module__m1ODcpJ {
  height: 40px;
  padding: 10px;
  border-radius: var(--default-border-radius);
}

.styles-module__m1ODcpJ:hover {
  background: var(--writer-blue-1);
  transition: var(--background-color-transition);
}

.styles-module__Aug0fNE {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
`;

export default {"statItemButton":"styles-module__m1ODcpJ","statsListItemValue":"styles-module__Aug0fNE"};
export { css, digest };
  