import React from 'react';
import cx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import { Enum } from '@writercolab/utils';
import { ColorDot, Icon, IconVariant, Dropdown, DropdownPlacement, ItemsTypes } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

export const TUserStatusFilterIdentifier = new Enum('active', 'invitePending', 'approvalPending');

export interface IUserStatusSortingDropdownProps {
  onPrimaryOptionClickAction: (id: string) => void;
  userFilterValue: typeof TUserStatusFilterIdentifier.type;
}

export const UserStatusSortingDropdown: React.FC<IUserStatusSortingDropdownProps> = ({
  onPrimaryOptionClickAction,
  userFilterValue,
}) => (
  <div className={styles.filterContainer}>
    <Dropdown
      containerClassName={cx(styles.filterDropdown, {
        [styles.filterDropdownActive]: !isEmpty(userFilterValue),
      })}
      trigger={<Icon name={IconVariant.FILTER} />}
      itemsType={ItemsTypes.SELECT}
      options={[
        {
          id: TUserStatusFilterIdentifier.enum.approvalPending,
          name: 'Approval pending',
          icon: <ColorDot color="#A400CD" className={styles.filtersDotIcon} size={12} />,
          active: userFilterValue === TUserStatusFilterIdentifier.enum.approvalPending,
        },
        {
          id: TUserStatusFilterIdentifier.enum.invitePending,
          name: 'Invite pending',
          icon: <ColorDot color="#6985FF" className={styles.filtersDotIcon} size={12} />,
          active: userFilterValue === TUserStatusFilterIdentifier.enum.invitePending,
        },
        {
          id: TUserStatusFilterIdentifier.enum.active,
          name: 'Active',
          icon: <ColorDot color="#5EEBC1" className={styles.filtersDotIcon} size={12} />,
          active: userFilterValue === TUserStatusFilterIdentifier.enum.active,
        },
      ]}
      useCheckbox
      onPrimaryOptionClickAction={onPrimaryOptionClickAction}
      placement={DropdownPlacement.BOTTOM_RIGHT}
      dropdownItemClassName={styles.dropdownItem}
      hideOnCLick={false}
    />
  </div>
);
