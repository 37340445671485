import Quill from 'quill';
import Delta from 'quill-delta';
import { QL_SNIPPET_HIGHLIGHT_FORMAT_NAME, normalizeAndCleanDelta } from '@writercolab/quill-delta-utils';

const getIssueId = (issue) => `.issue-${issue.issueId}`;

export const replaceSuggestion = (editor, issue, replacement) => {
  const { Registry } = Quill.import('parchment');
  const elements = editor.root.querySelectorAll(getIssueId(issue));
  let fromReplace = Number.MAX_VALUE;
  let untilReplace = 0;

  if (!elements.length) {
    return;
  }

  elements.forEach(element => {
    const blot = Registry.find(element);

    if (blot) {
      const from = blot.offset(editor.scroll);
      const until = from + blot.length();

      if (from < fromReplace) {
        fromReplace = from;
      }

      if (until > untilReplace) {
        untilReplace = until;
      }
    }
  });

  // if this is a delete highlight case removes extra space
  // at the end of the suggestion to avoid double space and other weird result
  if (replacement === '' && editor.getText(untilReplace, 1) === ' ') {
    untilReplace++;
  }

  const length = untilReplace - fromReplace;

  const { _, ...formats } = editor.getFormat(fromReplace, length);
  let replacementText = replacement;

  const lineBreaksRegex = /\n+$/gi;

  // WA-1924 if both highlight and replacement ends with one or multiple line breaks
  // trim replacement to avoid situation when quill considers \n as new <p>
  if (issue.highlight && issue.highlight.match(lineBreaksRegex) && replacement.match(lineBreaksRegex)) {
    replacementText = replacement.replace(lineBreaksRegex, '');
  }

  const applyDelta = new Delta().retain(fromReplace).insert(replacementText, formats).delete(length);
  editor.updateContents(applyDelta, 'user');

  editor.focus();
  editor.setSelection(fromReplace + replacementText.length, 0);
};

export const searchForShiftedIssue = (editor, issue) => {
  const CONTEXT_LENGTH = 10;
  const { from, length, highlight } = issue;
  // taking {CONTEXT_LENGTH} symbols before\after expected position
  const textPlusContext = editor.getText(from - CONTEXT_LENGTH, length + CONTEXT_LENGTH * 2);

  if (highlight) {
    let searchFrom = 0;
    // sometimes the from is smaller than {CONTEXT_LENGTH}, we need to consider this when calculate new from
    const beginningShift = from < CONTEXT_LENGTH ? CONTEXT_LENGTH - from : 0;
    let match = textPlusContext.indexOf(highlight, searchFrom);
    let bestFrom = -1;

    while (match !== -1) {
      const newFrom = match - CONTEXT_LENGTH + from + beginningShift;

      if (Math.abs(newFrom - from) < Math.abs(bestFrom - from)) {
        bestFrom = newFrom;
      }

      searchFrom = match + 1;
      match = textPlusContext.indexOf(highlight, searchFrom);
    }

    if (bestFrom !== -1) {
      return {
        ...issue,
        from: bestFrom,
        until: bestFrom + length,
      };
    }
  }

  return undefined;
};

export const getSnippetMeta = (quillEditor, element) => {
  const { Registry } = Quill.import('parchment');
  const blot = Registry.find(element);

  if (blot) {
    const from = blot.offset(quillEditor.scroll);
    const until = from + blot.length();
    const length = until - from;
    const { snippet } = blot;

    return {
      snippet,
      from,
      length,
    };
  }

  return undefined;
};

export const insertSnippet = (quillEditor, element) => {
  const snippetData = getSnippetMeta(quillEditor, element);

  if (snippetData) {
    const { from, length, snippet } = snippetData;

    // transform snippet html to delta
    const snippetInsertDelta = quillEditor.clipboard.convert({ html: snippet });

    quillEditor.formatText(from, length, QL_SNIPPET_HIGHLIGHT_FORMAT_NAME, false, 'api');

    // prepare full delta to insert
    let applyDelta = normalizeAndCleanDelta(snippetInsertDelta);

    const range = quillEditor.getSelection(true);
    const [thisLeaf] = quillEditor.getLine(range.index);
    if (thisLeaf && thisLeaf.domNode?.className === 'qlbt-cell-line') {
      const [line] = quillEditor.getLine(from);
      const lineFormats = line.formats();
      applyDelta = formatLineBreaks(applyDelta, lineFormats);
    }

    applyDelta = new Delta().retain(from).delete(length).concat(applyDelta);
    quillEditor.updateContents(applyDelta, 'user');

    quillEditor.setSelection(applyDelta.transformPosition(from), 0);
  }
};

export const formatLineBreaks = (pastedDelta, lineFormats) => {
  pastedDelta.ops.forEach(op => {
    op.attributes = { ...op.attributes };
    delete op.attributes['blockquote'];
    delete op.attributes['list'];
    delete op.attributes['header'];
    delete op.attributes['table'];
  });

  return pastedDelta.reduce((newDelta, op) => {
    if (op.insert && typeof op.insert === 'string') {
      const lines = [];
      let insertStr = op.insert;
      let start = 0;
      for (let i = 0; i < op.insert.length; i++) {
        if (insertStr.charAt(i) === '\n') {
          if (i === 0) {
            lines.push('\n');
          } else {
            lines.push(insertStr.substring(start, i));
            lines.push('\n');
          }
          start = i + 1;
        }
      }

      const tailStr = insertStr.substring(start);
      if (tailStr) lines.push(tailStr);

      lines.forEach(text => {
        text === '\n'
          ? newDelta.insert('\n', { ...op.attributes, ...lineFormats })
          : newDelta.insert(text, op.attributes);
      });
    } else {
      newDelta.insert(op.insert, op.attributes);
    }

    return newDelta;
  }, new Delta());
};
