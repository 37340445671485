export enum InputType {
  TEXTBOX = 'textbox',
  TEXTAREA = 'textarea',
  DROPDOWN = 'dropdown',
  FILE = 'file',
}

export interface ITemplateCategory {
  id: number;
  name: string;
  deleted: boolean;
  hidden?: boolean;
}

export interface ITemplateCategoryBE {
  category: ITemplateCategory;
  templatesCount: number;
}

export interface ITemplateFavorite {
  userId: number;
  templateIds: string[];
}

export interface ITemplateDraft {
  id: number;
  documentId: number;
  organizationId: number;
  teamId: number;
  title: string;
  body: string;
  deleted: boolean;
  createdUserId: number;
  creationTime: string;
  templateId: string;
  inputs: ITemplateCoWriteInput[] | ITemplateBlogBuilderInput;
}

export interface ITemplateBlogBuilderInputSection {
  outline: string;
  points: string[];
}

export interface ITemplateBlogBuilderInput {
  cta: string;
  keywords: string;
  title: string;
  type: string;
  sections: ITemplateBlogBuilderInputSection[];
}

export interface ITemplateCoWriteInput {
  name: string;
  value: string[];
}

export interface ITemplateDraftFeedbackParams {
  rate: number;
  feedback: string;
}

export interface ITemplate {
  categoryId: number;
  id: string;
  creationTime: string;
  description: string;
  favourite: boolean;
  modificationTime: string;
  name: string;
  inputs: ITemplateInput[];
  guideUrl?: string;
}

export interface ITemplateExtended extends ITemplate {
  tagTitle: string;
  tagColor: string;
  tagTextColor: string;
}

export const defaultCategory = (params?: ITemplateCategory): ITemplateCategory => ({
  id: -1,
  name: '',
  deleted: false,
  ...params,
});

export const defaultTemplate = (params?: ITemplate): ITemplate => ({
  categoryId: 1,
  id: 'f6e06604-4786-40b3-b42d-763fd2be8e6',
  name: 'Cover My Meds',
  inputs: [],
  favourite: false,
  description: 'Description goes here',
  creationTime: '2022-06-02T14:09:41.797205Z',
  modificationTime: '2022-06-02T14:11:06.314451Z',
  ...params,
});

export const defaultField = (params?: ITemplateInput): ITemplateInput => ({
  name: '',
  description: '',
  required: false,
  dynamic: false,
  type: InputType.TEXTBOX,
  help: '',
  subtitle: '',
  unitCopy: '',
  maxFields: 1,
  minFields: 10,
  options: [],
  ...params,
});

export const defaultCurrentTemplate: ITemplate = {
  ...defaultTemplate(),
};

export interface ITemplateInput {
  name: string;
  description: string;
  required: boolean;
  dynamic: boolean;
  type: InputType;
  help: string;
  subtitle: string;
  unitCopy: string;
  maxFields: number;
  minFields: number;
  options: string[];
}

export interface ITotalTemplateSearchResult {
  result: ITemplate[];
  totalCount: number;
  pagination: {
    offset: number;
    limit: number;
  };
}
