
const digest = 'dffe6424b172941a9f7726e00b4496b21e25506458a378ff6d845450761fd8b5';
const css = `.styles-module__3FzszTG b {
  font-weight: bold;
}

.styles-module__3FzszTG i {
  font-style: italic;
}

.styles-module__3FzszTG h1,
.styles-module__3FzszTG h2,
.styles-module__3FzszTG h3,
.styles-module__3FzszTG h4 {
  text-transform: none;
}

.styles-module__3FzszTG h3 {
  margin-block: 18px 18px;
}

.styles-module__3FzszTG p {
  margin-block: 18px 18px;
}

.styles-module__U9TyigE {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.styles-module__U9TyigE:hover {
  background-color: var(--classic-grey-1);
}
`;

export default {"modalContainer":"styles-module__3FzszTG","closeIcon":"styles-module__U9TyigE"};
export { css, digest };
  