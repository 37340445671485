import React from 'react';
import cx from 'clsx';

import { forwardRef } from 'react';
import styles from './styles.module.css';

/**
 * @deprecated use WDS
 */
export interface ITagProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  size?: TagSize;
  color?: TagColor;
  textAlign?: TagTextAlign;
  upperCase?: boolean;
}

/**
 * @deprecated use WDS
 */
export enum TagSize {
  M = 'medium',
  S = 'small',
}

/**
 * @deprecated use WDS
 */
export enum TagColor {
  GREY = 'grey',
  GREY1 = 'grey1',
  GREY2 = 'grey2',
  GREEN = 'green',
  GREEN3 = 'green3',
  LIGHT_GREEN = 'lightGreen',
  ORANGE = 'orange',
  BLACK = 'black',
  BLUE = 'blue',
  LIGHT_BLUE = 'lightBlue',
  LIGHT_PEACH = 'lightPeach',
  LIGHT_ORANGE = 'lightOrange',
  YELLOW_1 = 'yellow1',
}

/**
 * @deprecated use WDS
 */
export enum TagTextAlign {
  LEFT = 'left',
  CENTER = 'center',
}

/**
 * @deprecated use WDS
 */
export const Tag = forwardRef<HTMLDivElement, ITagProps>(
  (
    {
      size = TagSize.M,
      color = TagColor.GREY,
      textAlign = TagTextAlign.LEFT,
      className,
      children,
      upperCase,
      ...props
    },
    ref,
  ) => (
    <div
      className={cx(styles.tag, styles[size], styles[color], className, {
        [styles.upperCase]: upperCase,
        [styles.alignCenter]: textAlign === TagTextAlign.CENTER,
        [styles.alignLeft]: textAlign === TagTextAlign.LEFT,
      })}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  ),
);

Tag.displayName = 'Tag';

export default Tag;
