import React from 'react';
import cx from 'clsx';
import { SnackbarProvider } from 'notistack';

import { Enum } from '@writercolab/utils';
import styles from './styles.module.css';

export const SnackbarVerticalPosition = new Enum('top', 'bottom');
export const SnackbarHorizontalPosition = new Enum('left', 'right', 'center');

export interface SnackbarProviderProps {
  vertical?: typeof SnackbarVerticalPosition.type;
  horizontal?: typeof SnackbarHorizontalPosition.type;
  children?: React.ReactNode;
  containerClassName?: string;
  rootClassName?: string;
}

const snackbarStyles = {
  anchorOriginBottomRight: styles.anchorOriginBottomCenter,
  containerAnchorOriginBottomRight: styles.containerAnchorOriginBottomCenter,
  variantSuccess: styles.success,
  variantError: styles.error,
  variantWarning: styles.warning,
  variantInfo: styles.info,
};

export const SnackbarsProvider: React.FC<SnackbarProviderProps> = ({
  vertical = SnackbarVerticalPosition.enum.bottom,
  horizontal = SnackbarHorizontalPosition.enum.right,
  children,
  containerClassName,
  rootClassName,
}) => (
  <SnackbarProvider
    classes={{
      ...snackbarStyles,
      containerRoot: containerClassName,
      root: cx(styles.root, rootClassName),
    }}
    anchorOrigin={{
      vertical,
      horizontal,
    }}
  >
    {children}
  </SnackbarProvider>
);

export default SnackbarsProvider;
