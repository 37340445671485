
const digest = 'd2b07d0ec46540b7af3f64335525ae0ed687bfcc1246956186c6637fa80374c7';
const css = `.styles-module__JIOf8HB {
  max-height: 100%;
  overflow-y: auto;
}

.styles-module__gmEpD2y {
  padding: 30px 37px;
}

.styles-module__0PGRGnW {
  padding: 30px 37px;
  background: black;
}

.styles-module__w6js0J7 {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
}

.styles-module__eZHYwXz {
  color: var(--classic-grey-2);
}

.styles-module__Dkthndg {
  font-size: 18px;
}

.styles-module__JoyHzrQ {
  color: white;
}

.styles-module__u5WMdh9 {
  font-size: 14px;
  font-weight: 400;
}

.styles-module__u5WMdh9 ul {
  padding-left: 19px;
}

.styles-module__Ef6Wh3d {
  display: block;
  margin-bottom: 7px;
}

.styles-module__juoMX75 {
  margin-bottom: 7px;
}

.styles-module__w1Aysxk {
  margin-bottom: 30px;
}

.styles-module__l5K5WPE {
  padding-left: 33px;
}

.styles-module__MEPTWKx,
.styles-module__eJB0pKC {
  display: flex;
}

.styles-module__9hmaxSL {
  width: 215px;
  padding-right: 30px;
  font-size: 13px;
  line-height: 18px;
}

.styles-module__6tQq-lh {
  margin: 15px 0;
  font-size: 16px;
  font-weight: 600;
}

.styles-module__2yzdhw- {
  position: relative;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 19px;
  background-color: var(--classic-orange-1);
}

.styles-module__3ohl8Mb {
  background-color: var(--writer-green-3);
}

.styles-module__CsNeWeq {
  background-color: var(--classic-blue-2);
}

.styles-module__W3NXXm2 {
  width: 215px;
  margin-right: 50px;
}

.styles-module__Kcel75e {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.styles-module__hoZ5oU6 {
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.3px;
  line-height: 15px;
}

.styles-module__Pm-2Dt7 {
  font-size: 18px;
  font-weight: 600;
}

.styles-module__oGBk3ct {
  margin-bottom: 15px;
}

.styles-module__oGBk3ct:first-of-type {
  padding-top: 15px;
  border-top: var(--gray-border);
}

.styles-module__XxVsayS {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.styles-module__qNkxWbq {
  margin-bottom: 15px;
}

.styles-module__qNkxWbq a,
.styles-module__qNkxWbq a:visited,
.styles-module__qNkxWbq a:active {
  color: var(--classic-grey-2);
  text-decoration: none;
}

.styles-module__qNkxWbq:hover {
  color: white;
  text-decoration: underline;
}
`;

export default {"container":"styles-module__JIOf8HB","paddedContent":"styles-module__gmEpD2y","fullWidthContent":"styles-module__0PGRGnW","header":"styles-module__w6js0J7","gray":"styles-module__eZHYwXz","blockHeader":"styles-module__Dkthndg","whiteHeader":"styles-module__JoyHzrQ","block":"styles-module__u5WMdh9","blockItem":"styles-module__Ef6Wh3d","blockParagraph":"styles-module__juoMX75","withBottomMargin":"styles-module__w1Aysxk","paddedLeft":"styles-module__l5K5WPE","doDont":"styles-module__MEPTWKx","whatIs":"styles-module__eJB0pKC","doDontSection":"styles-module__9hmaxSL","doDontHeader":"styles-module__6tQq-lh","coloredText":"styles-module__2yzdhw-","coloredGreen":"styles-module__3ohl8Mb","coloredBlue":"styles-module__CsNeWeq","whatIsSection":"styles-module__W3NXXm2","whatIsHeader":"styles-module__Kcel75e","whatIsDescription":"styles-module__hoZ5oU6","whatIsExample":"styles-module__Pm-2Dt7","doDontItem":"styles-module__oGBk3ct","title":"styles-module__XxVsayS","link":"styles-module__qNkxWbq"};
export { css, digest };
  