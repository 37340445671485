import React from 'react';

import type { ISidebarCategory } from '@writercolab/types';
import { Button, ButtonTypes, Icon, IconVariant, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IPaidFeature {
  selectedCategory: ISidebarCategory;
  onClick: () => void;
}

export const PaidFeature: React.FC<IPaidFeature> = ({ selectedCategory, onClick }) => (
  <div className={styles.container}>
    <div className={styles.containerWrapper}>
      <div className={styles.lockContainer}>
        <Icon name={IconVariant.LOCK_LINE} width={35} height={45} />
        <div className={styles.billingTypeLabel}>
          <Text variant={TextSize.XS} caps>
            Team feature
          </Text>
        </div>
        <div className={styles.actionButtonContainer}>
          <Button
            className={styles.actionButton}
            size={SizeTypes.SMALL}
            type={ButtonTypes.PRIMARY}
            content="Get Writer Team"
            icon={<Icon width={28} height={36} name={IconVariant.PREMIUM} />}
            onClick={onClick}
          />
        </div>
        {selectedCategory?.lockedText && <Text variant={TextSize.M}>{selectedCategory.lockedText}</Text>}
      </div>
    </div>
  </div>
);
