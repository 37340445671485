
const digest = 'fd33f3fd1e788bc645eb8a5e5d597c881a3a2a56fb04a43e6a86132fe34d154a';
const css = `.VisibleIssuesList-module__jWCQ5yt {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.VisibleIssuesList-module__I4vxY6C {
  text-align: center;
}
`;

export default {"listScrollEmptySection":"VisibleIssuesList-module__jWCQ5yt","listScrollEmptySectionText":"VisibleIssuesList-module__I4vxY6C"};
export { css, digest };
  