import { Enum } from '@writercolab/utils';

export const E_INTEGRATION_TYPE = new Enum(
  'DEFAULT',
  'CHROME_EXTENSION',
  'EDGE_EXTENSION',
  'GOOGLE_DOCS_PLUGIN',
  'OUTLOOK_PLUGIN',
  'POWER_POINT_PLUGIN',
  'WORD_PLUGIN',
  'FIGMA_PLUGIN',
  'CONTENTFUL_PLUGIN',
  'WINDOWS_APP',
  'MACOS_APP',
  'EMBED',
  'PLAYGROUND',
);

export const E_CLIENT_ID = new Enum(
  'ChromePlugin',
  'GoogleDocsPlugin',
  'OutlookPlugin',
  'PowerPointPlugin',
  'WordPlugin',
  'FigmaPlugin',
  'Contentful',
  'Edge',
  'QordobaFE',
  'Unknown',
  'Embed',
  'Playground',
  'WindowsApp',
  'MacApp',
);

export const H_INTEGRATION_TYPE_TO_CLIENT_ID = E_INTEGRATION_TYPE.hash({
  CHROME_EXTENSION: E_CLIENT_ID.enum.ChromePlugin,
  GOOGLE_DOCS_PLUGIN: E_CLIENT_ID.enum.GoogleDocsPlugin,
  OUTLOOK_PLUGIN: E_CLIENT_ID.enum.OutlookPlugin,
  POWER_POINT_PLUGIN: E_CLIENT_ID.enum.PowerPointPlugin,
  WORD_PLUGIN: E_CLIENT_ID.enum.WordPlugin,
  FIGMA_PLUGIN: E_CLIENT_ID.enum.FigmaPlugin,
  CONTENTFUL_PLUGIN: E_CLIENT_ID.enum.Contentful,
  EDGE_EXTENSION: E_CLIENT_ID.enum.Edge,
  DEFAULT: E_CLIENT_ID.enum.QordobaFE,
  WINDOWS_APP: E_CLIENT_ID.enum.WindowsApp,
  MACOS_APP: E_CLIENT_ID.enum.MacApp,
  EMBED: E_CLIENT_ID.enum.Embed,
  PLAYGROUND: E_CLIENT_ID.enum.Playground,
});

export const H_CLIENT_ID_TO_INTEGRATION_TYPE = E_CLIENT_ID.hash({
  ChromePlugin: E_INTEGRATION_TYPE.enum.CHROME_EXTENSION,
  GoogleDocsPlugin: E_INTEGRATION_TYPE.enum.GOOGLE_DOCS_PLUGIN,
  OutlookPlugin: E_INTEGRATION_TYPE.enum.OUTLOOK_PLUGIN,
  PowerPointPlugin: E_INTEGRATION_TYPE.enum.POWER_POINT_PLUGIN,
  WordPlugin: E_INTEGRATION_TYPE.enum.WORD_PLUGIN,
  FigmaPlugin: E_INTEGRATION_TYPE.enum.FIGMA_PLUGIN,
  Contentful: E_INTEGRATION_TYPE.enum.CONTENTFUL_PLUGIN,
  Edge: E_INTEGRATION_TYPE.enum.EDGE_EXTENSION,
  QordobaFE: E_INTEGRATION_TYPE.enum.DEFAULT,
  Unknown: E_INTEGRATION_TYPE.enum.DEFAULT,
  Embed: E_INTEGRATION_TYPE.enum.DEFAULT,
  Playground: E_INTEGRATION_TYPE.enum.DEFAULT,
  WindowsApp: E_INTEGRATION_TYPE.enum.WINDOWS_APP,
  MacApp: E_INTEGRATION_TYPE.enum.MACOS_APP,
});

/**
 * @deprecated
 * when modify it please update E_INTEGRATION_TYPE
 * or better use it instead
 */
export enum IntegrationType {
  DEFAULT = 'DEFAULT',
  CHROME_EXTENSION = 'CHROME_EXTENSION',
  EDGE_EXTENSION = 'EDGE_EXTENSION',
  GOOGLE_DOCS_PLUGIN = 'GOOGLE_DOCS_PLUGIN',
  OUTLOOK_PLUGIN = 'OUTLOOK_PLUGIN',
  POWER_POINT_PLUGIN = 'POWER_POINT_PLUGIN',
  WORD_PLUGIN = 'WORD_PLUGIN',
  FIGMA_PLUGIN = 'FIGMA_PLUGIN',
  CONTENTFUL_PLUGIN = 'CONTENTFUL_PLUGIN',
}

export enum SidebarViewMode {
  OPEN = 'open',
  PREVIEW = 'preview',
}

/**
 * @deprecated This type is deprecated and will be removed in future versions.
 */
export interface ITableFilter {
  id: string;
  name: string;
  isSelected: boolean;
  tagColor?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
}

export interface IRephraseGrades {
  id: string;
  name: string;
  description?: string;
  enabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  visibility?: any;
}
