
const digest = 'f74491a6d537f5f16367b013b3a98698d322d39ec167117d3506e20332290ee6';
const css = `.Textarea-module__NuhYHZ- {
  min-width: 100px;
  max-width: 100%;
  min-height: 37px;
  max-height: 100%;
}

.Textarea-module__8JRSPaS {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-width: 100px;
  max-width: 100%;
  flex-direction: column;
  border: 1px solid var(--writer-graynu-2);
  border-radius: 8px;

  &.Textarea-module__VCiBIdM {
    border: 1px solid var(--writer-blue-3) !important;
    box-shadow: var(--input-box-shadow);
    outline: none;
  }

  &.Textarea-module__vSlgRDT {
    .Textarea-module__bh4bjvU {
      resize: none;
    }
  }

  &.Textarea-module__tjapEWB {
    border: 1px solid var(--writer-graynu-1) !important;
    background: var(--writer-graynu-1) !important;

    & .Textarea-module__bh4bjvU {
      background: var(--writer-graynu-1) !important;
      color: var(--writer-grey-2) !important;
      cursor: default;
      resize: none;
    }

    &:hover {
      border: 1px solid var(--writer-graynu-1) !important;
      box-shadow: none !important;
    }
  }

  &:focus {
    border: 1px solid var(--writer-blue-3);
    box-shadow: var(--input-box-shadow);
  }

  &:hover,
  &:active {
    border: 1px solid var(--writer-blue-3);
  }

  &.Textarea-module__HtJsNrT {
    border: 1px solid var(--writer-graynu-2);

    &:focus,
    &:hover {
      border: 1px solid var(--writer-blue-3);
      box-shadow: var(--input-box-shadow);
    }

    &:disabled {
      border: 1px solid var(--writer-graynu-1) !important;
      background: var(--writer-graynu-1) !important;
      box-shadow: none;
    }
  }

  &.Textarea-module__w0y-Vxb {
    border: 1px solid var(--classic-orange-1) !important;
    box-shadow: none !important;

    &:focus {
      border: 1px solid var(--classic-orange-1) !important;
    }
  }
}

/* Styles for textarea element */
.Textarea-module__bh4bjvU {
  overflow: hidden auto;
  min-width: 100px;
  max-width: 100%;
  min-height: 37px;
  max-height: 100%;
  flex-grow: 1;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background: var(--classic-white);
  color: var(--classic-black);
  font-size: var(--font-size-l);
  font-weight: var(--font-default-weight);
  outline: none;
  resize: vertical;

  &::placeholder {
    color: var(--writer-grey-3) !important;
    font-weight: var(--font-default-weight);
    opacity: 0.8;
  }
}

/* Styles for the resize icon */
.Textarea-module__uBsoqQZ {
  position: absolute;
  z-index: 2;
  right: -1px;
  bottom: 0;
  padding: 3px;
  border-radius: 0 0 8px;
  background: var(--classic-white);
  cursor: ns-resize;
}
`;

export default {"container":"Textarea-module__NuhYHZ-","textareaWrapper":"Textarea-module__8JRSPaS","focused":"Textarea-module__VCiBIdM","noResize":"Textarea-module__vSlgRDT","textarea":"Textarea-module__bh4bjvU","disabled":"Textarea-module__tjapEWB","empty":"Textarea-module__HtJsNrT","hasError":"Textarea-module__w0y-Vxb","resizeIcon":"Textarea-module__uBsoqQZ"};
export { css, digest };
  