import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'clsx';
import isEmpty from 'lodash/isEmpty';

import { Icon, IconVariant, useResizeObserver } from '@writercolab/ui-atoms';

import styles from './Textarea.module.css';

// Extend standard textarea props to allow all attributes
interface ITextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  error?: boolean;
  rows?: number;
  resizable?: boolean;
  shouldUpdateHeightOnWrapperResize?: boolean;
}

export const Textarea: React.FC<ITextareaProps> = ({
  className,
  error,
  rows,
  resizable = true,
  shouldUpdateHeightOnWrapperResize = false,
  children,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const showResizeIcon = resizable && !props.disabled;

  const updateHeight = useCallback(() => {
    if (resizable && textareaRef.current) {
      textareaRef.current.style.width = `${textareaRef.current.scrollWidth}px`;
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
      textareaRef.current.style.width = '';

      if (shouldUpdateHeightOnWrapperResize) {
        textareaRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [resizable, shouldUpdateHeightOnWrapperResize]);

  useEffect(() => {
    updateHeight();
  }, [props.value, updateHeight]);

  const onResizeWrapper = useCallback(
    () => (shouldUpdateHeightOnWrapperResize ? updateHeight : undefined),
    [shouldUpdateHeightOnWrapperResize, updateHeight],
  );

  useResizeObserver(wrapperRef, onResizeWrapper, [onResizeWrapper]);

  return (
    <div
      ref={wrapperRef}
      className={cx(className, styles.textareaWrapper, {
        [styles.hasError]: error,
        [styles.empty]: isEmpty(props.value),
        [styles.disabled]: props.disabled,
        [styles.focused]: isFocused,
        [styles.noResize]: !resizable,
      })}
    >
      <textarea
        ref={textareaRef}
        className={cx(styles.textarea, styles.container)}
        aria-invalid={!!error}
        aria-describedby={error ? 'textarea-error' : undefined}
        rows={rows}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        {...props}
      />
      {showResizeIcon && <Icon name={IconVariant.RESIZE_TEXTAREA} className={styles.resizeIcon} />}
      {children}
    </div>
  );
};
