import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';
import type { TMediaFileTabs, TSupportedFileUploadExt, TSupportedImageUploadExt } from './media.types';

export const TConsoleApplicationRole = new Enum('FullAccess', 'Draft', 'View');
export const TConsoleApplicationInputType = new Enum('text', 'dropdown', 'file', 'media');

export type TApplicationInputType = typeof TConsoleApplicationInputType.type;

export type TApplicationTag = components['schemas']['template_dto_BriefApplicationTag'];

export type TConsoleGenerationApplicationInput =
  components['schemas']['template_model_ApplicationData_ApplicationInput'];

interface BaseApplicationInput<O = unknown> extends TConsoleGenerationApplicationInput {
  options: O;
}

export interface TAppDropdownInput
  extends BaseApplicationInput<{
    list: string[];
  }> {
  inputType: typeof TConsoleApplicationInputType.enum.dropdown;
}

export interface TAppFileUploadInput
  extends BaseApplicationInput<{
    tabs: TMediaFileTabs[];
    maxWordCount: number;
    maxFiles: number;
    maxFileSizeMB: number;
    pasteTranscriptTitle: string;
    pasteTranscriptSubtitle: string;
    uploadSubtitle: string;
    uploadTitle: string;
    urlTitle: string;
    urlSubtitle: string;
    urlPlaceholder: string;
    selectExistingTitle: string;
    selectExistingSubtitle: string;
    fileTypes: TSupportedFileUploadExt[];
  }> {
  inputType: typeof TConsoleApplicationInputType.enum.file;
}

export interface TAppTextInput
  extends BaseApplicationInput<{
    multiline: boolean;
    dynamic: boolean;
    addButtonText: string;
    minFields: number;
    maxFields: number;
  }> {
  inputType: typeof TConsoleApplicationInputType.enum.text;
}

export interface TAppMediaUploadInput
  extends BaseApplicationInput<{
    maxImageSizeMB: number;
    fileTypes: TSupportedImageUploadExt[];
  }> {
  inputType: typeof TConsoleApplicationInputType.enum.media;
}

export type TAppInput = TAppTextInput | TAppDropdownInput | TAppFileUploadInput | TAppMediaUploadInput;
export type TAppInputOptions =
  | TAppTextInput['options']
  | TAppDropdownInput['options']
  | TAppFileUploadInput['options']
  | TAppMediaUploadInput['options'];

export type TApplicationBase = Pick<
  components['schemas']['template_model_BriefApplication'],
  | 'id'
  | 'name'
  | 'description'
  | 'guideUrl'
  | 'type'
  | 'icon'
  | 'idAlias'
  | 'shortDescription'
  | 'tutorialUrl'
  | 'tagIds'
>;
export type TApplicationBrief = TApplicationBase & components['schemas']['template_model_BriefApplication'];
export type TApplicationBriefResponse = components['schemas']['template_dto_BriefApplicationResponse'];
export type TApplicationExpand = TApplicationBase &
  components['schemas']['template_dto_ExpandDeployedApplicationResponse'];

export type TBriefChatApplicationData =
  components['schemas']['template_model_BriefApplicationData_BriefChatApplicationData'];

export type TBriefFrameworkApplicationData =
  components['schemas']['template_model_BriefApplicationData_BriefFrameworkApplicationData'];

export type TBriefGenerationApplicationData =
  components['schemas']['template_model_BriefApplicationData_BriefGenerationApplicationData'];

export type TBriefResearchApplicationData =
  components['schemas']['template_model_BriefApplicationData_BriefResearchApplicationData'];

export type TConsoleApplication = components['schemas']['template_dto_FullApplicationResponse'];
export type TConsoleChatApplicationData = components['schemas']['template_model_ApplicationData_ChatApplicationData'];
export type TConsoleGenerationApplicationData =
  components['schemas']['template_model_ApplicationData_GenerationApplicationData'];
export type TConsoleResearchApplicationData =
  components['schemas']['template_model_ApplicationData_ResearchApplicationData'];

export type TApplicationBriefPaginatedResponse =
  components['schemas']['model_PaginatedResult_template_dto_BriefApplicationResponse'];
export type TApplicationBriefPaginatedTemplateResponse =
  components['schemas']['model_PaginatedResult_template_model_BriefApplication'];

export type TApplicationStatus = components['schemas']['template_model_ApplicationStatus'];
export type TApplicationExportResponse = components['schemas']['template_dto_ExportApplicationResponse'];
export type TApplicationVersionCreate = components['schemas']['template_model_ApplicationVersionCreate'];

/**
 * @deprecated Migrate to TConsoleChatApplication
 */
export type TConsoleChatApplicationLegacy = TConsoleApplication & {
  data: TConsoleChatApplicationData;
};

export type TConsoleApplicationVersion = components['schemas']['template_model_ApplicationVersion'];
export type TConsoleApplicationVersionData = components['schemas']['template_model_ApplicationVersionData'];

export type TConsoleGenerationApplication = TConsoleApplication & {
  applicationVersionData: TConsoleApplicationVersionData & {
    data: TConsoleGenerationApplicationData;
  };
};

export type TConsoleResearchApplication = TConsoleApplication & {
  applicationVersionData: TConsoleApplicationVersionData & {
    data: TConsoleResearchApplicationData;
  };
};

export type TDeployedGenerationApplication = TApplicationExpand & {
  data: TConsoleGenerationApplicationData;
};

export type TDeployedChatApplication = TApplicationExpand & {
  data: TBriefChatApplicationData;
};

export type TDeployedResearchApplication = TApplicationExpand & {
  data: TBriefResearchApplicationData;
};

export type TConsoleChatApplication = TConsoleApplication & {
  applicationVersionData: TConsoleApplicationVersionData & {
    data: TConsoleChatApplicationData;
  };
};

export const EApplicationType = new Enum('generation', 'chat', 'framework', 'research');
export const EDevApplicationType = new Enum('framework', 'api', 'chatWithDb');

export const TApplicationState = new Enum('deployed', 'draft');

export const TApplicationSortField = new Enum('name', 'createdAt', 'deployedAt');
export const TApplicationSortOrder = new Enum('asc', 'desc');

export type TApplicationStatuses = typeof TApplicationState.type;
export type TDevApplicationType = typeof EDevApplicationType.type;
export type TBaseApplicationType = typeof EApplicationType.type;
export type TApplicationType = TBaseApplicationType | TDevApplicationType;

export const IMAGE_ANALYSIS_APP_ID_ALIAS = 'image_analyzer_app';

export interface TPaginatedConsoleArgs {
  limit: number;
  offset: number;
}

export interface TPaginatedConsoleExtraArgs {
  search?: string;
  createdByUserIds?: number[];
  deployedByUserIds?: number[];
  types?: TBaseApplicationType[];
  statuses?: TApplicationStatuses[];
  protected?: boolean;
  sortField?: typeof TApplicationSortField.type;
  sortOrder?: typeof TApplicationSortOrder.type;
}

export interface TPaginatedConsoleQuery extends TPaginatedConsoleArgs, TPaginatedConsoleExtraArgs {}

export type TGenerationRequest = components['schemas']['template_dto_GenerationRequest'];
export type TGenerationDraft = components['schemas']['template_model_Draft'];

export enum BUILDER_TEMPLATE_ID {
  SEO_BLOG = '7f74a63d-99ad-45ab-8078-e97297cb01bc',
  EVENT_TAKEAWAYS = 'd20d5fd7-2b36-4c4a-924a-26b2f1794338',
}
