import React, { type ChangeEvent, type ReactNode, useCallback, useRef, useState } from 'react';
import cx from 'clsx';
import { Checkbox, Icon, IconVariant, SearchBar, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import isEmpty from 'lodash/isEmpty';
import { Enum } from '@writercolab/utils';

import useInfiniteScroll from 'react-infinite-scroll-hook';

import styles from './styles.module.css';
import { useHandleOutsideMouseDown } from '../../hooks/useHandleOutsideMouseDown';

export { mockPaginatedModel } from './mock';
export const TFilterOptionsPosition = new Enum('left', 'right', 'center');
export const TFilterOptionsDirection = new Enum('top', 'bottom');

export interface IFilterOptionsFilter {
  id: string;
  name: string;
  description?: string;
  isSelected: boolean;
  isDisabled?: boolean;
  tagColor?: string;
  icon?: ReactNode;
}

interface IFilterOptionsBaseProps {
  renderTrigger: ({ isOpen, onClick }: { isOpen: boolean; onClick: () => void }) => ReactNode;
  title?: ReactNode;
  noResults?: ReactNode;
  options: IFilterOptionsFilter[];
  onClearAll?: () => void;
  onResetSearch?: () => void;
  className?: string;
  menuClassName?: string;
  menuWidth?: string;
  placement?: typeof TFilterOptionsPosition.type;
  isToggleMode?: boolean;
  isSearchable?: boolean;
  isAutoClose?: boolean;
  onSearch?: (value: string) => void;
  onLoadMore?: () => void;
  isLoading?: boolean;
  hasNextPage?: boolean;
  isEnableInfiniteScroll?: boolean;
  direction?: typeof TFilterOptionsDirection.type;
  renderOptionNode?: (filter: IFilterOptionsFilter) => ReactNode;
  isShowClearAll?: boolean;
  testId?: string;
}

export interface IFilterOptionsSingleSelectProps extends IFilterOptionsBaseProps {
  isSingleSelect: true;
  onChange: (filter: IFilterOptionsFilter) => void;
}

export interface IFilterOptionsMultiSelectProps extends IFilterOptionsBaseProps {
  isSingleSelect?: false;
  onChange: (filters: IFilterOptionsFilter[]) => void;
}

export type FilterOptionsProps = IFilterOptionsSingleSelectProps | IFilterOptionsMultiSelectProps;

export const FilterOptions = ({
  renderTrigger,
  title,
  noResults = (
    <Text className={styles.noResults} variant={TextSize.M} color={TextColor.GREY3} textAlign="center">
      No results
    </Text>
  ),
  options,
  onChange,
  className,
  menuClassName,
  onClearAll,
  onResetSearch,
  menuWidth = '211px',
  placement = 'right',
  isToggleMode = false,
  isSearchable = false,
  isSingleSelect = false,
  isAutoClose = false,
  onSearch,
  isEnableInfiniteScroll,
  isLoading,
  hasNextPage,
  onLoadMore,
  direction = 'bottom',
  renderOptionNode,
  isShowClearAll = true,
  testId,
}: FilterOptionsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = isEnableInfiniteScroll
    ? options
    : options.filter(filter => filter.name.toLowerCase().includes(searchValue.toLowerCase()));
  const isClearAllVisible = options.some(filter => filter.isSelected);

  useHandleOutsideMouseDown(containerRef, () => setIsOpen(false));

  const handleSelect = useCallback(
    (selectedFilter: IFilterOptionsFilter) => () => {
      if (isSingleSelect) {
        const updatedOption: IFilterOptionsFilter = options
          .map(filter =>
            filter.id === selectedFilter.id ? { ...filter, isSelected: true } : { ...filter, isSelected: false },
          )
          .find(filter => filter.isSelected) as IFilterOptionsFilter;
        (onChange as (filter: IFilterOptionsFilter) => void)(updatedOption);
      }

      if (isToggleMode) {
        const updatedOptions = options.map(filter =>
          filter.id === selectedFilter.id
            ? { ...filter, isSelected: !filter.isSelected }
            : { ...filter, isSelected: false },
        );
        (onChange as (filters: IFilterOptionsFilter[]) => void)(updatedOptions);
      }

      // multi select mode
      if (!isSingleSelect && !isToggleMode) {
        const updatedOptions = options.map(filter =>
          filter.id === selectedFilter.id ? { ...filter, isSelected: !filter.isSelected } : filter,
        );
        (onChange as (filters: IFilterOptionsFilter[]) => void)(updatedOptions);
      }

      if (isAutoClose) {
        setIsOpen(false);
      }
    },
    [isSingleSelect, isToggleMode, isAutoClose, options, onChange],
  );

  const handleToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleResetSearchValue = useCallback(() => {
    setSearchValue('');
    onResetSearch?.();
  }, [onResetSearch]);

  const handleClearAll = useCallback(() => {
    if (isSingleSelect) {
      return;
    }

    const clearedFilters: IFilterOptionsFilter[] = options.map(filter => ({ ...filter, isSelected: false }));
    (onChange as (filters: IFilterOptionsFilter[]) => void)(clearedFilters);
    handleResetSearchValue();
    onClearAll?.();
  }, [handleResetSearchValue, isSingleSelect, onChange, onClearAll, options]);

  const renderCheckBoxView = (filter: IFilterOptionsFilter) => (
    <Checkbox
      className={styles.checkbox}
      key={filter.id}
      checked={filter.isSelected || filter.isDisabled}
      onClick={handleSelect(filter)}
      disabled={filter.isDisabled}
    >
      <div className={styles.checkboxContent}>
        {(filter?.tagColor || filter?.icon) && (
          <div className={styles.tagColor} style={{ backgroundColor: filter.tagColor }}>
            {filter?.icon}
          </div>
        )}
        {renderOptionNode ? (
          renderOptionNode(filter)
        ) : (
          <Text variant={TextSize.M} color={filter.isDisabled ? TextColor.GREY3 : TextColor.GREY} ellipsisOverflow>
            {filter.name}
          </Text>
        )}
      </div>
    </Checkbox>
  );

  const renderCheckmarkView = (filter: IFilterOptionsFilter) => (
    <div
      key={filter.id}
      className={cx(styles.optionContent, {
        [styles.isSelected]: filter.isSelected,
      })}
      onClick={handleSelect(filter)}
    >
      {filter?.icon}
      {renderOptionNode ? (
        renderOptionNode(filter)
      ) : (
        <Text variant={TextSize.M} color={TextColor.GREY} ellipsisOverflow className={styles.optionText}>
          {filter.name}
        </Text>
      )}

      {filter.isSelected && (
        <span className={styles.checkWrapper}>
          <Icon name={IconVariant.CHECK} width={12} height={12} />
        </span>
      )}
    </div>
  );

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchValue(value);
      onSearch?.(value);
    },
    [onSearch],
  );

  const showClearAllBtn = isShowClearAll && !isSingleSelect && !isToggleMode && isClearAllVisible;

  const isShowNoResults = !isLoading && !hasNextPage && isSearchable && isEmpty(filteredOptions);
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: !!isLoading,
    hasNextPage: !!hasNextPage,
    onLoadMore: onLoadMore || (() => {}),
    rootMargin: '0px 0px 20px 0px',
    disabled: !isEnableInfiniteScroll,
  });

  return (
    <div className={cx(styles.container, className)} ref={containerRef} data-testid={testId}>
      {renderTrigger({ isOpen, onClick: handleToggle })}

      <div className={styles.menuContainer}>
        {isOpen && (
          <div
            className={cx(styles.menuWrapper, menuClassName)}
            style={{
              bottom: direction === 'top' ? '30px' : 'auto',
              left: ['center', 'right'].includes(placement) ? '0' : 'auto',
              right: ['center', 'left'].includes(placement) ? '0' : 'auto',
              width: placement === 'center' ? '100%' : menuWidth,
            }}
          >
            {(showClearAllBtn || title || isSearchable) && (
              <div className={styles.headerWrapper}>
                <div className={styles.titleRowWrapper}>
                  {title}
                  {showClearAllBtn && (
                    <Text
                      className={styles.clear}
                      variant={TextSize.XS}
                      upperCase
                      smallCaps
                      color={TextColor.GREY3}
                      onClick={handleClearAll}
                    >
                      Clear all
                    </Text>
                  )}
                </div>

                {isSearchable && (
                  <SearchBar
                    value={searchValue}
                    placeholder="Search"
                    onChange={handleSearch}
                    className={styles.searchBar}
                    handleClearInput={handleResetSearchValue}
                  />
                )}
              </div>
            )}

            <div className={styles.menu} ref={rootRef}>
              {filteredOptions.map(filter =>
                isSingleSelect ? renderCheckmarkView(filter) : renderCheckBoxView(filter),
              )}
              {(isLoading || hasNextPage) && <div ref={sentryRef} className={styles.placeholderLoading} />}
              {isShowNoResults && noResults}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
