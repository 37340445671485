
const digest = '47f9621c0e615515ba6df9fe55d84ce1b173f57457f43e1c0a6aad5142d911a9';
const css = `.FilterTriggerButton-module__racoatX {
  position: relative;
  width: max-content;
  height: max-content;
}

.FilterTriggerButton-module__4-PtW7D {
  width: 18px;
  height: 18px;
  position: absolute;
  top: -5px;
  right: -6px;
  color: var(--classic-white) !important;
  background-color: var(--classic-black) !important;

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    width: 20px;
    height: 20px;
    background-color: var(--writer-grey-1) !important;

    top: -6px;
    right: -8px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
`;

export default {"filterButton":"FilterTriggerButton-module__racoatX","clearFilterButton":"FilterTriggerButton-module__4-PtW7D"};
export { css, digest };
  