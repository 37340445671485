
const digest = 'e8cd53a93870edc515619e2f488733670745322397ec543441f16923af15aba5';
const css = `.styles-module__w9TYf-6 > div {
  max-width: 555px;
  padding: 4px 16px;
  border-radius: 4px;
  background-color: var(--black);
  box-shadow: var(--modal-box-shadow);
  font-size: var(--font-size-m);
}

.styles-module__htSdWxG,
.styles-module__htSdWxG > div {
  min-width: fit-content !important;
}

.styles-module__w9TYf-6 .styles-module__XEHcHPW {
  background: var(--orange-red) !important;
}

.styles-module__w9TYf-6 .styles-module__P-shRHk {
  background-color: var(--black) !important;
}

.styles-module__w9TYf-6 .styles-module__P-shRHk svg {
  fill: var(--classic-orange-3);
}

.styles-module__yIvTJGb {
  margin-right: 5px;
  vertical-align: sub;
}

.styles-module__yIvTJGb svg path {
  stroke: white;
}

.styles-module__RAr6GoP {
  display: flex;
  align-items: center;
}

.styles-module__RAr6GoP > div {
  margin-right: 10px;
}

.styles-module__ggffedr {
  display: flex;
  align-items: center;
  gap: 9px;
}

.styles-module__09s4qDB {
  width: 270px;
}

.styles-module__6K0uSgL {
  line-height: 19px;
  opacity: 0.9;
}

.styles-module__MOxpqIx {
  line-height: 19px;
  opacity: 0.9;
}

.styles-module__uzmhcl9 {
  display: flex;
  align-items: center;
  gap: 9px;
}

.styles-module__BMGRPrT {
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: var(--writer-green-3);
  flex-shrink: 0;
}

.styles-module__aN3sM7D {
  color: var(--classic-black);
}

.styles-module__fjpGqCc {
  display: flex;
  align-items: center;
  word-break: break-word;
  gap: 9px;
}

.styles-module__3DlxQqM {
  flex-shrink: 0;
  background: var(--classic-orange-1);
  color: var(--black);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  padding: 3px;
}

.styles-module__um-Jxu5 {
  margin-left: auto;
  padding: 1px;
  border: 0;
  background: var(--writer-grey-2);
  color: var(--white);
  border-radius: 50%;
  line-height: 1;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.styles-module__um-Jxu5:hover {
  background: var(--writer-grey-3);
}

.narrowSnackbar {
  width: 400px;
}
`;

export default {"root":"styles-module__w9TYf-6","anchorOriginBottomCenter":"styles-module__htSdWxG","info":"styles-module__XEHcHPW","error":"styles-module__P-shRHk","icon":"styles-module__yIvTJGb","loadingSnackbar":"styles-module__RAr6GoP","alertMessage":"styles-module__ggffedr","alertContent":"styles-module__09s4qDB","alertHeader":"styles-module__6K0uSgL","alertBody":"styles-module__MOxpqIx","successSnackbar":"styles-module__uzmhcl9","validIconContainer":"styles-module__BMGRPrT","validIcon":"styles-module__aN3sM7D","errorSnackbarContainer":"styles-module__fjpGqCc","offlineIcon":"styles-module__3DlxQqM","closeIcon":"styles-module__um-Jxu5"};
export { css, digest };
  