import type { IComputedValue } from 'mobx';
import { autorun, computed, makeObservable } from 'mobx';
import { requiredObject, getLogger } from '@writercolab/utils';
import type { IServiceProps } from './types';
import { BaseService } from './BaseService';
import { SUBSCRIPTION } from './const';
import { Feature } from '../Feature';
import { createAtomSubscriber } from '../atomSubscriber';

const logger = getLogger('Service', '@writercolab/mobx');

type TRequiredCtx<T> = {
  [K in keyof T]: Exclude<T[K], undefined | null>;
};

interface IProps<TRequired> extends IServiceProps {
  /**
   * Allow service to change state to "active" mode
   */
  isEnabled?(required: TRequiredCtx<TRequired>): boolean;

  /**
   * Describe shape of dependencies that needed to run service
   */
  required?(): TRequired;
}

/**
 * For created services which could be disabled
 */
export abstract class ManagedService<
  TContext extends Record<string, BaseService | undefined> = never,
  TContextNames extends keyof TContext = never,
  TRequired extends object = never,
> extends BaseService<TContext, TContextNames> {
  /**
   * required object existing only when service is active
   * don't use this object in code outside livecycle
   */
  protected readonly required!: TRequiredCtx<TRequired>;

  static accessor<T extends ManagedService>(service: T): IComputedValue<T | undefined> {
    const feature = new Feature<boolean>({
      isEnabled: () => service.required !== undefined,
      feature: service[SUBSCRIPTION],
    });

    const atom = createAtomSubscriber('logger', () =>
      autorun(() => {
        logger.debug(this.name, feature.data ? 'start' : 'stop');
      }),
    );

    return computed(() => {
      atom.reportObserved();

      if (!feature.data) {
        return undefined;
      }

      return service;
    });
  }

  constructor({ isEnabled, required, ...props }: IProps<TRequired>) {
    super(props);

    Object.defineProperty(this, 'required', {
      configurable: true,
      enumerable: true,
      get: () => {
        const reqValue = required
          ? requiredObject<TRequiredCtx<TRequired>>(required() as TRequiredCtx<TRequired>)
          : ({} as TRequiredCtx<TRequired>);

        if (reqValue === undefined) {
          return undefined;
        }

        if (isEnabled && !isEnabled(reqValue)) {
          return undefined;
        }

        return reqValue;
      },
    });

    makeObservable<typeof this, 'required'>(this, {
      required: computed,
    });
  }
}
