
const digest = 'd6d1c63d25d4f70e61272e470269ec4680a3ba4fd3a5a15672d3ebd8a43ce9ff';
const css = `@keyframes styles-module__AhBByez {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.styles-module__f-majTn {
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-name: styles-module__AhBByez;
  animation-timing-function: linear;
}
`;

export default {"fadeIn":"styles-module__f-majTn","fadein":"styles-module__AhBByez"};
export { css, digest };
  