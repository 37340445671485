
const digest = 'd27a8b820d3ef2effcc97f59f6a490c60d1a87bcf079e22654e0d5b3018678b1';
const css = `.styles-module__OhICq4f {
  display: flex;
  justify-content: center;
}

.styles-module__IahFHUp {
  position: relative;
  display: flex;
  width: 884px;
  justify-content: center;
}

.styles-module__uo-P4L2 {
  flex-direction: column;
}

.styles-module__-E3UzES {
  width: 205px;
  flex-direction: column;
}

.styles-module__tHTcGBF {
  padding: 0 31px;
}

.styles-module__Zwxubx6 {
  padding: 0;
}

/* banners */
.styles-module__OBkQSBG {
  display: block;
  width: 540px;
  height: 329px;
  background-repeat: no-repeat;
  background-size: contain;
}

.styles-module__81ItrU4 {
  width: 205px;
  height: 128px;
  margin: auto;
}

.styles-module__b-i94ES {
  position: absolute;
  top: 0;
  right: 47px;
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  place-content: center center;
}

.styles-module__b-i94ES:hover {
  background: var(--classic-grey-1);
}

.styles-module__b-i94ES svg {
  position: relative;
  margin: -6px 0 0;
}

.styles-module__b-i94ES svg path {
  fill: var(--writer-grey-3) !important;
}

.styles-module__b-i94ES:hover svg path {
  fill: var(--classic-black) !important;
}

.styles-module__JgEDPba {
  padding: 0;
  border: none;
  margin: 0;
  background: none;
  color: var(--writer-blue-5);
}

.styles-module__aBefErt {
  cursor: pointer;
}

.styles-module__n-V04CL {
  padding: 20px 0;
  font-size: var(--font-size-xl);
  line-height: 28px;
}

.styles-module__agFZKr4 {
  padding: 16px 0 0;
}

.styles-module__BCuBXEL {
  padding: 23px 0 0;
  line-height: 22px !important;
}

.styles-module__YWgYUxA {
  text-align: center !important;
}

.styles-module__i3G8Nco {
  padding: 5px;
  line-height: 21px;
}

.styles-module__tCVMI3O {
  width: 100% !important;
  margin: 10px 0 !important;
}

.styles-module__akPBkx- {
  padding: 8px 0 26px;
  border-bottom: 1px solid var(--writer-graynu-2);
  line-height: 31px;
}

.styles-module__R282-tz {
  font-weight: 400;
}

.styles-module__mmcKNcn span {
  color: var(--writer-blue-5);
}

.styles-module__cgWU6wZ {
  padding: 23px 0 0;
  color: var(--writer-grey-2);
  font-size: var(--heading-h4-size) !important;
  line-height: 31px;
}

.styles-module__cgWU6wZ .styles-module__aBefErt {
  color: var(--writer-blue-5);
  text-decoration: underline;
}

.styles-module__n-V04CL .styles-module__aBefErt {
  color: var(--writer-blue-5);
  text-decoration: underline;
}

.styles-module__i3G8Nco .styles-module__aBefErt:hover {
  text-decoration: underline;
}

.styles-module__cgWU6wZ .styles-module__aBefErt:hover {
  color: #4844ea;
  text-decoration: underline;
}

.styles-module__cgWU6wZ button {
  padding: 0;
  border: none;
  margin: 0;
  background: none;
}

.styles-module__-joHjxc {
  width: 540px;
  height: 329px;
}

.styles-module__Xh9Asi5 {
  width: 540px;
  height: 329px;
}
`;

export default {"container":"styles-module__OhICq4f","containerBanner":"styles-module__IahFHUp","containerBannerDirectionColumn":"styles-module__uo-P4L2","containerBannerSmall":"styles-module__-E3UzES","containerBannerContent":"styles-module__tHTcGBF","containerBannerContentSmall":"styles-module__Zwxubx6","banner":"styles-module__OBkQSBG","bannerSnippetsSidebar":"styles-module__81ItrU4","containerClose":"styles-module__b-i94ES","cleanButton":"styles-module__JgEDPba","clickable":"styles-module__aBefErt","containerBannerTextSmall":"styles-module__n-V04CL","containerBannerHeading":"styles-module__agFZKr4","containerBannerHeadingSmall":"styles-module__BCuBXEL","alignCenter":"styles-module__YWgYUxA","containerBannerDescriptionSmall":"styles-module__i3G8Nco","buttonFullWidth":"styles-module__tCVMI3O","containerBannerTextHeading":"styles-module__akPBkx-","containerBannerTextHeadingSmall":"styles-module__R282-tz","buttonBlue":"styles-module__mmcKNcn","containerBannerText":"styles-module__cgWU6wZ","snippetsBanner":"styles-module__-joHjxc","termsBanner":"styles-module__Xh9Asi5"};
export { css, digest };
  