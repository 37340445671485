import { computed, makeObservable } from 'mobx';

import { Subscriber } from '@writercolab/mobx';

import type { TUserNotification, ISidebarDataApi, SidebarDataClient } from '@writercolab/types';
import { ContentGenerationJobStatus } from '@writercolab/types';

const NOTIFICATIONS_START_DATE = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
const NOTIFICATIONS_LIMIT = 20;
const NOTIFICATIONS_SUMMARY = {
  [ContentGenerationJobStatus.enum.completed]: 0,
  [ContentGenerationJobStatus.enum.error]: 0,
  [ContentGenerationJobStatus.enum.transcript]: 0,
  [ContentGenerationJobStatus.enum.generating]: 0,
};

interface INotificationsModelOptions {
  client: SidebarDataClient;
}

export class NotificationsModel {
  private readonly client: INotificationsModelOptions['client'];

  constructor({ client }: INotificationsModelOptions) {
    this.client = client;

    makeObservable(this, {
      isNotificationsLoaded: computed,
      allNotifications: computed,
      teamNotifications: computed,
      notifications: computed,
      notificationsSummary: computed,
      currentDocumentNotification: computed,
    });
  }

  private filters = {
    recent: (n: TUserNotification['data']) => n.creationTime > NOTIFICATIONS_START_DATE.toISOString(),
    team: (n: TUserNotification['data']) => {
      const teamId = this.client.params.teamId();

      if (!teamId) {
        return false;
      }

      return n.teamId === teamId && n.creationTime > NOTIFICATIONS_START_DATE.toISOString();
    },
    document: (n: TUserNotification['data']) => {
      const documentId = this.client.params.documentId();

      if (!documentId) {
        return false;
      }

      return n.documentId === documentId;
    },
  };

  deriveNotifications(filter: (n: TUserNotification['data']) => boolean, limit = NOTIFICATIONS_LIMIT) {
    return (
      this.allNotifications
        ?.map(n => n.data)
        .filter(n => !!n && filter(n))
        .sort((a, b) => (a.creationTime > b.creationTime ? -1 : 1))
        .slice(0, limit) ?? []
    );
  }

  private readonly notificationsSubscriber = new Subscriber<
    ISidebarDataApi['onNotificationsSubscriber'],
    TUserNotification[]
  >({
    name: 'notificationsSubscriber',
    getId: () => this.client.api?.onNotificationsSubscriber,
    subscribe: (onNotificationsSubscriber, onDataUpdate) => onNotificationsSubscriber?.(onDataUpdate),
  });

  get isNotificationsLoaded() {
    return !!this.notificationsSubscriber.data;
  }

  get allNotifications() {
    return this.notificationsSubscriber.data ?? [];
  }

  get notifications() {
    return this.deriveNotifications(this.filters.recent);
  }

  get teamNotifications(): TUserNotification['data'][] {
    return this.deriveNotifications(this.filters.team);
  }

  get currentDocumentNotification(): TUserNotification['data'] | null {
    return this.deriveNotifications(this.filters.document, 1)[0] || null;
  }

  get notificationsSummary(): typeof NOTIFICATIONS_SUMMARY {
    const summary = { ...NOTIFICATIONS_SUMMARY };

    this.notifications.forEach(job => {
      if (summary[job.status]) {
        summary[job.status] += 1;
      } else {
        summary[job.status] = 1;
      }
    });

    return summary;
  }
}
