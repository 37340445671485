
const digest = '019dea862992f512481fd590f783cac350de33bc9d97a8e55a549d6dd66d0a0b';
const css = `.styles-module__TkNU1g9 {
  display: block;
}

.styles-module__QDknpJA {
  display: flex;
}
`;

export default {"container":"styles-module__TkNU1g9","wrapper":"styles-module__QDknpJA"};
export { css, digest };
  