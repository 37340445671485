import React, { useEffect, useRef, useState } from 'react';
import cx from 'clsx';
import styles from './styles.module.css';
import type { MuiTooltipProps } from '../mui';
import { MuiTooltip } from '../mui';
import { useModalContext } from '../../hooks/useModalRootContainer';

/**
 * @deprecated use WDS
 */
export enum TooltipAlignment {
  CENTER = 'center',
  LEFT = 'left',
}

/**
 * @deprecated use WDS
 */
export enum TooltipBackgroundColor {
  INHERIT = 'inherit',
  BLACK = 'black',
  WHITE = 'white',
  BLUE = 'blue',
  GREY = 'grey',
}

/**
 * @deprecated use WDS
 */
export enum TooltipArrowColor {
  BLACK = 'black',
  GREY = 'grey',
}

/**
 * @deprecated use WDS
 */
export interface ITooltipProps extends MuiTooltipProps {
  tooltipWidth?: number | string;
  disabled?: boolean;
  tag?: React.ReactNode;
  cta?: React.ReactNode;
  align?: TooltipAlignment;
  arrowStyles?: string;
  tooltipWrapperClassname?: string;
  tooltipTitleClassName?: string;
  tooltipBackgroundColor?: TooltipBackgroundColor;
  tooltipArrowColor?: TooltipArrowColor;
  showOnHorizontalTextOverflow?: boolean;
}

const Title: React.FC<{
  align: TooltipAlignment;
  tag?: React.ReactNode;
  title: React.ReactNode;
  cta?: React.ReactNode;
  tooltipTitleClassName?: string;
}> = ({ align, tag, title, cta, tooltipTitleClassName }) => (
  <div className={cx(styles.styledContent, styles[align])}>
    {tag && <div className={styles.tag}>{tag}</div>}
    <div className={cx(styles.title, tooltipTitleClassName)}>{title}</div>
    {cta && <div className={styles.cta}>{cta}</div>}
  </div>
);

/**
 * @deprecated use WDS
 */
export const Tooltip: React.FC<ITooltipProps> = ({
  disabled,
  title,
  placement,
  tooltipWidth,
  tag,
  align = TooltipAlignment.CENTER,
  children,
  arrowStyles,
  showOnHorizontalTextOverflow,
  tooltipWrapperClassname,
  tooltipTitleClassName,
  tooltipBackgroundColor = TooltipBackgroundColor.BLACK,
  tooltipArrowColor = TooltipArrowColor.BLACK,
  cta,
  PopperProps,
  ...props
}) => {
  const [disableTooltipOnOverflow, setDisableTooltipOnOverflow] = useState(false);
  const tooltipRef = useRef<HTMLParagraphElement>();
  const styledTooltipBackground = cx({
    [styles.styledTooltipWhite]: tooltipBackgroundColor === TooltipBackgroundColor.WHITE,
    [styles.styledTooltipBlue]: tooltipBackgroundColor === TooltipBackgroundColor.BLUE,
    [styles.styledTooltipGrey]: tooltipBackgroundColor === TooltipBackgroundColor.GREY,
  });

  const styledTooltipArrow = cx({
    [styles.styledArrowBlack]: tooltipArrowColor === TooltipArrowColor.BLACK,
    [styles.styledArrowGrey]: tooltipArrowColor === TooltipArrowColor.GREY,
  });

  const getModalsRootContainer = useModalContext();

  const componentStyles = {
    tooltip: cx(styles.styledTooltip, tooltipWrapperClassname, styledTooltipBackground),
    arrow: cx(styles.styledArrow, arrowStyles, styledTooltipArrow),
  };

  useEffect(() => {
    if (showOnHorizontalTextOverflow && tooltipRef.current) {
      const { clientWidth, scrollWidth } = tooltipRef.current;

      if (clientWidth >= scrollWidth) {
        setDisableTooltipOnOverflow(true);
      }
    }
  }, [showOnHorizontalTextOverflow]);

  if (disabled) {
    return children;
  }

  let titleComponent = <Title {...{ align, tag, title, cta, tooltipTitleClassName }} />;

  if (tooltipWidth) {
    titleComponent = (
      <div style={{ width: tooltipWidth }}>
        <Title {...{ align, tag, title, cta, tooltipTitleClassName }} />
      </div>
    );
  }

  return (
    <MuiTooltip
      arrow
      title={titleComponent}
      disableHoverListener={disabled || disableTooltipOnOverflow}
      placement={placement}
      ref={tooltipRef}
      PopperProps={{
        container: getModalsRootContainer(),
        ...PopperProps,
      }}
      componentsProps={{
        arrow: {
          className: componentStyles.arrow,
        },
        tooltip: {
          className: componentStyles.tooltip,
        },
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};

export default React.memo(Tooltip);
