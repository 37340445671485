
const digest = 'c493bd84ce2c537a299f6b16d7bc557c97e4408c57bd7eb747f18e5ffeb76058';
const css = `.styles-module__V3PhfSE {
  text-align: left;
}

.styles-module__V3PhfSE a,
.styles-module__V3PhfSE a:visited,
.styles-module__V3PhfSE a:active {
  color: var(--white);
}
`;

export default {"tooltipLink":"styles-module__V3PhfSE"};
export { css, digest };
  