import React from 'react';
import cx from 'clsx';

import styles from './styles.module.css';

export const NeutralLangInfo: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.paddedContent}>
      <h2 className={cx(styles.header)}>Disability</h2>
      <h2 className={cx(styles.header, styles.withBottomMargin, styles.gray)}>Use Neutral Language</h2>

      <h3 className={styles.blockHeader}>General tips for writing about disability</h3>
      <div className={cx(styles.block, styles.paddedLeft, styles.withBottomMargin)}>
        <span className={styles.blockItem}>
          <b>Is it relevant?</b> Refer to a person&apos;s disability only when it&apos;s relevant and necessary.
        </span>
        <span className={styles.blockItem}>
          <b>Is it sensitive?</b> Don&apos;t disclose information about a disability unless the person is comfortable
          with it.
        </span>
        <span className={styles.blockItem}>
          <b>What’s their preference?</b> Ask people which terms they themselves prefer to use. When in doubt, use
          neutral language.
        </span>
      </div>

      <h3 className={styles.blockHeader}>Use neutral language to describe disabilities</h3>
      <div className={cx(styles.block, styles.paddedLeft, styles.withBottomMargin)}>
        <p className={styles.blockParagraph}>
          When describing people with disabilities, avoid wording that implies victimhood (e.g.{' '}
          <b>
            <i>suffering from, brain damage</i>
          </b>
          ). These terms are unnecessary and may be hurtful because they imply that a person with a disability is
          deficient.
        </p>
        <p className={styles.blockParagraph}>
          Likewise, avoid describing people without disabilities as{' '}
          <b>
            <i>healthy, normal</i>
          </b>
          , or{' '}
          <b>
            <i>whole</i>
          </b>
          .
        </p>
      </div>

      <h3 className={styles.blockHeader}>How do I use neutral language?</h3>
      <div className={cx(styles.block, styles.paddedLeft)}>
        <ul className={cx(styles.list, styles.withBottomMargin)}>
          <li>Specify the type of disability being referenced where possible</li>
          <li>Use objective language to describe the disability</li>
        </ul>

        <div className={styles.doDont}>
          <div className={styles.doDontSection}>
            <h4 className={styles.doDontHeader}>Don&apos;t</h4>
            <div className={styles.doDontItem}>
              She <b>has been suffering from</b> early-onset Alzheimer&apos;s.
            </div>
            <div className={styles.doDontItem}>
              He is <b>confined to a wheelchair.</b>
            </div>
            <div className={styles.doDontItem}>
              My neighbor&apos;s daughter was born with a <b>congenital birth defect.</b>
            </div>
          </div>
          <div className={styles.doDontSection}>
            <h4 className={cx(styles.doDontHeader, styles.greenHeader)}>Do</h4>
            <div className={styles.doDontItem}>
              She <b>has been diagnosed with</b> early-onset Alzheimer&apos;s.
            </div>
            <div className={styles.doDontItem}>
              He <b>uses a wheelchair.</b>
            </div>
            <div className={styles.doDontItem}>
              My neighbor&apos;s daughter was born with a <b>congenital disability.</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.fullWidthContent}>
      <h3 className={cx(styles.blockHeader, styles.whiteHeader)}>Resources</h3>
      <div className={cx(styles.block, styles.paddedLeft)}>
        <div className={styles.title}>National Center on Disability and Journalism</div>
        <div className={styles.link}>
          <a href="https://ncdj.org/style-guide/" target="_blank" rel="noreferrer">
            https://ncdj.org/style-guide/
          </a>
        </div>
        <div className={styles.title}>Society of Professional Journalists</div>
        <div className={styles.link}>
          <a href="https://www.spj.org/diversity.asp" target="_blank" rel="noreferrer">
            spj.org
          </a>
        </div>
        <div className={styles.title}>The Diversity Style Guide</div>
        <div className={styles.link}>
          <a href="https://www.diversitystyleguide.com" target="_blank" rel="noreferrer">
            diversitystyleguide.com
          </a>
        </div>
        <div className={styles.title}>EARN</div>
        <div className={styles.link}>
          <a
            href="https://askearn.org/topics/retention-advancement/disability-etiquette/people-first-language/"
            target="_blank"
            rel="noreferrer"
          >
            https://askearn.org/topics/retention-advancement/disability-etiquette/people-first-language/
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default NeutralLangInfo;
