
const digest = '7300bf693f109262aafe3a4e29c92c17391202c9cf10ec1953e3adc6b9bc982a';
const css = `.styles-module__dbgC4sG h1,
.styles-module__dbgC4sG h2,
.styles-module__dbgC4sG h3 {
  color: var(--black);
}

.styles-module__dbgC4sG {
  position: relative;
  z-index: 4;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: var(--white);
  color: var(--black);
  font-family: var(--font-default);
  font-size: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: inherit;
}

.styles-module__pQSd6xA {
  padding-bottom: 50px;
}

.styles-module__dbgC4sG * {
  box-sizing: border-box;
}

.styles-module__yKyWLMK {
  height: 60px;
  border-radius: 6px;
  margin-right: 20px;
}

.styles-module__yKyWLMK > i {
  margin-right: 10px;
}

.styles-module__yKyWLMK > * {
  pointer-events: none;
}

.styles-module__aIoHM9C svg line,
.styles-module__aIoHM9C svg path {
  stroke: var(--writer-graynu-3);
}

.styles-module__lw-bXHh {
  display: flex;
  width: var(--sidebar-category-container-width);
  height: 100%;
  flex-direction: column;
}

.styles-module__n7kAYyA {
  display: flex;
  width: var(--sidebar-category-container-small-width);
  flex-direction: column;
}

.styles-module__xrTMS-7 {
  display: flex;
  width: var(--sidebar-category-container-medium-width);
  flex-direction: column;
}

.styles-module__0OUY-dw {
  flex: 1 1 auto;
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
}

.styles-module__0OUY-dw::-webkit-scrollbar {
  width: 0; /* For Chrome, Safari, and Opera */
}

.styles-module__LK81byt {
  margin-bottom: 8px;
}

.styles-module__ejpCbqk {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1px 12px 4px;
  gap: 20px;
}
`;

export default {"sidebar":"styles-module__dbgC4sG","sidebarDropdown":"styles-module__pQSd6xA","sidebarPreviewButton":"styles-module__yKyWLMK","sidebarPreviewButtonWithScoreHidden":"styles-module__aIoHM9C","categoriesAndSnippets":"styles-module__lw-bXHh","categoriesMiniAndSnippetsSmall":"styles-module__n7kAYyA","categoriesMiniAndSnippetsMedium":"styles-module__xrTMS-7","categoriesContainer":"styles-module__0OUY-dw","actionsAndStats":"styles-module__LK81byt","dropdown":"styles-module__ejpCbqk"};
export { css, digest };
  