import type { TTemplateFunction } from './types';

export const buildSeparatorVarRx = (start: string, end: string) => new RegExp(`${start}([^${start + end}]+)${end}`);
const defaultSeparatorVarRx = buildSeparatorVarRx('\\{', '\\}');

/**
 * @param template string with variables {var}
 * @returns Function with params which construct typed string
 *
 * @example
 * const tmpl = template('https://{host}/create/{action}?ref={ref}');
 * const nonConstObject = tmpl({
 *   host: 'example.com',
 *   action: 'item',
 *   ref: 1,
 * });
 * type TNonConst = typeof nonConstObject; // `https://${string}/create/${string}?ref=${number}`
 *
 * const constObject = tmpl({
 *    host: 'example.com',
 *    action: 'item',
 *    ref: 1,
 * } as const);
 * type TConst = typeof constObject; // `https://example.com/create/item?ref=1`
 */
export function template<T extends string, TSepStart extends string = '{', TSepEnd extends string = '}'>(
  tmpl: T,
  _start?: TSepStart,
  _end?: TSepEnd,
  rx: RegExp = defaultSeparatorVarRx,
) {
  const array = tmpl.split(rx);

  const fn: TTemplateFunction<T, TSepStart, TSepEnd> = args =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    array.map((item, i) => (i % 2 ? (args as Record<string, string | number>)[item] : item)).join('') as any;

  return fn;
}

/**
 * typed config for strings
 *
 * @param config Configuration
 * @returns typed object with getters for configuration values
 *
 * @example
 *
 * const config = templateConfig({
 *   url: 'example.com',
 *   url1: 'https://{host}/create/{action}?ref={ref}',
 * } as const);
 *
 * config.url() === 'example.com' // type is `example.com`
 * config.url1({ host: 'example.com', ... }) // the same behaviour as in template
 */
export function templateConfig<
  T extends Record<string, string>,
  TSepStart extends string = '{',
  TSepEnd extends string = '}',
>(config: T, rx = defaultSeparatorVarRx, start?: TSepStart, end?: TSepEnd) {
  return Object.keys(config).reduce(
    (memo, key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (memo as any)[key] = template(config[key], start, end, rx);

      return memo;
    },
    {} as {
      [K in keyof T]: TTemplateFunction<T[K], TSepStart, TSepEnd>;
    },
  );
}
