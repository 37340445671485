import TurndownService from 'turndown';

const turndownService = new TurndownService({
  headingStyle: 'atx',
  bulletListMarker: '-',
  codeBlockStyle: 'fenced',
  emDelimiter: '_',
  strongDelimiter: '**',
  linkStyle: 'inlined',
  preformattedCode: true,
});

turndownService.addRule('strikethrough', {
  filter: ['s', 'del'],
  replacement(content) {
    return `~~${content}~~`;
  },
});

turndownService.addRule('underline', {
  filter: ['u', 'ins'],
  replacement(content) {
    return `<u>${content}</u>`;
  },
});

turndownService.addRule('pre', {
  filter: 'pre',
  replacement(content) {
    return `\`\`\`\n${content}\n\`\`\``;
  },
});

turndownService.addRule('paragraph', {
  filter: 'p',
  replacement(content) {
    return `${content}\n`;
  },
});

export const convertHtmlToMarkdown = (html: string): string => turndownService.turndown(html);
