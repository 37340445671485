
const digest = '7e1b6ebf7a21e30e093c2f313e2109b4e567c7e30bc337693f935ee2084a6c05';
const css = `.CopyButton-module__i8OYLsv {
  margin-right: 10px;
}

.CopyButton-module__PiM-jiO {
  position: relative;
  width: max-content;
  height: 40px;
  border-radius: 25px;
  background-color: var(--writer-blue-2);
  padding: 6px;
  cursor: pointer;
  user-select: none;

  &.CopyButton-module__9lJD8lK {
    opacity: 0.5;
    pointer-events: none;
  }

  .CopyButton-module__49UlydC {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .CopyButton-module__oijSeks {
    position: relative;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--writer-blue-3);
  }

  .CopyButton-module__eSv-ln1 {
    padding-right: 6px;
    position: relative;
  }
}

.CopyButton-module__joCfma2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: rgb(217 217 217 / 70%);
  }
}

.CopyButton-module__FqayjzZ {
  display: flex;
  align-items: center;
}

.CopyButton-module__6nzlImS {
  margin-right: 5px;
}
`;

export default {"copyButtonCheckmark":"CopyButton-module__i8OYLsv","copyButtonIcon":"CopyButton-module__PiM-jiO","disabled":"CopyButton-module__9lJD8lK","wrapper":"CopyButton-module__49UlydC","iconWrapper":"CopyButton-module__oijSeks","titleWrapper":"CopyButton-module__eSv-ln1","copyIconButton":"CopyButton-module__joCfma2","tooltip":"CopyButton-module__FqayjzZ","copiedIcon":"CopyButton-module__6nzlImS"};
export { css, digest };
  