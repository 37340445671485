import type { FC, ReactNode } from 'react';
import React, { useEffect, useMemo, useRef } from 'react';
import type { Options } from '@emotion/cache';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

export type MuiCacheOptions = Options;

const InsertContainer: FC<{ container: Node }> = ({ container }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = ref.current;

    el?.appendChild(container);

    return () => {
      el?.removeChild(container);
    };
  }, [container]);

  return <div ref={ref} />;
};

export const MuiProvider: FC<{
  options: MuiCacheOptions;
  children?: ReactNode;
}> = ({ children, options }) => {
  const [cache, container] = useMemo(() => {
    const container = options.container ?? document.createElement('div');
    const cache = createCache({
      ...options,
      container,
    });

    return [cache, container];
  }, [options]);

  return (
    <CacheProvider value={cache}>
      <InsertContainer container={container} />
      {children}
    </CacheProvider>
  );
};
