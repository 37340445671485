import { DotLoader, Logo, LogoVariant } from "@writercolab/ui-atoms";
import styles from "./style.module.scss";

export const SplashScreen = () => (
  <div className={styles.container}>
    <div className={styles.pleaseWaitContainer}>
      <Logo variant={LogoVariant.BLACK} className={styles.logo} />
      <DotLoader className={styles.dotLoader} />
    </div>
  </div>
);
