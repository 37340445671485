import React from 'react';
import cx from 'clsx';
import styles from './styles.module.css';
import { Text, TextSize } from '../typography/Text/Text';
import { Icon, IconVariant } from '../Icon';

export interface IItemLabel {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  id: number;
  label: string;
  type?: ItemLabelType;
  onClick?: () => void;
  onClose?: () => void;
}

export enum ItemLabelType {
  GREEN,
  BLUE,
  RED,
}

export const ItemLabel = ({ className, label, id, onClick, onClose, type = ItemLabelType.GREEN }: IItemLabel) => (
  <div
    aria-hidden
    key={id}
    className={cx(className, styles.itemLabel, {
      [styles.itemGreen]: type === ItemLabelType.GREEN,
      [styles.itemRed]: type === ItemLabelType.RED,
      [styles.itemBlue]: type === ItemLabelType.BLUE,
    })}
    onClick={onClick}
  >
    <Text variant={TextSize.L}>{label}</Text>
    <Text variant={TextSize.XS}>{id}</Text>

    {onClose && (
      <div aria-hidden className={styles.closeIcon} onClick={() => onClose()}>
        <Icon name={IconVariant.CLOSE} width={12} height={12} />
      </div>
    )}
  </div>
);

export default ItemLabel;
