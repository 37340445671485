
const digest = '9168aa7eede27603805fc1f6772122851af9eb33df814a36b7b049feeb119149';
const css = `.styles-module__UrS7YZ6 {
  max-width: 129px;
}

.styles-module__XwiL84I {
  max-width: 220px;
}
`;

export default {"posInputGroup":"styles-module__UrS7YZ6","posTooltipTextWrapper":"styles-module__XwiL84I"};
export { css, digest };
  