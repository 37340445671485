
const digest = '5907da8be42b097d2918a93c9d12dfecf168e0c94e4a3fffc2d00987245b980d';
const css = `.styles-module__7c02xY9 {
  position: relative;
  cursor: pointer;
  user-select: none;
}

.styles-module__C-Rd-tJ {
  padding: 3px 5px 7px 6px;
}

.styles-module__C-Rd-tJ p {
  font-size: 8px;
}

.styles-module__NFAT1pp {
  position: absolute;
  z-index: 99999;
  overflow: hidden auto;
  padding: 8px 0;
  border: var(--gray-border);
  border-radius: 4px;
  background: var(--classic-white);
  box-shadow: var(--menu-box-shadow);

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgb(0 0 0 / 50%);
    box-shadow: 0 0 1px rgb(255 255 255 / 50%);
  }

  &::-webkit-scrollbar {
    width: 3px;
    appearance: none;
  }

  &.styles-module__RZGcWv- {
    top: 100%;
    left: 0;
  }

  &.styles-module__xsjmkaY {
    top: 100%;
    right: 0;
  }

  &.styles-module__IyLUZKq {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &.styles-module__Gny087x {
    top: -100%;
    left: 100%;
  }

  &.styles-module__UDsJC2j {
    bottom: 100%;
    left: 0;
  }

  &.styles-module__vWReVbt {
    bottom: 100%;
    left: auto;
  }

  &.styles-module__u-aoy9C {
    right: 0;
    bottom: 100%;
  }
}

.styles-module__UIUIdeE .styles-module__gFwDaDi {
  border-top: 0;
}

.styles-module__wlIC9p- {
  overflow: hidden;
  width: 20px;
  height: 11px;
  margin-left: auto;
}

.styles-module__TxSP8Ko {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 5px 5px 8px;
  border-radius: 4px;
  margin: 0 8px;
  color: var(--classic-black);
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
}

.styles-module__62KdbcG {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 5px 5px 12px;
  color: var(--classic-black);
}

.styles-module__62KdbcG p {
  margin: 0;
}

.styles-module__62KdbcG:hover p {
  text-decoration: underline;
}

.styles-module__gFwDaDi {
  padding: 5px 5px 5px 9px;
  margin: 3px 0;
}

.styles-module__TxSP8Ko p {
  margin: 0;
  margin-right: 9px;
  font-weight: 400;
}

.styles-module__fYCom1y .styles-module__TxSP8Ko {
  padding: 5px 5px 5px 9px;
}

.styles-module__TxSP8Ko span {
  font-size: 11px;
  line-height: 18px;
}

.styles-module__PxBjV8Y {
  display: none;
}

.styles-module__IwYIZhv {
  display: flex;
}

.styles-module__HrgVl7l .styles-module__gFwDaDi {
  margin: 0 0 3px;
}

.styles-module__TxSP8Ko:hover {
  background-color: var(--writer-blue-1);
}

.styles-module__TxSP8Ko.styles-module__H711IuU:hover {
  background-color: var(--classic-orange-0);
  color: var(--classic-orange-3);
}

.styles-module__B5IiEqf {
  cursor: default;
}

.styles-module__TxSP8Ko.styles-module__B5IiEqf:hover {
  background-color: transparent;
}

.styles-module__TxSP8Ko.styles-module__Hjtw4Rz {
  background-color: var(--writer-blue-2);
}

.styles-module__TxSP8Ko.styles-module__Hjtw4Rz p {
  font-weight: 600;
}

.styles-module__TxSP8Ko.styles-module__YsvI7N6 p {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__kTSCoAI {
  border: 1px solid var(--writer-graynu-2);
}

.styles-module__kTSCoAI:nth-child(2) {
  margin-right: 0;
  margin-left: 0;
}

.styles-module__kTSCoAI i {
  position: relative;
  margin: 0 0 0 -3px;
}

.styles-module__TxSP8Ko.styles-module__--kFO2c p {
  white-space: normal;
}

.styles-module__TxSP8Ko.styles-module__6gmcW-D {
  flex-wrap: wrap;
}

.styles-module__TxSP8Ko svg + span,
.styles-module__TxSP8Ko svg + p,
.styles-module__TxSP8Ko i + span,
.styles-module__TxSP8Ko i + p {
  margin-left: 7px;
}

.styles-module__gRIDct9 {
  overflow: hidden;
  width: 20px;
  height: 11px;
  margin-left: auto;
}

.styles-module__mQQnGoG {
  visibility: hidden;
}

.styles-module__gRIDct9 svg {
  width: 100%;
  height: 100%;
}

.styles-module__gRIDct9 svg path {
  stroke: currentcolor;
}

.styles-module__TxSP8Ko .styles-module__cfuh49I {
  margin-left: 0;
  width: 100%;
  white-space: initial;
}

.styles-module__5SwcPG1 {
  margin-right: 10px;
}

.styles-module__RG16M0v {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.styles-module__Izb-4WB > span {
  border: 1px solid var(--classic-grey-2);
  background: var(--classic-white);
}

.styles-module__Izb-4WB > span::after {
  top: 1px !important;
  left: 5px !important;
}

.styles-module__Izb-4WB > span:hover {
  border: 1px solid var(--classic-grey-2);
  background: var(--classic-white);
}

.styles-module__TxSP8Ko.styles-module__H711IuU svg path,
.styles-module__TxSP8Ko.styles-module__H711IuU svg line {
  stroke: var(--classic-orange-3);
}

.styles-module__yBccsxL {
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
}

.styles-module__eTXtax8 {
  padding: 8px 5px 5px 7px;
  border-top: none;
  border-bottom: none;
}

.styles-module__mhatyiE {
  padding: 14px 19px 8px 17px;
  border-top: 0.5px solid var(--writer-graynu-2);
  margin: 6px 0 0;
}

.styles-module__eTXtax8 p {
  color: var(--classic-blue-4);
  font-size: var(--font-size-m);
  font-weight: 600;
}

.styles-module__1J8BJPE {
  margin: 4px 0 !important;
}

/* Styling for "withTags" */
.styles-module__rCsJyhA {
  max-width: 170px;
  padding-right: 12px;
  margin: 0 0 2px;
}

/* Styling for "withCheckbox" */
.styles-module__c-UFJG2:hover {
  background: var(--white) !important;
}

.styles-module__c-UFJG2 .styles-module__gRIDct9 {
  display: none;
}

.styles-module__cHZYILQ {
  justify-content: space-between;
}

.styles-module__eyjEnFh {
  display: none;
}

.styles-module__Q7R4lC6 {
  color: var(--classic-black);
}

.styles-module__uQIWKjt {
  color: var(--classic-orange-3);
}

.styles-module__XpXnXNk {
  overflow: hidden;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  margin-left: auto;
}

.styles-module__6GOCwGn {
  position: absolute;
}
`;

export default {"dropdownContainer":"styles-module__7c02xY9","dropdownContainerLabel":"styles-module__C-Rd-tJ","dropdownItemsContainer":"styles-module__NFAT1pp","bottomLeft":"styles-module__RZGcWv-","bottomRight":"styles-module__xsjmkaY","bottomCenter":"styles-module__IyLUZKq","right":"styles-module__Gny087x","topLeft":"styles-module__UDsJC2j","top":"styles-module__vWReVbt","topRight":"styles-module__u-aoy9C","noPrimaryOptions":"styles-module__UIUIdeE","dropdownSecondaryContainer":"styles-module__gFwDaDi","defaultCheckIcon":"styles-module__wlIC9p-","dropdownItem":"styles-module__TxSP8Ko","dropdownSecondaryItem":"styles-module__62KdbcG","actionType":"styles-module__fYCom1y","separatorNoPrimaryOptions":"styles-module__PxBjV8Y","iconInlineType":"styles-module__IwYIZhv","dropdownItemNoPrimaryOptions":"styles-module__HrgVl7l","warning":"styles-module__H711IuU","disableHover":"styles-module__B5IiEqf","highlight":"styles-module__Hjtw4Rz","truncated":"styles-module__YsvI7N6","centerIcon":"styles-module__kTSCoAI","multiLine":"styles-module__--kFO2c","withDescription":"styles-module__6gmcW-D","checkIcon":"styles-module__gRIDct9","checkIconHidden":"styles-module__mQQnGoG","description":"styles-module__cfuh49I","dropdownItemIndicatorWrapper":"styles-module__5SwcPG1","dropdownItemCheckBox":"styles-module__RG16M0v","dropdownItemCheckBoxItem":"styles-module__Izb-4WB","noBorders":"styles-module__yBccsxL","dropdownAdditional":"styles-module__eTXtax8","descriptionContainer":"styles-module__mhatyiE","lineSeparator":"styles-module__1J8BJPE","withTags":"styles-module__rCsJyhA","withCheckbox":"styles-module__c-UFJG2","withLabel":"styles-module__cHZYILQ","textHidden":"styles-module__eyjEnFh","withLabelText":"styles-module__Q7R4lC6","withWarning":"styles-module__uQIWKjt","blueCheckIcon":"styles-module__XpXnXNk","portalContainer":"styles-module__6GOCwGn"};
export { css, digest };
  