import type { SuggestionCategoryType } from '@writercolab/types';
import { getLogger } from '../logger';
import type { ISuggestionSettings } from '../types/Suggestions';

const LOG = getLogger('suggestionUtil');

export const findSuggestionSection = (name: SuggestionCategoryType, suggestionSettings: ISuggestionSettings) => {
  const section = suggestionSettings.categories.find(category => category.category === name);

  if (!section) {
    LOG.error(`Section ${name} was not found in settings.`);
  }

  return section;
};
