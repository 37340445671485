import { getLogger } from '@writercolab/utils';
import { autorun, runInAction, makeObservable, computed, action, untracked } from 'mobx';
import type { IComputedValue, IAtom } from 'mobx';
import isEqual from 'lodash/isEqual';
import { createAtomSubscriber } from './atomSubscriber';
import { type IObservableField, observableField, type IObservableFieldOptions } from './observableField';

const logger = getLogger('Subscriber');

export interface ISubscriberProps<TArgs, T> extends IObservableFieldOptions<T> {
  /**
   * name of instance
   */
  name?: string;
  /**
   * use to use reactive
   *
   * @return params for subscriber,
   */
  getId(): TArgs | undefined;
  /**
   *
   * @param id params for subscriber
   * @param fn callback receives data
   */
  subscribe(id: TArgs, fn: (data: T) => void): (() => void) | void;
  /**
   * params for mobx computed({ equals })
   */
  equals?(a: T, b: T): boolean;
  equalsArgs?(a: TArgs | undefined, b: TArgs | undefined): boolean;
  /**
   * clear result after canceling subscription
   */
  autoclear?: boolean;
}

export class Subscriber<TArgs, T> {
  static ID = 0;

  constructor({ equals, equalsArgs, getId, subscribe, autoclear, name, ...opts }: ISubscriberProps<TArgs, T>) {
    this.name = name ?? `Subscriber${Subscriber.ID++}`;
    this.atom = createAtomSubscriber(`atom-${this.name}`, () => {
      const cancel = this.createSubscribtion();

      return () => {
        cancel();

        if (autoclear) {
          this.clear();
        }
      };
    });
    this.getId = computed<TArgs | undefined>(
      () => {
        try {
          return getId();
        } catch (err) {
          logger.error('getId', err);

          return undefined;
        }
      },
      {
        equals: equalsArgs ?? isEqual,
      },
    );
    this.subscribe = (id, fn) => untracked(() => subscribe(id, fn));

    this.$field = observableField<T>(opts);

    logger.debug(this.name, 'constructor');
    makeObservable(this, {
      data: computed({ equals, name: this.name }),
      clear: action,
    });
  }

  /**
   * must be call inside mobx reactive context
   * @return data from emitter
   */
  get data() {
    if (!this.atom.reportObserved() || !this.atom.isBeingObserved) {
      logger.debug(this.name, 'data outside context');
    }

    const data = this.$field.get();
    logger.debug(this.name, 'data', data);

    return data;
  }

  reportObserved() {
    return this.atom.reportObserved();
  }

  clear() {
    this.$field.set(undefined);
  }

  // private readonly
  public readonly name: string;

  private readonly getId: IComputedValue<TArgs | undefined>;

  private readonly subscribe: ISubscriberProps<TArgs, T>['subscribe'];

  private readonly $field: IObservableField<T>;

  private readonly atom: IAtom;

  // private helpers
  private createSubscribtion() {
    logger.debug(this.name, 'createSubscribtion::init');
    let cancelSubscribe: undefined | (() => void);

    const cancel = autorun(() => {
      cancelSubscribe?.();
      cancelSubscribe = undefined;

      const id = this.getId.get();
      logger.debug(this.name, 'createSubscribtion::getId', id);

      if (id === undefined) {
        return;
      }

      logger.debug(this.name, `createSubscribtion::init.create`, id);
      const cancelInner = this.subscribe(id, data => runInAction(() => this.$field.set(data)));

      cancelSubscribe = () => {
        logger.debug(this.name, `createSubscribtion::init.cancel`, id);
        cancelInner?.();
      };
    });

    return () => {
      logger.debug(this.name, 'createSubscribtion::cancel');
      cancelSubscribe?.();
      cancel();
    };
  }
}
