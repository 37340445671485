
const digest = '44d46c56f0bf93745d67e2c6fb997f8595cee44c9fcd0c49076383400eb28aa0';
const css = `.IconButton-module__SJVjNBk {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--writer-graynu-1);
  transition: background-color 0.2s;
  color: black;
  cursor: pointer;

  &.IconButton-module__m2WJ4Mw {
    background-color: transparent;
  }

  &:hover {
    background-color: var(--writer-graynu-2);
  }

  &.IconButton-module__Avl1kjX {
    border-radius: 50%;
  }
}
`;

export default {"iconButton":"IconButton-module__SJVjNBk","noBackground":"IconButton-module__m2WJ4Mw","rounded":"IconButton-module__Avl1kjX"};
export { css, digest };
  