import type { ChangeEvent } from 'react';
import React from 'react';
import { useState } from 'react';
import cx from 'clsx';
import { Enum } from '@writercolab/utils';
import styles from './styles.module.css';
import { Icon, IconVariant } from '../Icon';

/**
 * @deprecated use WDS
 */
export const TPosition = new Enum('left', 'right');

interface CheckboxProps {
  id?: string;
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  /** Is checked */
  checked?: boolean;
  /** Is disabled */
  disabled?: boolean;
  /** Is indeterminate */
  indeterminate?: boolean;
  /** On toggle callback */
  onClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Checkbox position */
  position?: typeof TPosition.type;
}

const CheckMark: React.FC<{
  isChecked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  isHovered?: boolean;
}> = ({ isChecked, indeterminate, disabled, isHovered }) => (
  <span
    className={cx(styles.checkbox, {
      [styles.checked]: isChecked,
      [styles.indeterminate]: indeterminate,
      [styles.disabled]: disabled,
    })}
  >
    {((!indeterminate && isChecked) || isHovered) && <Icon name={IconVariant.CHECKMARK} width={10} height={10} />}
    {indeterminate && !isChecked && !isHovered && <span className={styles.dash} />}
  </span>
);

/**
 * @deprecated use WDS
 */
export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  className,
  children,
  checked,
  disabled = false,
  indeterminate = false,
  onClick,
  position = 'left',
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const elClass = cx(styles.container, className, { [styles.disabled]: disabled });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <label
      className={elClass}
      htmlFor={id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {position === TPosition.enum.left && (
        <CheckMark isChecked={checked} indeterminate={indeterminate} disabled={disabled} isHovered={isHovered} />
      )}
      <input id={id} type="checkbox" disabled={disabled} checked={checked} onChange={handleChange} {...props} />
      {children && children}
      {position === TPosition.enum.right && (
        <CheckMark isChecked={checked} indeterminate={indeterminate} disabled={disabled} isHovered={isHovered} />
      )}
    </label>
  );
};
