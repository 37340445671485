import React from 'react';
import {
  FlagAu as FlagAU,
  FlagCa as FlagCA,
  FlagGb as FlagGB,
  FlagUs,
  IconsCommonPin,
  IconsCommonPinFilled,
} from '@writercolab/assets';

import Editor from '../../assets/icons/navIcons/editor.svg';
import SnippetsUnfilled from '../../assets/icons/navIcons/snippetsUnfilled.svg';
import SnippetsUnfilledGrey from '../../assets/icons/navIcons/snippetsUnfilledGrey.svg';
import SnippetsFilled from '../../assets/icons/navIcons/snippetsFilled.svg';
import Team from '../../assets/icons/navIcons/team.svg';
import TeamUnfilledGrey from '../../assets/icons/navIcons/teamUnfilledGrey.svg';
import Styleguide from '../../assets/icons/navIcons/styleguide.svg';
import Settings from '../../assets/icons/navIcons/settings.svg';
import Document from '../../assets/icons/navIcons/document.svg';
import DocumentExtended from '../../assets/icons/documentExtended.svg';
import Docs from '../../assets/icons/navIcons/docs.svg';
import Folder from '../../assets/icons/folder.svg';
import NewDoc from '../../assets/icons/navIcons/newDoc.svg';
import Portal from '../../assets/icons/navIcons/portal.svg';
import Reports from '../../assets/icons/navIcons/reports.svg';
import ReportsFilled from '../../assets/icons/navIcons/reportsFilled.svg';
import Suggestions from '../../assets/icons/navIcons/suggestions.svg';
import Terms from '../../assets/icons/navIcons/terms.svg';
import Trash from '../../assets/icons/navIcons/trash.svg';
import TrashWhite from '../../assets/icons/navIcons/trashWhite.svg';
import Download from '../../assets/icons/navIcons/download.svg';
import Wand from '../../assets/icons/navIcons/wand.svg';
import WandWhite from '../../assets/icons/navIcons/wandWhite.svg';
import FavoriteUnfilled from '../../assets/icons/navIcons/favoriteUnfilled.svg';
import FavoriteFilled from '../../assets/icons/navIcons/favoriteFilled.svg';
import MailSent from '../../assets/icons/navIcons/mailSent.svg';
import NavChrome from '../../assets/icons/navIcons/navChrome.svg';
import NavChromeGrey from '../../assets/icons/navIcons/navChromeGrey.svg';
import OpenLink from '../../assets/icons/navIcons/openLink.svg';
import Sort from '../../assets/icons/navIcons/sort.svg';
import Copy from '../../assets/icons/navIcons/copy.svg';
import Info from '../../assets/icons/navIcons/info.svg';
import InfoOutlined from '../../assets/icons/navIcons/infoOutlined.svg';
import Burger from '../../assets/icons/navIcons/burger.svg';
import Drag from '../../assets/icons/navIcons/drag.svg';
import Loading from '../../assets/icons/navIcons/loading.svg';
import Link from '../../assets/icons/navIcons/link.svg';
import DashboardFilled from '../../assets/icons/navIcons/dashboardFilled.svg';
import DashboardUnfilled from '../../assets/icons/navIcons/dashboardUnfilled.svg';
import TerminologyFilled from '../../assets/icons/navIcons/terminologyFilled.svg';
import TerminologyUnfilled from '../../assets/icons/navIcons/terminologyUnfilled.svg';
import TerminologyUnfilledGrey from '../../assets/icons/navIcons/terminologyUnfilledGrey.svg';
import SuggestionsFilled from '../../assets/icons/navIcons/suggestionsFilled.svg';
import SuggestionsUnfilledGrey from '../../assets/icons/navIcons/suggestionsUnfilledGrey.svg';
import TeamFilled from '../../assets/icons/navIcons/teamFilled.svg';
import SettingsFilled from '../../assets/icons/navIcons/settingsFilled.svg';
import SettingsUnfilledGrey from '../../assets/icons/navIcons/settingsUnfilledGrey.svg';
import ProfileUnfilled from '../../assets/icons/navIcons/profileUnfilled.svg';
import ProfileUnfilledGrey from '../../assets/icons/navIcons/profileUnfilledGrey.svg';
import ProfileFilled from '../../assets/icons/navIcons/profileFilled.svg';
import ArrowExternalLink from '../../assets/icons/navIcons/arrowExternalLink.svg';
import WhatsNew from '../../assets/icons/navIcons/whatsNew.svg';
import Video from '../../assets/icons/navIcons/video.svg';
import Support from '../../assets/icons/navIcons/support.svg';
import Linkedin from '../../assets/icons/socialIcons/linkedIn.svg';
import Twitter from '../../assets/icons/socialIcons/twitter.svg';
import Facebook from '../../assets/icons/socialIcons/facebook.svg';
import Google from '../../assets/icons/socialIcons/google.svg';
import Grid from '../../assets/icons/otherIcons/grid.svg';
import Search from '../../assets/icons/otherIcons/search.svg';
import List from '../../assets/icons/otherIcons/list.svg';
import Lock from '../../assets/icons/otherIcons/lock.svg';
import LockLine from '../../assets/icons/otherIcons/lockLine.svg';
import UnlockLine from '../../assets/icons/otherIcons/unlockLine.svg';
import LockWide from '../../assets/icons/otherIcons/lockWide.svg';
import Ellipses from '../../assets/icons/otherIcons/ellipses.svg';
import Check from '../../assets/icons/otherIcons/check.svg';
import Close from '../../assets/icons/otherIcons/close.svg';
import Plus from '../../assets/icons/otherIcons/plus.svg';
import PlusWhite from '../../assets/icons/otherIcons/plusWhite.svg';
import Add from '../../assets/icons/otherIcons/add.svg';
import CopyAndAdd from '../../assets/icons/copyAndAdd.svg';
import Remove from '../../assets/icons/otherIcons/remove.svg';
import Premium from '../../assets/icons/otherIcons/premium.svg';
import Refresh from '../../assets/icons/otherIcons/refresh.svg';
import RefreshWhite from '../../assets/icons/refreshWhite.svg';
import Fullscreen from '../../assets/icons/otherIcons/fullscreen.svg';
import DropDownArrow from '../../assets/icons/otherIcons/dropDownArrow.svg';
import Globe from '../../assets/icons/otherIcons/globe.svg';
import Code from '../../assets/icons/otherIcons/code.svg';
import Eye from '../../assets/icons/otherIcons/eye.svg';
import ClosedEye from '../../assets/icons/otherIcons/shut.svg';
import LineChart from '../../assets/icons/otherIcons/lineChart.svg';
import BlueCheckmark from '../../assets/icons/otherIcons/blueCheckmark.svg';
import GreyCheckmark from '../../assets/icons/otherIcons/greyCheckmark.svg';
import BlueUncheckedmark from '../../assets/icons/otherIcons/blueUncheckedmark.svg';
import Green1Checkmark from '../../assets/icons/otherIcons/greenCheckmark1.svg';
import Green2Checkmark from '../../assets/icons/otherIcons/greenCheckmark2.svg';
import XMarkCircle from '../../assets/icons/otherIcons/xMarkCircle.svg';
import XMarkCircleOrange from '../../assets/icons/otherIcons/xMarkCircleOrange.svg';
import Adobe from '../../assets/icons/integrationsIcons/adobe.svg';
import ManualUpload from '../../assets/icons/integrationsIcons/manualUpload.svg';
import Zendesk from '../../assets/icons/integrationsIcons/zendesk.svg';
import Marketo from '../../assets/icons/integrationsIcons/marketo.svg';
import Sketch from '../../assets/icons/integrationsIcons/sketch.svg';
import Wordpress from '../../assets/icons/integrationsIcons/wordpress.svg';
import Api from '../../assets/icons/integrationsIcons/api.svg';
import Magento from '../../assets/icons/integrationsIcons/magento.svg';
import Drupal from '../../assets/icons/integrationsIcons/drupal.svg';
import Git from '../../assets/icons/integrationsIcons/git.svg';
import Js from '../../assets/icons/integrationsIcons/js.svg';
import Ide from '../../assets/icons/integrationsIcons/ide.svg';
import Eloqua from '../../assets/icons/integrationsIcons/eloqua.svg';
import CiCd from '../../assets/icons/integrationsIcons/cicd.svg';
import Bitbucket from '../../assets/icons/integrationsIcons/bitbucket.svg';
import Confluence from '../../assets/icons/integrationsIcons/confluence.svg';
import Dropbox from '../../assets/icons/integrationsIcons/dropbox.svg';
import Gdrive from '../../assets/icons/integrationsIcons/gdrive.svg';
import Github from '../../assets/icons/integrationsIcons/github.svg';
import Gmail from '../../assets/icons/integrationsIcons/gmail.svg';
import Hubspot from '../../assets/icons/integrationsIcons/hubspot.svg';
import Intercom from '../../assets/icons/integrationsIcons/intercom.svg';
import Notion from '../../assets/icons/integrationsIcons/notion.svg';
import Readme from '../../assets/icons/integrationsIcons/readme.svg';
import Salesforce from '../../assets/icons/integrationsIcons/salesforce.svg';
import Sharepoint from '../../assets/icons/integrationsIcons/sharepoint.svg';
import Slack from '../../assets/icons/integrationsIcons/slack.svg';
import ChromeWhite from '../../assets/icons/extensions/chromeWhite.svg';
import WriterForFigma from '../../assets/icons/extensions/writerForFigma.svg';
import WriterForGoogleDocs from '../../assets/icons/extensions/writerForGoogleDocs.svg';
import WriterForOutlook from '../../assets/icons/extensions/writerForOutlook.svg';
import WriterForWord from '../../assets/icons/extensions/writerForWord.svg';
import Alert from '../../assets/icons/integrationsIcons/alert.svg';
import AlertNoStroke from '../../assets/icons/integrationsIcons/alertNoStroke.svg';
import Filter from '../../assets/icons/termsIcons/filter.svg';
import ClipMode from '../../assets/icons/termsIcons/clipMode.svg';
import WrapMode from '../../assets/icons/termsIcons/wrapMode.svg';
import SpreadsheetExtended from '../../assets/icons/spreadsheetExtended.svg';
import SortAsc from '../../assets/icons/navIcons/arrowSortAsc.svg';
import SortDesc from '../../assets/icons/navIcons/arrowSortDesc.svg';
import ArrowRightAlt from '../../assets/icons/navIcons/arrowRightAlt.svg';
import ArrowUp from '../../assets/icons/navIcons/arrowUp.svg';
import ArrowDown from '../../assets/icons/navIcons/arrowDown.svg';
import ArrowDownAlt from '../../assets/icons/otherIcons/arrowDownAlt.svg';
import ArrowBreadcrumb from '../../assets/icons/navIcons/arrowBreadcrumb.svg';
import BackArrow from '../../assets/icons/navIcons/backArrow.svg';
import BackArrowRoundBlack from '../../assets/icons/navIcons/backArrowRoundBlack.svg';
import Apostrophe from '../../assets/icons/navIcons/apostrophe.svg';
import Ampersand from '../../assets/icons/navIcons/ampersand.svg';
import Contractions from '../../assets/icons/navIcons/contractions.svg';
import Comma from '../../assets/icons/navIcons/comma.svg';
import Dash from '../../assets/icons/navIcons/dash.svg';
import Exclamation from '../../assets/icons/navIcons/exclamation.svg';
import Parentheses from '../../assets/icons/navIcons/parentheses.svg';
import Percentages from '../../assets/icons/navIcons/percentages.svg';
import ThreeDots from '../../assets/icons/navIcons/threeDots.svg';
import Dot from '../../assets/icons/navIcons/dot.svg';
import QuotationMark from '../../assets/icons/navIcons/quotationMark.svg';
import ColonsAndSemi from '../../assets/icons/navIcons/colonsAndSemi.svg';
import Badge from '../../assets/icons/navIcons/badge.svg';
import Visa from '../../assets/icons/creditcard/visa.svg';
import MasterCard from '../../assets/icons/creditcard/masterCard.svg';
import AmericanExpress from '../../assets/icons/creditcard/americanExpress.svg';
import DinersClub from '../../assets/icons/creditcard/dinersClub.svg';
import Discover from '../../assets/icons/creditcard/discover.svg';
import JCB from '../../assets/icons/creditcard/jcb.svg';
import UnionPay from '../../assets/icons/creditcard/unionPay.svg';
import Unknown from '../../assets/icons/creditcard/unknown.svg';
import Lifebuoy from '../../assets/icons/navIcons/lifebuoy.svg';
import Building from '../../assets/icons/navIcons/building.svg';
import CopyPaste from '../../assets/icons/otherIcons/copypaste.svg';
import Templates from '../../assets/icons/navIcons/templates.svg';
import TemplatesFilled from '../../assets/icons/navIcons/templatesFilled.svg';
import TemplatesGrey from '../../assets/icons/navIcons/templatesGrey.svg';
import RightSlide from '../../assets/icons/navIcons/rightSlide.svg';
import RemoveBlack from '../../assets/icons/otherIcons/removeBlack.svg';
import Checkmark from '../../assets/icons/otherIcons/checkmark.svg';
import MoreHoriz from '../../assets/icons/otherIcons/more_horiz.svg';
import Expand from '../../assets/icons/otherIcons/expand.svg';
import AwesomeRed from '../../assets/icons/otherIcons/awesome_red.svg';
import Collapse from '../../assets/icons/otherIcons/collapse.svg';
import Esc from '../../assets/icons/escIcon.svg';
import Enter from '../../assets/icons/enterIcon.svg';
import LongArrowUp from '../../assets/icons/otherIcons/longArrowUp.svg';
import LongArrowDownLeft from '../../assets/icons/otherIcons/longArrowDownLeft.svg';
import StarLined from '../../assets/icons/starLined.svg';

import PalmyraX from '../../assets/icons/aiModelsIcons/palmyraX.svg';
import PalmyraX32K from '../../assets/icons/aiModelsIcons/palmyraX32k.svg';
import PalmyraMed from '../../assets/icons/aiModelsIcons/palmyraMed.svg';
import PalmyraInstruct from '../../assets/icons/aiModelsIcons/palmyraInstruct.svg';
import PalmyraVision from '../../assets/icons/aiModelsIcons/palmyraVision.svg';

import ConsoleTextGeneration from '../../assets/icons/console/text.svg';
import ConsoleChat from '../../assets/icons/console/chat.svg';
import ConsoleApi from '../../assets/icons/console/api.svg';
import ConsoleRewrite from '../../assets/icons/console/rewrite.svg';
import FrameworkApp from '../../assets/icons/console/frameworkApp.svg';
import FrameworkAppMono from '../../assets/icons/console/frameworkAppMono.svg';
import ApiApp from '../../assets/icons/console/apiApp.svg';
import ChatWithDB from '../../assets/icons/console/chatWithDb.svg';
import Upload from '../../assets/icons/upload.svg';

import PlaylistAdd from '../../assets/icons/otherIcons/playlist_add.svg';
import OutlinedFlag from '../../assets/icons/otherIcons/outlined_flag.svg';
import Lightning from '../../assets/icons/otherIcons/lightning.svg';
import NoIntegration from '../../assets/icons/noIntegration.svg';
import GoogleLogo from '../../assets/icons/google.svg';
import Feedback from '../../assets/icons/otherIcons/feedback.svg';
import ThumbsUp from '../../assets/icons/otherIcons/thumbsUp.svg';
import ThumbsUpFilled from '../../assets/icons/otherIcons/thumbsUpFilled.svg';
import ThumbsDown from '../../assets/icons/otherIcons/thumbsDown.svg';
import ThumbsDownFilled from '../../assets/icons/otherIcons/thumbsDownFilled.svg';
import TableColumns from '../../assets/icons/otherIcons/tableColumns.svg';
import Inputs from '../../assets/icons/navIcons/portalLine.svg';
import ColorBox from '../../assets/icons/navIcons/colorBox.svg';
import TeaserBox from '../../assets/icons/navIcons/teaserBox.svg';
import ColorBoxVariant1 from '../../assets/icons/navIcons/colorBoxVariant1.svg';
import AllSettings from '../../assets/icons/navIcons/allSettings.svg';
import ResizeTextarea from '../../assets/icons/navIcons/resizeTextarea.svg';
import CustomCategories from '../../assets/icons/otherIcons/customCategory.svg';
import CopyTwo from '../../assets/icons/navIcons/copyTwo.svg';
import Rewrite from '../../assets/icons/otherIcons/anotherWand.svg';
import Awesome from '../../assets/icons/otherIcons/awesome.svg';
import Resize from '../../assets/icons/otherIcons/resize.svg';
import EventTakeaways from '../../assets/icons/otherIcons/eventTakeaways.svg';
import Microphone from '../../assets/icons/otherIcons/microphone.svg';
import MediaFileVideo from '../../assets/icons/otherIcons/mediaFileVideo.svg';
import MediaFileAudio from '../../assets/icons/otherIcons/mediaFileAudio.svg';
import MediaFileDocument from '../../assets/icons/otherIcons/mediaFileDocument.svg';
import IncreasingEllipsis from '../../assets/icons/otherIcons/increasingEllipsis.svg';
import TwitterUnfilled from '../../assets/icons/twitterUnfilled.svg';
import WriterForContentful from '../../assets/icons/extensions/writerForContentful.svg';
import WriterForEdge from '../../assets/icons/extensions/writerForEdge.svg';
import RefreshThin from '../../assets/icons/refreshLine.svg';
import Warning from '../../assets/icons/warning.svg';
import WarningMark from '../../assets/icons/warningMark.svg';

import EditorFormatBold from '../../assets/icons/editor/boldIcon.svg';
import EditorFormatStrikethrough from '../../assets/icons/editor/strikethroughIcon.svg';
import EditorFormatItalic from '../../assets/icons/editor/italicIcon.svg';
import EditorFormatUnderline from '../../assets/icons/editor/underlineIcon.svg';
import EditorFormatLink from '../../assets/icons/editor/linkIcon.svg';
import EditorFormatTypography from '../../assets/icons/editor/typographyIcon.svg';
import EditorFormatListBullet from '../../assets/icons/editor/listBulletIcon.svg';
import EditorFormatListOrdered from '../../assets/icons/editor/listOrderIcon.svg';
import EditorFormatAlign from '../../assets/icons/editor/listAlignIcon.svg';
import EditorFormatAlignLeft from '../../assets/icons/editor/listAlignLeftIcon.svg';
import EditorFormatAlignCenter from '../../assets/icons/editor/listAlignCenterIcon.svg';
import EditorFormatAlignRight from '../../assets/icons/editor/listAlignRigthIcon.svg';
import EditorIndentDecrease from '../../assets/icons/editor/indentDecreaseIcon.svg';
import EditorIndentIncrease from '../../assets/icons/editor/indentIncreaseIcon.svg';
import EditorClear from '../../assets/icons/editor/clearIcon.svg';
import EditorCode from '../../assets/icons/editor/codeIcon.svg';
import EditorImage from '../../assets/icons/editor/imageIcon.svg';
import EditorTable from '../../assets/icons/editor/tableIcon.svg';
import EditorQuoteblockTable from '../../assets/icons/editor/quoteblockIcon.svg';
import Claim from '../../assets/icons/otherIcons/claimIcon.svg';
import ClaimWhite from '../../assets/icons/otherIcons/claimIconWhite.svg';
import Highlights from '../../assets/icons/otherIcons/highlightsIcon.svg';
import LightningUnion from '../../assets/icons/otherIcons/lightningUnion.svg';
import LightningUnionMauve from '../../assets/icons/otherIcons/lightningUnionMauve.svg';
import CursorSelection from '../../assets/icons/editor/cursorSelection.svg';
import MagicLinksSnackbar from '../../assets/icons/editor/magicLinkSnackbar.svg';
import BrokenLink from '../../assets/icons/otherIcons/brokenLink.svg';
import TextLine from '../../assets/icons/otherIcons/textLine.svg';
import Stars from '../../assets/icons/navIcons/starts.svg';
import RoundGreenChecks from '../../assets/icons/otherIcons/roundGreenChecks.svg';
import PinkBook from '../../assets/icons/otherIcons/pinkBook.svg';
import YellowMagicStick from '../../assets/icons/otherIcons/yellowMagicStick.svg';
import WandWithBorder from '../../assets/icons/navIcons/magicWandWithBorder.svg';
import ExpandAllScreen from '../../assets/icons/navIcons/expandAllScreen.svg';
import UseInNewDoc from '../../assets/icons/navIcons/useInNewDoc.svg';
import QuarterBurger from '../../assets/icons/navIcons/querterBurger.svg';
import GoToDoc from '../../assets/icons/navIcons/goToDoc.svg';
import ListScrollUp from '../../assets/icons/listScrollUp.svg';
import ListScrollDown from '../../assets/icons/listScrollDown.svg';
import Draft from '../../assets/icons/draft.svg';
import DraftExtended from '../../assets/icons/draftExtended.svg';
import DraftActive from '../../assets/icons/draftActive.svg';
import ImageAlignLeft from '../../assets/icons/imageAlignLeft.svg';
import ImageAlignMiddle from '../../assets/icons/imageAlignMiddle.svg';
import ImageAlignRight from '../../assets/icons/imageAlignRight.svg';
import Knowledge from '../../assets/icons/knowledge.svg';
import KnowledgeActive from '../../assets/icons/knowledgeActive.svg';

import CoWriteShortcut from '../../assets/icons/shortcuts/coWrite.svg';
import BlogShortcut from '../../assets/icons/shortcuts/blog.svg';
import AskWriterShortcut from '../../assets/icons/shortcuts/askWriter.svg';
import RecapsShortcut from '../../assets/icons/shortcuts/recaps.svg';
import ImageAnalyzerShortcut from '../../assets/icons/shortcuts/imageAnalyzer.svg';

import AskWriterOnboarding from '../../assets/icons/onboarding/askWriter.svg';
import BlogOnboarding from '../../assets/icons/onboarding/blog.svg';
import RecapsOnboarding from '../../assets/icons/onboarding/recaps.svg';
import HeadphonesWithMic from '../../assets/icons/headphonesWithMic.svg';
import Guide from '../../assets/icons/guide.svg';
import LightBulbUnfilled from '../../assets/icons/lightBulb.svg';
import LightBulbFilled from '../../assets/icons/lightbulbFilled.svg';
import KnowledgeGraph from '../../assets/icons/knowledgeGraph.svg';
import KnowledgeGraphFilled from '../../assets/icons/knowledgeGraphFilled.svg';

import WordmarkBlue from '../../assets/wordmark/wordmarkBlue.svg';
import WordmarkGreen from '../../assets/wordmark/wordmarkGreen.svg';
import WordmarkGrey from '../../assets/wordmark/wordmarkGrey.svg';
import WordmarkWhite from '../../assets/wordmark/wordmarkWhite.svg';
import WordmarkTransparent from '../../assets/wordmark/wordmarkTransparent.svg';
import CommandsArrowBreadcrumb from '../../assets/icons/commands/arrowBreadcrumb.svg';
import CommandShortcut from '../../assets/icons/commands/shortcut.svg';
import NineDots from '../../assets/icons/nineDots.svg';
import CrystalBall from '../../assets/icons/crystalBall.svg';

import GlobeMonoline from '../../assets/icons/globeMonoline.svg';
import Ball from '../../assets/icons/ball.svg';
import CommandKey from '../../assets/icons/commandKey.svg';
import ArrowDownInFilledCircle from '../../assets/icons/arrowDownInFilledCircle.svg';
import ArrowLeft from '../../assets/icons/arrowLeft.svg';
import ArrowDownWhite from '../../assets/icons/arrowDownWhite.svg';
import ArrowDownLong from '../../assets/icons/arrowDownLong.svg';

import Flag from '../../assets/icons/flag.svg';
import FlagFilled from '../../assets/icons/flagFilled.svg';
import CommandsCheck from '../../assets/icons/commands/check.svg';
import CommandsEllipses from '../../assets/icons/commands/ellipses.svg';
import CommandsKeepWriting from '../../assets/icons/commands/keepWriting.svg';
import CommandsLightbulb from '../../assets/icons/commands/lightbulb.svg';
import CommandsPencil from '../../assets/icons/commands/pencil.svg';
import CommandsRegenerate from '../../assets/icons/commands/regenerate.svg';
import CommandsStar from '../../assets/icons/commands/star.svg';
import CommandsWand from '../../assets/icons/commands/wand.svg';
import CommandsCopy from '../../assets/icons/commands/copy.svg';
import CommandsTick from '../../assets/icons/commands/tick.svg';
import CommandsVoiceTune from '../../assets/icons/commands/tune.svg';
import WriterForMac from '../../assets/icons/extensions/writerForMac.svg';

import AdsContentType from '../../assets/icons/contentType/ads.svg';
import AdsContentTypeBold from '../../assets/icons/contentType/adsBold.svg';
import BlogContentType from '../../assets/icons/contentType/blog.svg';
import BlogContentTypeBold from '../../assets/icons/contentType/blogBold.svg';
import CaseStudyContentType from '../../assets/icons/contentType/caseStudy.svg';
import DefaultLongformContentType from '../../assets/icons/contentType/defaultLongform.svg';
import DefaultShortformContentType from '../../assets/icons/contentType/defaultShortform.svg';
import EmailContentType from '../../assets/icons/contentType/email.svg';
import EmailContentTypeBold from '../../assets/icons/contentType/emailBold.svg';
import ErrorMessageContentType from '../../assets/icons/contentType/errorMessage.svg';
import FaqContentType from '../../assets/icons/contentType/faq.svg';
import ListContentType from '../../assets/icons/contentType/list.svg';
import MessageBubbleContentType from '../../assets/icons/contentType/messageBubble.svg';
import SocialContentType from '../../assets/icons/contentType/social.svg';
import SummaryContentType from '../../assets/icons/contentType/summary.svg';
import SupportArticleContentType from '../../assets/icons/contentType/supportArticle.svg';
import ImageContentType from '../../assets/icons/contentType/image.svg';
import AbstractDollarSign from '../../assets/icons/categories/abstractDollarSign.svg';
import CrossInCircle from '../../assets/icons/categories/crossInCircle.svg';
import DocWithFiveLines from '../../assets/icons/categories/docWithFiveLines.svg';
import DocWithTwoLines from '../../assets/icons/categories/docWithTwoLines.svg';
import Ecommerce from '../../assets/icons/categories/eCommerce.svg';
import Tech from '../../assets/icons/categories/tech.svg';
import Summary from '../../assets/icons/categories/summary.svg';
import MessageBubble from '../../assets/icons/messageBubble.svg';
import MessageBubbleAlt from '../../assets/icons/messageBubbleAlt.svg';
import DoubleArrow from '../../assets/icons/doubleArrow.svg';
import Pencil from '../../assets/icons/pencil.svg';
import Clip from '../../assets/icons/clip.svg';
import Transform from '../../assets/icons/transform.svg';

import ConsoleAppSocial from '../../assets/icons/console/appTypeIcons/social.svg';
import ConsoleAppMessaging from '../../assets/icons/console/appTypeIcons/messaging.svg';
import ConsoleAppAds from '../../assets/icons/console/appTypeIcons/ads.svg';
import ConsoleAppBlogs from '../../assets/icons/console/appTypeIcons/blogs.svg';
import ConsoleCaseStudies from '../../assets/icons/console/appTypeIcons/caseStudies.svg';
import ConsoleAppLists from '../../assets/icons/console/appTypeIcons/lists.svg';
import ConsoleAppErrors from '../../assets/icons/console/appTypeIcons/errors.svg';
import ConsoleAppFaq from '../../assets/icons/console/appTypeIcons/faqs.svg';
import ConsoleAppSummaries from '../../assets/icons/console/appTypeIcons/summaries.svg';
import ConsoleAppSupportArticles from '../../assets/icons/console/appTypeIcons/supportArticles.svg';
import ConsoleAppEmails from '../../assets/icons/console/appTypeIcons/emails.svg';
import ConsoleAppShortForm from '../../assets/icons/console/appTypeIcons/shortform.svg';
import ConsoleAppLongForm from '../../assets/icons/console/appTypeIcons/longform.svg';
import ConsoleAppImage from '../../assets/icons/console/appTypeIcons/image.svg';

import Transcript from '../../assets/icons/otherIcons/transcript.svg';
import MacOsBlack from '../../assets/icons/extensions/macosBlack.svg';

import Hi from '../../assets/icons/hi.svg';
import WTag from '../../assets/icons/wTag.svg';
import ArrowRightLogOut from '../../assets/icons/navIcons/arrowRightLogOut.svg';

import ContentfulLogo from '../../assets/icons/contentfulLogo.svg';

import FolderTransparent from '../../assets/icons/navIcons/folderRounded.svg';
import Documents from '../../assets/icons/navIcons/documents.svg';
import DocumentsFilled from '../../assets/icons/navIcons/documentsFilled.svg';
import Setup from '../../assets/icons/otherIcons/setup.svg';
import SetupFilled from '../../assets/icons/otherIcons/setupFilled.svg';
import CrystalBallFilled from '../../assets/icons/crystalBallFilled.svg';
import AllSettingsFilled from '../../assets/icons/navIcons/allSettingsFilled.svg';

import VoiceSetup from '../../assets/icons/voice/setup.svg';
import VoiceStar from '../../assets/icons/voice/star.svg';
import VoiceTune from '../../assets/icons/voice/tune.svg';
import PersonAdd from '../../assets/icons/personAdd.svg';
import PersonAdmin from '../../assets/icons/personAdmin.svg';
import PersonSimple from '../../assets/icons/personSimple.svg';
import PersonUpdate from '../../assets/icons/personUpdate.svg';
import PersonRemove from '../../assets/icons/personRemove.svg';
import Minus from '../../assets/icons/otherIcons/minus.svg';
import MinusCircle from '../../assets/icons/otherIcons/minusCircle.svg';
import PaperСlip from '../../assets/icons/otherIcons/paperСlip.svg';
import DoubleArrowFilled from '../../assets/icons/otherIcons/doubleArrow.svg';
import Web from '../../assets/icons/navIcons/web.svg';
import InfoLetter from '../../assets/icons/otherIcons/infoLetter.svg';
import Guardrails from '../../assets/icons/guardrails.svg';
import GuardrailsUnfilled from '../../assets/icons/guardrailsUnfilled.svg';
import Playground from '../../assets/icons/playground.svg';
import PlaygroundFilled from '../../assets/icons/playgroundFilled.svg';
import Bin from '../../assets/icons/bin.svg';
import PlusCircled from '../../assets/icons/plusCircled.svg';
import Resync from '../../assets/icons/resync.svg';
import CrystalBallTransparent from '../../assets/icons/otherIcons/crystalBallTransperent.svg';
import GlobeTransparent from '../../assets/icons/otherIcons/globeTransperant.svg';

import MicrophoneSimple from '../../assets/icons/microphoneSimple.svg';
import WarningRound from '../../assets/icons/warningRound.svg';
import TuneSettings from '../../assets/icons/tuneSettings.svg';

import SandboxUnFilled from '../../assets/icons/sandboxUnfilled.svg';
import SandboxFilled from '../../assets/icons/sandboxFilled.svg';
import BookFilled from '../../assets/icons/bookFilled.svg';
import BookUnFilled from '../../assets/icons/bookUnfilled.svg';
import ArrowExternalSquare from '../../assets/icons/arrowExternalSquare.svg';
import ApiSettingsFilled from '../../assets/icons/apiSettingsFilled.svg';
import ApiSettingsUnfilled from '../../assets/icons/apiSettingsUnfilled.svg';
import EmbedCode from '../../assets/icons/embedCode.svg';
import History from '../../assets/icons/history.svg';
import Steps from '../../assets/icons/steps.svg';
import Academy from '../../assets/icons/academy.svg';

export enum IconVariant {
  ADOBE = 'ADOBE',
  MANUAL_UPLOAD = 'MANUAL_UPLOAD',
  ZENDESK = 'ZENDESK',
  MARKETO = 'MARKETO',
  WORDPRESS = 'WORDPRESS',
  SKETCH = 'SKETCH',
  API = 'API',
  MAGENTO = 'MAGENTO',
  DRUPAL = 'DRUPAL',
  GIT = 'GIT',
  JS = 'JS',
  IDE = 'IDE',
  ELOQUA = 'ELOQUA',
  CICD = 'CICD',
  BITBUCKET = 'BITBUCKET',
  CONFLUENCE = 'CONFLUENCE',
  DROPBOX = 'DROPBOX',
  GDRIVE = 'GDRIVE',
  GITHUB = 'GITHUB',
  GMAIL = 'GMAIL',
  HUBSPOT = 'HUBSPOT',
  INTERCOM = 'INTERCOM',
  NOTION = 'NOTION',
  README = 'README',
  SALESFORCE = 'SALESFORCE',
  SHAREPOINT = 'SHAREPOINT',
  SLACK = 'SLACK',
  EDITOR = 'EDITOR',
  STYLEGUIDE = 'STYLEGUIDE',
  SNIPPETS_UNFILLED = 'SNIPPETS_UNFILLED',
  SNIPPETS_UNFILLED_GREY = 'SNIPPETS_UNFILLED_GREY',
  SNIPPETS_FILLED = 'SNIPPETS_FILLED',
  TEAM = 'TEAM',
  TEAM_UNFILLED_GREY = 'TEAM_UNFILLED_GREY',
  SETTINGS = 'SETTINGS',
  DOCUMENT = 'DOCUMENT',
  DOCUMENT_EXTENDED = 'DOCUMENT_EXTENDED',
  DOCS = 'DOCS',
  NEW_DOC = 'NEW_DOC',
  PORTAL = 'PORTAL',
  SUGGESTIONS = 'SUGGESTIONS',
  REPORTS_UNFILLED = 'REPORTS_UNFILLED',
  REPORTS_FILLED = 'REPORTS_FILLED',
  TERMS = 'TERMS',
  TRASH = 'TRASH',
  TRASH_WHITE = 'TRASH_WHITE',
  DOWNLOAD = 'DOWNLOAD',
  FAVORITE_UNFILLED = 'FAVORITE_UNFILLED',
  FAVORITE_FILLED = 'FAVORITE_FILLED',
  MAIL_SENT = 'MAIL_SENT',
  NAV_CHROME = 'NAV_CHROME',
  NAV_CHROME_GREY = 'NAV_CHROME_GREY',
  OPEN_LINK = 'OPEN_LINK',
  SORT = 'SORT',
  COPY = 'COPY',
  INFO = 'INFO',
  INFO_OUTLINED = 'INFO_OUTLINED',
  BURGER = 'BURGER',
  DRAG = 'DRAG',
  SEARCH = 'SEARCH',
  LOADING = 'LOADING',
  LINK = 'LINK',
  LINKEDIN = 'LINKEDIN',
  TWITTER = 'TWITTER',
  TWITTER_UNFILLED = 'TWITTER_UNFILLED',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  GRID = 'GRID',
  LIST = 'LIST',
  DASHBOARD_FILLED = 'DASHBOARD_FILLED',
  DASHBOARD_UNFILLED = 'DASHBOARD_UNFILLED',
  TERMINOLOGY_FILLED = 'TERMINOLOGY_FILLED',
  TERMINOLOGY_UNFILLED = 'TERMINOLOGY_UNFILLED',
  TERMINOLOGY_UNFILLED_GREY = 'TERMINOLOGY_UNFILLED_GREY',
  SUGGESTIONS_FILLED = 'SUGGESTIONS_FILLED',
  SUGGESTIONS_UNFILLED_GREY = 'SUGGESTIONS_UNFILLED_GREY',
  TEAM_FILLED = 'TEAM_FILLED',
  SETTINGS_FILLED = 'SETTINGS_FILLED',
  SETTINGS_UNFILLED_GREY = 'SETTINGS_UNFILLED_GREY',
  PROFILE_UNFILLED = 'PROFILE_UNFILLED',
  PROFILE_UNFILLED_GREY = 'PROFILE_UNFILLED_GREY',
  PROFILE_FILLED = 'PROFILE_FILLED',
  ARROW_EXTERNAL_LINK = 'ARROW_EXTERNAL_LINK',
  WHATS_NEW = 'WHATS_NEW',
  VIDEO = 'VIDEO',
  SUPPORT = 'SUPPORT',
  LOCK = 'LOCK',
  LOCK_LINE = 'LOCK_LINE',
  UNLOCK_LINE = 'UNLOCK_LINE',
  LOCK_WIDE = 'LOCK_WIDE',
  ELLIPSES = 'ELLIPSES',
  CHECK = 'CHECK',
  CLOSE = 'CLOSE',
  CLOSE_WHITE = 'CLOSE_WHITE',
  PLUS = 'PLUS',
  PLUS_WHITE = 'PLUS_WHITE',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  PREMIUM = 'PREMIUM',
  REFRESH = 'REFRESH',
  REFRESH_WHITE = 'REFRESH_WHITE',
  FULLSCREEN = 'FULLSCREEN',
  DROP_DOWN_ARROW = 'DROP_DOWN_ARROW',
  BREADCRUMB_ARROW = 'BREADCRUMB_ARROW',
  BACK_ARROW = 'BACK_ARROW',
  BACK_ARROW_ROUND_BLACK = 'BACK_ARROW_ROUND_BLACK',
  GLOBE = 'GLOBE',
  GLOBE_MONOLINE = 'globeMonoline',
  CODE = 'CODE',
  EYE = 'EYE',
  CLOSED_EYE = 'CLOSED_EYE',
  WAND = 'WAND',
  WAND_WHITE = 'WAND_WHITE',
  WAND_WITH_BORDER = 'WAND_WITH_BORDER',
  LINE_CHART = 'LINE_CHART',
  BLUE_CHECKMARK = 'BLUE_CHECKMARK',
  GREY_CHECKMARK = 'GREY_CHECKMARK',
  BLUE_UNCHECKEDMARK = 'BLUE_UNCHECKEDMARK',
  GREEN1_CHECKMARK = 'GREEN1_CHECKMARK',
  GREEN2_CHECKMARK = 'GREEN2_CHECKMARK',
  X_MARK_CIRCLE = 'X_MARK_CIRCLE',
  X_MARK_CIRCLE_ORANGE = 'X_MARK_CIRCLE_ORANGE',
  CHROME_WHITE = 'CHROME_WHITE',
  MAC_OS_BLACK = 'APPLE_BLACK',
  WRITER_FOR_GOOGLEDOCS = 'WRITER_FOR_GOOGLEDOCS',
  WRITER_FOR_OUTLOOK = 'WRITER_FOR_OUTLOOK',
  WRITER_FOR_FIGMA = 'WRITER_FOR_FIGMA',
  WRITER_FOR_WORD = 'WRITER_FOR_WORD',
  WRITER_FOR_CONTENTFUL = 'WRITER_FOR_CONTENTFUL',
  WRITER_FOR_EDGE = 'WRITER_FOR_EDGE',
  ALERT = 'ALERT',
  ALERT_NO_STROKE = 'ALERT_NO_STROKE',
  FILTER = 'FILTER',
  CLIP_MODE = 'CLIP_MODE',
  WRAP_MODE = 'WRAP_MODE',
  SPREADSHEET = 'SPREADSHEET',
  SPREADSHEET_EXTENDED = 'SPREADSHEET_EXTENDED',
  SORT_ASC = 'SORT_ASC',
  SORT_DESC = 'SORT_DESC',
  ARROW_RIGHT_ALT = 'ARROW_RIGHT_ALT',
  ARROW_UP = 'ARROW_UP',
  ARROW_DOWN = 'ARROW_DOWN',
  ARROW_DOWN_ALT = 'ARROW_DOWN_ALT',
  APOSTROPHE = 'APOSTROPHE',
  AMPERSAND = 'AMPERSAND',
  COLONS_AND_SEMI = 'COLONS_AND_SEMI',
  CONTRACTIONS = 'CONTRACTIONS',
  COMMA = 'COMMA',
  DASH = 'DASH',
  EXCLAMATION = 'EXCLAMATION',
  PARENTHESES = 'PARENTHESES',
  PERCENTAGES = 'PERCENTAGES',
  THREEDOTS = 'THREEDOTS',
  QUOTATIONMARK = 'QUOTATIONMARK',
  DOT = 'DOT',
  BADGE = 'BADGE',
  CREDIT_CARD_VISA = 'CREDIT_CARD_VISA',
  CREDIT_CARD_AMERICAN_EXPRESS = 'CREDIT_CARD_AMERICAN_EXPRESS',
  CREDIT_CARD_DINERS_CLUB = 'CREDIT_CARD_DINERS_CLUB',
  CREDIT_CARD_DISCOVER = 'CREDIT_CARD_DISCOVER',
  CREDIT_CARD_JCB = 'CREDIT_CARD_JCB',
  CREDIT_CARD_MASTERCARD = 'CREDIT_CARD_MASTERCARD',
  CREDIT_CARD_UNIONPAY = 'CREDIT_CARD_UNIONPAY',
  CREDIT_CARD_UNKNOWN = 'CREDIT_CARD_UNKNOWN',
  LIFEBUOY = 'LIFEBUOY',
  BUILDING = 'BUILDING',
  RIGHT_SLIDE = 'RIGHT_SLIDE',
  COPY_PASTE = 'copyPaste',
  COPY_AND_ADD = 'copyAndAdd',
  TEMPLATES = 'TEMPLATES',
  TEMPLATES_FILLED = 'TEMPLATES_FILLED',
  TEMPLATES_GREY = 'TEMPLATES_GREY',
  REMOVE_BLACK = 'REMOVE_BLACK',
  CHECKMARK = 'CHECKMARK',
  MORE_HORIZ = 'MOREHORIZ',
  EXPAND = 'EXPAND',
  COLLAPSE = 'COLLAPSE',
  PLAYLIST_ADD = 'PLAYLISTADD',
  OUTLINED_FLAG = 'OUTLINEDFLAG',
  NO_INTEGRATION = 'NO_INTEGRATION',
  LIGHTNING = 'LIGHTNING',
  GOOGLE_LOGO = 'GOOGLE_LOGO',
  FEEDBACK = 'FEEDBACK',
  THUMBS_UP = 'THUMBS_UP',
  THUMBS_UP_FILLED = 'THUMBS_UP_FILLED',
  THUMBS_DOWN = 'THUMBS_DOWN',
  THUMBS_DOWN_FILLED = 'THUMBS_DOWN_FILLED',
  TABLE_COLUMNS = 'TABLE_COLUMNS',
  INPUTS = 'INPUTS',
  COLOR_BOX = 'COLOR_BOX',
  TEASER_BOX = 'TEASER_BOX',
  COLOR_BOX_VARIANT_1 = 'COLOR_BOX_VARIANT_1',
  ALL_SETTINGS = 'ALL_SETTINGS',
  RESIZE_TEXTAREA = 'RESIZE_TEXTAREA',
  CUSTOM_CATEGORIES = 'CUSTOM_CATEGORIES',
  COPY_ANOTHER = 'COPY_ANOTHER',
  REWRITE = 'REWRITE',
  AWESOME = 'AWESOME',
  RESIZE = 'RESIZE',
  FOLDER = 'FOLDER',
  IMAGE_ALIGN_LEFT = 'IMAGE_ALIGN_LEFT',
  IMAGE_ALIGN_MIDDLE = 'IMAGE_ALIGN_MIDDLE',
  IMAGE_ALIGN_RIGHT = 'IMAGE_ALIGN_RIGHT',
  EDITOR_FORMAT_BOLD = 'EDITOR_FORMAT_BOLD',
  EDITOR_FORMAT_ITALIC = 'EDITOR_FORMAT_ITALIC',
  EDITOR_FORMAT_UNDERLINE = 'EDITOR_FORMAT_UNDERLINE',
  EDITOR_FORMAT_LINK = 'EDITOR_FORMAT_LINK',
  EDITOR_FORMAT_TYPOGRAPHY = 'EDITOR_FORMAT_TYPOGRAPHY',
  EDITOR_FORMAT_LIST_BULLET = 'EDITOR_FORMAT_LIST_BULLET',
  EDITOR_FORMAT_LIST_ORDERED = 'EDITOR_FORMAT_LIST_ORDERED',
  EDITOR_FORMAT_ALIGN = 'EDITOR_FORMAT_ALIGN',
  EDITOR_FORMAT_ALIGN_LEFT = 'EDITOR_FORMAT_ALIGN_LEFT',
  EDITOR_FORMAT_ALIGN_RIGHT = 'EDITOR_FORMAT_ALIGN_RIGHT',
  EDITOR_FORMAT_ALIGN_CENTER = 'EDITOR_FORMAT_ALIGN_CENTER',
  EDITOR_FORMAT_INDENT_DECREASE = 'EDITOR_FORMAT_INDENT_DECREASE',
  EDITOR_FORMAT_INDENT_INCREASE = 'EDITOR_FORMAT_INDENT_INCREASE',
  EDITOR_FORMAT_CLEAR = 'EDITOR_FORMAT_CLEAR',
  EDITOR_FORMAT_STRIKETHROUGH = 'EDITOR_FORMAT_STRIKETHROUGH',
  EDITOR_FORMAT_CODE = 'EDITOR_FORMAT_CODE',
  EDITOR_FORMAT_IMAGE = 'EDITOR_FORMAT_IMAGE',
  EDITOR_FORMAT_TABLE = 'EDITOR_FORMAT_TABLE',
  EDITOR_FORMAT_QUOTEBLOCK = 'EDITOR_FORMAT_QUOTEBLOCK',
  HIGHLIGHTS = 'HIGHLIGHTS',
  CLAIM = 'CLAIM',
  CLAIM_WHITE = 'CLAIM_WHITE',
  AWESOME_RED = 'AWESOME_RED',

  LIGHTNING_UNION = 'LIGHTNING_UNION',
  LIGHTNING_UNION_MAUVE = 'LIGHTNING_UNION_MAUVE',
  BROKEN_LINK = 'BROKEN_LINK',
  TEXT_LINE = 'TEXT_LINE',
  REFRESH_THIN = 'REFRESH_THIN',
  WARNING = 'WARNING',
  WARNING_MARK = 'WARNING_MARK',
  CURSOR_SELECTION = 'CURSOR_SELECTION',
  MAGIC_LINK_SNACKBAR = 'MAGIC_LINK_SNACKBAR',
  STARS = 'STARS',
  ROUND_GREEN_CHECKS = 'ROUND_GREEN_CHECKS',
  YELLOW_MAGIC_STICK = 'YELLOW_MAGIC_STICK',
  PING_BOOK = 'PING_BOOK',
  EXPAND_ALL_SCREEN = 'EXPAND_ALL_SCREEN',
  USE_IN_NEW_DOC = 'USE_IN_NEW_DOC',
  QUARTER_BURGER = 'QUARTER_BURGER',
  GO_TO_DOC = 'GO_TO_DOC',
  EVENT_TAKEAWAYS = 'EVENT_TAKEAWAYS',
  MICROPHONE = 'MICROPHONE',
  MICROPHONE_SIMPLE = 'MICROPHONE_SIMPLE',
  MEDIA_FILE_VIDEO = 'MEDIA_FILE_VIDEO',
  MEDIA_FILE_AUDIO = 'MEDIA_FILE_AUDIO',
  MEDIA_FILE_DOCUMENT = 'MEDIA_FILE_DOCUMENT',
  INCREASING_ELLIPSIS = 'INCREASING_ELLIPSIS',
  DRAFT = 'DRAFT',
  DRAFT_EXTENDED = 'DRAFT_EXTENDED',
  LIST_SCROLL_UP = 'LIST_SCROLL_UP',
  LIST_SCROLL_DOWN = 'LIST_SCROLL_DOWN',
  DRAFT_ACTIVE = 'DRAFT_ACTIVE',
  ENTER = 'ENTER',
  ESC = 'ESC',

  COWRITE_SHORTCUT = 'COWRITE_SHORTCUT',
  BLOG_SHORTCUT = 'BLOG_SHORTCUT',
  ASKWRITER_SHORTCUT = 'ASKWRITER_SHORTCUT',
  RECAPS_SHORTCUT = 'RECAPS_SHORTCUT',
  IMAGE_ANALYZER_SHORTCUT = 'IMAGE_ANALYZER_SHORTCUT',

  BLOG_ONBOARDING = 'BLOG_ONBOARDING',
  ASKWRITER_ONBOARDING = 'ASKWRITER_ONBOARDING',
  RECAPS_ONBOARDING = 'RECAPS_ONBOARDING',
  HEADPHONES_WITH_MIC = 'HEADPHONES_WITH_MIC',
  GUIDE = 'GUIDE',
  LIGHT_BULB = 'LIGHT_BULB',
  LIGHT_BULB_FILLED = 'LIGHT_BULB_FILLED',
  KNOWLEDGE_GRAPH = 'KNOWLEDGE_GRAPH',
  KNOWLEDGE_GRAPH_FILLED = 'KNOWLEDGE_GRAPH_FILLED',
  COMMANDS_ARROW_BREADCRUMB = 'COMMANDS_ARROW_BREADCRUMB',

  WORDMARK_BLUE = 'WORDMARK_BLUE',
  WORDMARK_WHITE = 'WORDMARK_WHITE',
  WORDMARK_GREY = 'WORDMARK_GREY',
  WORDMARK_GREEN = 'WORDMARK_GREEN',
  WORDMARK_TRANSPARENT = 'WORDMARK_TRANSPARENT',
  // TODO: deprecate these icons as they are duplicative of LIGHT_BULB & LIGHT_BULB_FILLED
  // and no longer used for KG
  KNOWLEDGE = 'KNOWLEDGE',
  KNOWLEDGE_ACTIVE = 'KNOWLEDGE_ACTIVE',

  COMMANDS_SHORTCUT = 'COMMANDS_SHORTCUT',
  NINE_DOTS = 'NINE_DOTS',
  CRYSTAL_BALL = 'CRYSTAL_BALL',
  BALL = 'BALL',
  COMMAND_KEY = 'COMMAND_KEY',
  ARROW_DOWN_IN_FILLED_CIRCLE = 'ARROW_DOWN_IN_FILLED_CIRCLE',
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_DOWN_WHITE = 'ARROW_DOWN_WHITE',
  LONG_ARROW_DOWN = 'LONG_ARROW_DOWN',

  FLAG = 'FLAG',
  FLAG_FILLED = 'FLAG_FILLED',
  COMMANDS_CHECK = 'COMMANDS_CHECK',
  COMMANDS_ELLIPSES = 'COMMANDS_ELLIPSES',
  COMMANDS_KEEP_WRITING = 'COMMANDS_KEEP_WRITING',
  COMMANDS_LIGHTBULB = 'COMMANDS_LIGHTBULB',
  COMMANDS_PENCIL = 'COMMANDS_PENCIL',
  COMMANDS_REGENERATE = 'COMMANDS_REGENERATE',
  COMMANDS_STAR = 'COMMANDS_STAR',
  COMMANDS_WAND = 'COMMANDS_WAND',
  COMMANDS_COPY = 'COMMANDS_COPY',
  COMMANDS_TICK = 'COMMANDS_TICK',
  COMMANDS_VOICE_TUNE = 'COMMANDS_VOICE_TUNE',
  WRITER_FOR_MAC = 'WRITER_FOR_MAC',
  ADS_CONTENT_TYPE = 'ADS_CONTENT_TYPE',
  ADS_CONTENT_TYPE_BOLD = 'ADS_CONTENT_TYPE_BOLD',
  BLOG_CONTENT_TYPE = 'BLOG_CONTENT_TYPE',
  BLOG_CONTENT_TYPE_BOLD = 'BLOG_CONTENT_TYPE_BOLD',
  CASE_STUDY_CONTENT_TYPE = 'CASE_STUDY_CONTENT_TYPE',
  DEFAULT_LONGFORM_CONTENT_TYPE = 'DEFAULT_LONGFORM_CONTENT_TYPE',
  DEFAULT_SHORTFORM_CONTENT_TYPE = 'DEFAULT_SHORTFORM_CONTENT_TYPE',
  EMAIL_CONTENT_TYPE = 'EMAIL_CONTENT_TYPE',
  EMAIL_CONTENT_TYPE_BOLD = 'EMAIL_CONTENT_TYPE_BOLD',
  ERROR_MESSAGE_CONTENT_TYPE = 'ERROR_MESSAGE_CONTENT_TYPE',
  FAQ_CONTENT_TYPE = 'FAQ_CONTENT_TYPE',
  LIST_CONTENT_TYPE = 'LIST_CONTENT_TYPE',
  MESSAGE_BUBBLE_CONTENT_TYPE = 'MESSAGE_BUBBLE_CONTENT_TYPE',
  SOCIAL_CONTENT_TYPE = 'SOCIAL_CONTENT_TYPE',
  SUMMARY_CONTENT_TYPE = 'SUMMARY_CONTENT_TYPE',
  SUPPORT_ARTICLE_CONTENT_TYPE = 'SUPPORT_ARTICLE_CONTENT_TYPE',
  IMAGE_CONTENT_TYPE = 'IMAGE_CONTENT_TYPE',
  ABSTRACT_DOLLAR_SIGN = 'ABSTRACT_DOLLAR_SIGN',
  CROSS_IN_CIRCLE = 'CROSS_IN_CIRCLE',
  DOC_WITH_FIVE_LINES = 'DOC_WITH_FIVE_LINES',
  DOC_WITH_TWO_LINES = 'DOC_WITH_TWO_LINES',
  ECOMMERCE = 'ECOMMERCE',
  TECH = 'TECH',
  SUMMARY = 'SUMMARY',
  MESSAGE_BUBBLE = 'MESSAGE_BUBBLE',
  MESSAGE_BUBBLE_ALT = 'MESSAGE_BUBBLE_ALT',
  LONG_ARROW_UP = 'LONG_ARROW_UP',
  LONG_ARROW_DOWN_LEFT = 'LONG_ARROW_DOWN_LEFT',
  DOUBLE_ARROW = 'DOUBLE_ARROW',
  PENCIL = 'PENCIL',
  CLIP = 'CLIP',
  TRANSFORM = 'TRANSFORM',
  START_LINED = 'START_LINED',

  // AI Models Icons
  PALMYRA_X = 'PALMYRA_X',
  PALMYRA_X_32K = 'PALMYRA_X_32K',
  PALMYRA_MED = 'PALMYRA_MED',
  PALMYRA_INSTRUCT = 'PALMYRA_INSTRUCT',
  PALMYRA_VISION = 'PALMYRA_VISION',

  // Console Apps Icons
  CONSOLE_TEXT_GENERATION = 'CONSOLE_TEXT_GENERATION',
  CONSOLE_REWRITE = 'CONSOLE_REWRITE',
  CONSOLE_CHAT = 'CONSOLE_CHAT',
  CONSOLE_API = 'CONSOLE_API',
  FRAMEWORK_APP = 'FRAMEWORK_APP',
  FRAMEWORK_APP_MONO = 'FRAMEWORK_APP_MONO',
  API_APP = 'API_APP',
  CHAT_WITH_DB = 'CHAT_WITH_DB',

  // Console Apps Types
  CONSOLE_APP_SOCIAL = 'CONSOLE_APP_SOCIAL',
  CONSOLE_APP_MESSAGING = 'CONSOLE_APP_MESSAGING',
  CONSOLE_APP_ADS = 'CONSOLE_APP_ADS',
  CONSOLE_APP_BLOGS = 'CONSOLE_APP_BLOGS',
  CONSOLE_APP_CASE_STUDIES = 'CONSOLE_APP_CASE_STUDIES',
  CONSOLE_APP_LISTS = 'CONSOLE_APP_LISTS',
  CONSOLE_APP_ERRORS = 'CONSOLE_APP_ERRORS',
  CONSOLE_APP_FAQ = 'CONSOLE_APP_FAQ',
  CONSOLE_APP_SUMMARIES = 'CONSOLE_APP_SUMMARIES',
  CONSOLE_APP_SUPPORT_ARTICLES = 'CONSOLE_APP_SUPPORT_ARTICLES',
  CONSOLE_APP_EMAILS = 'CONSOLE_APP_EMAILS',
  CONSOLE_APP_SHORT_FORM = 'CONSOLE_APP_SHORT_FORM',
  CONSOLE_APP_LONG_FORM = 'CONSOLE_APP_LONG_FORM',
  CONSOLE_APP_IMAGE = 'CONSOLE_APP_IMAGE',
  UPLOAD = 'UPLOAD',

  TRANSCRIPT = 'TRANSCRIPT',

  HI = 'HI',
  W_TAG = 'W_TAG',
  ARROW_RIGHT_LOG_OUT = 'ARROW_RIGHT_LOG_OUT',

  // Flags
  FLAG_AU = 'FLAG_AU',
  FLAG_GB = 'FLAG_GB',
  FLAG_CA = 'FLAG_CA',
  FLAG_US = 'FLAG_US',

  CONTENTFUL_LOGO = 'CONTENTFUL_LOGO',

  FOLDER_ROUNDED = 'FOLDER_ROUNDED',
  DOCUMENTS = 'DOCUMENTS',
  DOCUMENTS_FILLED = 'DOCUMENTS_FILLED',
  SETUP = 'SETUP',
  SETUP_FILLED = 'SETUP_FILLED',
  CRYSTAL_BALL_FILLED = 'CRYSTAL_BALL_FILLED',
  ALL_SETTINGS_FILLED = 'ALL_SETTINGS_FILLED',
  TUNE_SETTINGS = 'TUNE_SETTINGS',

  // Voice & Tone
  VOICE_SETUP = 'VOICE_SETUP',
  VOICE_STAR = 'VOICE_STAR',
  VOICE_TUNE = 'VOICE_TUNE',

  PERSON_ADD = 'PERSON_ADD',
  PERSON_REMOVE = 'PERSON_REMOVE',
  PERSON_ADMIN = 'PERSON_ADMIN',
  PERSON_SIMPLE = 'PERSON_SIMPLE',
  PERSON_UPDATE = 'PERSON_UPDATE',

  GUARDRAILS_FILLED = 'GUARDRAILS_FILLED',
  GUARDRAILS_UNFILLED = 'GUARDRAILS_UNFILLED',

  PLAYGROUND_UNFILLED = 'PLAYGROUND_UNFILLED',
  PLAYGROUND_FILLED = 'PLAYGROUND_FILLED',

  BIN = 'BIN',
  PLUS_CIRCLED = 'PLUS_CIRCLED',
  RESYNC = 'RESYNC',

  MINUS = 'MINUS',
  MINUS_CIRCLE = 'MINUS_CIRCLE',
  PAPER_CLIP = 'PAPER_CLIP',
  DOUBLE_ARROW_FILLED = 'DOUBLE_ARROW_FILLED',
  WEB = 'WEB',
  INFO_LETTER = 'INFO_LETTER',

  CRYSTAL_BALL_TRANSPARENT = 'CRYSTAL_BALL_TRANSPARENT',

  GLOBE_TRANSPARENT = 'GLOBE_TRANSPARENT',
  WARNING_ROUND = 'WARNING_ROUND',
  SANDBOX_FILLED = 'SANDBOX_FILLED',
  SANDBOX_UNFILLED = 'SANDBOX_UNFILLED',
  BOOK_FILLED = 'BOOK_FILLED',
  BOOK_UNFILLED = 'BOOK_UNFILLED',
  ARROW_EXTERNAL_SQUARE = 'ARROW_EXTERNAL_SQUARE',
  API_SETTINGS_FILLED = 'API_SETTINGS_FILLED',
  API_SETTINGS_UNFILLED = 'API_SETTINGS_UNFILLED',
  EMBED_CODE = 'EMBED_CODE',
  HISTORY = 'HISTORY',
  STEPS = 'STEPS',
  ACADEMY = 'ACADEMY',

  PIN = 'PIN',
  PIN_FILLED = 'PIN_FILLED',
}

export const iconsList: Record<IconVariant, React.FC<React.SVGProps<SVGSVGElement>>> = {
  [IconVariant.EDITOR]: props => <Editor {...props} />,
  [IconVariant.STYLEGUIDE]: props => <Styleguide {...props} />,
  [IconVariant.SNIPPETS_UNFILLED]: props => <SnippetsUnfilled {...props} />,
  [IconVariant.SNIPPETS_UNFILLED_GREY]: props => <SnippetsUnfilledGrey {...props} />,
  [IconVariant.SNIPPETS_FILLED]: props => <SnippetsFilled {...props} />,
  [IconVariant.TEAM]: props => <Team {...props} />,
  [IconVariant.TEAM_UNFILLED_GREY]: props => <TeamUnfilledGrey {...props} />,
  [IconVariant.SETTINGS]: props => <Settings {...props} />,
  [IconVariant.SETTINGS_FILLED]: props => <SettingsFilled {...props} />,
  [IconVariant.DOCUMENT]: props => <Document {...props} />,
  [IconVariant.DOCUMENT_EXTENDED]: props => <DocumentExtended {...props} />,
  [IconVariant.DOCS]: props => <Docs {...props} />,
  [IconVariant.NEW_DOC]: props => <NewDoc {...props} />,
  [IconVariant.PORTAL]: props => <Portal {...props} />,
  [IconVariant.SUGGESTIONS]: props => <Suggestions {...props} />,

  [IconVariant.TERMS]: props => <Terms {...props} />,
  [IconVariant.TRASH]: props => <Trash {...props} />,
  [IconVariant.DOWNLOAD]: props => <Download {...props} />,
  [IconVariant.FAVORITE_UNFILLED]: props => <FavoriteUnfilled {...props} />,
  [IconVariant.FAVORITE_FILLED]: props => <FavoriteFilled {...props} />,
  [IconVariant.MAIL_SENT]: props => <MailSent {...props} />,
  [IconVariant.NAV_CHROME]: props => <NavChrome {...props} />,
  [IconVariant.NAV_CHROME_GREY]: props => <NavChromeGrey {...props} />,
  [IconVariant.OPEN_LINK]: props => <OpenLink {...props} />,
  [IconVariant.SORT]: props => <Sort {...props} />,
  [IconVariant.COPY]: props => <Copy {...props} />,
  [IconVariant.INFO]: props => <Info {...props} />,
  [IconVariant.INFO_OUTLINED]: props => <InfoOutlined {...props} />,
  [IconVariant.BURGER]: props => <Burger {...props} />,
  [IconVariant.DRAG]: props => <Drag {...props} />,
  [IconVariant.LOADING]: props => <Loading {...props} />,
  [IconVariant.LINK]: props => <Link {...props} />,
  [IconVariant.DASHBOARD_FILLED]: props => <DashboardFilled {...props} />,
  [IconVariant.DASHBOARD_UNFILLED]: props => <DashboardUnfilled {...props} />,
  [IconVariant.TERMINOLOGY_FILLED]: props => <TerminologyFilled {...props} />,
  [IconVariant.TERMINOLOGY_UNFILLED]: props => <TerminologyUnfilled {...props} />,
  [IconVariant.TERMINOLOGY_UNFILLED_GREY]: props => <TerminologyUnfilledGrey {...props} />,
  [IconVariant.SUGGESTIONS_FILLED]: props => <SuggestionsFilled {...props} />,
  [IconVariant.SUGGESTIONS_UNFILLED_GREY]: props => <SuggestionsUnfilledGrey {...props} />,
  [IconVariant.TEAM_FILLED]: props => <TeamFilled {...props} />,
  [IconVariant.SETTINGS_UNFILLED_GREY]: props => <SettingsUnfilledGrey {...props} />,
  [IconVariant.PROFILE_UNFILLED]: props => <ProfileUnfilled {...props} />,
  [IconVariant.PROFILE_UNFILLED_GREY]: props => <ProfileUnfilledGrey {...props} />,
  [IconVariant.PROFILE_FILLED]: props => <ProfileFilled {...props} />,
  [IconVariant.ARROW_EXTERNAL_LINK]: props => <ArrowExternalLink {...props} />,
  [IconVariant.WHATS_NEW]: props => <WhatsNew {...props} />,
  [IconVariant.VIDEO]: props => <Video {...props} />,
  [IconVariant.SUPPORT]: props => <Support {...props} />,
  [IconVariant.LINKEDIN]: props => <Linkedin {...props} />,
  [IconVariant.TWITTER]: props => <Twitter {...props} />,
  [IconVariant.FACEBOOK]: props => <Facebook {...props} />,
  [IconVariant.GOOGLE]: props => <Google {...props} />,
  [IconVariant.GRID]: props => <Grid {...props} />,
  [IconVariant.LIST]: props => <List {...props} />,
  [IconVariant.WAND]: props => <Wand {...props} />,
  [IconVariant.WAND_WHITE]: props => <WandWhite {...props} />,
  [IconVariant.SEARCH]: props => <Search {...props} />,
  [IconVariant.LOCK]: props => <Lock {...props} />,
  [IconVariant.LOCK_LINE]: props => <LockLine {...props} />,
  [IconVariant.UNLOCK_LINE]: props => <UnlockLine {...props} />,
  [IconVariant.LOCK_WIDE]: props => <LockWide {...props} />,
  [IconVariant.ELLIPSES]: props => <Ellipses {...props} />,
  [IconVariant.CHECK]: props => <Check {...props} />,
  [IconVariant.CLOSE]: props => <Close {...props} />,
  [IconVariant.CLOSE_WHITE]: props => <Close {...props} />,
  [IconVariant.PLUS]: props => <Plus {...props} />,
  [IconVariant.PLUS_WHITE]: props => <PlusWhite {...props} />,
  [IconVariant.ADD]: props => <Add {...props} />,
  [IconVariant.REMOVE]: props => <Remove {...props} />,
  [IconVariant.PREMIUM]: props => <Premium {...props} />,
  [IconVariant.REFRESH]: props => <Refresh {...props} />,
  [IconVariant.FULLSCREEN]: props => <Fullscreen {...props} />,
  [IconVariant.DROP_DOWN_ARROW]: props => <DropDownArrow {...props} />,
  [IconVariant.GLOBE]: props => <Globe {...props} />,
  [IconVariant.CODE]: props => <Code {...props} />,
  [IconVariant.EYE]: props => <Eye {...props} />,
  [IconVariant.CLOSED_EYE]: props => <ClosedEye {...props} />,
  [IconVariant.LINE_CHART]: props => <LineChart {...props} />,
  [IconVariant.BLUE_CHECKMARK]: props => <BlueCheckmark {...props} />,
  [IconVariant.GREY_CHECKMARK]: props => <GreyCheckmark {...props} />,
  [IconVariant.BLUE_UNCHECKEDMARK]: props => <BlueUncheckedmark {...props} />,
  [IconVariant.GREEN1_CHECKMARK]: props => <Green1Checkmark {...props} />,
  [IconVariant.GREEN2_CHECKMARK]: props => <Green2Checkmark {...props} />,
  [IconVariant.X_MARK_CIRCLE]: props => <XMarkCircle {...props} />,
  [IconVariant.X_MARK_CIRCLE_ORANGE]: props => <XMarkCircleOrange {...props} />,
  [IconVariant.ADOBE]: props => <Adobe {...props} />,
  [IconVariant.MANUAL_UPLOAD]: props => <ManualUpload {...props} />,
  [IconVariant.ZENDESK]: props => <Zendesk {...props} />,
  [IconVariant.MAGENTO]: props => <Magento {...props} />,
  [IconVariant.MARKETO]: props => <Marketo {...props} />,
  [IconVariant.WORDPRESS]: props => <Wordpress {...props} />,
  [IconVariant.SKETCH]: props => <Sketch {...props} />,
  [IconVariant.BITBUCKET]: props => <Bitbucket {...props} />,
  [IconVariant.CONFLUENCE]: props => <Confluence {...props} />,
  [IconVariant.DROPBOX]: props => <Dropbox {...props} />,
  [IconVariant.GDRIVE]: props => <Gdrive {...props} />,
  [IconVariant.GITHUB]: props => <Github {...props} />,
  [IconVariant.GMAIL]: props => <Gmail {...props} />,
  [IconVariant.HUBSPOT]: props => <Hubspot {...props} />,
  [IconVariant.INTERCOM]: props => <Intercom {...props} />,
  [IconVariant.NOTION]: props => <Notion {...props} />,
  [IconVariant.README]: props => <Readme {...props} />,
  [IconVariant.SALESFORCE]: props => <Salesforce {...props} />,
  [IconVariant.SHAREPOINT]: props => <Sharepoint {...props} />,
  [IconVariant.SLACK]: props => <Slack {...props} />,
  [IconVariant.API]: props => <Api {...props} />,
  [IconVariant.DRUPAL]: props => <Drupal {...props} />,
  [IconVariant.GIT]: props => <Git {...props} />,
  [IconVariant.JS]: props => <Js {...props} />,
  [IconVariant.IDE]: props => <Ide {...props} />,
  [IconVariant.ELOQUA]: props => <Eloqua {...props} />,
  [IconVariant.CICD]: props => <CiCd {...props} />,
  [IconVariant.CHROME_WHITE]: props => <ChromeWhite {...props} />,
  [IconVariant.WRITER_FOR_GOOGLEDOCS]: props => <WriterForGoogleDocs {...props} />,
  [IconVariant.WRITER_FOR_OUTLOOK]: props => <WriterForOutlook {...props} />,
  [IconVariant.WRITER_FOR_FIGMA]: props => <WriterForFigma {...props} />,
  [IconVariant.WRITER_FOR_WORD]: props => <WriterForWord {...props} />,
  [IconVariant.ALERT]: props => <Alert {...props} />,
  [IconVariant.ALERT_NO_STROKE]: props => <AlertNoStroke {...props} />,
  [IconVariant.FILTER]: props => <Filter {...props} />,
  [IconVariant.CLIP_MODE]: props => <ClipMode {...props} />,
  [IconVariant.WRAP_MODE]: props => <WrapMode {...props} />,
  [IconVariant.SPREADSHEET]: props => <SpreadsheetExtended {...props} />,
  [IconVariant.SPREADSHEET_EXTENDED]: props => <SpreadsheetExtended {...props} />,
  [IconVariant.SORT_ASC]: props => <SortAsc {...props} />,
  [IconVariant.SORT_DESC]: props => <SortDesc {...props} />,
  [IconVariant.ARROW_RIGHT_ALT]: props => <ArrowRightAlt {...props} />,
  [IconVariant.ARROW_UP]: props => <ArrowUp {...props} />,
  [IconVariant.ARROW_DOWN]: props => <ArrowDown {...props} />,
  [IconVariant.ARROW_DOWN_ALT]: props => <ArrowDownAlt {...props} />,
  [IconVariant.APOSTROPHE]: props => <Apostrophe {...props} />,
  [IconVariant.AMPERSAND]: props => <Ampersand {...props} />,
  [IconVariant.COLONS_AND_SEMI]: props => <ColonsAndSemi {...props} />,
  [IconVariant.CONTRACTIONS]: props => <Contractions {...props} />,
  [IconVariant.COMMA]: props => <Comma {...props} />,
  [IconVariant.DASH]: props => <Dash {...props} />,
  [IconVariant.EXCLAMATION]: props => <Exclamation {...props} />,
  [IconVariant.PARENTHESES]: props => <Parentheses {...props} />,
  [IconVariant.PERCENTAGES]: props => <Percentages {...props} />,
  [IconVariant.QUOTATIONMARK]: props => <QuotationMark {...props} />,
  [IconVariant.THREEDOTS]: props => <ThreeDots {...props} />,
  [IconVariant.DOT]: props => <Dot {...props} />,
  [IconVariant.BADGE]: props => <Badge {...props} />,
  [IconVariant.CREDIT_CARD_VISA]: props => <Visa {...props} />,
  [IconVariant.CREDIT_CARD_MASTERCARD]: props => <MasterCard {...props} />,
  [IconVariant.CREDIT_CARD_AMERICAN_EXPRESS]: props => <AmericanExpress {...props} />,
  [IconVariant.CREDIT_CARD_DISCOVER]: props => <Discover {...props} />,
  [IconVariant.CREDIT_CARD_DINERS_CLUB]: props => <DinersClub {...props} />,
  [IconVariant.CREDIT_CARD_JCB]: props => <JCB {...props} />,
  [IconVariant.CREDIT_CARD_UNIONPAY]: props => <UnionPay {...props} />,
  [IconVariant.CREDIT_CARD_UNKNOWN]: props => <Unknown {...props} />,
  [IconVariant.BREADCRUMB_ARROW]: props => <ArrowBreadcrumb {...props} />,
  [IconVariant.BACK_ARROW]: props => <BackArrow {...props} />,
  [IconVariant.BACK_ARROW_ROUND_BLACK]: props => <BackArrowRoundBlack {...props} />,
  [IconVariant.LIFEBUOY]: props => <Lifebuoy {...props} />,
  [IconVariant.BUILDING]: props => <Building {...props} />,
  [IconVariant.COPY_PASTE]: props => <CopyPaste {...props} />,
  [IconVariant.COPY_AND_ADD]: props => <CopyAndAdd {...props} />,
  [IconVariant.TEMPLATES]: props => <Templates {...props} />,
  [IconVariant.TEMPLATES_FILLED]: props => <TemplatesFilled {...props} />,
  [IconVariant.TEMPLATES_GREY]: props => <TemplatesGrey {...props} />,
  [IconVariant.RIGHT_SLIDE]: props => <RightSlide {...props} />,
  [IconVariant.REMOVE_BLACK]: props => <RemoveBlack {...props} />,
  [IconVariant.CHECKMARK]: props => <Checkmark {...props} />,
  [IconVariant.MORE_HORIZ]: props => <MoreHoriz {...props} />,
  [IconVariant.EXPAND]: props => <Expand {...props} />,
  [IconVariant.COLLAPSE]: props => <Collapse {...props} />,
  [IconVariant.PLAYLIST_ADD]: props => <PlaylistAdd {...props} />,
  [IconVariant.OUTLINED_FLAG]: props => <OutlinedFlag {...props} />,
  [IconVariant.NO_INTEGRATION]: props => <NoIntegration {...props} />,
  [IconVariant.LIGHTNING]: props => <Lightning {...props} />,
  [IconVariant.GOOGLE_LOGO]: props => <GoogleLogo {...props} />,
  [IconVariant.FEEDBACK]: props => <Feedback {...props} />,
  [IconVariant.THUMBS_UP]: props => <ThumbsUp {...props} />,
  [IconVariant.THUMBS_UP_FILLED]: props => <ThumbsUpFilled {...props} />,
  [IconVariant.THUMBS_DOWN]: props => <ThumbsDown {...props} />,
  [IconVariant.THUMBS_DOWN_FILLED]: props => <ThumbsDownFilled {...props} />,
  [IconVariant.TABLE_COLUMNS]: props => <TableColumns {...props} />,
  [IconVariant.INPUTS]: props => <Inputs {...props} />,
  [IconVariant.COLOR_BOX]: props => <ColorBox {...props} />,
  [IconVariant.TEASER_BOX]: props => <TeaserBox {...props} />,
  [IconVariant.COLOR_BOX_VARIANT_1]: props => <ColorBoxVariant1 {...props} />,
  [IconVariant.ALL_SETTINGS]: props => <AllSettings {...props} />,
  [IconVariant.RESIZE_TEXTAREA]: props => <ResizeTextarea {...props} />,
  [IconVariant.CUSTOM_CATEGORIES]: props => <CustomCategories {...props} />,
  [IconVariant.COPY_ANOTHER]: props => <CopyTwo {...props} />,
  [IconVariant.REWRITE]: props => <Rewrite {...props} />,
  [IconVariant.AWESOME]: props => <Awesome {...props} />,
  [IconVariant.RESIZE]: props => <Resize {...props} />,
  [IconVariant.IMAGE_ALIGN_LEFT]: props => <ImageAlignLeft {...props} />,
  [IconVariant.IMAGE_ALIGN_MIDDLE]: props => <ImageAlignMiddle {...props} />,
  [IconVariant.IMAGE_ALIGN_RIGHT]: props => <ImageAlignRight {...props} />,
  [IconVariant.FOLDER]: props => <Folder {...props} />,
  [IconVariant.EDITOR_FORMAT_BOLD]: props => <EditorFormatBold {...props} />,
  [IconVariant.EDITOR_FORMAT_ITALIC]: props => <EditorFormatItalic {...props} />,
  [IconVariant.EDITOR_FORMAT_UNDERLINE]: props => <EditorFormatUnderline {...props} />,
  [IconVariant.EDITOR_FORMAT_LINK]: props => <EditorFormatLink {...props} />,
  [IconVariant.EDITOR_FORMAT_TYPOGRAPHY]: props => <EditorFormatTypography {...props} />,
  [IconVariant.EDITOR_FORMAT_LIST_BULLET]: props => <EditorFormatListBullet {...props} />,
  [IconVariant.EDITOR_FORMAT_LIST_ORDERED]: props => <EditorFormatListOrdered {...props} />,
  [IconVariant.EDITOR_FORMAT_ALIGN]: props => <EditorFormatAlign {...props} />,
  [IconVariant.EDITOR_FORMAT_ALIGN_LEFT]: props => <EditorFormatAlignLeft {...props} />,
  [IconVariant.EDITOR_FORMAT_ALIGN_RIGHT]: props => <EditorFormatAlignRight {...props} />,
  [IconVariant.EDITOR_FORMAT_ALIGN_CENTER]: props => <EditorFormatAlignCenter {...props} />,
  [IconVariant.EDITOR_FORMAT_INDENT_INCREASE]: props => <EditorIndentIncrease {...props} />,
  [IconVariant.EDITOR_FORMAT_INDENT_DECREASE]: props => <EditorIndentDecrease {...props} />,
  [IconVariant.EDITOR_FORMAT_CLEAR]: props => <EditorClear {...props} />,
  [IconVariant.EDITOR_FORMAT_STRIKETHROUGH]: props => <EditorFormatStrikethrough {...props} />,
  [IconVariant.EDITOR_FORMAT_CODE]: props => <EditorCode {...props} />,
  [IconVariant.EDITOR_FORMAT_IMAGE]: props => <EditorImage {...props} />,
  [IconVariant.EDITOR_FORMAT_TABLE]: props => <EditorTable {...props} />,
  [IconVariant.EDITOR_FORMAT_QUOTEBLOCK]: props => <EditorQuoteblockTable {...props} />,
  [IconVariant.HIGHLIGHTS]: props => <Highlights {...props} />,
  [IconVariant.CLAIM]: props => <Claim {...props} />,
  [IconVariant.CLAIM_WHITE]: props => <ClaimWhite {...props} />,
  [IconVariant.TRASH_WHITE]: props => <TrashWhite {...props} />,
  [IconVariant.REFRESH_WHITE]: props => <RefreshWhite {...props} />,
  [IconVariant.WRITER_FOR_CONTENTFUL]: props => <WriterForContentful {...props} />,
  [IconVariant.WRITER_FOR_EDGE]: props => <WriterForEdge {...props} />,
  [IconVariant.TWITTER_UNFILLED]: props => <TwitterUnfilled {...props} />,
  [IconVariant.AWESOME_RED]: props => <AwesomeRed {...props} />,
  [IconVariant.LIGHTNING_UNION]: props => <LightningUnion {...props} />,
  [IconVariant.LIGHTNING_UNION_MAUVE]: props => <LightningUnionMauve {...props} />,
  [IconVariant.REFRESH_THIN]: props => <RefreshThin {...props} />,
  [IconVariant.WARNING]: props => <Warning {...props} />,
  [IconVariant.WARNING_MARK]: props => <WarningMark {...props} />,
  [IconVariant.CURSOR_SELECTION]: props => <CursorSelection {...props} />,
  [IconVariant.MAGIC_LINK_SNACKBAR]: props => <MagicLinksSnackbar {...props} />,
  [IconVariant.BROKEN_LINK]: props => <BrokenLink {...props} />,
  [IconVariant.TEXT_LINE]: props => <TextLine {...props} />,
  [IconVariant.STARS]: props => <Stars {...props} />,
  [IconVariant.ROUND_GREEN_CHECKS]: props => <RoundGreenChecks {...props} />,
  [IconVariant.PING_BOOK]: props => <PinkBook {...props} />,
  [IconVariant.YELLOW_MAGIC_STICK]: props => <YellowMagicStick {...props} />,
  [IconVariant.WAND_WITH_BORDER]: props => <WandWithBorder {...props} />,
  [IconVariant.EXPAND_ALL_SCREEN]: props => <ExpandAllScreen {...props} />,
  [IconVariant.USE_IN_NEW_DOC]: props => <UseInNewDoc {...props} />,
  [IconVariant.QUARTER_BURGER]: props => <QuarterBurger {...props} />,
  [IconVariant.GO_TO_DOC]: props => <GoToDoc {...props} />,
  [IconVariant.LIST_SCROLL_UP]: props => <ListScrollUp {...props} />,
  [IconVariant.LIST_SCROLL_DOWN]: props => <ListScrollDown {...props} />,
  [IconVariant.EVENT_TAKEAWAYS]: props => <EventTakeaways {...props} />,
  [IconVariant.MICROPHONE]: props => <Microphone {...props} />,
  [IconVariant.MICROPHONE_SIMPLE]: props => <MicrophoneSimple {...props} />,
  [IconVariant.MEDIA_FILE_VIDEO]: props => <MediaFileVideo {...props} />,
  [IconVariant.MEDIA_FILE_AUDIO]: props => <MediaFileAudio {...props} />,
  [IconVariant.MEDIA_FILE_DOCUMENT]: props => <MediaFileDocument {...props} />,
  [IconVariant.INCREASING_ELLIPSIS]: props => <IncreasingEllipsis {...props} />,
  [IconVariant.DRAFT]: props => <Draft {...props} />,
  [IconVariant.DRAFT_EXTENDED]: props => <DraftExtended {...props} />,
  [IconVariant.DRAFT_ACTIVE]: props => <DraftActive {...props} />,
  [IconVariant.ESC]: props => <Esc {...props} />,
  [IconVariant.ENTER]: props => <Enter {...props} />,
  [IconVariant.COWRITE_SHORTCUT]: props => <CoWriteShortcut {...props} />,
  [IconVariant.BLOG_SHORTCUT]: props => <BlogShortcut {...props} />,
  [IconVariant.ASKWRITER_SHORTCUT]: props => <AskWriterShortcut {...props} />,
  [IconVariant.RECAPS_SHORTCUT]: props => <RecapsShortcut {...props} />,
  [IconVariant.IMAGE_ANALYZER_SHORTCUT]: props => <ImageAnalyzerShortcut {...props} />,
  [IconVariant.ASKWRITER_ONBOARDING]: props => <AskWriterOnboarding {...props} />,
  [IconVariant.BLOG_ONBOARDING]: props => <BlogOnboarding {...props} />,
  [IconVariant.RECAPS_ONBOARDING]: props => <RecapsOnboarding {...props} />,
  [IconVariant.HEADPHONES_WITH_MIC]: props => <HeadphonesWithMic {...props} />,
  [IconVariant.GUIDE]: props => <Guide {...props} />,
  [IconVariant.COMMANDS_ARROW_BREADCRUMB]: props => <CommandsArrowBreadcrumb {...props} />,
  [IconVariant.KNOWLEDGE]: props => <Knowledge {...props} />,
  [IconVariant.KNOWLEDGE_ACTIVE]: props => <KnowledgeActive {...props} />,
  [IconVariant.COMMANDS_SHORTCUT]: props => <CommandShortcut {...props} />,
  [IconVariant.NINE_DOTS]: props => <NineDots {...props} />,
  [IconVariant.CRYSTAL_BALL]: props => <CrystalBall {...props} />,
  [IconVariant.GLOBE_MONOLINE]: props => <GlobeMonoline {...props} />,
  [IconVariant.BALL]: props => <Ball {...props} />,
  [IconVariant.COMMAND_KEY]: props => <CommandKey {...props} />,
  [IconVariant.LIGHT_BULB]: props => <LightBulbUnfilled {...props} />,
  [IconVariant.LIGHT_BULB_FILLED]: props => <LightBulbFilled {...props} />,
  [IconVariant.KNOWLEDGE_GRAPH]: props => <KnowledgeGraph {...props} />,
  [IconVariant.KNOWLEDGE_GRAPH_FILLED]: props => <KnowledgeGraphFilled {...props} />,
  [IconVariant.WORDMARK_BLUE]: props => <WordmarkBlue {...props} />,
  [IconVariant.WORDMARK_GREY]: props => <WordmarkGrey {...props} />,
  [IconVariant.WORDMARK_WHITE]: props => <WordmarkWhite {...props} />,
  [IconVariant.WORDMARK_GREEN]: props => <WordmarkGreen {...props} />,
  [IconVariant.WORDMARK_TRANSPARENT]: props => <WordmarkTransparent {...props} />,
  [IconVariant.ARROW_DOWN_IN_FILLED_CIRCLE]: props => <ArrowDownInFilledCircle {...props} />,
  [IconVariant.ARROW_LEFT]: props => <ArrowLeft {...props} />,
  [IconVariant.ARROW_DOWN_WHITE]: props => <ArrowDownWhite {...props} />,
  [IconVariant.LONG_ARROW_DOWN]: props => <ArrowDownLong {...props} />,
  [IconVariant.FLAG]: props => <Flag {...props} />,
  [IconVariant.FLAG_FILLED]: props => <FlagFilled {...props} />,
  [IconVariant.COMMANDS_CHECK]: props => <CommandsCheck {...props} />,
  [IconVariant.COMMANDS_ELLIPSES]: props => <CommandsEllipses {...props} />,
  [IconVariant.COMMANDS_PENCIL]: props => <CommandsPencil {...props} />,
  [IconVariant.COMMANDS_KEEP_WRITING]: props => <CommandsKeepWriting {...props} />,
  [IconVariant.COMMANDS_LIGHTBULB]: props => <CommandsLightbulb {...props} />,
  [IconVariant.COMMANDS_REGENERATE]: props => <CommandsRegenerate {...props} />,
  [IconVariant.COMMANDS_STAR]: props => <CommandsStar {...props} />,
  [IconVariant.COMMANDS_WAND]: props => <CommandsWand {...props} />,
  [IconVariant.COMMANDS_COPY]: props => <CommandsCopy {...props} />,
  [IconVariant.COMMANDS_TICK]: props => <CommandsTick {...props} />,
  [IconVariant.COMMANDS_VOICE_TUNE]: props => <CommandsVoiceTune {...props} />,
  [IconVariant.LONG_ARROW_UP]: props => <LongArrowUp {...props} />,
  [IconVariant.LONG_ARROW_DOWN_LEFT]: props => <LongArrowDownLeft {...props} />,
  [IconVariant.WRITER_FOR_MAC]: props => <WriterForMac {...props} />,
  [IconVariant.ADS_CONTENT_TYPE]: props => <AdsContentType {...props} />,
  [IconVariant.ADS_CONTENT_TYPE_BOLD]: props => <AdsContentTypeBold {...props} />,
  [IconVariant.BLOG_CONTENT_TYPE]: props => <BlogContentType {...props} />,
  [IconVariant.BLOG_CONTENT_TYPE_BOLD]: props => <BlogContentTypeBold {...props} />,
  [IconVariant.CASE_STUDY_CONTENT_TYPE]: props => <CaseStudyContentType {...props} />,
  [IconVariant.DEFAULT_LONGFORM_CONTENT_TYPE]: props => <DefaultLongformContentType {...props} />,
  [IconVariant.DEFAULT_SHORTFORM_CONTENT_TYPE]: props => <DefaultShortformContentType {...props} />,
  [IconVariant.EMAIL_CONTENT_TYPE]: props => <EmailContentType {...props} />,
  [IconVariant.EMAIL_CONTENT_TYPE_BOLD]: props => <EmailContentTypeBold {...props} />,
  [IconVariant.ERROR_MESSAGE_CONTENT_TYPE]: props => <ErrorMessageContentType {...props} />,
  [IconVariant.FAQ_CONTENT_TYPE]: props => <FaqContentType {...props} />,
  [IconVariant.LIST_CONTENT_TYPE]: props => <ListContentType {...props} />,
  [IconVariant.MESSAGE_BUBBLE_CONTENT_TYPE]: props => <MessageBubbleContentType {...props} />,
  [IconVariant.SOCIAL_CONTENT_TYPE]: props => <SocialContentType {...props} />,
  [IconVariant.SUMMARY_CONTENT_TYPE]: props => <SummaryContentType {...props} />,
  [IconVariant.SUPPORT_ARTICLE_CONTENT_TYPE]: props => <SupportArticleContentType {...props} />,
  [IconVariant.IMAGE_CONTENT_TYPE]: props => <ImageContentType {...props} />,
  [IconVariant.ABSTRACT_DOLLAR_SIGN]: props => <AbstractDollarSign {...props} />,
  [IconVariant.CROSS_IN_CIRCLE]: props => <CrossInCircle {...props} />,
  [IconVariant.DOC_WITH_FIVE_LINES]: props => <DocWithFiveLines {...props} />,
  [IconVariant.DOC_WITH_TWO_LINES]: props => <DocWithTwoLines {...props} />,
  [IconVariant.ECOMMERCE]: props => <Ecommerce {...props} />,
  [IconVariant.TECH]: props => <Tech {...props} />,
  [IconVariant.SUMMARY]: props => <Summary {...props} />,
  [IconVariant.MESSAGE_BUBBLE]: props => <MessageBubble {...props} />,
  [IconVariant.MESSAGE_BUBBLE_ALT]: props => <MessageBubbleAlt {...props} />,
  [IconVariant.DOUBLE_ARROW]: props => <DoubleArrow {...props} />,
  [IconVariant.PENCIL]: props => <Pencil {...props} />,
  [IconVariant.CLIP]: props => <Clip {...props} />,
  [IconVariant.TRANSFORM]: props => <Transform {...props} />,
  [IconVariant.START_LINED]: props => <StarLined {...props} />,
  [IconVariant.PALMYRA_X]: props => <PalmyraX {...props} />,
  [IconVariant.PALMYRA_X_32K]: props => <PalmyraX32K {...props} />,
  [IconVariant.PALMYRA_MED]: props => <PalmyraMed {...props} />,
  [IconVariant.PALMYRA_INSTRUCT]: props => <PalmyraInstruct {...props} />,
  [IconVariant.PALMYRA_VISION]: props => <PalmyraVision {...props} />,

  [IconVariant.CONSOLE_TEXT_GENERATION]: props => <ConsoleTextGeneration {...props} />,
  [IconVariant.CONSOLE_CHAT]: props => <ConsoleChat {...props} />,
  [IconVariant.CONSOLE_REWRITE]: props => <ConsoleRewrite {...props} />,
  [IconVariant.CONSOLE_API]: props => <ConsoleApi {...props} />,
  [IconVariant.FRAMEWORK_APP]: props => <FrameworkApp {...props} />,
  [IconVariant.FRAMEWORK_APP_MONO]: props => <FrameworkAppMono {...props} />,
  [IconVariant.API_APP]: props => <ApiApp {...props} />,
  [IconVariant.CHAT_WITH_DB]: props => <ChatWithDB {...props} />,

  [IconVariant.CONSOLE_APP_SOCIAL]: props => <ConsoleAppSocial {...props} />,
  [IconVariant.CONSOLE_APP_MESSAGING]: props => <ConsoleAppMessaging {...props} />,
  [IconVariant.CONSOLE_APP_ADS]: props => <ConsoleAppAds {...props} />,
  [IconVariant.CONSOLE_APP_BLOGS]: props => <ConsoleAppBlogs {...props} />,
  [IconVariant.CONSOLE_APP_CASE_STUDIES]: props => <ConsoleCaseStudies {...props} />,
  [IconVariant.CONSOLE_APP_LISTS]: props => <ConsoleAppLists {...props} />,
  [IconVariant.CONSOLE_APP_ERRORS]: props => <ConsoleAppErrors {...props} />,
  [IconVariant.CONSOLE_APP_FAQ]: props => <ConsoleAppFaq {...props} />,
  [IconVariant.CONSOLE_APP_SUMMARIES]: props => <ConsoleAppSummaries {...props} />,
  [IconVariant.CONSOLE_APP_SUPPORT_ARTICLES]: props => <ConsoleAppSupportArticles {...props} />,
  [IconVariant.CONSOLE_APP_EMAILS]: props => <ConsoleAppEmails {...props} />,
  [IconVariant.CONSOLE_APP_SHORT_FORM]: props => <ConsoleAppShortForm {...props} />,
  [IconVariant.CONSOLE_APP_LONG_FORM]: props => <ConsoleAppLongForm {...props} />,
  [IconVariant.CONSOLE_APP_IMAGE]: props => <ConsoleAppImage {...props} />,
  [IconVariant.UPLOAD]: props => <Upload {...props} />,
  [IconVariant.TRANSCRIPT]: props => <Transcript {...props} />,
  [IconVariant.MAC_OS_BLACK]: props => <MacOsBlack {...props} />,
  [IconVariant.HI]: props => <Hi {...props} />,
  [IconVariant.W_TAG]: props => <WTag {...props} />,
  [IconVariant.ARROW_RIGHT_LOG_OUT]: props => <ArrowRightLogOut {...props} />,

  [IconVariant.FLAG_AU]: props => <FlagAU {...props} />,
  [IconVariant.FLAG_GB]: props => <FlagGB {...props} />,
  [IconVariant.FLAG_CA]: props => <FlagCA {...props} />,
  [IconVariant.FLAG_US]: props => <FlagUs {...props} />,

  [IconVariant.CONTENTFUL_LOGO]: props => <ContentfulLogo {...props} />,
  [IconVariant.FOLDER_ROUNDED]: props => <FolderTransparent {...props} />,
  [IconVariant.DOCUMENTS]: props => <Documents {...props} />,
  [IconVariant.DOCUMENTS_FILLED]: props => <DocumentsFilled {...props} />,
  [IconVariant.SETUP]: props => <Setup {...props} />,
  [IconVariant.CRYSTAL_BALL_FILLED]: props => <CrystalBallFilled {...props} />,
  [IconVariant.SETUP_FILLED]: props => <SetupFilled {...props} />,
  [IconVariant.ALL_SETTINGS_FILLED]: props => <AllSettingsFilled {...props} />,
  [IconVariant.TUNE_SETTINGS]: props => <TuneSettings {...props} />,

  [IconVariant.VOICE_SETUP]: props => <VoiceSetup {...props} />,
  [IconVariant.VOICE_STAR]: props => <VoiceStar {...props} />,
  [IconVariant.VOICE_TUNE]: props => <VoiceTune {...props} />,
  [IconVariant.PERSON_ADD]: props => <PersonAdd {...props} />,
  [IconVariant.PERSON_ADMIN]: props => <PersonAdmin {...props} />,
  [IconVariant.PERSON_REMOVE]: props => <PersonRemove {...props} />,
  [IconVariant.PERSON_SIMPLE]: props => <PersonSimple {...props} />,
  [IconVariant.PERSON_UPDATE]: props => <PersonUpdate {...props} />,
  [IconVariant.MINUS]: props => <Minus {...props} />,
  [IconVariant.MINUS_CIRCLE]: props => <MinusCircle {...props} />,
  [IconVariant.PAPER_CLIP]: props => <PaperСlip {...props} />,
  [IconVariant.DOUBLE_ARROW_FILLED]: props => <DoubleArrowFilled {...props} />,
  [IconVariant.WEB]: props => <Web {...props} />,
  [IconVariant.INFO_LETTER]: props => <InfoLetter {...props} />,
  [IconVariant.GUARDRAILS_FILLED]: props => <Guardrails {...props} />,
  [IconVariant.GUARDRAILS_UNFILLED]: props => <GuardrailsUnfilled {...props} />,
  [IconVariant.PLAYGROUND_UNFILLED]: props => <Playground {...props} />,
  [IconVariant.PLAYGROUND_FILLED]: props => <PlaygroundFilled {...props} />,
  [IconVariant.CRYSTAL_BALL_TRANSPARENT]: props => <CrystalBallTransparent {...props} />,
  [IconVariant.GLOBE_TRANSPARENT]: props => <GlobeTransparent {...props} />,
  [IconVariant.REPORTS_UNFILLED]: props => <Reports {...props} />,
  [IconVariant.REPORTS_FILLED]: props => <ReportsFilled {...props} />,
  [IconVariant.WARNING_ROUND]: props => <WarningRound {...props} />,
  [IconVariant.BIN]: props => <Bin {...props} />,
  [IconVariant.PLUS_CIRCLED]: props => <PlusCircled {...props} />,
  [IconVariant.RESYNC]: props => <Resync {...props} />,
  [IconVariant.SANDBOX_FILLED]: props => <SandboxFilled {...props} />,
  [IconVariant.SANDBOX_UNFILLED]: props => <SandboxUnFilled {...props} />,
  [IconVariant.BOOK_FILLED]: props => <BookFilled {...props} />,
  [IconVariant.BOOK_UNFILLED]: props => <BookUnFilled {...props} />,
  [IconVariant.ARROW_EXTERNAL_SQUARE]: props => <ArrowExternalSquare {...props} />,
  [IconVariant.API_SETTINGS_FILLED]: props => <ApiSettingsFilled {...props} />,
  [IconVariant.API_SETTINGS_UNFILLED]: props => <ApiSettingsUnfilled {...props} />,
  [IconVariant.EMBED_CODE]: props => <EmbedCode {...props} />,
  [IconVariant.HISTORY]: props => <History {...props} />,
  [IconVariant.STEPS]: props => <Steps {...props} />,
  [IconVariant.ACADEMY]: props => <Academy {...props} />,

  [IconVariant.PIN]: props => <IconsCommonPin {...props} />,
  [IconVariant.PIN_FILLED]: props => <IconsCommonPinFilled {...props} />,
};

export const editorFormatIcons = [
  IconVariant.EDITOR_FORMAT_BOLD,
  IconVariant.EDITOR_FORMAT_ITALIC,
  IconVariant.EDITOR_FORMAT_UNDERLINE,
  IconVariant.EDITOR_FORMAT_LINK,
  IconVariant.EDITOR_FORMAT_TYPOGRAPHY,
  IconVariant.EDITOR_FORMAT_LIST_BULLET,
  IconVariant.EDITOR_FORMAT_LIST_ORDERED,
  IconVariant.EDITOR_FORMAT_ALIGN,
  IconVariant.EDITOR_FORMAT_ALIGN_LEFT,
  IconVariant.EDITOR_FORMAT_ALIGN_RIGHT,
  IconVariant.EDITOR_FORMAT_ALIGN_CENTER,
  IconVariant.EDITOR_FORMAT_INDENT_DECREASE,
  IconVariant.EDITOR_FORMAT_INDENT_INCREASE,
  IconVariant.EDITOR_FORMAT_CLEAR,
  IconVariant.EDITOR_FORMAT_STRIKETHROUGH,
  IconVariant.EDITOR_FORMAT_CODE,
  IconVariant.EDITOR_FORMAT_IMAGE,
  IconVariant.EDITOR_FORMAT_TABLE,
  IconVariant.EDITOR_FORMAT_QUOTEBLOCK,
];

export const shortcutIcons = [
  IconVariant.COWRITE_SHORTCUT,
  IconVariant.BLOG_SHORTCUT,
  IconVariant.ASKWRITER_SHORTCUT,
  IconVariant.RECAPS_SHORTCUT,
];

export const socialIcons = [
  IconVariant.LINKEDIN,
  IconVariant.TWITTER,
  IconVariant.TWITTER_UNFILLED,
  IconVariant.FACEBOOK,
  IconVariant.GOOGLE,
];

export const onboardingIcons = [
  IconVariant.ASKWRITER_ONBOARDING,
  IconVariant.BLOG_ONBOARDING,
  IconVariant.RECAPS_ONBOARDING,
];

export const creditcardIcons = [
  IconVariant.CREDIT_CARD_VISA,
  IconVariant.CREDIT_CARD_AMERICAN_EXPRESS,
  IconVariant.CREDIT_CARD_DINERS_CLUB,
  IconVariant.CREDIT_CARD_DISCOVER,
  IconVariant.CREDIT_CARD_JCB,
  IconVariant.CREDIT_CARD_MASTERCARD,
  IconVariant.CREDIT_CARD_UNIONPAY,
  IconVariant.CREDIT_CARD_UNKNOWN,
];

export const integrationIcons = [
  IconVariant.JS,
  IconVariant.SKETCH,
  IconVariant.ADOBE,
  IconVariant.GIT,
  IconVariant.BITBUCKET,
  IconVariant.API,
  IconVariant.CICD,
  IconVariant.IDE,
  IconVariant.MANUAL_UPLOAD,
  IconVariant.WORDPRESS,
  IconVariant.ELOQUA,
  IconVariant.MAGENTO,
  IconVariant.ZENDESK,
  IconVariant.MARKETO,
  IconVariant.DRUPAL,
];

export const extensionIcons = [
  IconVariant.CHROME_WHITE,
  IconVariant.WRITER_FOR_GOOGLEDOCS,
  IconVariant.WRITER_FOR_OUTLOOK,
  IconVariant.WRITER_FOR_WORD,
  IconVariant.WRITER_FOR_FIGMA,
  IconVariant.WRITER_FOR_CONTENTFUL,
  IconVariant.WRITER_FOR_EDGE,
  IconVariant.WRITER_FOR_MAC,
  IconVariant.MAC_OS_BLACK,
];

export const contentTypeIcons = [
  IconVariant.ADS_CONTENT_TYPE,
  IconVariant.BLOG_CONTENT_TYPE,
  IconVariant.CASE_STUDY_CONTENT_TYPE,
  IconVariant.DEFAULT_LONGFORM_CONTENT_TYPE,
  IconVariant.DEFAULT_SHORTFORM_CONTENT_TYPE,
  IconVariant.EMAIL_CONTENT_TYPE,
  IconVariant.ERROR_MESSAGE_CONTENT_TYPE,
  IconVariant.FAQ_CONTENT_TYPE,
  IconVariant.LIST_CONTENT_TYPE,
  IconVariant.MESSAGE_BUBBLE_CONTENT_TYPE,
  IconVariant.SOCIAL_CONTENT_TYPE,
  IconVariant.SUMMARY_CONTENT_TYPE,
  IconVariant.SUPPORT_ARTICLE_CONTENT_TYPE,
  IconVariant.IMAGE_CONTENT_TYPE,
];

export const aiModelsIcons = [
  IconVariant.PALMYRA_X,
  IconVariant.PALMYRA_X_32K,
  IconVariant.PALMYRA_MED,
  IconVariant.PALMYRA_INSTRUCT,
  IconVariant.PALMYRA_VISION,
];

export const consoleAppIcons = [
  IconVariant.CONSOLE_TEXT_GENERATION,
  IconVariant.CONSOLE_CHAT,
  IconVariant.CONSOLE_REWRITE,
  IconVariant.API_APP,
  IconVariant.FRAMEWORK_APP,
  IconVariant.CHAT_WITH_DB,
];

export const consoleAppTypeIcons = [
  IconVariant.CONSOLE_APP_SOCIAL,
  IconVariant.CONSOLE_APP_MESSAGING,
  IconVariant.CONSOLE_APP_ADS,
  IconVariant.CONSOLE_APP_BLOGS,
  IconVariant.CONSOLE_APP_CASE_STUDIES,
  IconVariant.CONSOLE_APP_LISTS,
  IconVariant.CONSOLE_APP_ERRORS,
  IconVariant.CONSOLE_APP_FAQ,
  IconVariant.CONSOLE_APP_SUMMARIES,
  IconVariant.CONSOLE_APP_SUPPORT_ARTICLES,
  IconVariant.CONSOLE_APP_EMAILS,
  IconVariant.CONSOLE_APP_SHORT_FORM,
  IconVariant.CONSOLE_APP_LONG_FORM,
];

export const aiNavBarIcons = [
  IconVariant.DASHBOARD_UNFILLED,
  IconVariant.DASHBOARD_FILLED,
  IconVariant.REPORTS_FILLED,
  IconVariant.REPORTS_UNFILLED,
  IconVariant.GUARDRAILS_FILLED,
  IconVariant.GUARDRAILS_UNFILLED,
  IconVariant.LIGHT_BULB,
  IconVariant.LIGHT_BULB_FILLED,
  IconVariant.KNOWLEDGE_GRAPH,
  IconVariant.KNOWLEDGE_GRAPH_FILLED,
  IconVariant.SANDBOX_FILLED,
  IconVariant.SANDBOX_UNFILLED,
  IconVariant.BOOK_UNFILLED,
  IconVariant.BOOK_FILLED,
  IconVariant.SETTINGS_FILLED,
  IconVariant.SETTINGS,
  IconVariant.TEMPLATES,
  IconVariant.TEMPLATES_FILLED,
  IconVariant.ARROW_EXTERNAL_SQUARE,
  IconVariant.API_SETTINGS_UNFILLED,
  IconVariant.API_SETTINGS_FILLED,
];
