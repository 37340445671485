import React, { useEffect, useState } from 'react';

import styles from './styles.module.css';

export interface FadeProps {
  show: boolean;
  className?: string;
  time?: number;
  children: React.ReactNode;
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(({ show, className, time, children }, forwardedRef) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) {
      setRender(true);
    }
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) {
      setRender(false);
    }
  };

  return render ? (
    <div
      ref={forwardedRef}
      className={className}
      style={{ animation: `${show ? styles['fade-in'] : styles['fade-out']} ${time || 0.3}s` }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  ) : null;
});

Fade.displayName = 'Fade';
