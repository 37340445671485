/**
 * A class that encapsulates a context object, allowing for dynamic property
 * access and cloning with additional context properties.
 *
 * @template TContext - A type that extends a record with string keys and values
 *                      of any type.
 */
export class Context<TContext = {}> {
  /**
   * The context object with properties that are either the return type of
   * functions or the original value.
   */
  readonly context: {
    [K in keyof TContext]: TContext[K] extends () => infer A ? A : TContext[K];
  };

  /**
   * Constructs a new Context instance.
   *
   * @param ctx - The initial context object.
   */
  constructor(private readonly ctx: TContext) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const context = {} as any;
    Object.entries(ctx as object).forEach(([key, val]) => {
      if (typeof val === 'function') {
        Object.defineProperty(context, key, {
          enumerable: true,
          configurable: true,
          get: val as () => unknown,
        });
      } else {
        context[key] = val;
      }
    });
    this.context = context;
  }

  /**
   * Clones the current context and merges it with additional context properties.
   *
   * @template TNewContext - A type that extends a record with string keys and values
   *                  of any type.
   * @param ctx - The additional context properties to merge.
   * @returns A new Context instance with the merged context properties.
   */
  clone<TNewContext>(ctx: TNewContext, transform: void): Context<TContext & TNewContext>;
  clone<TNewContext, TransformContext>(
    ctx: TNewContext,
    transform: (ctx: TContext) => TransformContext,
  ): Context<TransformContext & TNewContext>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clone(ctx: any, transform: any): any {
    const localContext = transform ? transform(this.ctx) : this.ctx;

    return new Context({
      ...localContext,
      ...ctx,
    });
  }
}
