import React from 'react';
import cx from 'clsx';
import { GradeScoreColor } from '@writercolab/ui-sidebar-models';
import { Text, TextSize } from '@writercolab/ui-atoms';
import styles from './styles.module.css';
import { FleschKincaidScoreTooltip } from '../FleschKincaidScoreTooltip';
import type { UIDocumentStatsModel } from '../../organisms/DocumentStats/UIDocumentStatsModel';

interface DocumentGradeLevelProps {
  model: Omit<UIDocumentStatsModel, 'defaultOption' | 'onDocumentStatOptionChange'>;
  classNameTooltip?: string;
}

export const DocumentGradeLevel = ({ model, classNameTooltip }: DocumentGradeLevelProps) => {
  const { isFleschKincaidScoreAvailable, gradeColor, fleschKincaidScoreValueReadable } = model;

  return (
    <FleschKincaidScoreTooltip classNameTitle={classNameTooltip} isEmpty={!isFleschKincaidScoreAvailable}>
      <div className={styles.fleschContainer}>
        <Text variant={TextSize.XL} bold ellipsisOverflow>
          Grade level
        </Text>
        <div
          className={cx(styles.fleschValue, {
            [styles.valueGreen]: gradeColor === GradeScoreColor.enum.green,
            [styles.valueBlue]: gradeColor === GradeScoreColor.enum.blue,
            [styles.valuePurple]: gradeColor === GradeScoreColor.enum.purple,
            [styles.valueGray]: gradeColor === GradeScoreColor.enum.gray,
          })}
        >
          <Text variant={TextSize.XL} bold>
            {fleschKincaidScoreValueReadable}
          </Text>
        </div>
      </div>
    </FleschKincaidScoreTooltip>
  );
};
