import type { RequestServiceInitialize, components } from '@writercolab/network';
import { Adapter } from './Adapter';
import type { IAnalyticsTrack } from '../types';

/**
 * @template TContext - The context type extending a record with string keys and unknown values.
 * @extends Adapter
 * @implements {Partial<IAnalytics<TContext>>}
 *
 * A class that adapts requests for analytics tracking.
 */
export class RequestAdapter<TContext, TParams extends Record<string, unknown>>
  extends Adapter
  implements IAnalyticsTrack<TParams, TContext>
{
  /**
   * @param {RequestServiceInitialize['api']} api - The API service used for making requests.
   */
  constructor(private readonly api: RequestServiceInitialize['api']) {
    super();
  }

  /**
   * Tracks an event with the specified name and properties.
   *
   * @template T - The type of the analytic event.
   * @param {T} eventName - The name of the event to track.
   * @param {Omit<TParams[T], keyof TContext>} properties - The properties of the event, excluding context keys.
   * @returns {Promise<void>} A promise that resolves when the event is tracked.
   */
  async track<T extends keyof TParams>(eventName: T, properties: Omit<TParams[T], keyof TContext>) {
    await this.api.post(RequestAdapter.encodeUrl('/api/analytics/track'), {
      params: {},
      body: {
        eventName: eventName as string,
        properties,
      },
    });
  }

  /**
   * Tracks an anonymous event with the specified name.
   *
   * @template T - The type of the analytic event.
   * @param {T} eventName - The name of the anonymous event to track.
   * @param {Omit<TActivityParamsOrAny[T], keyof TContext>} properties - The properties of the event, excluding context keys.
   * @returns {Promise<void>} A promise that resolves when the anonymous event is tracked.
   */
  async trackAnonymousEvent<T extends keyof TParams>(eventName: T, properties?: Omit<TParams[T], keyof TContext>) {
    await this.api.post(RequestAdapter.encodeUrl('/api/analytics/anonymous/track'), {
      params: {},
      body: { eventName: eventName as string, properties: properties ?? {} },
    });
  }

  /**
   * Tracks an event for a specific organization with the specified name and properties.
   *
   * @template T - The type of the analytic event.
   * @param {number} organizationId - The ID of the organization.
   * @param {T} eventName - The name of the event to track.
   * @param {TActivityParamsOrAny[T]} properties - The properties of the event.
   * @returns {Promise<void>} A promise that resolves when the organization event is tracked.
   */
  async trackOrganizationEvent<T extends keyof TParams>(organizationId: number, eventName: T, properties: TParams[T]) {
    await this.api.post(RequestAdapter.encodeUrl('/api/analytics/organization/{organizationId}/track'), {
      params: { path: { organizationId } },
      body: {
        eventName: eventName as string,
        properties,
      },
    });
  }

  /**
   * Identifies a user with the specified traits.
   *
   * @param {components['schemas']['analytics_dto_IdentifyRequest']} param0 - The identify request containing user traits.
   * @returns {Promise<void>} A promise that resolves when the user is identified.
   */
  async identify(organizationId: number, traits: components['schemas']['analytics_dto_IdentifyRequest']['traits']) {
    await this.api.post('/api/analytics/organization/{organizationId}/identify', {
      params: {
        path: { organizationId },
      },
      body: {
        traits,
      },
    });
  }

  /**
   * Tracks a Page activity with the specified parameters.
   *
   * @template T - The type of the analytic event.
   * @param {number} organizationId - The ID of the organization.
   * @param {components['schemas']['analytics_dto_PageViewRequest']} properties - The properties of the page view event.
   * @returns {Promise<void>} A promise that resolves when the page view event is tracked.
   */
  async page(properties: components['schemas']['analytics_dto_PageViewRequest']) {
    await this.api.post('/api/analytics/page', {
      body: properties,
    });
  }

  /**
   * Encodes the URL by replacing '/analytics' with '/anlytcs'.
   *
   * @template T - The type of the URL string.
   * @param {T} url - The URL to encode.
   * @returns {T} The encoded URL.
   */
  static encodeUrl<T extends string>(url: T): T {
    return url.replace('/analytics', '/anlytcs') as T;
  }
}
