import React from 'react';
import cx from 'clsx';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import styles from './styles.module.css';
import { Icon, IconVariant } from '../Icon';
import 'react-circular-progressbar/dist/styles.css';

export interface IScoreProps {
  value: number;
  hideScoreValue: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export const Score: React.FC<IScoreProps> = ({ value, hideScoreValue, isLoading, onClick }) => (
  <div>
    <CircularProgressbarWithChildren
      className={styles.circularProgressbar}
      value={!hideScoreValue ? value : 0}
      strokeWidth={7}
      styles={buildStyles({
        pathColor: 'black',
        textColor: 'black',
        trailColor: '#d4d6db',
        strokeLinecap: 'butt',
      })}
    >
      {isLoading ? (
        <div className={styles.spinner} />
      ) : (
        !hideScoreValue && (
          <span className={cx(styles.container, { [styles.containerClickable]: onClick })}>
            <span className={cx(styles.scoreText, { [styles.scoreTextHidden]: isLoading })}>{value}</span>
            <span
              aria-hidden
              className={cx(styles.refreshButton, { [styles.refreshAnimation]: isLoading })}
              onClick={() => onClick?.()}
            >
              <Icon name={IconVariant.REFRESH} />
            </span>
          </span>
        )
      )}
    </CircularProgressbarWithChildren>
  </div>
);

export default Score;
