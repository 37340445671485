import React from 'react';
import { useCallback } from 'react';
import cx from 'clsx';

import { Enum } from '@writercolab/utils';

import styles from './Slider.module.css';

import { Label } from '../Label';

export const TSliderVariant = new Enum('default', 'black');

interface ISliderProps {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  label?: string | React.ReactNode;
  onChange?: (value: number) => void;
  className?: string;
  style?: React.CSSProperties;
  inline?: boolean;
  hideInputArrow?: boolean;
  children?: React.ReactNode;
  variant?: typeof TSliderVariant.type;
  inputRounded?: boolean;
  inputNumberStyle?: React.CSSProperties;
  inputRangeStyle?: React.CSSProperties;
}

export const Slider: React.FC<ISliderProps> = ({
  variant = TSliderVariant.enum.default as ISliderProps['variant'],
  className,
  label,
  onChange,
  value = 0,
  min = 0,
  max = 1,
  step = 1,
  inline = false,
  inputNumberStyle = {},
  inputRangeStyle = {},
  inputRounded = false,
  hideInputArrow = false,
  children,
}) => {
  const progressBarColor =
    variant === TSliderVariant.enum.black ? 'var(--classic-black, black)' : 'var(--writer-blue-4, #6985ff)';
  const progress = ((value - min) / (max - min)) * 100;

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Parse the input value as a float and clamp it within the range [min, max]
      const val = Math.min(max, Math.max(min, parseFloat(e.target.value)));

      onChange?.(val);
    },
    [min, max, onChange],
  );

  return (
    <div
      className={cx(className, styles.sliderWrapper, {
        [styles.sliderWrapperBlack]: variant === TSliderVariant.enum.black,
      })}
    >
      <div className={cx(styles.inputWrapper, { [styles.inputWrapperInline]: inline })}>
        <div className={styles.sliderText}>
          {label && <Label className={styles.sliderLabel}>{label}</Label>}
          {children && <div>{children}</div>}
          {!inline && (
            <div
              className={cx(styles.inputValue, {
                [styles.inputValueRounded]: inputRounded,
                [styles.inputValueArrowsHidden]: hideInputArrow,
              })}
            >
              <input
                className={styles.inputNumber}
                type="number"
                value={value}
                min={min}
                max={max}
                onChange={handleValueChange}
                step={step}
                style={inputNumberStyle}
              />
            </div>
          )}
        </div>
        <div className={styles.inputRangeWrapper}>
          <input
            className={styles.inputSlider}
            type="range"
            value={value}
            min={min}
            max={max}
            onChange={handleValueChange}
            step={step}
            style={{
              ...inputRangeStyle,
              background: `linear-gradient(to right, ${progressBarColor} ${progress}%, var(--writer-graynu-1, #F5F5F9) 0px`,
            }}
          />
        </div>
        {inline && (
          <div
            className={cx(styles.inputValue, {
              [styles.inputValueRounded]: inputRounded,
              [styles.inputValueArrowsHidden]: hideInputArrow,
            })}
          >
            <input
              className={styles.inputNumber}
              type="number"
              value={value}
              min={min}
              max={max}
              onChange={handleValueChange}
              step={step}
              style={inputNumberStyle}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Slider;
