import React from 'react';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useVirtualizer } from '@tanstack/react-virtual';
import type { UIVisibleIssuesListModel } from './UIVisibleIssuesListModel';
import styles from './VisibleIssuesListContent.module.css';
import { IssueCard } from '../IssueCard';

export const VisibleIssuesListContent = observer<{
  isSmallPadding?: boolean;
  model: UIVisibleIssuesListModel;
}>(({ model, isSmallPadding = false }) => {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: model.list?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 72,
    paddingEnd: 150,
    overscan: 3,
  });

  const items = virtualizer.getVirtualItems();
  const firstItem = items[0] as (typeof items)[number] | undefined;

  model.useController(virtualizer);

  const { list } = model;

  return (
    <div
      ref={parentRef}
      className={cx(styles.issuesList, { [styles.issuesListWide]: isSmallPadding })}
      style={{
        height: '100%',
        contain: 'strict',
      }}
    >
      <div
        style={{
          height: virtualizer.getTotalSize(),
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            transform: `translateY(${firstItem?.start ?? 0}px)`,
          }}
        >
          {list && items.map(virtualRow => <IssueCard key={virtualRow.key} model={list[virtualRow.index]} />)}
        </div>
      </div>
    </div>
  );
});
