import React from 'react';

import { Button, ButtonTypes } from '@writercolab/ui-atoms';

export const ThreeDotsLoader: React.FC<{
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
}> = ({ className }) => <Button className={className} isLoading type={ButtonTypes.TRANSPARENT} content="" />;
