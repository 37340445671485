import type { EventSourceMessage, FetchEventSourceInit } from '@fortaine/fetch-event-source';
import { fetchEventSource, EventStreamContentType } from '@fortaine/fetch-event-source';
import type { FetchResponse } from 'openapi-fetch';
import type { HttpMethod } from 'openapi-typescript-helpers';
import createClientOrigin from 'openapi-fetch';
import { ApiError, ApiStreamError } from '@writercolab/errors';
import type { pathsExtra } from './schema_extra';
import { applyMiddleware } from './utils/applyMiddleware';
import { header2obj } from './utils/header2obj';
import type { IApiOptions, TClient } from './utils/types';
import { streamIterator } from './utils/streamIterator';

export function guard<T>(resp: FetchResponse<T>): FetchResponse<T> {
  if ('error' in resp) {
    throw new ApiError(resp.error, resp.response);
  }

  return resp;
}

const FIELD = '__response_$_';

export function createApi({
  middleware,
  createClient = createClientOrigin,
  fetchEventSource: callFetchEventSource = fetchEventSource,
  fetch = globalThis.fetch,
  guard: guardMiddleware = true,
  ...clientOptions
}: IApiOptions) {
  type Paths = pathsExtra;

  const nativeFetch: typeof fetch = (url, init) =>
    fetch(url, init).then(response => {
      if (init?.headers && header2obj(init.headers)['accept'] === EventStreamContentType) {
        const headers = new Headers(response.headers);

        // don't allow parse response for text/event-stream
        headers.set('Content-Length', '0');

        const ret = new Response(response.body, {
          headers,
          status: response.status,
          statusText: response.statusText,
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (ret as any)[FIELD] = response;

        return ret;
      }

      return response;
    });
  const ffetch: typeof fetch = middleware
    ? (url, init) => applyMiddleware(middleware, url, init, nativeFetch)
    : nativeFetch;

  const apiOrigin = createClient<Paths>({
    fetch: ffetch,
    ...clientOptions,
  });

  const ret: Record<string, unknown> = {};

  const apiMethod = (init: RequestInit | undefined) => {
    const method = (init?.method?.toUpperCase() ?? 'GET') as Uppercase<HttpMethod>;

    const fn = apiOrigin[method];

    return fn as unknown as (
      url: RequestInfo | URL,
      init: RequestInit | undefined,
    ) => Promise<{ response: Response; error?: unknown }>;
  };

  // recover Content-Length header for streaming method
  const fetchStreamMethod: typeof fetch = (url, init) =>
    apiMethod(init)(url, init).then(({ response, error }) => {
      const hasField = FIELD in response;

      if (hasField) {
        return response[FIELD] as Response;
      }

      if (error) {
        throw new ApiError(error, response);
      }

      return response;
    });

  // patch use our custom fetch `fetchStreamMethod` for stream method
  const fetchEventSourceImpl: typeof fetchEventSource = (url, init) =>
    callFetchEventSource(url, { ...init, fetch: fetchStreamMethod });

  Object.keys(apiOrigin).forEach(k => {
    if (guardMiddleware) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ret[k] = (...args: any[]) => (apiOrigin as any)[k](...args).then(guard);
    }

    ret[k.toLowerCase()] = ret[k];
    const capKey = k.charAt(0).toUpperCase() + k.toLowerCase().slice(1);

    const streamMethod = (url: string, init: FetchEventSourceInit) =>
      fetchEventSourceImpl(url, {
        method: k.toUpperCase(),
        openWhenHidden: true,
        async onopen(resp) {
          if (!resp.ok && resp.status >= 300) {
            const text = await resp
              .clone()
              .text()
              .catch(() => resp.statusText);

            throw new ApiStreamError(text, resp);
          }
        },
        ...init,
      });
    ret[`stream${capKey}`] = streamMethod;

    const streamIterMethod = (url: string, init: FetchEventSourceInit) => {
      let cancel: undefined | ((ev: Event) => void);
      const { signal } = init;

      const { ctrl, iterator } = streamIterator<EventSourceMessage>(() =>
        streamMethod(url, {
          ...init,
          onclose() {
            if (cancel) {
              signal?.removeEventListener('abort', cancel);
            }

            cancel = undefined;

            ctrl.close();
          },
          onmessage: ev => {
            ctrl.resolve(ev);
          },
          onerror: err => {
            if (cancel) {
              signal?.removeEventListener('abort', cancel);
            }

            cancel = undefined;
            ctrl.reject(err);

            throw err;
          },
        }),
      );

      if (signal) {
        cancel = () => ctrl.reject(signal.reason);

        signal.addEventListener('abort', cancel);
      }

      return iterator;
    };

    ret[`streamIter${capKey}`] = streamIterMethod;
  });

  const api = ret as TClient<Paths>;

  return {
    api,
    fetch: ffetch,
    fetchEventSource: fetchEventSourceImpl,
  };
}
