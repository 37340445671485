import type { FC, ReactNode } from 'react';
import React from 'react';
import { Text, TextSize, Heading, HeadingVariant } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IFreeCancelledProps {
  header?: React.ReactNode;
  description?: React.ReactNode;
  children?: ReactNode;
}

const ExpiredPanelComponent: React.FC<IFreeCancelledProps> = ({ header, description, children }) => (
  <div className={styles.expiredContainer}>
    {header ? (
      <Heading variant={HeadingVariant.H3} bold>
        {header}
      </Heading>
    ) : null}
    {description ? (
      <div className={styles.expiredDescription}>
        <Text variant={TextSize.L}>{description}</Text>
      </div>
    ) : null}

    {children}
  </div>
);

const List: FC<{ children?: ReactNode }> = ({ children }) => <div className={styles.prosList}>{children}</div>;

export const ExpiredPanel = ExpiredPanelComponent as typeof ExpiredPanelComponent & { List: typeof List };

ExpiredPanel.List = List;
