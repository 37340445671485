
const digest = '0c53ce089892bbc3d98f8379ff90df76c953fac9d1e81552c97cebe13442267c';
const css = `.styles-module__dQQ2gcu {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.styles-module__dQQ2gcu.styles-module__38ISpQ- {
  margin-top: 0;
}

.styles-module__oiUZ7Nl {
  display: flex;
  gap: 4px;
  margin-right: auto;
  overflow: hidden;
}

.styles-module__dQQ2gcu .styles-module__gkltQy5 {
  background-color: var(--writer-green-3);
  font-weight: 500;
  height: 28px;
  border-radius: 14px;
}

.styles-module__dQQ2gcu .styles-module__gkltQy5:hover {
  background-color: var(--writer-green-4);
  color: var(--black) !important;
}

.styles-module__ZESzTHh {
  padding: 5px 10px;
  border-radius: 4px;
  color: var(--classic-grey-3);
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.3px;
  line-height: 15px;
  text-transform: uppercase;
  transition: var(--background-color-transition);
}

.styles-module__ZESzTHh:hover {
  background-color: var(--classic-blue-1);
  color: var(--classic-blue-4);
}

.styles-module__PZ-tKK4 {
  margin-block: 0;
  padding-inline-start: 5px;
}

.styles-module__CCTg2CI {
  display: flex;
  gap: 8px;
}

.styles-module__yCAkbdQ {
  display: flex;
  align-items: center;
  min-width: 28px;
  border-radius: 8px;
  gap: 4px;
  background-color: var(--writer-graynu-1);
}

.styles-module__AoYOSgu {
  padding: 5px;
}

.styles-module__DAXuIJL {
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 2px;
  background-color: var(--writer-graynu-1);
  cursor: pointer;
  opacity: 0.7;
  transition: var(--background-color-transition);
}

.styles-module__DAXuIJL:hover {
  background-color: var(--writer-graynu-2);
  opacity: 1;
}

.styles-module__Q5M2iHH {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 0;
}

.styles-module__cwwANR0 {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: var(--background-color-transition);
}

.styles-module__cwwANR0:hover {
  background-color: var(--writer-graynu-2);
}

.styles-module__YrtbCss {
  top: calc(100% + 5px) !important;
  width: calc(100vw - 222px);
  min-width: 136px;
  max-width: 232px;
}

.styles-module__j8NrUXw {
  width: 28px;
  height: 28px;
  padding: 7px;
  border-radius: 50%;
}

.styles-module__-WBCocy {
  top: 30px;
  right: -12px;

  &.styles-module__JmjpH-3 {
    top: 32px;
  }
}

.styles-module__XM-IElP {
  width: calc(100vw - 194px);
  min-width: 166px;
  max-width: 260px;
}

.styles-module__YrtbCss > div,
.styles-module__XM-IElP > div {
  white-space: normal;
}

.styles-module__YrtbCss > div:hover,
.styles-module__XM-IElP > div:hover {
  background-color: var(--writer-blue-2);
}
`;

export default {"controls":"styles-module__dQQ2gcu","controlsInverted":"styles-module__38ISpQ-","controlButtonsWithLabelContainer":"styles-module__oiUZ7Nl","acceptAllBtn":"styles-module__gkltQy5","learnMoreButton":"styles-module__ZESzTHh","itemsList":"styles-module__PZ-tKK4","actions":"styles-module__CCTg2CI","ignoreActions":"styles-module__yCAkbdQ","ignoreActionsWithPadding":"styles-module__AoYOSgu","controlButton":"styles-module__DAXuIJL","ignoreButtonFitsContainer":"styles-module__Q5M2iHH","ignoreArrow":"styles-module__cwwANR0","ignoreDropdownContainer":"styles-module__YrtbCss","moreButton":"styles-module__j8NrUXw","wrongSuggestionComment":"styles-module__-WBCocy","compact":"styles-module__JmjpH-3","dropdownContainer":"styles-module__XM-IElP"};
export { css, digest };
  