/* eslint-disable @typescript-eslint/no-explicit-any */
import { getLogger } from '@writercolab/utils';
import { setClipboardData } from './setClipboardData';

const LOG = getLogger('textUtils', '@writercolab-dom');

/**
 * Function for copying text and html (optionally) to clipboard
 * @param params
 * @return {boolean}
 */

export function copyToClipboard({ text = '', html = '' }: { text?: string; html?: string }): boolean {
  if ((window as any)?.clipboardData && (window as any)?.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    setClipboardData<any>(window, { text, html });

    return true;
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const copyListener = (event: ClipboardEvent) => {
      event.preventDefault();
      setClipboardData<ClipboardEvent>(event, { text, html });
    };

    document.addEventListener('copy', copyListener, false);

    const textarea = document.createElement('textarea');
    textarea.dataset['qordobaIgnore'] = 'true';
    textarea.textContent = text || html;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();

    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      LOG.warn('Copy to clipboard failed.', ex);

      return false;
    } finally {
      document.body.removeChild(textarea);
      document.removeEventListener('copy', copyListener, false);
    }
  }

  return false;
}
