
const digest = '5c83cc1cf1251ac3128e9c81e633f62dce92e8c3dfe3b0b026754829cdc9146f';
const css = `.styles-module__D6FI4Dm {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
}

.styles-module__or4y364 {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  border: 1px solid var(--writer-graynu-2);
  border-radius: var(--default-border-radius);
  margin-bottom: 11px;
  background: var(--classic-white);
  font-family: var(--font-default);
  resize: vertical;
}

.styles-module__or4y364:hover {
  border: 1px solid var(--writer-blue-3);
}

.styles-module__or4y364:focus {
  border: 1px solid var(--writer-blue-3);
  box-shadow: var(--input-box-shadow);
}

.styles-module__or4y364:focus-visible {
  border: 1px solid var(--writer-blue-3) !important;
  box-shadow: var(--input-box-shadow) !important;
  outline: none;
}

.styles-module__bV0hT6- {
  position: absolute;
  z-index: 2;
  right: 1px;
  bottom: 1px;
  padding: 3px;
  border-radius: 0 0 8px;
  background: var(--classic-white);
}

.styles-module__or4y364::-webkit-resizer {
  opacity: 0;
}

.styles-module__or4y364::placeholder {
  color: var(--writer-grey-3);
  font-weight: var(--font-default-weight);
  opacity: 0.8;
}

.styles-module__P9VwB7a.styles-module__w-FB5-g {
  border-radius: var(--default-border-radius);
  box-shadow: var(--menu-box-shadow) !important;
}

.styles-module__P9VwB7a.styles-module__w-FB5-g textarea {
  border: 1px solid var(--classic-orange-1) !important;
}

.styles-module__P9VwB7a {
  position: relative;
  display: grid;
}

.styles-module__P9VwB7a > textarea {
  overflow: hidden;
  font-size: var(--font-size-xl);
  line-height: 17px;
}

.styles-module__P9VwB7a > textarea[aria-hidden='true'] {
  padding: 0 0.7rem !important;
}

.styles-module__P9VwB7a > textarea,
.styles-module__P9VwB7a::after {
  padding: 0.9rem 0.6rem;
  border: 1px solid var(--writer-graynu-2);
  margin: 0;
  font: inherit;
  font-size: var(--font-size-xl);
  grid-area: 1 / 1 / 2 / 2;
  line-height: 17px;
}
`;

export default {"textareaContainer":"styles-module__D6FI4Dm","textareaContainerItem":"styles-module__or4y364","resizeIcon":"styles-module__bV0hT6-","textareaContainerGrowWrap":"styles-module__P9VwB7a","error":"styles-module__w-FB5-g"};
export { css, digest };
  