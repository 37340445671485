import React from 'react';
import { Enum } from '@writercolab/utils';
import { Heading, HeadingVariant, Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';
import imgIssueLookingGood from '@writercolab/assets/static/png/issue_looking_good.png';
import imgBeginWriting from '@writercolab/assets/static/png/begin_writing.png';

import styles from './styles.module.css';

export const NoIssuesVariant = new Enum('emptyDocument', 'noIssuesFound', 'noIntegration');

const NoIssuesState = NoIssuesVariant.hash({
  emptyDocument: {
    image: <img src={imgBeginWriting} alt="begin writing" width={148} />,
    title: 'Begin writing',
    description: 'We’ll let you know if your text has any issues.',
  },
  noIssuesFound: {
    image: <img src={imgIssueLookingGood} alt="looking good" width={148} />,
    title: 'Looking good',
    description: 'We’ve scanned your text for hundreds of issues and found none. Well done!',
  },

  noIntegration: {
    image: <Icon name={IconVariant.NO_INTEGRATION} />,
    title: 'Select a text field to get started',
    description: 'Activate your cursor in a text field or editor to see writing suggestions',
  },
});

interface INoIssuesProps {
  variant: typeof NoIssuesVariant.type;
  title?: string;
  description?: string;
}

export const NoIssues = ({ variant, title, description }: INoIssuesProps) => {
  const state = NoIssuesState[variant];

  return (
    <div className={styles.noIssuesContainer}>
      {state.image}

      <Heading variant={HeadingVariant.H4} className={styles.noIssuesTitle}>
        {title || state.title}
      </Heading>

      <Text variant={TextSize.M}>{description || state.description}</Text>
    </div>
  );
};
