import React from 'react';

import cx from 'clsx';

import styles from './IconButton.module.css';
import type { IconVariant } from '../Icon/iconsList';
import { Icon } from '../Icon';

interface IconButtonProps {
  className?: string;
  width?: number;
  height?: number;
  icon: IconVariant;
  iconWidth?: number;
  iconHeight?: number;
  noBackground?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  rounded?: boolean;
}

/**
 * @deprecated use WDS
 */
export const IconButton = React.forwardRef<HTMLDivElement, IconButtonProps>((props, ref) => {
  const {
    className,
    iconHeight,
    icon,
    iconWidth,
    height,
    width,
    rounded = true,
    onClick,
    noBackground,
    ...restProps
  } = props;

  return (
    <div
      ref={ref}
      className={cx(className, styles.iconButton, {
        [styles.noBackground]: noBackground,
        [styles.rounded]: rounded,
      })}
      style={{ width: `${width}px`, height: `${height}px` }}
      onClick={onClick}
      {...restProps}
    >
      <Icon name={icon} width={iconWidth} height={iconHeight} />
    </div>
  );
});

IconButton.displayName = 'IconButton';
