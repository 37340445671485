import type { cmudict } from '@writercolab/cmudict';
import { countSyllables } from './syllableCount';

/**
 * Calculates the average number of syllables per word in the given array of words.
 * @param words - An array of words to calculate the average number of syllables per word for.
 * @returns The average number of syllables per word in the given array of words.
 */
export function getSyllablesPerWord(words: string[], sylDict: typeof cmudict) {
  const syllables = words.reduce((sum, word) => {
    const count = countSyllables(word, sylDict);

    return sum + count;
  }, 0);

  return syllables / words.length;
}
