import qs from 'qs';

import { PaginatedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize, components } from '@writercolab/network';

import { getContentType, encodeFilename } from '@writercolab/file-utils';

import type {
  TApplicationVersionCreate,
  TConsoleApplication,
  TApplicationBriefResponse,
  TApplicationExpand,
  TApplicationExportResponse,
  TApplicationBriefPaginatedResponse,
  TPaginatedConsoleArgs,
  TPaginatedConsoleExtraArgs,
  TPaginatedConsoleQuery,
} from '@writercolab/types';

import { getLogger, extractBackendResponseErrorMessage } from '@writercolab/utils';

interface IConsoleApplicationsApiOpts {
  organizationId: number;
  request: RequestServiceInitialize['api'];
  initialPaginationArgs?: TPaginatedConsoleExtraArgs;
}

const LOG = getLogger('ApplicationsApiModel');

export const DEFAULT_PAGE_SIZE = 50;

export class ApplicationsApiModel {
  public pagination: PaginatedModel<
    TApplicationBriefPaginatedResponse,
    TApplicationBriefResponse,
    TPaginatedConsoleArgs,
    TPaginatedConsoleExtraArgs
  >;

  constructor(private readonly opts: IConsoleApplicationsApiOpts) {
    this.pagination = new PaginatedModel<
      TApplicationBriefPaginatedResponse,
      TApplicationBriefResponse,
      TPaginatedConsoleArgs,
      TPaginatedConsoleExtraArgs
    >({
      argsExtra: this.opts.initialPaginationArgs ?? {},

      argsDefault: {
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      },
      extractMeta: obj => {
        const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

        if (offset >= obj.totalCount) {
          return this.pagination.args;
        }

        return {
          offset,
          limit: DEFAULT_PAGE_SIZE,
        };
      },
      extract: obj => obj.result ?? [],
      load: async ({ args, extra }) => {
        const query: TPaginatedConsoleQuery = {
          ...args,
          ...extra,
        };

        const { data } = await this.opts.request.get('/api/template/organization/{organizationId}/v2/application', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
            },
            query,
          },
          querySerializer: query => qs.stringify(query, { arrayFormat: 'repeat' }),
        });

        return data;
      },
    });
  }

  async getApplicationWithDeployments(applicationId: string): Promise<TConsoleApplication> {
    try {
      return await this.opts.request
        .get('/api/template/organization/{organizationId}/application/{applicationId}/deployment', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data as TConsoleApplication);
    } catch (e) {
      LOG.error(e);
      throw e;
    }
  }

  async getApplicationForPreview(teamId: number, applicationId: string): Promise<TApplicationExpand> {
    try {
      return await this.opts.request
        .get(
          '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed/{applicationIdOrIdAlias}',
          {
            params: {
              path: {
                organizationId: this.opts.organizationId,
                teamId,
                applicationIdOrIdAlias: applicationId,
              },
            },
          },
        )
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);
      throw e;
    }
  }

  deleteApp = async (id: string) => {
    try {
      await this.opts.request.delete(`/api/template/organization/{organizationId}/application/{applicationId}`, {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            applicationId: id,
          },
        },
      });
    } catch (e) {
      LOG.error(e);
      throw e;
    }
  };

  duplicateApp = async (applicationId: string) => {
    try {
      await this.opts.request.post(
        '/api/template/organization/{organizationId}/application/{applicationId}/duplicate',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        },
      );
    } catch (e) {
      LOG.error(e);
      throw e;
    }
  };

  exportApp = async (applicationId: string): Promise<Blob> => {
    try {
      const { data } = await this.opts.request.get(
        '/api/template/organization/{organizationId}/application/{applicationId}/export',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
          parseAs: 'blob',
        },
      );

      return data as unknown as Blob;
    } catch (e) {
      LOG.error(e);

      throw new Error(extractBackendResponseErrorMessage(e));
    }
  };

  uploadAvatar = async (file: File, applicationId: string) =>
    this.opts.request
      .post('/api/template/organization/{organizationId}/application/{applicationId}/avatar/upload', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            applicationId,
          },
          header: {
            'Content-Type': getContentType(file),
            'Content-Disposition': `attachment; filename*="${encodeFilename(file.name)}"`,
            'Content-Length': file.size,
          },
        },
        body: file as unknown as string,
        bodySerializer: body => body,
      })
      .then(res => res.data);

  // New endpoints
  createApplicationAndVersion = async (data: TApplicationVersionCreate): Promise<TConsoleApplication> => {
    try {
      const res = await this.opts.request.post(`/api/template/organization/{organizationId}/v2/application`, {
        params: {
          path: {
            organizationId: this.opts.organizationId,
          },
        },
        body: data,
      });

      return res.data;
    } catch (e) {
      LOG.error(e);
      throw e;
    }
  };

  async updateApplicationData(applicationId: string, data: components['schemas']['template_model_ApplicationData']) {
    try {
      return await this.opts.request
        .put('/api/template/organization/{organizationId}/application/{applicationId}/data', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
          body: {
            data,
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);
      throw e;
    }
  }

  async updateApplicationMetadata(
    applicationId: string,
    data: components['schemas']['template_model_ApplicationVersionUpdate'],
  ) {
    try {
      return await this.opts.request
        .put('/api/template/organization/{organizationId}/application/{applicationId}/metadata', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
          body: data,
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);
      throw e;
    }
  }

  async duplicateApplication({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .post('/api/template/organization/{organizationId}/application/{applicationId}/duplicate', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  async importApplication(application: TApplicationExportResponse) {
    try {
      return await this.opts.request
        .post('/api/template/organization/{organizationId}/application/import', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
            },
          },
          body: application,
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  // Unlock application
  async createAppVersionDraft({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .post('/api/template/organization/{organizationId}/application/{applicationId}/v2/version', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  // Cancel application draft
  async deleteAppLatestVersionDraft({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .delete('/api/template/organization/{organizationId}/application/{applicationId}/v2/version', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  // Publish application draft
  async publishAppVersionDraft({
    applicationId,
    applicationVersionId,
    applicationVersionDataId,
  }: {
    applicationId: string;
    applicationVersionId?: string | null;
    applicationVersionDataId?: string | null;
  }) {
    try {
      return await this.opts.request
        .put('/api/template/organization/{organizationId}/application/{applicationId}/publish', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
          body: {
            applicationVersionId,
            applicationVersionDataId,
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  // Playground deployment
  async deployPlaygroundApp({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .post('/api/template/organization/{organizationId}/application/{applicationId}/deployment/playground', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  async undeployPlaygroundApp({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .delete('/api/template/organization/{organizationId}/application/{applicationId}/deployment/playground', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  // Embed deployment
  async deployEmbedApp({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .post('/api/template/organization/{organizationId}/application/{applicationId}/deployment/embed', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
          body: {},
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  async undeployEmbedApp({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .delete('/api/template/organization/{organizationId}/application/{applicationId}/deployment/embed', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  async updateEmbedDeployment({
    applicationId,
    data,
  }: {
    applicationId: string;
    data: components['schemas']['template_dto_DeployEmbedApplicationRequest'];
  }) {
    try {
      return await this.opts.request
        .put('/api/template/organization/{organizationId}/application/{applicationId}/deployment/embed', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
          body: data,
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  async refreshEmbedToken({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .put('/api/template/organization/{organizationId}/application/{applicationId}/deployment/embed/refresh', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  // Slack deployment
  async deploySlackApp({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .post('/api/template/organization/{organizationId}/application/{applicationId}/deployment/slack', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  async undeploySlackApp({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .delete('/api/template/organization/{organizationId}/application/{applicationId}/deployment/slack', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  async getIsSlackEnabled() {
    try {
      return await this.opts.request
        .get('/api/organization/v2/{organizationId}/slack-enabled', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  // Writer deployment
  async deployWriterApp({
    applicationId,
    data,
  }: {
    applicationId: string;
    data: components['schemas']['template_model_WriterDeploymentRequest'];
  }) {
    try {
      return await this.opts.request
        .post('/api/template/organization/{organizationId}/application/{applicationId}/deployment/writer', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
          body: data,
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  async updateWriterDeployment({
    applicationId,
    data,
  }: {
    applicationId: string;
    data: components['schemas']['template_model_WriterDeploymentRequest'];
  }) {
    try {
      return await this.opts.request
        .put('/api/template/organization/{organizationId}/application/{applicationId}/deployment/writer', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
          body: data,
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  async undeployWriterApp({ applicationId }: { applicationId: string }) {
    try {
      return await this.opts.request
        .delete('/api/template/organization/{organizationId}/application/{applicationId}/deployment/writer', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);

      throw e;
    }
  }

  protectApp = async (
    applicationId: string,
    body: {
      protected: boolean;
    },
  ) => {
    try {
      return await this.opts.request
        .put(`/api/template/organization/{organizationId}/application/{applicationId}/protect`, {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              applicationId,
            },
          },
          body,
        })
        .then(res => res.data);
    } catch (e) {
      LOG.error(e);
      throw e;
    }
  };

  get isLoading() {
    return this.pagination.status === 'pending';
  }

  get data(): TApplicationBriefResponse[] {
    return this.pagination.value ?? [];
  }

  get emptyData(): boolean {
    return this.data.length === 0;
  }

  refresh = async () => {
    this.pagination.reload();
    await this.pagination.promise;
  };
}
