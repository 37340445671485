import type Delta from 'quill-delta';

import type { Persistence, User } from 'firebase/auth';
import type { DocumentReference, FirestoreSettings } from 'firebase/firestore';
import { Enum } from '@writercolab/utils';
import type { IFirebaseIssue, IStyleguide, IssuesPipeline, TUserNotification } from '@writercolab/types';

export const COLLECTION = new Enum('issue-v2', 'viewers', 'field');

export const FIELDS = new Enum('core', 'data');

export interface ISidebarDataParams {
  organizationId: () => number | string | undefined;
  teamId: () => number | string | undefined;
  documentId: () => string | undefined;
  personaId: string | number;
  userId: () => string | number | undefined;
}

export interface ISidebarDataApi {
  getDocumentDelta: (() => Promise<Delta>) | undefined;
  updateDocumentUser: ((user: ICollaborationUserWithMeta) => Promise<void>) | undefined;
  getDocumentPipeline: (() => Promise<IssuesPipeline | undefined>) | undefined;
  onContentHashes: ((fn: (data: IPipelineContentHashes) => void) => () => void) | undefined;
  onDocumentDelta: ((fn: (d: Delta) => void) => () => void) | undefined;
  onDocumentContentSubscriber: ((fn: (data: string) => void) => () => void) | undefined;
  onViewerSubscriber: ((fn: (data: ICollaborationUserWithMeta[]) => void) => () => void) | undefined;
  onIssuesSubscriber: ((fn: (issues: IFirebaseIssue[]) => void) => () => void) | undefined;
  onStyleguideSubscriber: ((fn: (data: IStyleguide) => void) => () => void) | undefined;
  onNotificationsSubscriber: ((fn: (notifications: TUserNotification[]) => void) => () => void) | undefined;
}

export interface SidebarDataClient {
  user: Pick<User, 'uid'> | undefined;
  api: ISidebarDataApi | undefined;
  params: ISidebarDataParams;
}

export interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseId?: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export interface IFirestoreSettings extends FirestoreSettings {
  useFetchStreams?: boolean;
}

export interface IFirebaseSettings {
  firebaseStatePersistenceType?: Persistence;
  firestoreSettings?: IFirestoreSettings;
  recaptchaEnterpriseKey?: string;
  useAppCheck?: boolean;
}

export interface IStyleguideMapping {
  userId: number;
  styleguideId: string;
  styleguideRef: DocumentReference;
}

export interface IPipelineContentHashes {
  basic: string;
  claim: string;
  full: string;
  plagiarism: string;
}

export interface ICollaborationUser {
  id: string;
  fullName: string;
  email: string | null;
  avatar?: string;
  isTyping?: boolean;
}

export interface ICollaborationUserWithMeta extends ICollaborationUser {
  sessionId: string;
  lastViewed?: number;
  lastTyping?: number;
}
