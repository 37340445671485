import React from 'react';

import cx from 'clsx';

import { copyToClipboard } from '@writercolab/dom';
import { Button, ButtonTypes, Heading, HeadingVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';
import type { BaseComponentsProps } from '@writercolab/types';

import draftsZeroState from '@writercolab/assets/static/backgrounds/draftsZeroStateBackground.png';
import emptyPagePath from '@writercolab/assets/static/backgrounds/emptyPage.png';
import sadCharacter from '@writercolab/assets/static/backgrounds/sadCharacter.png';

import styles from './Unreachable.module.css';

import { CopyBox } from '../CopyBox';

interface IUnreachable extends BaseComponentsProps {
  type: typeof UnreachableType.type;
  hash?: string;
  dashboardRouteHref?: string;
  onCopySuccess?: () => void;
}

export const UnreachableType = new Enum(
  'error404page',
  'error404docs',
  'noDocs',
  'draftsListEmpty',
  'draftsListZeroState',
  'noResultsEmpty',
  'appCrash',
);

export const Unreachable: React.FC<IUnreachable> = ({ type, hash, dashboardRouteHref, onCopySuccess }) => {
  const handleCopy = (data: { text?: string; html?: string }) => {
    copyToClipboard(data);
    onCopySuccess?.();
  };

  switch (type) {
    case UnreachableType.enum.error404page:
      return (
        <div className={cx(styles.container, styles.variationPage)}>
          <Text className={styles.errorMessage} bold>
            404
          </Text>
          <Text className={styles.description} bold>
            This link isn’t quite right...
          </Text>
          <Text className={styles.navigation} variant={TextSize.XL}>
            Check the link, or head to the <a href={dashboardRouteHref}>Dashboard</a>
          </Text>
          <img src={sadCharacter} alt="Empty page" width={215} height={215} />
        </div>
      );

    case UnreachableType.enum.error404docs:
      return (
        <div className={styles.container}>
          <Text className={styles.errorMessage} bold>
            404
          </Text>
          <Text className={styles.description} bold>
            This link isn’t quite right...
          </Text>
          <Text className={styles.navigation} variant={TextSize.XL}>
            Check the link, or search for your doc on the <a href={dashboardRouteHref}>Dashboard</a>
          </Text>
          <img src={emptyPagePath} alt="Empty page" width={164} height={180} />
        </div>
      );

    case UnreachableType.enum.noDocs:
      return (
        <div className={styles.container}>
          <Text className={cx(styles.errorMessage, styles.noDocsMessage)} bold>
            No docs yet
          </Text>
          <Text className={styles.navigation} variant={TextSize.XL}>
            Create a new doc to get started
          </Text>
          <img src={emptyPagePath} alt="Empty page" width={164} height={180} />
        </div>
      );

    case UnreachableType.enum.draftsListEmpty:
      return (
        <div className={cx(styles.container, styles.noResultsContainer)}>
          <img src={emptyPagePath} alt="Empty page" width={164} height={180} />

          <Text variant={TextSize.XXXXL} color={TextColor.GREY2}>
            No results. Want to start with a new draft?
          </Text>
        </div>
      );

    case UnreachableType.enum.noResultsEmpty:
      return (
        <div className={cx(styles.container, styles.noResultsContainer)}>
          <img src={emptyPagePath} alt="Empty page" width={164} height={180} />

          <Text variant={TextSize.XXXL} color={TextColor.GREY2}>
            No results
          </Text>
        </div>
      );

    case UnreachableType.enum.draftsListZeroState:
      return (
        <div className={cx(styles.container, styles.noResultsContainer)}>
          <img src={draftsZeroState} alt="Zero state" width={275} height={275} />
        </div>
      );

    case UnreachableType.enum.appCrash:
      return (
        <div className={cx(styles.appCrash)}>
          <img src={sadCharacter} alt="Empty page" width={215} height={215} />
          <div className={styles.content}>
            <Heading className={styles.heading} variant={HeadingVariant.H2} bold>
              Oops something went wrong
            </Heading>
            <Text className={styles.errorDescription} variant={TextSize.XL}>
              Try to reload or email us at{' '}
              <a href="mailto:support@writer.com" className={styles.mailtoHref}>
                support@writer.com
              </a>{' '}
              with the following hash, our team will assist with resolving the issue
            </Text>
            {hash && <CopyBox content={hash} contentDescription="Hash" onCopy={handleCopy} />}
            {/* eslint-disable-next-line no-restricted-globals  */}
            <Button className={styles.reloadButton} type={ButtonTypes.PRIMARY} onClick={() => location.reload()}>
              Reload
            </Button>
          </div>
        </div>
      );

    default:
      return <div></div>;
  }
};
