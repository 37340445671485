import type { ContextHelper } from './types';
import type { Adapter } from '../adapters';
import type { IAnalyticsUnionTrack } from '../types';
import { getLogger } from '../utils/logger';
import { type Context, SystemContext, type TSystemContextBase } from '../context';

const LOG = getLogger('AnalyticsServiceBase');

export type TAdapterBase<TParamsActivity extends Record<string, unknown>, TContext, TOtherAdapters> = Adapter &
  (IAnalyticsUnionTrack<TParamsActivity, TContext> | TOtherAdapters);

export interface IAnalyticsOptions<TContext> {
  integrationType: TSystemContextBase['integration_type'];
  clientId?: TSystemContextBase['client_id'];
  context?: Context<TContext & TSystemContextBase>;
}

/**
 * Service for handling analytics tracking and event logging.
 *
 * @template TContext - The context type for the analytics service, which includes integration type and client ID.
 *
 * @example
 * ```typescript
 * const analyticsService = new AnalyticsServiceBase([...], { integrationType: 'integrationType', clientId: 'clientId' });
 * analyticsService.track('eventName', { property: 'value' });
 * ```
 */
export class AnalyticsServiceBase<TParamsActivity extends Record<string, unknown>, TContext, TOtherAdapters>
  implements IAnalyticsUnionTrack<TParamsActivity, TContext>
{
  /**
   * internal context
   */
  protected readonly ctx: Context<TContext & TSystemContextBase>;

  /**
   * Constructs an instance of AnalyticsService.
   *
   * @param adapters - The adapters or API used for tracking events.
   * @param integrationType - The type of integration.
   * @param clientId - The client ID, defaults to unknown if not provided.
   */
  constructor(
    protected adapters: TAdapterBase<TParamsActivity, TContext, TOtherAdapters>[],
    options: IAnalyticsOptions<TContext>,
  ) {
    this.ctx =
      options.context ??
      new SystemContext<TContext>({ integrationType: options.integrationType, clientId: options.clientId });
  }

  /**
   * Adds additional context to the current instance of AnalyticsService.
   *
   * @template TNewContext - The additional context type.
   * @template TNewContext - The new context type, which extends the original context.
   *
   * @param ctx - The additional context.
   *
   * @returns A new instance of AnalyticsService with the additional context.
   */
  withContext<TNewContext, TransformContext = 'skip'>(
    ctx: ContextHelper<TNewContext>,
    transform?: TransformContext extends 'skip' ? void : (ctx: TContext) => TransformContext,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newContext = this.ctx.clone(ctx, transform as any);

    return new AnalyticsServiceBase<
      TParamsActivity,
      TransformContext extends 'skip' ? TContext & TNewContext : TransformContext & TNewContext,
      TOtherAdapters
    >(this.adapters as TAdapterBase<TParamsActivity, unknown, TOtherAdapters>[], {
      integrationType: this.integrationType,
      clientId: this.clientId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      context: newContext as any,
    });
  }

  /**
   * Adds an adapter to the analytics system.
   *
   * @param adapter - An array of adapters to be added.
   * @returns A function that, when called, removes the added adapter from the list of adapters.
   */
  addAdapter(adapter: TAdapterBase<TParamsActivity, TContext, TOtherAdapters>) {
    this.adapters.push(adapter);

    return () => {
      const list = this.adapters.filter(p => p !== adapter);

      if (list.length !== this.adapters.length) {
        this.adapters = list;
      }
    };
  }

  /**
   * Tracks an event with the specified name and properties.
   *
   * @template T - The type of the analytic event.
   *
   * @param eventName - The name of the event.
   * @param activityProperties - The properties of the event.
   *
   * @returns A promise that resolves when the event has been tracked.
   */
  async track<T extends keyof TParamsActivity>(
    eventName: T,
    activityProperties: {
      [K in keyof Omit<TParamsActivity[T], keyof TContext>]: Omit<TParamsActivity[T], keyof TContext>[K];
    },
  ): Promise<void> {
    LOG.debug('track', { eventName, activityProperties });
    const properties = {
      ...activityProperties,
      ...this.ctx.context,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await Promise.all(this.adapters.map(p => ('track' in p ? p.track(eventName, properties as any) : undefined)));
  }

  /**
   * Tracks an anonymous event with the specified name.
   *
   * @template T - The type of the analytic event.
   *
   * @param eventName - The name of the event.
   *
   * @returns A promise that resolves when the event has been tracked.
   */
  async trackAnonymousEvent<T extends keyof TParamsActivity>(
    eventName: T,
    activityProperties?: {
      [K in keyof Omit<TParamsActivity[T], keyof TContext>]: Omit<TParamsActivity[T], keyof TContext>[K];
    },
  ): Promise<void> {
    LOG.debug('trackAnonymousEvent', { eventName });

    await Promise.all(
      this.adapters.map(p =>
        'trackAnonymousEvent' in p
          ? p.trackAnonymousEvent(eventName, {
              ...activityProperties,
              ...this.ctx.context,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any)
          : undefined,
      ),
    );
  }

  /**
   * Tracks an organization event with the specified organization ID, event name, and properties.
   *
   * @template T - The type of the analytic event.
   *
   * @param organizationId - The ID of the organization.
   * @param eventName - The name of the event.
   * @param activityProperties - The properties of the event.
   *
   * @returns A promise that resolves when the event has been tracked.
   */
  async trackOrganizationEvent<T extends keyof TParamsActivity>(
    organizationId: number,
    eventName: T,
    activityProperties: {
      [K in keyof Omit<TParamsActivity[T], keyof TContext>]: Omit<TParamsActivity[T], keyof TContext>[K];
    },
  ): Promise<void> {
    LOG.debug('trackOrganizationEvent', { organizationId, eventName, activityProperties });

    const properties = {
      ...activityProperties,
      ...this.ctx.context,
    };
    await Promise.all(
      this.adapters.map(p =>
        'trackOrganizationEvent' in p ? p.trackOrganizationEvent(organizationId, eventName, properties) : undefined,
      ),
    );
  }

  /**
   * Gets the client ID from the context.
   *
   * @returns The client ID.
   */
  get clientId() {
    return this.ctx.context.client_id as TSystemContextBase['client_id'];
  }

  /**
   * Gets the integration type from the context.
   *
   * @returns The integration type.
   */
  get integrationType() {
    return this.ctx.context.integration_type as TSystemContextBase['integration_type'];
  }
}
