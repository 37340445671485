import { makeObservable, computed } from 'mobx';
import { FieldModel } from './FieldModel';
import { FieldListModel } from './FieldListModel';

export interface ITreeValue<T> {
  [key: string]: ITreeValue<T> | T;
}

type TFieldModelBase = {
  value: unknown;
  error: string | undefined | ITreeValue<string | undefined>;
  touched: boolean | ITreeValue<boolean>;
  busy: boolean;
};

const MAGIC_FORM = Symbol('MAGIC_FORM');

export type TMagicForm<TForm extends Record<string, TFieldModel>, TFieldModel extends TFieldModelBase> = {
  /**
   * @deprecated please don't use it, for reference to form use FormModel.form(...)
   */
  form: TForm;
} & {
  [K in keyof TForm]: TForm[K] extends FormModel<infer A, infer B> ? TMagicForm<A, B> : TForm[K];
};

export class FormModel<TForm extends Record<string, TFieldModel>, TFieldModel extends TFieldModelBase> {
  static build<TForm extends Record<string, TFieldModel>, TFieldModel extends TFieldModelBase>(
    build: (b: {
      field: typeof FieldModel.build;
      fieldAsync: typeof FieldModel.buildAsync;
      fieldList: typeof FieldListModel.build;
      fieldListAsync: typeof FieldListModel.buildAsync;
      form: <TForm1 extends Record<string, TFieldModel1>, TFieldModel1 extends TFieldModelBase>(
        form: TForm1,
      ) => FormModel<TForm1, TFieldModel1>;
    }) => TForm,
  ) {
    return new FormModel(
      build({
        field: FieldModel.build,
        fieldAsync: FieldModel.buildAsync,
        form: form => new FormModel(form),
        fieldList: FieldListModel.build,
        fieldListAsync: FieldListModel.buildAsync,
      }),
    );
  }

  static form<TForm extends Record<string, TFieldModel>, TFieldModel extends TFieldModelBase>(
    magicForm: TMagicForm<TForm, TFieldModel>,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (magicForm as any)[MAGIC_FORM] as FormModel<TForm, TFieldModel>;
  }

  public value!: {
    [K in keyof TForm]: Exclude<TForm[K]['value'], undefined>;
  };

  public touched!: { [K in keyof TForm]: TForm[K]['touched'] };
  public readonly error!: undefined | ITreeValue<string | undefined>;
  public readonly busy!: boolean;
  public readonly form: TMagicForm<TForm, TFieldModel>;

  private constructor(form: TForm) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const magicForm: any = {
      [MAGIC_FORM]: this,
      form,
    };

    Object.keys(form).forEach(key => {
      const item = form[key];

      if (item instanceof FormModel) {
        magicForm[key] = item.form;
      } else {
        magicForm[key] = item;
      }
    });

    this.form = magicForm;

    Object.defineProperties(this, {
      value: {
        enumerable: true,
        configurable: true,
        get() {
          const ret: Record<string, unknown> = {};
          Object.entries(form).forEach(([key, ref]) => {
            ret[key] = ref.value;
          });

          return ret;
        },
        set(v) {
          v &&
            Object.entries(v).forEach(([key, v]) => {
              if (key in form) {
                form[key].value = v;
              }
            });
        },
      },
      error: {
        enumerable: true,
        configurable: true,
        get() {
          const ret: Record<string, string | undefined | ITreeValue<string | undefined>> = {};
          let hasError = false;
          Object.entries(form).forEach(([key, ref]) => {
            const { error } = ref;

            if (error) {
              hasError = true;
              ret[key] = error;
            }
          });

          return hasError ? ret : undefined;
        },
      },
      touched: {
        enumerable: true,
        configurable: true,
        get() {
          const ret: Record<string, boolean | ITreeValue<boolean>> = {};
          Object.entries(form).forEach(([key, ref]) => {
            ret[key] = ref.touched;
          });

          return ret;
        },
        set(v) {
          v &&
            Object.entries(v).forEach(([key, val]) => {
              if (key in form) {
                form[key].touched = val as boolean | ITreeValue<boolean>;
              }
            });
        },
      },
      busy: {
        enumerable: true,
        configurable: true,
        get: () => !Object.values(form).every(ref => ref.busy === false),
      },
    });

    makeObservable(this, {
      value: computed.struct,
      error: computed.struct,
      touched: computed.struct,
      touchedForm: computed,
      errors: computed.struct,
      busy: computed,
    });
  }

  get touchedForm() {
    return findTrue(this.touched);
  }

  get errors(): undefined | Record<string, string> {
    if (!this.error) {
      return undefined;
    }

    const errors = reduceString(this.error);

    return Object.keys(errors).length ? errors : undefined;
  }
}

function findTrue(tree: ITreeValue<boolean>): boolean {
  return !!Object.values(tree).find(item => (typeof item === 'boolean' ? item : !!findTrue(item)));
}

function reduceString(tree: ITreeValue<string | undefined>, name: string[] = [], errors: Record<string, string> = {}) {
  Object.entries(tree).forEach(([k, v]) => {
    if (typeof v === 'string') {
      errors[name.concat(k).join('.')] = v;
    } else if (v) {
      reduceString(v, name.concat(k), errors);
    }
  });

  return errors;
}
