import type { ChangeEvent, SyntheticEvent } from 'react';
import React from 'react';
import cx from 'clsx';
import styles from './styles.module.css';
import { Icon, IconVariant } from '../Icon';

export interface SearchBarProps {
  keyword: string;
  setKeyword: (keyword: string) => void;
  className?: string;
}

export const SidebarSearchBar: React.FC<SearchBarProps> = ({ keyword, setKeyword, className }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.nativeEvent.stopImmediatePropagation();
    setKeyword(e.target.value);
  };

  // we need use stopImmediatePropagation to prevent events from external sites such as notion.so, atlassian.net
  const handleStopImmediatePropagation = (e: SyntheticEvent) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div className={cx(styles.container, className)}>
      <Icon name={IconVariant.SEARCH} className={styles.searchIcon} />
      <input
        className={styles.searchbar}
        placeholder="Search"
        value={keyword}
        onChange={handleChange}
        onKeyDown={handleStopImmediatePropagation}
        onFocus={handleStopImmediatePropagation}
        onBlur={handleStopImmediatePropagation}
        onMouseDown={handleStopImmediatePropagation}
        onClick={handleStopImmediatePropagation}
      />
    </div>
  );
};

export default SidebarSearchBar;
