import { getLogger } from './logger';

const logger = getLogger('requiredObject', '@writercolab/utils');

type TPartial<T> = {
  [P in keyof T]: T[P] | undefined | null;
};

/**
 * Validates that a partial object has all required properties with non-null and non-undefined values.
 * @param obj - The partial object to validate.
 * @param test - An optional function that can be used to perform additional tests on each key-value pair.
 * If this function returns false for a given key-value pair, the function will return undefined.
 * @returns The input object, cast to its full type, if all required properties have non-null and non-undefined values,
 * or undefined otherwise.
 */
export function requiredObject<T extends object>(
  obj: TPartial<T>,
  test: <TKey extends keyof T>(key: TKey, value: T[TKey]) => boolean = (_, value) =>
    value !== undefined && value !== null,
) {
  for (const [key, value] of Object.entries(obj)) {
    if (!test(key as keyof T, value as T[keyof T])) {
      logger.debug(`Test for key:${key} and value:(${value}) failed`);

      return undefined;
    }
  }

  return obj as T;
}
