import type { TeamId } from '@writercolab/types';
import type { OrganizationId } from './Organization';

export enum DocumentHighlightType {
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  META = 'meta',
  POINTS = 'points',
}

export interface DocumentHighlightEntry {
  text: string;
  userId: number;
  hash: string;
  createdAt: string;
}

export const HIGHLIGHTS_SORTING_ORDER: DocumentHighlightType[] = [
  DocumentHighlightType.META,
  DocumentHighlightType.POINTS,
  DocumentHighlightType.LINKEDIN,
  DocumentHighlightType.TWITTER,
];

export interface DocumentHighlightEntryExtended extends DocumentHighlightEntry {
  id: string;
  text: string;
  userId: number;
  hash: string;
  createdAt: string;
  type: DocumentHighlightType;
  loading?: boolean;
  upToDate?: boolean;
}

export interface DocumentHighlights {
  type: DocumentHighlightType;
  entry: DocumentHighlightEntry[];
  userId: number;
  version: number;
  loading?: boolean;
}

export interface DocumentHighlightsExtended extends DocumentHighlights {
  id: string;
  loading?: boolean;
  error?: boolean;
  upToDate?: boolean;
  entry: DocumentHighlightEntryExtended[];
}

export interface DocumentHighlightsGenerate {
  highlights: {
    id: number;
    organizationId: string | OrganizationId;
    teamId: string | TeamId;
    documentId: number;
    highlights: DocumentHighlights[];
  };
}
