import React from 'react';
import cx from 'classnames';

import { TermExampleType } from '@writercolab/common-utils';
import { Icon, IconVariant } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

const Description = ({ description, examples, className }) => (
  <div className={cx(styles.description, styles.scrollbar, className)}>
    <span dangerouslySetInnerHTML={{ __html: description || '' }}></span>
    {examples &&
      examples.map((item, index) => (
        <div key={item.type + index} className={styles.examplesContainer}>
          <span className={styles.examplesIcon}>
            {item.type === TermExampleType.Good ? (
              <Icon name={IconVariant.GREEN2_CHECKMARK} />
            ) : (
              <Icon name={IconVariant.X_MARK_CIRCLE_ORANGE} />
            )}
          </span>
          <span>{item.example}</span>
        </div>
      ))}
  </div>
);

export default Description;
