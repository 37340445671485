/**
 * Generates the URL for the snippets page within a style guide.
 *
 * @param appRootUrl - The root URL of the application.
 * @param orgId - The ID of the organization.
 * @param teamId - The ID of the team.
 * @param queryParams - Optional query parameters to be appended to the URL.
 * @returns The complete URL for the snippets page.
 */
export function getSnippetsPageUrl(
  appRootUrl: string,
  orgId: string | number,
  teamId: string | number,
  queryParams?: Record<string, string>,
): string {
  let snippetsPage = `${appRootUrl}/organization/${orgId}/team/${teamId}/styleguide/snippets`;

  if (queryParams) {
    const searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach(k => searchParams.append(k, queryParams[k]));
    snippetsPage += `?${searchParams.toString()}`;
  }

  return snippetsPage;
}
