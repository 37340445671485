
const digest = 'ec1f3f81b840ce75b9c6b7d114c3543242e623fc7be9be4a97be610001ee50cd';
const css = `.styles-module__P6lZi0n {
  border: 1px solid transparent;
  border-radius: 8px;
  background: var(--writer-graynu-1);
  transition: background-color 0.3s, border-color 0.5s, outline-width 0.1s;
}

.styles-module__OHlYsLC {
  width: 18px;
  height: 18px;
  margin: 0 0 2px 11px;
}

.styles-module__oqNR7CZ {
  height: auto;
  padding: 8px 6px;
  font-family: var(--font-default);
  font-size: 16px;
  line-height: 24px;
}

.styles-module__oqNR7CZ::placeholder {
  color: var(--writer-grey-3);
  font-weight: 400;
  opacity: 0.8;
}

.styles-module__Zs6NTuZ path {
  fill: var(--writer-grey-3);
}

.styles-module__oqNR7CZ::-webkit-search-cancel-button {
  display: none;
}

.styles-module__l9BXL89 {
  background: #fafafa;
  color: var(--writer-grey-3);
}

.styles-module__P6lZi0n:hover {
  border: 1px solid var(--writer-blue-3);
  background: var(--white);
}

.styles-module__M1iJnfE {
  border: 1px solid var(--writer-blue-3) !important;
  background: var(--classic-white) !important;
  outline: 4px solid var(--writer-blue-1);
}

.styles-module__g0g9fWs {
  border: 1px solid var(--classic-orange-1);
  box-shadow: var(--menu-box-shadow);
}

.styles-module__5ODgMOh {
  border: 1px solid var(--writer-graynu-2);
  background: var(--classic-white);
}

.styles-module__UukzOlC {
  width: 16px !important;
  height: 16px !important;
  border: none !important;
  margin-right: 11px;
  background-color: var(--writer-graynu-1) !important;
}

.styles-module__UukzOlC:hover {
  background-color: var(--writer-graynu-2) !important;
  cursor: pointer;
}

.styles-module__P6lZi0n input {
  font-family: var(--font-default);
}

.styles-module__AzuovcV {
  display: flex;
  flex-direction: column;
}

.styles-module__pfDHf4i {
  margin-top: 16px;
  margin-left: 18px;
}

.styles-module__rxWOcJu {
  margin-top: 19px;
  margin-left: 18px;
  background-color: var(--writer-blue-2) !important;
  background-image: linear-gradient(90deg, var(--writer-blue-2), var(--writer-blue-1), var(--writer-blue-2)) !important;
}
`;

export default {"searchInputWrapper":"styles-module__P6lZi0n","inputSearchIcon":"styles-module__OHlYsLC","searchInput":"styles-module__oqNR7CZ","inputSearchIconInactive":"styles-module__Zs6NTuZ","disabledSearch":"styles-module__l9BXL89","focused":"styles-module__M1iJnfE","error":"styles-module__g0g9fWs","complete":"styles-module__5ODgMOh","closeButton":"styles-module__UukzOlC","magicLinks":"styles-module__AzuovcV","magicLinksText":"styles-module__pfDHf4i","magicLinksEnd":"styles-module__rxWOcJu"};
export { css, digest };
  