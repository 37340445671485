import type {
  IFirebaseIssue,
  IIssue,
  IIssueMeta,
  ISidebarCategory,
  TOrgTeamUserActivityParams,
} from '@writercolab/types';
import { CategoryType, IssueCardType, IssueCategory, IssueFlag, IssueType } from '@writercolab/types';
import { computeSegmentFromPositions } from '@writercolab/text-utils';
import { createCacheFactory } from '@writercolab/utils';
import type { IAnalyticsTrack } from '@writercolab/analytics';
import { createAtomSubscriber, Subscriber } from '@writercolab/mobx';
import type { Emitter } from 'nanoevents';
import { action, comparer, computed, type IObservableValue, makeObservable, observable, reaction } from 'mobx';
import {
  CATEGORIES_IGNORED_FROM_SIDEBAR,
  ISSUE_TYPES_IGNORED_FROM_SIDEBAR,
  NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
  PLAGIARISM_MIN_MATCH_PERCENT,
} from './constants';
import type { ICategoriesModel } from '../categories';
import { inRange } from '../utils/inRange';
import { getLogger } from '../logger';
import type { IIssuesModel, TSelectedIssueContext } from '../utils';
import { IssueUpdateType } from '../utils';
import type { TUISidebarModelsAnalyticsParams } from '../../analytics';
import { UISidebarModelsAnalyticsActivity } from '../../analytics';

const LOG = getLogger('IssuesModel');

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined;

export type TState = {
  selected: IIssue | undefined;
  list: IIssue[];
  category: ISidebarCategory | undefined;
};

export interface IIssuesModelParams {
  apiFlagSuggestionAsWrong: (
    issue: IIssue,
    state: IssueFlag,
    segment: string,
    comment?: string | null,
  ) => Promise<void>;
  apiBulkFlagSuggestionsAsWrong: (
    flagSuggestionsResults: {
      issue: IIssue;
      state: IssueFlag;
      segment: string;
      comment?: string | null;
    }[],
  ) => Promise<void>;
  apiSuggestionComment: (issue: IIssue, comment: string) => Promise<void>;
  apiReportOnAcceptSuggestion: (replacement: string, issue: IIssue, segment: string) => Promise<void>;
  apiBulkReportOnAcceptSuggestions: (replacement: string, issues: IIssue[], segment: string) => Promise<void>;
  analytics: IAnalyticsTrack<TUISidebarModelsAnalyticsParams, TOrgTeamUserActivityParams>;
  documentId: string;
  eventBus: Emitter<{
    onFlagSuggestionCallback?: (issue: IIssue, flagType: IssueFlag, comment?: string | null) => void;
    onSelectSuggestion?: (issue: IIssue, source: typeof IssueUpdateType.type) => void;
    onApplySuggestionCallback?: (replacement: string, issue: IIssue, documentId?: string) => void;
    onBulkApplyCallback?: (issuesToApply: IIssue[], replacement: string, documentId?: string) => void;
  }>;
  firebaseIssuesData: () => IFirebaseIssue[] | undefined;
  documentContentData: () => string | undefined;
  categories: Pick<
    ICategoriesModel,
    | 'categoriesList'
    | 'betaIssueCategories'
    | 'allIssuesCategories'
    | 'getSidebarCategory'
    | 'selectedCategory'
    | 'isPlagiarismCategorySelected'
  >;
  /**
   * Callback allows to hide issues if client decided that couldn't show it
   */
  isIssueVisible?(issue: IIssue): boolean;
  /**
   * Callback allows to implement custom selection strategy for the client
   * @param issue - issue
   * @param ctx - selection context
   * @param list - list of using issues
   */
  onSelectionStrategy?(
    issue: IIssue | undefined,
    ctx: TSelectedIssueContext | undefined,
    list: IIssue[],
  ): IIssue | undefined;

  getLocalContent?: () => string | undefined;
}

/**
 * Represents the model for managing issues in the sidebar.
 */
export class IssuesModel implements IIssuesModel {
  /**
   * Set selected issue and source
   */
  private readonly $selectedIssueContext = observable.box<TSelectedIssueContext>(
    {
      issueId: undefined,
      source: IssueUpdateType.enum.unknown,
    },
    { deep: false },
  );

  /**
   * @deprecated
   * please use $visibleRange instead of $visibleIssuesMap
   */
  protected readonly $visibleIssuesMap = observable.box<Map<string, boolean>>(undefined, {
    deep: false,
  });

  /**
   * Represents the visible range of issues.
   * @remarks
   * The visible range is defined as an array of tuples, where each tuple represents the start and end indices of a visible range.
   * @typeParam start - The start index of the visible range.
   * @typeParam end - The end index of the visible range.
   */
  protected readonly $visibleRange = observable.box<Array<readonly [start: number, end: number]>>(undefined, {
    deep: false,
  });

  // Forced visible sidebar issues ids
  private readonly factory = createCacheFactory<IIssue | undefined, IFirebaseIssue, string>({
    hash: p => `${p.issueId}_${p.from}_${p.until}_${p.version}`,
    create: p => this.processIssue(p),
  });

  /**
   * Represents a map of deleted issues.
   */
  protected deletedIssuesMap: IObservableValue<Record<string, boolean>> = observable.box({}, { deep: false });

  constructor(private opts: IIssuesModelParams) {
    makeObservable<IssuesModel, 'setIssuesVisibility'>(this, {
      isEmptyState: computed,
      expandedIssueId: computed,
      allIssues: computed({ equals: comparer.shallow }),
      list: computed({ equals: comparer.shallow }),
      sidebarIssues: computed({ equals: comparer.shallow }),
      currentIssues: computed({ equals: comparer.shallow }),
      currentSidebarIssues: computed({ equals: comparer.shallow }),
      visibleSidebarIssues: computed({ equals: comparer.shallow }),
      issuesByCategory: computed({ equals: comparer.shallow }),
      selectedSidebarIssue: computed({ equals: comparer.identity }),
      totalSidebarIssuesCount: computed({ equals: comparer.default }),
      visibleIssuesMap: computed,
      setSelectedIssue: action.bound,
      setIssuesVisibility: action.bound,
    });
  }

  /**
   * Subscribe to selectedIssueContext changes
   */
  private readonly atomSelectedIssue = createAtomSubscriber('atomSelectedIssue', () => {
    const reactionDisposer = reaction(
      () => ({
        selected: this.selectedSidebarIssue,
        list: this.currentSidebarIssues,
        category: this.opts.categories.selectedCategory,
      }),
      (newState, prevState) => {
        this.updateSelectedIssue(newState, prevState);
      },
    );

    return () => reactionDisposer();
  });

  /**
   * Return context of selected issue id and source
   */
  get selectedIssueContext() {
    return this.$selectedIssueContext.get();
  }

  private readonly $nextIssueId = observable.box<string>();

  private readonly $selectedCategory = observable.box<ISidebarCategory>(undefined, {
    deep: false,
    equals: comparer.identity,
  });

  private get eventBus() {
    return this.opts.eventBus;
  }

  private readonly setupNextIssue = action((issue: IIssue | undefined) => {
    const issueId = issue?.issueId;

    if (!issueId) {
      this.$nextIssueId.set(issueId);

      return;
    }

    const index = this.visibleSidebarIssues.findIndex(issue => issue.issueId === issueId);

    if (index < 0) {
      this.$nextIssueId.set(undefined);

      return;
    }

    const nextIndex = index + 1;

    if (nextIndex < this.visibleSidebarIssues.length) {
      const item = this.visibleSidebarIssues[nextIndex];
      this.$nextIssueId.set(item.issueId);
    } else if (index - 1 > 0) {
      const item = this.visibleSidebarIssues[index - 1];
      this.$nextIssueId.set(item.issueId);
    } else {
      this.$nextIssueId.set(undefined);
    }
  });

  /**
   * Represents the index history of the UIIssueModel.
   * It keeps track of the previous and current index values.
   */
  private readonly $issueHistory = (() => {
    const subscriber = new Subscriber<
      boolean,
      { cur: IIssue | undefined; prev: IIssue | undefined; replacement: undefined | string }
    >({
      autoclear: true,
      getId: () => true,
      subscribe: (_, push) => {
        let cur: IIssue | undefined;
        let prev: IIssue | undefined;
        push({ cur, prev, replacement: undefined });

        const cancel = [
          this.eventBus.on('onApplySuggestionCallback', replacement => {
            push({ cur, prev, replacement });
          }),
          this.eventBus.on('onFlagSuggestionCallback', issue => {
            this.setupNextIssue(issue);
          }),
          this.eventBus.on('onSelectSuggestion', issue => {
            this.setupNextIssue(issue);
          }),
          reaction(
            () => this.$selectedIssue.get().item,
            item => {
              prev = cur;
              cur = item;
              push({ prev, cur, replacement: undefined });
            },
            { equals: comparer.shallow },
          ),
        ];

        return () => cancel.forEach(cb => cb());
      },
    });

    return computed(() => subscriber.data ?? { prev: undefined, cur: undefined, replacement: undefined }, {
      equals: comparer.structural,
    });
  })();

  /**
   * Represents the selected issue in the UI.
   * @readonly
   */
  private readonly $selectedIssue = computed(
    () => {
      const ctx = this.$selectedIssueContext.get();

      // we are looking for on all available, but maybe invisible elements
      const list = this.currentSidebarIssues;
      const iLen = list.length;

      if (ctx) {
        for (let i = 0; i < iLen; i++) {
          const item = list[i];

          if (item.issueId === ctx.issueId) {
            return { index: i, item } as const;
          }
        }
      }

      return { index: -1, item: undefined } as const;
    },
    { equals: comparer.default },
  );

  private readonly $selectedIssueComputed = computed(
    () => {
      const ref = this.$selectedIssue.get();

      // we need to start it earliar
      const { prev, replacement } = this.$issueHistory.get();

      if (ref.item) {
        return ref.item;
      }

      if (!this.currentSidebarIssues.length) {
        return undefined;
      }

      // element could be invisible, but available
      const firstElement = this.currentSidebarIssues[0];

      // if category was changed we have to show first item
      if (this.$selectedCategory.get() !== this.opts.categories.selectedCategory) {
        return firstElement;
      }

      const nextIssueId = this.$nextIssueId.get();

      // try to find next issues
      const nextIssue = nextIssueId ? this.currentSidebarIssues.find(item => item.issueId === nextIssueId) : undefined;

      if (nextIssue) {
        return nextIssue;
      }

      // try to find next issue from offset of previous plus length of it's replacement
      const nextOffset = prev ? prev.from + (replacement?.length ?? 0) : -1;
      const nextOffsetItem =
        nextOffset > 0 ? this.currentSidebarIssues.find(item => item.from >= nextOffset) : undefined;

      if (nextOffsetItem) {
        return nextOffsetItem;
      }

      // if we can't find next element, try to find prev element
      const prevOffset = prev ? prev.from : -1;
      const prevOffsetItem =
        prevOffset < 0
          ? undefined
          : this.currentSidebarIssues.reduce(
              (memo, item) => {
                if (item.from <= prevOffset) {
                  if (!memo || item.from >= memo.from) {
                    return item;
                  }
                }

                return memo;
              },
              undefined as IIssue | undefined,
            );

      if (prevOffsetItem) {
        return prevOffsetItem;
      }

      const indexElement = ref.index >= 0 ? this.currentSidebarIssues[ref.index] : undefined;

      if (indexElement) {
        return indexElement;
      }

      return firstElement;
    },
    { equals: comparer.identity },
  );

  /**
   * Gets the selected issue.
   * @returns The selected issue, or undefined if no issue is selected.
   */
  get selectedIssue(): IIssue | undefined {
    const { onSelectionStrategy } = this.opts;
    const issue = this.$selectedIssueComputed.get();

    if (onSelectionStrategy) {
      return onSelectionStrategy(issue, this.$selectedIssueContext.get(), this.list);
    } else {
      return issue;
    }
  }

  /**
   * Returns currently selected issue id
   */
  get expandedIssueId(): string | undefined {
    // report observed to make sure that reaction will be triggered
    this.atomSelectedIssue.reportObserved();
    const currId = this.$selectedIssueContext.get().issueId;

    return currId !== undefined ? currId : this.currentSidebarIssues[0]?.issueId;
  }

  /**
   * Update selected issue in sidebar when init and when we delete selected issue (apply/mark/delete)
   * @param selected
   * @param list
   * @param category
   * @param prevSelected
   * @param prevList
   * @param prevCategory
   */
  protected updateSelectedIssue(
    { selected, list, category }: TState,
    { selected: prevSelected, list: prevList, category: prevCategory }: TState,
  ): void {
    // in this case we need to check if is we have selected issue in sidebar or no, this need only in case when we delete selected issue (apply/mark/delete)
    if (!selected && list.length) {
      const prevIndex = prevList.findIndex((issue: IIssue) => issue.issueId === prevSelected?.issueId);
      const issueToSelect =
        prevIndex === -1 || category !== prevCategory ? list[0] : list[Math.min(prevIndex, list.length - 1)];

      this.setSelectedIssue(issueToSelect, 'api');
    }
  }

  /**
   * Checks if the state is empty.
   * @returns {boolean | undefined} True if the state is empty, false otherwise.
   */
  get isEmptyState() {
    const editorContent = this.opts.documentContentData();

    // We can't say that state is empty if we haven't loaded it
    if (editorContent === undefined) {
      return undefined;
    }

    // if content is empty but we can compare it with local one and
    // it's not equal, this means that we have to wait a bit more
    if (!editorContent && this.opts.getLocalContent) {
      if (this.opts.getLocalContent() !== editorContent) {
        return undefined;
      }
    }

    return !editorContent || !editorContent.length || (editorContent.length === 1 && editorContent[0] === '\n');
  }

  /**
   * Returns an array of all issues.
   *
   * @returns {IIssue[]} The array of all issues.
   */
  get allIssues(): IIssue[] {
    LOG.warn('[start] Building issues list:');
    LOG.info('Deleted issues map:', this.deletedIssuesMap);

    if (!this.opts.categories.categoriesList) {
      LOG.warn('Categories list is still loading.');

      return [];
    }

    if (!this.opts.firebaseIssuesData()) {
      LOG.warn('Firebase issues list is still loading.');

      return [];
    }

    const mapper = this.factory.next();
    const result = this.opts
      .firebaseIssuesData()
      ?.map(firebaseIssue => {
        // hide issues with no category (including invisible categories that won't exist in CategoriesContext)
        if (!this.opts.categories.allIssuesCategories.includes(firebaseIssue.category)) {
          LOG.warn('Cant process, category not allowed, locked or disabled', firebaseIssue);

          return undefined;
        }

        return mapper(firebaseIssue);
      })
      .filter(notEmpty)
      .sort((a, b) => a.from - b.from) as IIssue[];

    LOG.info('[end] Building issues list:', result);

    return result;
  }

  /**
   * Gets the list of issues.
   * @returns An array of IIssue objects.
   */
  get list(): IIssue[] {
    const { isIssueVisible } = this.opts;
    const list = this.allIssues?.filter(issue => !this.deletedIssuesMap.get()[issue.issueId]);

    return isIssueVisible ? list.filter(issue => isIssueVisible(issue)) : list;
  }

  /**
   * Returns full list of issues filtered by category
   */
  get currentIssues() {
    return IssuesModel.getIssuesByCategory(this.list, this.opts.categories.selectedCategory);
  }

  /**
   * Returns full list of sidebar issues (excluding dictionary etc.)
   */
  get sidebarIssues() {
    return IssuesModel.filterNoNSidebarIssues(this.list);
  }

  /**
   * Returns issues that is currently displayed in the sidebar (filtered by category)
   */
  get currentSidebarIssues() {
    return IssuesModel.filterNoNSidebarIssues(this.currentIssues);
  }

  /**
   * Returns the list of sidebar issues that are currently visible based on the visible range and visible issues map.
   * If the visible issues map is undefined and the visible range is empty, the function returns the current sidebar issues.
   * If the visible issues map is defined and not empty, the function returns the current sidebar issues filtered by the issue IDs in the visible issues map.
   * If the visible range is defined and not empty, the function returns the current sidebar issues filtered by the range of positions specified in the visible range.
   * If neither the visible issues map nor the visible range is defined, the function returns an empty array.
   * @returns {Array<IIssue>} The list of visible sidebar issues.
   */
  get visibleSidebarIssues(): IIssue[] {
    const { currentSidebarIssues } = this;

    const visibleRange = this.$visibleRange.get();
    const visibleIssuesMap = this.$visibleIssuesMap.get();

    /*
     * If visibleIssuesMap is undefined, sidebar issues list should be full
     * */
    if (!visibleIssuesMap && !visibleRange) {
      return currentSidebarIssues;
    }

    /*
     * If visibleIssuesMap is defined, but empty, sidebar issues list should be empty too
     * */
    if (visibleIssuesMap) {
      if (visibleIssuesMap.size) {
        return currentSidebarIssues.filter(item => visibleIssuesMap.has(item.issueId));
      }
    } else if (visibleRange) {
      if (visibleRange.length) {
        return currentSidebarIssues.filter(item => inRange(item.from, item.until, visibleRange));
      }
    }

    return [];
  }

  /**
   * Returns number of issues by category
   */
  get issuesByCategory(): Record<CategoryType, number> {
    const { categoriesList } = this.opts.categories;

    if (!categoriesList) {
      return {} as Record<CategoryType, number>;
    }

    return categoriesList.reduce(
      (res, category) => {
        res[category.id] = IssuesModel.getIssuesByCategory(this.sidebarIssues, category).length;

        return res;
      },
      {} as Record<CategoryType, number>,
    );
  }

  /**
   * Returns issue that is currently selected in the sidebar
   */
  get selectedSidebarIssue(): IIssue | undefined {
    const issue = this.currentSidebarIssues.find(issue => issue.issueId === this.$selectedIssueContext.get().issueId);

    return issue;
  }

  /**
   * Returns total number of issues in the sidebar
   */
  get totalSidebarIssuesCount() {
    if (this.opts.categories.isPlagiarismCategorySelected) {
      return this.sidebarIssues.length;
    }

    return this.sidebarIssues.length - this.issuesByCategory[CategoryType.PLAGIARISM];
  }

  /**
   * Set's currently selected issue in sidebar
   * @param issue
   * @param source
   */
  setSelectedIssue(issue: IIssue, source: typeof IssueUpdateType.type = IssueUpdateType.enum.unknown) {
    const issueId = this.$selectedIssueContext.get()?.issueId;

    // if issue is not selected or selected issue is not the same as the new one
    if (issueId !== issue.issueId) {
      // call analytics
      this.opts.analytics.track(UISidebarModelsAnalyticsActivity.suggestionViewed, {
        suggestion_category: issue.category,
        suggestion_issue_type: issue.issueType,
        card_type: IssueCardType.SIDEBAR,
      });

      // set selected issue
      this.$selectedIssueContext.set({
        issueId: issue.issueId,
        source,
      });

      this.$selectedCategory.set(this.opts.categories.selectedCategory);
    }

    this.eventBus.emit('onSelectSuggestion', issue, source);
  }

  /**
   * @deprecated
   * please use setIssuesVisibility instead of this method
   * Shows\Hides issue by id in the sidebar
   * @param issue
   * @param isVisible
   */
  public setIssueVisibility(issue: Pick<IIssue, 'issueId'>, isVisible: boolean) {
    this.deletedIssuesMap.set({
      ...this.deletedIssuesMap.get(),
      [issue.issueId]: !isVisible,
    });
  }

  /**
   * Shows\Hides issues by id in the sidebar
   * @param issues
   * @param isVisible
   */
  public setIssuesVisibility<TIssue extends Pick<IIssue, 'issueId'>>(issues: TIssue[], isVisible: boolean) {
    const updatedIssues: Record<string, boolean> = {};
    issues.forEach(issue => {
      updatedIssues[issue.issueId] = !isVisible;
    });
    this.deletedIssuesMap.set({
      ...this.deletedIssuesMap.get(),
      ...updatedIssues,
    });
  }

  /**
   * @deprecated
   * please use setVisibleRange inteadof this method
   */
  readonly setVisibleIssuesMap = action((map: Map<string, boolean> | undefined) => {
    this.$visibleIssuesMap.set(map);
    this.$visibleRange.set(undefined);
  });

  /**
   * Sets the visible range of issues.
   *
   * @param range - An array of tuples representing the start and end indices of the range.
   * @returns void
   */
  readonly setVisibleRange = action((range: Array<readonly [start: number, end: number]> | undefined) => {
    this.$visibleRange.set(range);
    this.$visibleIssuesMap.set(undefined);
  });

  /**
   * Retrieves issues from a source array based on the specified category.
   * If a category is provided, it filters the issues that belong to that category.
   * If no category is provided, it filters out issues with the category "Plagiarism".
   *
   * @param source - The array of issues to filter.
   * @param category - The category to filter the issues by.
   * @returns An array of issues that match the specified category.
   */
  static getIssuesByCategory(source: IIssue[], category?: ISidebarCategory) {
    if (category) {
      return source.filter(issue => category.categories.includes(issue.category));
    }

    return source.filter(issue => issue.category !== IssueCategory.Plagiarism);
  }

  /**
   * Checks if an issue has changed by comparing the properties of the cached issue and the new issue.
   * @param cachedIssue The cached issue object.
   * @param newIssue The new issue object.
   * @returns True if the issue has changed, false otherwise.
   */
  static hasIssueChanged(cachedIssue: IIssue, newIssue: IFirebaseIssue) {
    return cachedIssue.from !== newIssue.from || cachedIssue.until !== newIssue.until;
  }

  /**
   * Processes the meta object of an issue.
   *
   * @param meta - The meta object to be processed.
   * @returns The processed meta object.
   */
  static processMetaObject(meta: IIssueMeta) {
    /* todo: need to remove this logic */
    let metaObj = {} as IIssueMeta;

    if (typeof meta === 'string') {
      // BE sends meta object as string so parsing is needed until BE gets updated
      try {
        metaObj = JSON.parse(meta);
      } catch (e) {
        LOG.warn('Cant parse issue meta object', meta);
      }
    } else {
      metaObj = meta;
    }

    if (Array.isArray(metaObj)) {
      // handles old plagiarism issues where meta used to be an object
      metaObj = { matches: metaObj.map(item => ({ ...item, similarity: 95 })) };
    }

    return metaObj;
  }

  /**
   * Processes an issue and returns an IIssue object.
   *
   * @param issue - The IFirebaseIssue object to be processed.
   * @returns The processed IIssue object, or undefined if the issue cannot be processed.
   */
  private processIssue(issue: IFirebaseIssue): IIssue | undefined {
    let meta = IssuesModel.processMetaObject(issue.meta || {});
    const isBeta = this.opts.categories.betaIssueCategories.includes(issue.category) || meta?.isBeta;

    LOG.info('New issue. Trying process the issue:', issue);
    const documentContent = this.opts.documentContentData();

    if (!documentContent) {
      LOG.warn('Cant process, no content', issue);

      return undefined;
    }

    // ignore plagiarisms with similarity less then threshold
    if (
      issue.category === IssueCategory.Plagiarism &&
      issue.meta?.matches?.every(match => match.similarity < PLAGIARISM_MIN_MATCH_PERCENT)
    ) {
      LOG.warn('Cant process, wrong plagiarism', issue);

      return undefined;
    }

    // hide all dictionary (approved) term without description
    if (issue.issueType === IssueType.DICTIONARY && !issue.description?.trim()) {
      LOG.warn('Cant process, dictionary term with empty description', issue);

      return undefined;
    }

    const sidebarCategory = this.opts.categories.getSidebarCategory(issue.category);

    if (!sidebarCategory) {
      LOG.warn('Not able to get sidebarCategory (old styleguide?)', issue);
    }

    const { from, until, description } = issue;

    let segment = '';

    if ([IssueType.UNCLEAR_REFERENCES, IssueType.READABILITY].includes(issue.issueType)) {
      segment = computeSegmentFromPositions(documentContent, from, until);
    }

    if (issue.issueType === IssueType.READABILITY) {
      meta = { ...meta, header: 'Readability' };
    }

    return {
      ...issue,
      isBeta: !!isBeta,
      length: until - from,
      sidebarCategory,
      highlight: documentContent.slice(from, until),
      description,
      segment,
      meta,
    };
  }

  /**
   * Handles the flagging of an issue.
   *
   * @param state - The state of the issue flag.
   * @param issue - The issue to be flagged.
   * @param cardType - The type of the issue card.
   * @param comment - The comment to be added to the issue.
   * @returns A Promise that resolves when the flagging process is complete.
   */
  private async onFlagIssue(state: IssueFlag, issue: IIssue, cardType: IssueCardType, comment?: string | null) {
    const segment = computeSegmentFromPositions(
      this.opts.documentContentData() || '',
      issue.from,
      issue.until,
      NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
    );

    this.setIssuesVisibility([issue], false);

    try {
      this.opts.eventBus.emit('onFlagSuggestionCallback', issue, state);
      await this.opts.apiFlagSuggestionAsWrong(issue, state, segment, comment);
    } catch (e) {
      LOG.warn(e);
      this.setIssuesVisibility([issue], true);
    }

    const analyticsEvent =
      state === IssueFlag.IGNORE
        ? UISidebarModelsAnalyticsActivity.suggestionIgnored
        : UISidebarModelsAnalyticsActivity.suggestionFlagged;

    this.opts.analytics.track(analyticsEvent, {
      suggestion_category: issue.category,
      suggestion_issue_type: issue.issueType,
      card_type: cardType,
      suggestions_count: 1,
    });
  }

  /**
   * Filters out issues that should not be displayed in the sidebar.
   *
   * @param list - The list of issues to filter.
   * @returns The filtered list of issues.
   */
  static filterNoNSidebarIssues(list: IIssue[]) {
    return (
      list
        // some of the categories\issue types must be ignored in the sidebar
        .filter(issue => !CATEGORIES_IGNORED_FROM_SIDEBAR.includes(issue.category))
        .filter(issue => !ISSUE_TYPES_IGNORED_FROM_SIDEBAR.includes(issue.issueType))
    );
  }

  /**
   * Handles the delete issue click event.
   * @param issue - The issue to be deleted.
   * @param cardType - The type of the issue card.
   */
  readonly onDeleteIssueClick = (issue: IIssue, cardType: IssueCardType) =>
    this.onFlagIssue(IssueFlag.IGNORE, issue, cardType);

  /**
   * Bulk delete similar issues.
   * @param issues - Issues to delete.
   * @param cardType - The type of the issue card.
   */
  readonly onBulkDeleteIssues = async (issues: IIssue[], cardType: IssueCardType) => {
    const deleteResults = await Promise.all(
      issues.map(async issue => {
        const segment = computeSegmentFromPositions(
          this.opts.documentContentData() || '',
          issue.from,
          issue.until,
          NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
        );

        this.opts.eventBus.emit('onFlagSuggestionCallback', issue, IssueFlag.IGNORE);

        return {
          issue,
          state: IssueFlag.IGNORE,
          segment,
        };
      }),
    );

    this.setIssuesVisibility(issues, false);

    await this.opts.apiBulkFlagSuggestionsAsWrong(deleteResults);

    const firstIssue = issues[0];
    this.opts.analytics.track(UISidebarModelsAnalyticsActivity.suggestionIgnored, {
      suggestion_category: firstIssue.category,
      suggestion_issue_type: firstIssue.issueType,
      card_type: cardType,
      suggestions_count: issues.length,
    });
  };

  /**
   * Marks an issue as wrong.
   * @param issue - The issue to mark as wrong.
   * @param cardType - The type of issue card.
   */
  readonly onMarkIssueAsWrong = (issue: IIssue, cardType: IssueCardType, comment?: string | null) =>
    this.onFlagIssue(IssueFlag.WRONG, issue, cardType, comment);

  /**
   * Applies a suggestions to the issues and performs necessary actions.
   * @param issues - The issues objects.
   * @param replacement - The replacement string for the suggestions.
   * @param cardType - The type of the issue card.
   */
  readonly onBulkAcceptSuggestions = async (issues: IIssue[], replacement: string, cardType: IssueCardType) => {
    const firstIssue = issues[0];
    this.opts.analytics.track(UISidebarModelsAnalyticsActivity.suggestionAccepted, {
      suggestion_category: firstIssue.category,
      suggestion_issue_type: firstIssue.issueType,
      card_type: cardType,
      suggestions_count: issues.length,
    });

    const segment = computeSegmentFromPositions(
      this.opts.documentContentData() || '',
      firstIssue.from,
      firstIssue.until,
      NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
    );

    this.setIssuesVisibility(issues, false);

    await this.opts.apiBulkReportOnAcceptSuggestions(replacement, issues, segment);

    this.opts.eventBus.emit('onBulkApplyCallback', issues, replacement, this.opts.documentId);
  };

  /**
   * Handles the resolution of a claimed issue.
   * @param issue - The issue to be resolved.
   */
  readonly onClaimResolve = (issue: IIssue) => this.onFlagIssue(IssueFlag.IGNORE, issue, IssueCardType.INLINE);

  /**
   * Handles the claim decline action for an issue.
   * @param issue - The issue to be claimed or declined.
   */
  readonly onClaimDecline = (issue: IIssue) => this.onFlagIssue(IssueFlag.WRONG, issue, IssueCardType.INLINE);

  /**
   * Marks the specified issues as deleted by setting their visibility to false.
   * @param issues - The issues to mark as deleted.
   */
  public readonly markIssuesAsDeleted = (issues: IIssue[]): void => this.setIssuesVisibility(issues, false);

  /**
   * Applies a suggestion to the issue and performs necessary actions.
   * @param replacement - The replacement string for the suggestion.
   * @param issue - The issue object.
   * @param cardType - The type of the issue card.
   */
  onApplySuggestion = async (replacement: string, issue: IIssue, cardType: IssueCardType) => {
    this.opts.analytics.track(UISidebarModelsAnalyticsActivity.suggestionAccepted, {
      suggestion_category: issue.category,
      suggestion_issue_type: issue.issueType,
      card_type: cardType,
      suggestions_count: 1,
    });

    const segment = computeSegmentFromPositions(
      this.opts.documentContentData() || '',
      issue.from,
      issue.until,
      NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT,
    );

    this.setIssuesVisibility([issue], false);

    try {
      this.opts.eventBus.emit('onApplySuggestionCallback', replacement, issue, this.opts.documentId);
      await this.opts.apiReportOnAcceptSuggestion(replacement, issue, segment);
    } catch (e) {
      LOG.debug('emitted onApplySuggestionCallback', e);
      LOG.warn(e);
      this.setIssuesVisibility([issue], true);
    }
  };

  /**
   * @deprecated
   * please use compare currentSidebarIssues and visibleSidebarIssues
   */
  public get visibleIssuesMap() {
    return this.$visibleIssuesMap.get();
  }
}
