import React from 'react';
import cx from 'clsx';

import styles from './styles.module.css';

import PassiveVoiceAExample from '../../assets/backgrounds/passive_voice_a.svg';
import PassiveVoiceBExample from '../../assets/backgrounds/passive_voice_b.svg';

export const PassiveVoiceInfo: React.FC = () => (
  <div className={styles.container}>
    <h2 className={cx(styles.header, styles.withBottomMargin)}>Passive voice vs. Active voice</h2>

    <p className={cx(styles.description, styles.withBottomMargin)}>
      Passive vs. active has everything to do with the sentence subject, which is the who or what that appears before
      the action. In active voice, the subject does the action. In passive voice, the subject receives the action. To
      turn passive voice into active voice, make sure that the doer of the action is the subject of the sentence.
    </p>

    <p className={cx(styles.description, styles.withBottomMargin)}>
      <b>Why use active voice?</b> Active-voice sentences are easier to read because they are more concise and direct.
    </p>

    <div className={cx(styles.withBottomMargin)}>
      <h3 className={styles.exampleHeader}>Example A</h3>
      <PassiveVoiceAExample className={styles.exampleImg} />
    </div>
    <div className={cx(styles.withBottomMargin)}>
      <h3 className={styles.exampleHeader}>Example B</h3>
      <p className={cx(styles.exampleBText, styles.description)}>
        Some passive sentences don’t specify a{' '}
        <b>
          <i>doer of action</i>
        </b>
        . You’ll need to fill in this missing info to make the sentence active.
      </p>
      <PassiveVoiceBExample className={styles.exampleImg} />
    </div>
  </div>
);

export default PassiveVoiceInfo;
