import debounce from 'lodash/debounce';
import Delta from 'quill-delta';

import {
  type CollectionReference,
  type DocumentData,
  type DocumentReference,
  type DocumentSnapshot,
  type Firestore,
  type QuerySnapshot,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';

import type {
  ICollaborationUserWithMeta,
  IFirebaseIssue,
  IStyleguide,
  IssuesPipeline,
  ISidebarDataApi,
  ISidebarDataParams,
  IPipelineContentHashes,
  IStyleguideMapping,
  TUserNotification,
} from '@writercolab/types';
import { deltaToString, processIncomingDelta } from '@writercolab/quill-delta-utils';
import { getLogger, isDocumentIsValid, viewerIsActive } from '@writercolab/utils';
import { comparer, computed, makeObservable } from 'mobx';
import { COLLECTION, FIELDS } from '@writercolab/types';

const LOG = getLogger('FirebaseApi', 'firebase');

/**
 * Represents a Firebase API implementation.
 * This class provides various methods and properties for interacting with Firebase Firestore.
 */
export class FirebaseApi implements ISidebarDataApi {
  constructor(
    private readonly firestore: Firestore,
    private readonly params: ISidebarDataParams,
  ) {
    makeObservable(this, {
      ctrl: computed({ equals: comparer.identity }),

      organizationId: computed({ equals: comparer.default }),
      teamId: computed({ equals: comparer.default }),
      documentId: computed({ equals: comparer.default }),
      userId: computed({ equals: comparer.default }),
      personaId: computed({ equals: comparer.default }),
      documentPath: computed({ equals: comparer.default }),

      updateDocumentUser: computed,
      onIssuesSubscriber: computed,
      getDocumentDelta: computed,
      getDocumentPipeline: computed,
      onContentHashes: computed,
      onDocumentDelta: computed,
      onDocumentContentSubscriber: computed,
      onViewerSubscriber: computed,
      onNotificationsSubscriber: computed,
    });
  }

  /**
   * Represents the control object for interacting with a Firebase document.
   * @returns An object with methods for accessing different collections and fields within the document.
   */
  get ctrl() {
    const { documentId, firestore, documentPath } = this;

    if (!documentPath || !documentId) {
      return undefined;
    }

    const getPersonaDocument = () => doc(firestore, `${documentPath}/document/`, documentId);

    const getDocumentInfoCollection = () =>
      collection(getPersonaDocument(), COLLECTION.enum.field) as CollectionReference;

    const getViewersCollection = () =>
      collection(getPersonaDocument(), COLLECTION.enum.viewers) as CollectionReference<ICollaborationUserWithMeta>;

    const getIssueCollection = () =>
      collection(getPersonaDocument(), COLLECTION.enum['issue-v2']) as CollectionReference<IFirebaseIssue>;

    const getDocumentFieldRef = (field: typeof FIELDS.type) => doc(getDocumentInfoCollection(), field);

    return {
      getPersonaDocument,
      getDocumentInfoCollection,
      getViewersCollection,
      getIssueCollection,
      getDocumentFieldRef,
    };
  }

  /**
   * Gets the organization ID.
   *
   * @returns The organization ID.
   */
  get organizationId() {
    return this.params.organizationId();
  }

  /**
   * Retrieves the team ID.
   *
   * @returns The team ID.
   */
  get teamId() {
    return this.params.teamId();
  }

  /**
   * Retrieves the document ID.
   *
   * @returns The document ID if it is valid, otherwise undefined.
   */
  get documentId() {
    const docId = this.params.documentId();

    if (!isDocumentIsValid(docId)) {
      return undefined;
    }

    return docId;
  }

  /**
   * Gets the user ID.
   *
   * @returns The user ID.
   */
  get userId() {
    return this.params.userId();
  }

  /**
   * Gets the persona ID.
   *
   * @returns The persona ID.
   */
  get personaId() {
    return this.params.personaId;
  }

  /**
   * Returns the document path based on the organization ID, team ID, and persona ID.
   * If both organization ID and team ID are provided, the path will be in the format:
   * `/organization/{organizationId}/workspace/{teamId}/persona/{personaId}`
   * If either organization ID or team ID is missing, the path will be undefined.
   */
  get documentPath() {
    const { organizationId, teamId, personaId } = this;

    return organizationId && teamId
      ? `/organization/${organizationId}/workspace/${teamId}/persona/${personaId}`
      : undefined;
  }

  // callbacks

  get updateDocumentUser() {
    const { ctrl } = this;

    return (
      ctrl &&
      (async (user: ICollaborationUserWithMeta) => {
        LOG.debug('updateDocumentUser', user);

        const viewersCollection = collection(ctrl.getPersonaDocument(), COLLECTION.enum.viewers);
        const currentUserDoc = doc(viewersCollection, user.sessionId);

        await setDoc(currentUserDoc, user, { merge: true });
      })
    );
  }

  /**
   * Returns a function that subscribes to changes in the Firebase issue collection.
   * The returned function takes a callback function as a parameter, which will be called with an array of IFirebaseIssue objects whenever there are changes in the collection.
   * The callback function will be debounced with a delay of 500 milliseconds to avoid excessive calls.
   *
   * @returns {((fn: (issues: IFirebaseIssue[]) => void) => void) | undefined} A function that subscribes to changes in the Firebase issue collection.
   */
  get onIssuesSubscriber() {
    const { ctrl } = this;

    return (
      ctrl &&
      ((fn: (issues: IFirebaseIssue[]) => void) =>
        onSnapshot(
          ctrl.getIssueCollection(),
          debounce((snapshot: QuerySnapshot<IFirebaseIssue>) => {
            const issues = snapshot.docs.map(doc => doc.data());
            fn(issues);
          }, 500),
        ))
    );
  }

  /**
   * Retrieves the document delta.
   *
   * @returns {Promise<Delta>} A function that asynchronously resolves to the document delta.
   */
  get getDocumentDelta() {
    const { ctrl } = this;

    if (!ctrl) {
      return undefined;
    }

    const fn = async () => {
      const dataField = ctrl.getDocumentFieldRef(FIELDS.enum.data);
      const document = await getDoc(dataField);
      const delta = new Delta(document.get('data')) as Delta;

      return processIncomingDelta(delta);
    };

    return fn;
  }

  /**
   * Retrieves the document pipeline.
   * @returns A function that asynchronously retrieves the pipeline ID from the document.
   *          Returns undefined if the control is not available or if the pipeline ID is not found.
   */
  get getDocumentPipeline() {
    const { ctrl } = this;

    if (!ctrl) {
      return undefined;
    }

    return async () => {
      const coreField = ctrl.getDocumentFieldRef(FIELDS.enum.core);
      const meta = await getDoc(coreField);
      const pipelineId = meta.get('pipelineId');

      return pipelineId ? (pipelineId as IssuesPipeline) : undefined;
    };
  }

  /**
   * Returns a callback function that listens for changes in the 'pipelineContentHashes' field of the core document.
   * The callback function receives the updated pipeline content hashes.
   *
   * @returns A callback function that takes a data parameter of type IPipelineContentHashes.
   */
  get onContentHashes() {
    const { ctrl } = this;

    return (
      ctrl &&
      ((fn: (data: IPipelineContentHashes) => void) => {
        const coreField = ctrl.getDocumentFieldRef(FIELDS.enum.core);

        return onSnapshot(coreField, coreDocument => {
          const pipelineContentHashes = coreDocument.get('pipelineContentHashes') as IPipelineContentHashes;
          fn(pipelineContentHashes);
        });
      })
    );
  }

  /**
   * Returns a callback function that listens for changes in the document delta.
   * @returns {(fn: (d: Delta) => void) => () => void} The callback function that can be used to unsubscribe from the changes.
   */
  get onDocumentDelta() {
    const { ctrl } = this;

    return (
      ctrl &&
      ((fn: (d: Delta) => void) => {
        const coreField = ctrl.getDocumentFieldRef(FIELDS.enum.data);

        return onSnapshot(coreField, document => {
          const delta = new Delta(document.get('data')) as Delta;
          fn(delta);
        });
      })
    );
  }

  /**
   * Subscribes to changes in the styleguide document derived from the root document.
   *
   * @param fn - The callback function to invoke with the updated styleguide data.
   * @returns A function to unsubscribe from the listener.
   */
  get onStyleguideSubscriber() {
    const { documentPath, firestore } = this;

    if (!documentPath) {
      return undefined;
    }

    return (fn: (data: IStyleguide) => void) => {
      let styleguideUnsubscribe: (() => void) | null = null;

      const rootUnsubscribe = onSnapshot(doc(firestore, documentPath), (persona: DocumentSnapshot<DocumentData>) => {
        const styleguideMapping = persona.get('mapping') as IStyleguideMapping;

        if (styleguideMapping) {
          styleguideUnsubscribe?.();
          styleguideUnsubscribe = null;

          styleguideUnsubscribe = onSnapshot(
            styleguideMapping.styleguideRef,
            (styleguideSnapshot: DocumentSnapshot<DocumentData>) => {
              const styleguide = styleguideSnapshot.data() as IStyleguide;

              if (styleguide) {
                fn(styleguide);
              }
            },
          );
        }
      });

      return () => {
        rootUnsubscribe();

        styleguideUnsubscribe?.();
        styleguideUnsubscribe = null;
      };
    };
  }

  /**
   * Returns a subscriber function that listens for changes in the document content.
   * The subscriber function receives the updated document content as a string.
   *
   * @returns {((fn: (data: string) => void) => void) | undefined} The subscriber function or undefined if the controller is not available.
   */
  get onDocumentContentSubscriber() {
    const { ctrl } = this;

    return (
      ctrl &&
      ((fn: (data: string) => void) =>
        onSnapshot(ctrl.getDocumentFieldRef(FIELDS.enum.data), (documentSnapshot: DocumentSnapshot<DocumentData>) =>
          fn(deltaToString(documentSnapshot.get('data'))),
        ))
    );
  }

  /**
   * Returns a function that listens for changes in the viewers collection and invokes the provided callback function with an array of collaboration users with metadata.
   *
   * @returns {(fn: (data: ICollaborationUserWithMeta[]) => void) => void} A function that takes a callback function as a parameter and sets up the listener for changes in the viewers collection.
   */
  get onViewerSubscriber() {
    const { ctrl } = this;

    return (
      ctrl &&
      ((fn: (data: ICollaborationUserWithMeta[]) => void) =>
        onSnapshot(
          ctrl.getViewersCollection(),
          debounce((snapshot: QuerySnapshot<ICollaborationUserWithMeta>) => {
            const viewers = snapshot.docs.map(doc => doc.data()).filter(({ lastViewed }) => viewerIsActive(lastViewed));
            fn(viewers);
          }, 500),
        ))
    );
  }

  /**
   * Returns a function that subscribes to Firebase notifications for the current user.
   * The returned function takes a callback function as a parameter, which will be called with the notifications data.
   * If the user or organization ID is not available, undefined is returned.
   *
   * @returns A function that subscribes to Firebase notifications.
   */
  get onNotificationsSubscriber() {
    const { firestore, organizationId, userId } = this;

    if (!userId || !organizationId) {
      return undefined;
    }

    return (fn: (notifications: TUserNotification[]) => void) =>
      onSnapshot(
        collection(
          doc(firestore, `/organization/${organizationId}/user/`, `${userId}`),
          'notification',
        ) as CollectionReference<TUserNotification>,
        (snapshot: QuerySnapshot<TUserNotification>) => {
          const notifications = snapshot.docs.map(doc => doc.data());
          fn(notifications);
        },
      );
  }
}
