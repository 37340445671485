
const digest = '3b2e261c84205b8369f30a9c7bc015c348df8adcbff5e52ab4df6eb9efed0007';
const css = `.styles-module__LYzbDfJ {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 12px 16px;
  background: var(--white);
}

.styles-module__9oJm1UZ {
  margin-top: 8px;
}

.styles-module__kcppttI {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.3px;
  line-height: 15px;
  text-transform: uppercase;
}

.styles-module__zP7sMFJ {
  /* works just in chrome */
  /* stylelint-disable-line */
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  overflow: hidden;
  max-height: 32px;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

/* Card Header Section */
.styles-module__LI0f7oE .styles-module__Nelbw6m {
  color: var(--classic-orange-2);
}

.styles-module__IC-75lS {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--classic-grey-3);
}

.styles-module__ACZQ1wG {
  display: flex;
}

.styles-module__h8GGaHy {
  position: absolute;
  left: 17px;
  display: flex;
  overflow: hidden;
  width: calc(100% - 17px);
}

.styles-module__Nelbw6m {
}

.styles-module__rbSvKU0 {
  display: block;
  padding: 2px 6px;
  border-radius: 4px;
  margin-top: -3px;
  margin-left: 10px;
  background: var(--classic-grey-2);
  color: var(--white);
}

.styles-module__VX0wu-0 {
  max-height: 8px;
  margin: auto 17px auto 0;
}

.styles-module__zdUM33k {
  display: block;
  font-size: 11px;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__ddqh7n4 {
  overflow: hidden;
  padding-right: 8px;
  color: var(--black);
  font-size: var(--font-size-m);
  font-weight: normal !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__sG4k1th {
  max-width: 100%;
  margin: 12px 0;
  box-shadow: var(--issue-card-collapsed-shadow);
  user-select: text;
}

.styles-module__sG4k1th:last-child {
  margin: 12px 0 0;
}

.styles-module__2HGNJbK {
  overflow: visible;
  max-width: 100%;
}

.styles-module__sG4k1th:hover,
.styles-module__2HGNJbK {
  box-shadow: var(--issue-card-highlighted-shadow);
}

.styles-module__sG4k1th:not(.styles-module__2HGNJbK) {
  cursor: pointer;
}

.styles-module__DclduYq {
  padding: 6px 8px 0 20px;
  color: var(--dark-blue-grey);
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
}

/* IssueHolder */
.styles-module__tny0cQ- {
  position: relative;
}

.styles-module__N6HVG8c {
  padding-left: 5px;
  color: var(--classic-grey-3);
  font-weight: 400;
}

.styles-module__1QSbauz {
  font-weight: 400;
  font-size: 12px;
}

/* Plagiarism Contents */
.styles-module__Sd9ommc {
}

.styles-module__IYZLU0E {
  margin-top: 12px;
  color: var(--black);
  font-size: 11px;
}

.styles-module__tiFhGEo {
  font-size: 13px;
  font-weight: 600;
}

.styles-module__33q7BHF {
  color: #ed6e49;
}

.styles-module__vy1H-Oq {
  font-weight: 400;
}

.styles-module__cC8VBPJ {
  overflow: hidden;
  color: var(--classic-grey-2);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__yWl5LIf {
  color: var(--classic-grey-2);
  font-size: 9px;
  text-decoration: none;
}

.styles-module__yWl5LIf:hover {
  color: var(--dark-grey);
  text-decoration: underline;
}

.styles-module__YVejiFR {
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 17px;
  background-color: var(--writer-green-2);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  transition: var(--background-color-transition);
}

.styles-module__YVejiFR:hover {
  background-color: var(--writer-green-3);
}

/* animation */
.styles-module__LYzbDfJ,
.styles-module__-2I5agK {
  border-radius: 8px;
}

.styles-module__-2I5agK {
  transition:
    var(--box-shadow-transition),
    max-height var(--issue-card-transition);
}

.styles-module__5sSyD-T {
  transition: opacity var(--issue-card-transition);
}

.styles-module__h8GGaHy,
.styles-module__ACZQ1wG,
.styles-module__NAoNfoK {
  opacity: 0;
}

.styles-module__871wVRc {
  max-height: 48px;
}

.styles-module__871wVRc .styles-module__h8GGaHy {
  opacity: 1;
}

.styles-module__LYzbDfJ:not(.styles-module__871wVRc) .styles-module__ACZQ1wG,
.styles-module__LYzbDfJ:not(.styles-module__871wVRc) .styles-module__NAoNfoK {
  opacity: 1;
}

/* /animation */

.styles-module__Zw2XRza {
  padding-left: 15px;
}
`;

export default {"container":"styles-module__LYzbDfJ","fkChangeIndicator":"styles-module__9oJm1UZ","title":"styles-module__kcppttI","multiLineEllipsis":"styles-module__zP7sMFJ","dontUseCard":"styles-module__LI0f7oE","cardTitle":"styles-module__Nelbw6m styles-module__kcppttI","cardHeader":"styles-module__IC-75lS","categoryLabel":"styles-module__ACZQ1wG styles-module__5sSyD-T","preview":"styles-module__h8GGaHy styles-module__5sSyD-T","betaTag":"styles-module__rbSvKU0 styles-module__kcppttI","labelColor":"styles-module__VX0wu-0","collapsedDescription":"styles-module__zdUM33k","collapsedLabel":"styles-module__ddqh7n4","mainContainer":"styles-module__sG4k1th","expandedContainer":"styles-module__2HGNJbK","segmentHighlight":"styles-module__DclduYq","acceptAllChangesLabel":"styles-module__tny0cQ-","subHighlight":"styles-module__N6HVG8c","textDiff":"styles-module__1QSbauz","plagiarismContainer":"styles-module__Sd9ommc","matchContainer":"styles-module__IYZLU0E","matchPercentHolder":"styles-module__tiFhGEo","matchPercentHighlight":"styles-module__33q7BHF","matchDescription":"styles-module__vy1H-Oq styles-module__zP7sMFJ","matchLinkHolder":"styles-module__cC8VBPJ","matchLink":"styles-module__yWl5LIf","acceptAllButton":"styles-module__YVejiFR","animationContainer":"styles-module__-2I5agK","animateOpacity":"styles-module__5sSyD-T","content":"styles-module__NAoNfoK styles-module__5sSyD-T","collapsedContainer":"styles-module__871wVRc","shiftedContent":"styles-module__Zw2XRza"};
export { css, digest };
  