import { Enum, getLogger } from '@writercolab/utils';
import { autorun, computed, makeObservable, observable, runInAction, untracked, when } from 'mobx';
import type { TDispose, TPromise } from '../Feature';
import { Feature } from '../Feature';

const logger = getLogger('AbstractService', '@writercolab/mobx');

export interface IAbstractServiceParams {
  /**
   * Allow service to change state to "active" mode
   */
  isEnabled(): boolean;
}

export interface IAbstractServiceParamsMixure {
  /**
   * List of features. Each features run in "active" state of service
   */
  features?: Feature<unknown>[];
  /**
   * Name of service
   */
  name: string;
}

/**
 * Various of disposable callbacks
 */
export type TDisposeService = TPromise<TDispose>;

/**
 * @deprecated use `BuildContextService` with `ManagedService` and `ConstService`
 */
export abstract class AbstractService {
  /**
   * State types
   */
  static T_STATE = new Enum('disabled', 'active', 'pause');

  /**
   * Name of service
   */
  public readonly NAME: string;

  /**
   * Stae of service
   */
  public readonly state!: typeof AbstractService.T_STATE.type;

  /**
   * Call when service change state from 'disabled' to another. And call return callback after return to 'disabled' state
   */
  onCreate: undefined | (() => TDisposeService);

  /**
   * Call when service change state to 'active' state. And call return callback after change 'active' state
   */
  onActivate: undefined | (() => TDisposeService);

  /**
   * Used when state === 'active'
   */
  onObservedActivate: undefined | (() => void);

  constructor({ features = [], isEnabled, name }: IAbstractServiceParams & IAbstractServiceParamsMixure) {
    logger.debug(name, 'contructor');
    this.NAME = name;

    // create(on) -> activate(on) -> activate(off) -> create(off)

    const $activateStopped = observable.box(true);

    const scopeCreate = new Feature({
      name: `${this.NAME}_create`,
      feature: () => {
        logger.debug(`${this.NAME}_create`, 'active');
        const cancel = untracked(() => this.onCreate?.());

        return () => {
          const flag = untracked(() => $activateStopped.get());

          if (!flag) {
            return when(() => $activateStopped.get() !== true).then(() => {
              dispose(cancel, () => {
                logger.debug(`${this.NAME}_create`, 'dispose');
              });
            });
          } else {
            return dispose(cancel, () => {
              logger.debug(`${this.NAME}_create`, 'dispose');
            });
          }
        };
      },
      isEnabled: () => true,
    });

    const scopeActivate = new Feature({
      name: `${this.NAME}_activate`,
      feature: () => {
        logger.debug(`${this.NAME}_activate`, 'active');
        const cancel = untracked(() => this.onActivate?.());
        runInAction(() => $activateStopped.set(false));

        const { onObservedActivate } = this;
        const cancelReported = onObservedActivate ? autorun(() => onObservedActivate()) : undefined;

        return () => {
          logger.debug(`${this.NAME}_activate`, 'dispose');
          cancelReported?.();

          return dispose(cancel, () => runInAction(() => $activateStopped.set(true)));
        };
      },
      isEnabled: () => isEnabled() && scopeCreate.data === true,
    });

    Object.defineProperty(this, 'state', {
      get() {
        features.forEach(feature => feature.data);

        // eslint-disable-next-line no-unused-expressions
        scopeCreate.data;

        // eslint-disable-next-line no-unused-expressions
        scopeActivate.data;

        if (!scopeCreate.data) {
          return AbstractService.T_STATE.enum.disabled;
        }

        if (!scopeActivate.data) {
          return AbstractService.T_STATE.enum.pause;
        }

        return AbstractService.T_STATE.enum.active;
      },
      enumerable: true,
      configurable: true,
    });

    makeObservable(this, { state: computed.struct });
  }

  /**
   * Run service
   * @returns disposable callback
   */
  run(): () => void {
    logger.debug(this.NAME, 'run');

    return autorun(() => this.state);
  }
}

function dispose(ref: TDisposeService | undefined, cb?: () => void): void | Promise<void> {
  if (!ref) {
    return;
  }

  if (!Feature.isDispose(ref)) {
    const ret = ref.then(ctx => {
      Feature.toCallDispose(ctx, cb);
    });

    return ret;
  }

  Feature.toCallDispose(ref, cb);
}
