
const digest = 'a7baa169b9b2ebd3512927ad5f185882678c07dc3afdb33ce037e3e7f14a5754';
const css = `.styles-module__WN2-X9S {
  max-width: 500px !important;
  background-color: var(--classic-black) !important;
  font-family: Poppins, verdana, monospace !important;
}

.styles-module__aGloqtU {
  padding: 16px !important;
  background-color: var(--classic-white) !important;
  box-shadow: var(--modal-box-shadow);
  color: var(--classic-black) !important;
}

.styles-module__-e7Zx6d {
  padding: 16px !important;
  background-color: #5551ff !important;
  box-shadow: var(--modal-box-shadow);
  color: var(--classic-white) !important;
}

.styles-module__-aEWEEi {
  background-color: var(--writer-grey-3) !important;
  color: var(--classic-white) !important;
}

.styles-module__7INnJ5S {
  color: var(--classic-black) !important;
}

.styles-module__Nu51uFo {
  color: var(--writer-grey-3) !important;
}

.styles-module__6IQqH-g {
  display: flex;
  flex-direction: column;
  padding: 4px;
  font-size: 11px;
  line-height: 18px;
}

.styles-module__6IQqH-g .MuiTooltip-tooltipPlacementRight {
  margin: 0 8px;
}

.styles-module__6IQqH-g.styles-module__WFpVHDU {
  align-items: center;
  text-align: center;
}

.styles-module__6IQqH-g.styles-module__Wbq2QcC {
  text-align: left;
}

.styles-module__tXNoAlH {
  margin-top: 5px;
  margin-bottom: 6px;
}

.styles-module__dj8GTgy {
  display: flex;
  justify-content: center;
}

.styles-module__tXNoAlH > div {
  margin: 0 !important;
}
`;

export default {"styledTooltip":"styles-module__WN2-X9S","styledTooltipWhite":"styles-module__aGloqtU","styledTooltipBlue":"styles-module__-e7Zx6d","styledTooltipGrey":"styles-module__-aEWEEi","styledArrow":"styles-module__7INnJ5S","styledArrowGrey":"styles-module__Nu51uFo","styledContent":"styles-module__6IQqH-g","center":"styles-module__WFpVHDU","left":"styles-module__Wbq2QcC","tag":"styles-module__tXNoAlH","cta":"styles-module__dj8GTgy"};
export { css, digest };
  