import type { components, RequestServiceInitialize } from '@writercolab/network';
import type { IGtmActivityParams } from '@writercolab/types';
import { AnalyticsServiceBase } from './AnalyticsServiceBase';
import type { IAnalyticsOptions, TAdapterBase } from './AnalyticsServiceBase';
import type { ContextHelper } from './types';
import { Adapter, RequestAdapter, GtmAdapter } from '../adapters';
import type { IAnalyticsGtm, IAnalyticsIdentify, IAnalyticsPage } from '../types';
import { getLogger } from '../utils/logger';

const LOG = getLogger('AnalyticsService');

type TOtherAdapters =
  | IAnalyticsGtm<IGtmActivityParams>
  | IAnalyticsIdentify<components['schemas']['analytics_dto_IdentifyRequest']['traits']>
  | IAnalyticsPage<components['schemas']['analytics_dto_PageViewRequest']>;

/**
 * Represents a type alias for an adapter in the analytics service.
 *
 * @template TContext - The type of the context used by the adapter.
 */
export type TAdapter<TParams extends Record<string, unknown>, TContext> = TAdapterBase<
  TParams,
  TContext,
  TOtherAdapters
>;

/**
 * Service for handling analytics tracking and identification.
 *
 * @template TContext - The type of the context used in the analytics service.
 *
 * @extends AnalyticsServiceBase<TContext, TActivityParamsOrAny, TOtherAdapters>
 * @implements IAnalyticsGtm<IGtmActivityParams>
 * @implements IAnalyticsIdentify<components['schemas']['analytics_dto_IdentifyRequest']['traits']>
 * @implements IAnalyticsPage<components['schemas']['analytics_dto_PageViewRequest']>
 */
export class AnalyticsService<TParamsActivity extends Record<string, unknown>, TContext = {}>
  extends AnalyticsServiceBase<TParamsActivity, TContext, TOtherAdapters>
  implements
    IAnalyticsGtm<IGtmActivityParams>,
    IAnalyticsIdentify<components['schemas']['analytics_dto_IdentifyRequest']['traits']>,
    IAnalyticsPage<components['schemas']['analytics_dto_PageViewRequest']>
{
  static convertAdapters<TContext, TParamsStatic extends Record<string, unknown>>(
    adapters: RequestServiceInitialize['api'] | TAdapter<TParamsStatic, TContext> | TAdapter<TParamsStatic, TContext>[],
  ): TAdapter<TParamsStatic, TContext>[] {
    if (Array.isArray(adapters)) {
      return [...adapters];
    } else if (adapters instanceof Adapter) {
      return [adapters];
    } else {
      return [new RequestAdapter<TContext, TParamsStatic>(adapters), new GtmAdapter<IGtmActivityParams>()];
    }
  }

  /**
   * Constructs an instance of AnalyticsService.
   *
   * @param adapters - The adapters or API used for tracking events.
   * @param integrationType - The type of integration.
   * @param clientId - The client ID, defaults to unknown if not provided.
   */
  constructor(
    adapters:
      | RequestServiceInitialize['api']
      | TAdapter<TParamsActivity, TContext>
      | TAdapter<TParamsActivity, TContext>[],
    options: IAnalyticsOptions<TContext> | string,
  ) {
    super(
      AnalyticsService.convertAdapters(adapters),
      typeof options === 'string' ? { integrationType: options } : options,
    );
  }

  /**
   * Tracks a GTM activity with the specified parameters.
   *
   * @param params - The parameters of the GTM activity.
   *
   * @returns A promise that resolves when the activity has been tracked.
   */
  async trackGtmActivity(params: IGtmActivityParams): Promise<void> {
    LOG.debug('trackGtmActivity', { params, context: this.ctx.context });

    await Promise.all(this.adapters.map(p => ('trackGtmActivity' in p ? p.trackGtmActivity(params) : undefined)));
  }

  /**
   * Identifies a user with the specified parameters.
   *
   * @param params - The identification parameters.
   *
   * @returns A promise that resolves when the user has been identified.
   */
  async identify(organizationId: number, traits: components['schemas']['analytics_dto_IdentifyRequest']['traits']) {
    LOG.debug('identify', traits);

    await Promise.all(this.adapters.map(p => ('identify' in p ? p.identify(organizationId, traits) : undefined)));
  }

  /**
   * Tracks a page view event with the provided properties.
   *
   * @param properties - The properties of the page view event, conforming to the analytics_dto_PageViewRequest schema.
   * @returns A promise that resolves when all adapters have processed the page view event.
   */
  async page(properties: components['schemas']['analytics_dto_PageViewRequest']) {
    LOG.debug('page', properties);

    await Promise.all(this.adapters.map(p => ('page' in p ? p.page(properties) : undefined)));
  }

  /**
   * Creates a new instance of `AnalyticsService` with the provided context merged with the existing context.
   *
   * @template TNewContext - The type of the new context to be merged.
   * @param {TNewContext} ctx - The new context to be merged with the existing context.
   * @returns {AnalyticsService<TNewContext & TContext>} A new instance of `AnalyticsService` with the merged context.
   */

  override withContext<TNewContext, TransformContext = 'skip'>(
    ctx: ContextHelper<TNewContext>,
    transform?: TransformContext extends 'skip' ? void : (ctx: TContext) => TransformContext,
  ) {
    const inst = new AnalyticsService<
      TParamsActivity,
      TransformContext extends 'skip' ? TContext & TNewContext : TransformContext & TNewContext
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    >(this.adapters as any, {
      integrationType: this.integrationType,
      clientId: this.clientId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      context: this.ctx.clone(ctx, transform as any) as any,
    });

    return inst;
  }
}
