// eslint-disable-next-line no-restricted-syntax
import Dompurify from 'dompurify';

export type SanitizeConfig = Dompurify.Config;

/**
 * Configuration object for sanitizing HTML content.
 *
 * @property {string[]} FORBID_TAGS - List of HTML tags to be forbidden and removed during sanitization.
 * @property {Object} USE_PROFILES - Profiles to be used for sanitization.
 * @property {boolean} USE_PROFILES.html - Indicates whether the HTML profile should be used.
 */
const CONFIG: SanitizeConfig = {
  FORBID_TAGS: ['script', 'style', 'link', 'meta', 'base', 'iframe', 'frame', 'frameset', 'object'],
  USE_PROFILES: {
    html: true,
  },
};

export function sanitize(html: string, config = CONFIG): string {
  return Dompurify.sanitize(html, config) as string;
}
