import type { SharedQueryParam } from '@writercolab/types';
import type { SharedUtmQueryParam } from '../types';
import { SharedQueryParamStandardValue } from '../types';

export function getParamValue(
  param: SharedQueryParam | SharedUtmQueryParam,
  locationSearch?: string,
  defaultVal?: string,
): null | string {
  const _locationSearch = locationSearch || window.location.search;
  const existingQueryParams = new URLSearchParams(_locationSearch);

  return (existingQueryParams.has(param) && existingQueryParams.get(param)) || defaultVal || null;
}

export function getStandardParamValueAsBoolean(param: SharedQueryParam, locationSearch?: string): boolean {
  const _locationSearch = locationSearch || window.location.search;
  const existingQueryParams = new URLSearchParams(_locationSearch);

  return existingQueryParams.has(param) && existingQueryParams.get(param) === SharedQueryParamStandardValue.positive;
}

export const extractQueryParameters = (url: string): Record<string, string> => {
  const _url = new URL(url);
  const queryParams = new URLSearchParams(_url.searchParams);

  return Object.fromEntries(queryParams.entries());
};

export const extractSourcePath = (url: string): string => {
  const _url = new URL(url);

  return `${_url.origin}${_url.pathname}`;
};

export const getTopLevelDomain = (): string => {
  const domainParts = window.location.hostname.split('.');
  let topLevelDomain = domainParts[domainParts.length - 1];

  if (domainParts && domainParts[domainParts.length - 2]) {
    topLevelDomain = `${domainParts[domainParts.length - 2]}.${topLevelDomain}`;
  }

  return topLevelDomain;
};
