import type { ITeam, IUserPartial, IUserProfile } from '@writercolab/types';

export enum SharingOptions {
  PRIVATE = 'private',
  TEAM = 'public',
  MEMBERS = 'shared',
}

export interface IDocument extends IDocumentShareStatus {
  id: number;
  team: ITeam;
  score: number;
  createdUser: IUserPartial;
  modifiedUser: IUserPartial;
  title: string;
  modifiedUserId: number;
  organizationId: number;
  createdUserId: number;
  creationTime: string;
  modificationTime: string;
  modifiedByMeTime: string;
  openedByMeTime: string;
  favorite: boolean;
}

export interface ICreateDocument extends IDocument {
  teamId: number;
  deleted: boolean;
}

export interface IDocumentShareStatus {
  access: SharingOptions;
  users?: IUserProfile[];
}

export interface IDocumentShareStatusPayload {
  access: SharingOptions;
  userIds?: number[];
}
