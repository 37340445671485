import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  isMacOs,
  BillingProduct,
  CONTACT_SALES_LINK,
  NUMBER_OF_WORDS_FOR_AUTO_WRITE,
} from '@writercolab/common-utils';
import { AiAssistantSubscriptionModel } from '@writercolab/models';
import {
  IconVariant,
  Tag,
  TagColor,
  Text,
  TextColor,
  TextSize,
  useCustomSnackbar,
} from '@writercolab/ui-atoms';
import { AnalyticsEvents } from '../../utils/constants';
import styles from "../../components/ContentForm/style.module.scss";

export const useAutoWrite = (quillRef, modelRef, subscription, organizationId, workspaceId, requestService) => {
  const subscriptionModel = useMemo(
    () => {
      return new AiAssistantSubscriptionModel({
        api: requestService.api,
        organizationId: () => organizationId,
        teamId: () => workspaceId,
      });
    }, [requestService, organizationId, workspaceId],
  );

  useEffect(() => {
    subscriptionModel.$subscription.reload();
  }, [subscriptionModel]);

  const { enqueueCustomSnackbar } = useCustomSnackbar();
  const checkClaimsOnNextSave = useRef(false);

  const coWriteLimits = useMemo(() => ({
    exceeded: subscriptionModel.limits?.coWrite.exceeded,
    limit: subscriptionModel.limits?.coWrite.limit,
    value: subscriptionModel.limits?.coWrite.value,
  }), [subscriptionModel.limits?.coWrite]);
  const currentContentWords = modelRef.current?.documentStats?.contentMetrics?.wordCount;

  const showLimitExceededSnackbar = useCallback(
    () =>
      enqueueCustomSnackbar(`You're out of words for this month! Upgrade to Enterprise to get more.`, {
        icon: IconVariant.LIGHTNING_UNION,
        iconClass: styles.lightningWarnIcon,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const scheduleClaimsOnNextSave = () => {
    checkClaimsOnNextSave.current = true;
  };
  const [autoWriteLoading, setAutoWriteLoading] = useState(false);
  const isAutoWriteAvailable = useMemo(
    () =>
      subscription &&
      subscription.status !== 'canceled' &&
      subscription.productName !== BillingProduct.FREE &&
      !coWriteLimits.exceeded &&
      currentContentWords >= NUMBER_OF_WORDS_FOR_AUTO_WRITE,
    [
      subscription,
      coWriteLimits.exceeded,
      currentContentWords,
    ],
  );

  const onClickAutoWrite = useCallback(async (triggeredFromShortcut) => {
      if (coWriteLimits.exceeded) {
        showLimitExceededSnackbar();
      }

      if (!isAutoWriteAvailable) {
        return;
      }

      if (autoWriteLoading) {
        enqueueCustomSnackbar(`Hang on! We're still generating content for you.`, {
          icon: IconVariant.LIGHTNING_UNION,
          iconClass: styles.lightningWarnIcon,
        });

        return;
      }

      const editor = quillRef.current;

      if (!editor) {
        return;
      }

      modelRef.current?.analytics.track(AnalyticsEvents.triggerAutoWrite, {
        trigger_type: triggeredFromShortcut === true ? 'keyboard_shortcut' : 'button',
      });

      const autowriteModule = quillRef.current.editor.getModule('autowrite');

      setAutoWriteLoading(true);

      try {
        const { hasContent } = await autowriteModule.keepWriting();

        if (!hasContent) {
          enqueueCustomSnackbar(`We weren't able to generate content. Please try again.`, {
            icon: IconVariant.LIGHTNING_UNION,
            iconClass: styles.lightningWarnIcon,
          });
        } else {
          scheduleClaimsOnNextSave();
        }
      } catch (e) {
        showLimitExceededSnackbar();
      } finally {
        setAutoWriteLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      coWriteLimits.exceeded,
      isAutoWriteAvailable,
      autoWriteLoading,
      modelRef,
    ],
  );

  useEffect(() => {
    if (!subscription || subscription.productName === BillingProduct.FREE || subscription.status === 'canceled') {
      setAutoWriteToolTipText({
        tooltipText: (
          <div>
            <Text variant={TextSize.M} bold inline color={TextColor.WHITE}>
              Keep writing is available on Team plans.
            </Text>{' '}
            <a target="_blank" rel="noreferrer" href={`https://${
              process.env.NODE_ENV !== 'production'
                ? process.env.REACT_APP_API_HOST
                : window.env.REACT_APP_API_HOST
            }/organization/${organizationId}/admin/billing?triggerUpdateTo=${BillingProduct.TEAM}`}>
              <Text variant={TextSize.M} bold underline inline color={TextColor.WHITE}>
                Upgrade to Team
              </Text>
            </a>{' '}
            <Text variant={TextSize.M} bold inline color={TextColor.WHITE}>
              to enable.
            </Text>
          </div>
        ),
        tooltipSize: 2,
        enabled: false,
      });
    } else if (coWriteLimits?.exceeded) {
      setAutoWriteToolTipText({
        tooltipText: (
          <div>
            <Text variant={TextSize.M} color={TextColor.WHITE} bold>
              Keep writing is not available,
            </Text>
            <Text variant={TextSize.M} color={TextColor.WHITE} bold>
              because you’ve reached your limit
            </Text>
            <Text variant={TextSize.M} color={TextColor.WHITE} bold>
              of {coWriteLimits?.limit} words generated
            </Text>
            <Text variant={TextSize.M} color={TextColor.WHITE} bold className={styles.contactLink}>
              To raise your limit,{' '}
              <a target="_blank" rel="noreferrer" href={CONTACT_SALES_LINK}>
                contact sales
              </a>
              .
            </Text>
          </div>
        ),
        tooltipSize: 3,
        enabled: false,
      });
    } else if (!isAutoWriteAvailable) {
      setAutoWriteToolTipText({
        tooltipText: 'Keep writing is only available for docs with >5 words',
        tooltipSize: 1,
        enabled: false,
      });
    } else {
      setAutoWriteToolTipText({
        tooltipText: (
          <div>
            <Text variant={TextSize.M} bold color={TextColor.WHITE}>
              Keep writing
            </Text>
            <Text variant={TextSize.M} color={TextColor.WHITE} className={styles.autoWriteTooltip}>
              {isMacOs() ? 'cmd + enter' : 'ctrl + enter'}
            </Text>
            <Tag color={TagColor.LIGHT_GREEN} className={styles.betaTag}>
              <Text caps>Beta</Text>
            </Tag>
          </div>
        ),
        tooltipSize: 1,
        enabled: true,
      });
    }
  }, [
    isAutoWriteAvailable,
    organizationId,
    subscription,
    coWriteLimits,
  ]);

  const [autoWriteToolTipText, setAutoWriteToolTipText] = useState({
    tooltipText: 'Keep writing',
    tooltipSize: 0,
    enabled: true,
  });

  return { onClickAutoWrite, isAutoWriteAvailable, autoWriteLoading, autoWriteToolTipText };
};
