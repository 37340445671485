import { PaginatedModel } from '@writercolab/mobx';

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

interface TPaginatedArgs {
  offset: number;
  limit: number;
}

interface TPaginatedExtraArgs {
  search?: string;
}

interface TOAuthAppsQuery extends TPaginatedArgs, TPaginatedExtraArgs {}

interface TPaginatedResponse {
  totalCount: number;
  pagination: TPaginatedArgs;
  result: TResponse[];
}

interface TResponse {
  id: string;
  name: string;
}

// Mock Data for the Response
const mockOAuthAppsData: TResponse[] = Array.from({ length: 200 }, (_, i) => ({
  id: `app-${i}`,
  name: `App ${i}`,
}));

// Mock Load Function with Delay
const mockLoad = async ({ args, extra }: { args: TPaginatedArgs; extra: TPaginatedExtraArgs }) => {
  const query: TOAuthAppsQuery = { ...args, ...extra };
  console.log('Mock API called with query:', query);

  // Simulate API delay
  await delay(1000);

  // Filter and sort mock data based on `query`
  let filteredData = [...mockOAuthAppsData];

  if (query.search) {
    filteredData = filteredData.filter(app => app.name.toLowerCase().includes(query.search?.toLowerCase() ?? ''));
  }

  // Paginate the results based on offset and limit
  const paginatedData = filteredData.slice(query.offset, query.offset + query.limit);

  // Return a structure similar to the real API response
  return {
    totalCount: filteredData.length,
    pagination: { offset: query.offset, limit: query.limit },
    result: paginatedData,
  } as TPaginatedResponse;
};

// Using Mock Load Function in PaginatedModel

export const mockPaginatedModel = new PaginatedModel<
  TPaginatedResponse,
  TResponse,
  TPaginatedArgs,
  TPaginatedExtraArgs
>({
  argsExtra: {
    search: undefined,
  },
  argsDefault: {
    offset: 0,
    limit: 20,
  },
  extractMeta: obj => {
    const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

    return offset >= obj.totalCount ? { offset: 0, limit: 20 } : { offset, limit: 20 };
  },
  extract: obj => obj.result ?? [],
  load: mockLoad,
});
