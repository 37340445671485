import cx from 'classnames';
import { Button, ButtonTypes, Icon, IconVariant, SizeTypes, Tooltip } from '@writercolab/ui-atoms';
import styles from "./style.module.scss";

export const KeepWritingButton = ({ className, onClickAutoWrite, isAutoWriteAvailable, autoWriteLoading, autoWriteToolTipText }) => {
  return (
    <div className={className} id='writer-auto-write-button'>
      <Tooltip
        disabled={!autoWriteToolTipText.tooltipText}
        tooltipWrapperClassname={cx({
          [styles.tooltipSmall]: autoWriteToolTipText.tooltipSize === 0,
          [styles.tooltipMedium]: autoWriteToolTipText.tooltipSize === 1,
          [styles.tooltipLarge]: autoWriteToolTipText.tooltipSize === 2,
        })}
        title={autoWriteToolTipText.tooltipText}
        placement='bottom'
      >
        <Button
          className={cx(styles.containerToolButton, {
            [styles.toolButtonSquaredLoading]: autoWriteLoading,
            [styles.toolButtonSquaredLoadingMauve]: autoWriteLoading,
          })}
          disabled={!isAutoWriteAvailable}
          htmlType='button'
          round
          size={SizeTypes.MEDIUM}
          type={ButtonTypes.MAUVE}
          icon={<Icon name={isAutoWriteAvailable ? IconVariant.LIGHTNING_UNION_MAUVE : IconVariant.LIGHTNING_UNION} />}
          onClick={() => onClickAutoWrite(false)}
        />
      </Tooltip>
    </div>
  );
};
