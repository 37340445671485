import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { SidebarModel } from '@writercolab/ui-sidebar-models';
import { UISidebarModel } from './UISidebarModel';
import { Sidebar, type IUiMixinProps } from './Sidebar';

export interface ISidebarPure extends IUiMixinProps {
  model: SidebarModel;
}

/**
 * @deprecated please use Sidebar from @writercolab/ui-sidebar
 */
export const SidebarPure: FC<ISidebarPure> = ({ model, ...props }) => {
  const uiModel = useMemo(() => new UISidebarModel({ model }), [model]);

  return <Sidebar {...props} model={uiModel} />;
};
