import { getDescription } from '@writercolab/ui-sidebar';

import { TERM_SKIP_CATEGORIES } from './constants';

export function isTermAvailable(category) {
  return !TERM_SKIP_CATEGORIES.has(category);
}

export function getIssueCardPayload({
  description,
  meta,
  category,
  suggestions,
  issueType,
  highlight,
  sidebarCategory,
}) {
  return {
    issueType,
    correction: suggestions,
    category,
    sidebarCategory,
    highlight,
    subHighlight: meta?.pos,
    examples: meta?.examples,
    description: getDescription(
      category,
      description,
      highlight,
      suggestions?.[0],
    ),
    addTermAvailable: isTermAvailable(category),
  };
}
