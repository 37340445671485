
const digest = '445784653d79ccdba1cc01af98a28d4dcd38d924cc995f3b8fdd79b289155091';
const css = `.styles-module__zUIrZtf {
  position: relative;
  flex-shrink: 0;
}

.styles-module__4NWcm1l {
  position: absolute;
  z-index: 2;
  bottom: 40px;
  left: 0;
}

.styles-module__3KLKcqw {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  border: 0;
  background: transparent;
}

.styles-module__kzaf3Lm {
  display: flex;
  height: 34px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.styles-module__kdRqawA {
  color: var(--classic-grey-2);
}

.styles-module__OW3u5bJ {
  transform: rotate(180deg);
  transform-origin: center;
}

.styles-module__PuuC4ZG {
  width: 34px;
}

.styles-module__8wOq6QC {
  background: var(--writer-green-3);
}

.styles-module__cUTg1Rz {
  background: var(--writer-blue-2);
}

.styles-module__7ZrXMvZ {
  background: var(--classic-purple-1);
}

.styles-module__7pkH7PY {
  background: var(--classic-grey-1);
}
`;

export default {"main":"styles-module__zUIrZtf","dropdown":"styles-module__4NWcm1l","selectedOption":"styles-module__3KLKcqw","selectedOptionValue":"styles-module__kzaf3Lm","arrow":"styles-module__kdRqawA","arrowUp":"styles-module__OW3u5bJ","gradeLevelValue":"styles-module__PuuC4ZG","statValueGreen":"styles-module__8wOq6QC","statValueBlue":"styles-module__cUTg1Rz","statValuePurple":"styles-module__7ZrXMvZ","statValueGray":"styles-module__7pkH7PY"};
export { css, digest };
  