import { action, computed, createAtom, observable } from 'mobx';
import isEqual from 'lodash/isEqual';
import { getLogger } from '@writercolab/utils';

const logger = getLogger('LinkedModel');

export interface ILinkedModelParams<T, TSet extends (...args: unknown[]) => void> {
  get(): T;
  set: TSet;
  name?: string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class LinkedModel<T, TSet extends (...args: any[]) => unknown> {
  static ID = 0;

  private atomUpdate = createAtom('atomUpdate');

  readonly get: () => T;

  readonly set: TSet;

  readonly name: string;

  private loadingState = observable.box(false);

  get loading() {
    const ret = this.loadingState.get();
    logger.debug('loading', ret);

    return ret;
  }

  refresh() {
    logger.debug('refresh', this.name);
    this.atomUpdate.reportChanged();
  }

  constructor(opts: ILinkedModelParams<T, TSet>) {
    this.name = opts.name ?? `LinkedModel-${LinkedModel.ID++}`;
    const setter = (...args: unknown[]) => {
      logger.debug('setter', this.name);
      this.loadingState.set(true);
      const ret = opts.set.apply(this, args);

      if (ret instanceof Promise) {
        ret.then(
          () => {
            this.atomUpdate.reportChanged();
            this.loadingState.set(false);
          },
          () => {
            this.loadingState.set(false);
          },
        );
      } else {
        this.atomUpdate.reportChanged();
        this.loadingState.set(false);
      }

      return ret;
    };

    const getter = computed(
      () => {
        logger.debug('getter', this.name);
        this.atomUpdate.reportObserved();

        return opts.get();
      },
      {
        name: this.name,
        equals: (a, b) => {
          const ret = isEqual(a, b);

          if (!ret) {
            logger.debug('update', this.name);
          } else {
            logger.debug('cached', this.name);
          }

          return ret;
        },
      },
    );

    this.set = action(setter as unknown as TSet);
    this.get = () => getter.get();
  }
}
