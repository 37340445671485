import { IntegrationType, H_INTEGRATION_TYPE_TO_CLIENT_ID } from '@writercolab/types';
import type { AnalyticsEventProperties } from '../analytics/analytics';
import Request from '../../utils/requestUtils';

/**
 * @deprecated use AnalytisService from @writercolab/analytics instead
 */
export const internalTrackActivity = async (
  orgId: number | string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventName: any,
  activityProperties?: AnalyticsEventProperties,
): Promise<void> => {
  const initialEventProperties = {
    signup_method: '',
    referral: '',
    utm_content: '',
    utm_referrer: '',
    utm_campaign: '',
    utm_medium: '',
    utm_term: '',
    utm_source: '',
    client_id: H_INTEGRATION_TYPE_TO_CLIENT_ID[IntegrationType.DEFAULT],
  };

  await Request.getAxiosInstance().post(`/anlytcs/organization/${orgId}/track`, {
    eventName,
    properties: { ...initialEventProperties, ...activityProperties },
  });
};
