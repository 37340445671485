/**
 * Options for creating a factory.
 * @template T - The type of object that the factory will create.
 * @template TParams - The type of parameters that will be passed to the factory's create and recreate methods.
 */
export interface IFactoryOptions<T, TParams> {
  /** The set of objects that the factory will manage. */
  readonly list?: Set<T>;

  /** Creates a new instance of the object that the factory will manage. */
  create(params: TParams): T;

  /**
   * Re-creates an existing instance of the object that the factory manages.
   * This method is called when the factory needs to recycle an existing object instead of creating a new one.
   */
  recreate(instance: T, params: TParams): void;

  /** Called when an object is disposed of by the factory. */
  onDispose?(value: T): void;

  /** Called when a new object is created by the factory. */
  onCreate?(value: T): void;
}

/**
 * A factory for managing a set of objects of a specific type.
 * @template T - The type of object that the factory manages.
 * @template TParams - The type of parameters that will be passed to the factory's create and recreate methods.
 */
export interface IFactory<T, TParams> {
  /** Creates a new instance of the object that the factory manages. */
  create(params: TParams): T;

  /** Disposes of an object that the factory manages. */
  dispose(value: T): void;
}

/**
 * Creates a new factory for managing a set of objects of a specific type.
 * @template T - The type of object that the factory will manage.
 * @template TParams - The type of parameters that will be passed to the factory's create and recreate methods.
 * @param options - The options for the factory.
 * @returns A new factory for managing objects of type T.
 */
export function createFactory<T, TParams>({
  list = new Set<T>(),
  create,
  recreate,
  onCreate,
  onDispose,
}: IFactoryOptions<T, TParams>): IFactory<T, TParams> {
  return {
    create(params: TParams) {
      const iter = list.values().next();

      if (!iter.done) {
        const item = iter.value;
        list.delete(item);

        recreate(item, params);

        onCreate?.(item);

        return item;
      } else {
        const item = create(params);
        onCreate?.(item);

        return item;
      }
    },

    dispose(value: T) {
      onDispose?.(value);
      list.add(value);
    },
  };
}

/**
 * Creates a cache factory that generates cache functions based on the provided parameters.
 *
 * @template T - The type of the cache item.
 * @template TParams - The type of the parameters used to generate cache items.
 * @template TId - The type of the cache item identifier.
 * @param {Object} options - The options object.
 * @param {Function} options.hash - A function that generates a unique identifier for the cache item based on the parameters.
 * @param {Function} options.create - A function that creates a new cache item based on the parameters.
 * @param {Function} [options.update] - An optional function that updates an existing cache item based on the parameters.
 * @returns {Object} - An object containing the `next` function that generates cache functions.
 */
export function createCacheFactory<T, TParams, TId = string>({
  hash,
  create,
  update,
}: {
  hash: (params: TParams) => TId;
  create: (params: TParams) => T;
  update?: (inst: T, params: TParams) => void;
}) {
  let map = new Map<TId, T>();

  return {
    /**
     * Retrieves the cache.
     * @returns The cache object.
     */
    getCache(): Map<TId, T> {
      return map;
    },
    /**
     * Clears the cache.
     */
    clear(): void {
      map.clear();
    },
    /**
     * Generates a cache function based on the current state of the cache.
     *
     * @returns {Function} - A cache function that takes parameters and returns a cache item.
     */
    next(): (params: TParams) => T {
      const cache = map;
      const local = new Map();
      map = local;

      return (params: TParams) => {
        const id = hash(params);
        // first of all we try to find object in old cache
        // but we created object on the current tick of the loop
        // it could be in the new cache (local)
        const ref = cache.get(id) ?? local.get(id);

        if (ref) {
          update?.(ref, params);
        }

        const item = ref ?? create(params);

        local.set(id, item);

        return item;
      };
    },
  };
}
