import React, { useState } from 'react';
import { wordPluralize } from '@writercolab/text-utils';
import { Button, ButtonTypes, Modal, Text, TextSize, Icon, IconVariant } from '@writercolab/ui-atoms';
import styles from './styles.module.css';
import { getLogger } from '../../utils/logger';

const LOG = getLogger('DeleteTerms');

interface IDeleteTermsModalProps {
  isOpen: boolean;
  numOfTerms: number;
  changeModalState: (flag: boolean) => void;
  onSubmit: () => void;
  isSnippet?: boolean;
}

const DeleteTermHeader: React.FC<{
  numOfTerms: number;
  isSnippet?: boolean;
}> = ({ numOfTerms, isSnippet = false }) => {
  const message = `Delete ${numOfTerms} ${wordPluralize(numOfTerms, isSnippet ? 'snippet' : 'term')}`;

  return (
    <Text variant={TextSize.XXL} bold>
      {message}
    </Text>
  );
};

const DeleteTermDescription: React.FC<{
  numOfTerms: number;
  isSnippet?: boolean;
}> = ({ numOfTerms, isSnippet = false }) => {
  let message = `Are you sure you want to permanently delete this ${isSnippet ? 'snippet' : 'term'}?`;

  if (numOfTerms > 1) {
    message = `Are you sure you want to permanently delete these ${isSnippet ? 'snippets' : 'terms'}?`;
  }

  return (
    <Text variant={TextSize.M} bold>
      {message}
    </Text>
  );
};

export const DeleteTermsModal: React.FC<IDeleteTermsModalProps> = ({
  isOpen,
  numOfTerms,
  changeModalState,
  onSubmit,
  isSnippet,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseModal = () => changeModalState(false);
  const handleDeleteTerm = async () => {
    setIsLoading(true);
    try {
      await onSubmit();
    } catch (err) {
      LOG.error(err);
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <Modal open={isOpen} title="" className={styles.deleteModal} hideCloseButton {...props}>
      <div className={styles.styledDeleteContent}>
        <Icon name={IconVariant.TRASH} className={styles.icon} />
        <DeleteTermHeader numOfTerms={numOfTerms} isSnippet={isSnippet} />
        <DeleteTermDescription numOfTerms={numOfTerms} isSnippet={isSnippet} />
        <div className={styles.controlButtons}>
          <Button
            className={styles.submitButton}
            type={ButtonTypes.DEFAULT}
            content="No, cancel"
            onClick={handleCloseModal}
          />
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            danger
            content="Yes, delete"
            onClick={handleDeleteTerm}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};
