import { Enum } from '@writercolab/utils';

export const MediaFileTabs = new Enum('URL', 'UPLOAD', 'SELECT_EXISTING', 'PASTE_TRANSCRIPT');
export type TMediaFileTabs = typeof MediaFileTabs.type;

export const SupportedFileUploadExt = new Enum(
  'pdf',
  'docx',
  'txt',
  'html',
  'csv',
  'xlsx',
  'pptx',
  'srt',
  'mp3',
  'mp4',
);
export type TSupportedFileUploadExt = typeof SupportedFileUploadExt.type;

export const SupportedImageUploadExt = new Enum(
  'raw',
  'ico',
  'pdf',
  'tiff',
  'gif',
  'jpeg',
  'jpg',
  'png',
  'bmp',
  'svg',
  'svgz',
  'webp',
);
export type TSupportedImageUploadExt = typeof SupportedImageUploadExt.type;

export type IAbstractFile = {
  fileId?: string;
  id?: string;
  name: string;
  url?: string | null;
};

interface IListModel<T> {
  list: T[];
  total: number;
  refresh: () => void;
}

export type IFilesListModel = IListModel<IAbstractFile>;

export interface IUploadNewFileModel {
  upload: (
    file: File,
    opts?: {
      abortSignal?: AbortSignal;
    },
  ) => Promise<IAbstractFile>;
}

export interface IUploadNewUrlModel {
  uploadAsUrl: (url: string) => Promise<IAbstractFile>;
}
