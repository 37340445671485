import type { UserTeamRole } from './auth.types';

export type TeamId = number | null;

export interface ITeamAdmins {
  id: number;
  email: string;
  fullName: string;
  role?: UserTeamRole;
}

export interface ITeamTermBank {
  id: number;
  teamId: number;
  teamName: string;
}

export enum RemovePersonActionType {
  ASSIGN_ALL_DOCS = 'owned_documents',
  ASSIGN_SHARED_DOCS = 'shared_documents',
  DELETE_ALL_DOCS = 'delete',
}
/**
 * @deprecated Migrate to network types
 */
export interface ITeam {
  id: number;
  name: string;
  organizationId: number;
  snippet: {
    teamName: string;
    teamId?: number;
  };
  styleguide: {
    styleguideId: string;
    teamName: string;
    teamId?: number;
  };
  termBank: {
    id: number;
    teamName: string;
    teamId?: number;
  };
}

/**
 * @deprecated Migrate to network types
 */
export interface ITeamDetails extends ITeam {
  acceptedSuggestionsCount: number;
  activeUsersCount: number;
  admins: ITeamAdmins[];
  creationTime: string;
  modificationTime: string;
  snippetCount: number;
  termCount: number;
  userCount: number;
  wordsCheckedCount: number;
}

export interface ITeamDetailsExtended extends ITeam {
  termBank: ITeamTermBank;
  admins: ITeamAdmins[];
  termCount: number;
  snippetCount: number;
  activeUsersCount: number;
  acceptedSuggestionsCount: number;
  wordsCheckedCount: number;
}
