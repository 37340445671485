// Enum to represent the type of operation being performed on an array element
export enum ELog {
  Retain = 0, // element is retained
  Add = 1, // element is added
  Delete = 2, // element is deleted
}

export type TLogExtendedItem<TSource, TTarget> =
  | [ELog.Retain, ...TSource[]]
  | [ELog.Delete, ...TSource[]]
  | [ELog.Add, ...TTarget[]];

// Type to represent a log of changes between two arrays
export type TLogExtended<TSource, TTarget> = Array<TLogExtendedItem<TSource, TTarget>>;

// Interface to represent the options for optimizing a log of changes
export interface ILogOptimize<TTarget> {
  type: 'patch';
  data: Array<ELog | number | TTarget>;
}

// Interface to represent a lookup table for storing the length of LCS
export interface ILookup {
  get(r: number, c: number): number;
}

// Type to represent a slice of an array-like object
export type TArrayLikeSlice<T> = ArrayLike<T> & {
  slice(start?: number | undefined, end?: number | undefined): T extends string ? string : T[];
};

export interface IDiffLogger<TSource, TTarget> {
  add(...args: [ELog.Add, TTarget] | [ELog.Retain, TSource] | [ELog.Delete, TSource]): void;
}
