import type { User } from 'firebase/auth';

import { TestProperties } from '@writercolab/mobx';

import type { ISidebarDataApi, SidebarDataClient, ISidebarDataParams } from '@writercolab/types';

const { mock } = TestProperties;

export const API = mock<ISidebarDataApi>({});
export const USER = mock<User>({});
export const PARAMS = mock<ISidebarDataParams>({});

export function createFirebaseClientMock({
  getUser = () => USER,
  getApi = () => API,
  getParams = () => PARAMS,
}: {
  getUser?: () => User | undefined;
  getApi?: () => ISidebarDataApi;
  getParams?: () => ISidebarDataParams;
} = {}): SidebarDataClient {
  return Object.defineProperties({} as SidebarDataClient, {
    api: {
      enumerable: true,
      configurable: true,
      get: getApi,
    },
    user: {
      enumerable: true,
      configurable: true,
      get: getUser,
    },
    params: {
      enumerable: true,
      configurable: true,
      get: getParams,
    },
  });
}
