
const digest = '3a816e9b37ef0ac3c72948033cb329d4e4082b569ccabf5f8c7521ac5605833b';
const css = `.styles-module__J8hYE8S {
  z-index: 0;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 10px;
  cursor: pointer;
  user-select: none;
}

.styles-module__lmgz1Ff {
  margin-left: 6px;
  color: var(--classic-grey-2);
  cursor: pointer;
}

.styles-module__J8hYE8S:hover .styles-module__lmgz1Ff {
  color: var(--black);
}

.styles-module__J8hYE8S i {
  position: relative;
  display: flex;
  width: 26px;
  height: 26px;
  align-items: center;
}

.styles-module__J8hYE8S i svg {
  position: relative;
  z-index: 1;
}

.styles-module__J8hYE8S:hover i::before {
  position: absolute;
  z-index: 1;
  left: -3px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: var(--writer-blue-2);
  content: '';
}
`;

export default {"addRowButton":"styles-module__J8hYE8S","addRowButtonText":"styles-module__lmgz1Ff"};
export { css, digest };
  