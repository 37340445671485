import React from 'react';
import { type ReactNode, useCallback } from 'react';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { BillingProduct, CategoryType, SidebarCategoryStatus } from '@writercolab/types';
import { SkeletonLoader, SkeletonLoaderType } from '@writercolab/ui-atoms';
import styles from './styles.module.css';
import { UIIssuesListModel } from './UIIssuesListModel';
import { PaidFeature } from '../../molecules/PaidFeature';

import { NoIssues, NoIssuesVariant } from '../../molecules/NoIssues';
import { EnterpriseCancelled } from '../../molecules/EnterpriseCancelled';
import { TrialExpired } from '../../molecules/TrialExpired';
import { PlanCancelled } from '../../molecules/PlanCancelled';
import { FreeCancelled } from '../../molecules/FreeCancelled';
import { VisibleIssuesList } from '../VisibleIssuesList';
import { UISidebarAnalyticsActivity, fromParamConfig } from '../../../analytics';

export { UIIssuesListModel };

export interface IssuesListProps {
  model: UIIssuesListModel;
}

const UnlockedState: React.FC<{ isShrinked?: boolean; children?: ReactNode }> = ({ children, isShrinked }) => (
  <div
    className={cx(styles.container, {
      [styles.containerMini]: isShrinked,
    })}
  >
    {children}
  </div>
);

export const IssuesList = observer<IssuesListProps>(({ model }) => {
  const {
    analytics,
    selectedCategory,
    isStandard,
    isSmallPadding,
    isLoading,
    isEmptyState,
    isEnterprisePlanCancelled,
    isNoIntegration,
    isTeamTrialExpired,
    isOrgAdmin,
    billingPageHref,
    isTeamPlanCancelled,
    isFreeCancelled,
    activateFreeHref,
    paidFeatureHref,
    currentSidebarIssues,
    modelVisibleIssuesList,
  } = model;

  const onPaidFeatureClick = useCallback(() => {
    if (!selectedCategory) {
      return;
    }

    const activity =
      selectedCategory?.billingProduct === BillingProduct.TEAM
        ? UISidebarAnalyticsActivity.startTeamTrial
        : UISidebarAnalyticsActivity.purchasePro;
    analytics.track(activity, {
      clicked_from: fromParamConfig[selectedCategory.id as unknown as keyof typeof fromParamConfig],
    });

    window.open(paidFeatureHref, '_blank');
  }, [analytics, selectedCategory, paidFeatureHref]);

  const renderUnlockedStateContent = () => {
    if (isEnterprisePlanCancelled) {
      return <EnterpriseCancelled />;
    }

    if (isTeamTrialExpired) {
      return <TrialExpired isOrgAdmin={isOrgAdmin} onClickHref={billingPageHref ?? ''} />;
    }

    if (isTeamPlanCancelled) {
      return <PlanCancelled isOrgAdmin={isOrgAdmin} onClickHref={billingPageHref} />;
    }

    if (isFreeCancelled) {
      return <FreeCancelled isOrgAdmin={isOrgAdmin} onClickHref={activateFreeHref} />;
    }

    if (isLoading) {
      return (
        <SkeletonLoader
          type={SkeletonLoaderType.ISSUE_CARD}
          repeat={9}
          className={cx(styles.issuesListSkeleton, { [styles.issuesListSkeletonWide]: isSmallPadding })}
        />
      );
    }

    if (isNoIntegration) {
      return <NoIssues variant={NoIssuesVariant.enum.noIntegration} />;
    }

    if (isEmptyState) {
      return <NoIssues variant={NoIssuesVariant.enum.emptyDocument} />;
    }

    if (!currentSidebarIssues.length) {
      return <NoIssues variant={NoIssuesVariant.enum.noIssuesFound} description={selectedCategory ? ' ' : ''} />;
    }

    return <VisibleIssuesList model={modelVisibleIssuesList} isSmallPadding={isSmallPadding} />;
  };

  return selectedCategory?.status === SidebarCategoryStatus.LOCKED ? (
    <div className={styles.enterpriseContainer}>
      <PaidFeature selectedCategory={selectedCategory} onClick={onPaidFeatureClick} />
    </div>
  ) : (
    <UnlockedState isShrinked={!isStandard}>{renderUnlockedStateContent()}</UnlockedState>
  );
});
