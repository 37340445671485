import isEqual from 'lodash/isEqual';
import type { IEqualsComparer } from 'mobx';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { PromisedError } from '@writercolab/errors';

export interface ILoadModelProps<T, TParams> {
  load: (params: TParams) => Promise<T>;
  equals?: IEqualsComparer<T>;
  name?: string;
}
export class LoadModel<T, TParams = void> {
  static ID = 0;

  private readonly load: ILoadModelProps<T, TParams>['load'];

  private readonly $data = observable.box<T | undefined | PromisedError>(undefined, { deep: false });

  private readonly $loading = observable.box<'pending' | 'rejected' | 'fulfilled'>('pending');

  public readonly name: string;

  constructor(opts: ILoadModelProps<T, TParams>) {
    this.load = opts.load;
    this.name = opts.name ?? `PromisedModel-${LoadModel.ID++}`;
    const equals = opts.equals ?? isEqual;
    makeObservable(this, {
      valueWithError: computed({ equals }),
      value: computed({ equals }),
      status: computed,
    });
  }

  get valueWithError() {
    return this.$data.get();
  }

  get value() {
    const val = this.$data.get();

    return val instanceof PromisedError ? undefined : val;
  }

  get valueSmoothWithError() {
    return this.valueWithError;
  }

  get valueSmooth() {
    return this.value;
  }

  get status() {
    return this.$loading.get();
  }

  clear() {
    runInAction(() => this.$data.set(undefined));
  }

  async refresh(params: TParams) {
    try {
      runInAction(() => this.$loading.set('pending'));
      const data = await this.load(params);
      runInAction(() => {
        this.$data.set(data);
        this.$loading.set('fulfilled');
      });

      return data;
    } catch (err) {
      const error = new PromisedError(err);
      runInAction(() => {
        this.$data.set(error);
        this.$loading.set('rejected');
      });
      throw error;
    }
  }
}
