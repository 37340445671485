
const digest = '2bc1750d7e22a8383510e8e79fa5f9a73236e9d012682f27a2af1066c80115ff';
const css = `.styles-module__Kh8GUng {
  width: 100%;
  padding: 12px var(--sidebar-horizontal-spacing) 0 0;
}

.styles-module__0uSENk2 {
  width: 100%;
  padding: 12px 20px 0 0 !important;
}

.styles-module__5A5Sgf4 {
  width: 40px;
  height: 40px;
}

.styles-module__0uSENk2 .styles-module__Gq5UCeU {
  padding: 0;
}

.styles-module__Gq5UCeU {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  margin-bottom: 12px;
}
`;

export default {"categoriesPanel":"styles-module__Kh8GUng","miniPanel":"styles-module__0uSENk2","scoreContainer":"styles-module__5A5Sgf4","header":"styles-module__Gq5UCeU"};
export { css, digest };
  