import React from 'react';
import { type FC, type ReactNode, type RefObject, useRef } from 'react';

import cx from 'clsx';
import { Enum } from '@writercolab/utils';
import styles from './NavLink.module.css';
import { LinkText } from '../LinkText';
import { Text, TextColor } from '../typography/Text/Text';
import { Tooltip } from '../Tooltip';
import { Button, ButtonTypes } from '../Button';
import { Icon, IconVariant } from '../Icon';

export const NavLinkVariant = new Enum('standard', 'action');

export interface INavLinkProps {
  className?: string;
  variant?: typeof NavLinkVariant.type;
  label: ReactNode;
  description?: ReactNode;
  icon?: ReactNode;
  iconActive?: ReactNode;
  active?: boolean;
  isMinified?: boolean;
  tooltipText?: string;
  actionTooltipText?: string;
  tooltipPlacement?: string;
  isPrimary?: boolean;
  onClick?: () => void;
  onActionClick?: (e: React.MouseEvent) => void;
  actionLoading?: boolean;
  children?: (props: { containerRef: RefObject<HTMLElement> }) => ReactNode;
}

export const NavLink: FC<INavLinkProps> = ({
  className,
  isPrimary,
  variant = NavLinkVariant.enum.standard,
  iconActive,
  label,
  icon,
  active,
  isMinified,
  tooltipText,
  actionTooltipText,
  onClick,
  description,
  onActionClick,
  actionLoading,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const activeIcon = active ? iconActive : icon;

  return (
    <div className={styles.containerWrapper}>
      <div
        ref={containerRef}
        className={cx(className, styles.container, {
          [styles.minified]: isMinified,
          [styles.minifiedPrimary]: isMinified && isPrimary,
          [styles.minifiedActive]: isMinified && active,
        })}
      >
        {variant === NavLinkVariant.enum.standard && (
          <Tooltip
            title={
              <Text color={TextColor.WHITE} bold>
                {tooltipText}
              </Text>
            }
            disabled={!tooltipText}
            placement={isMinified ? 'right' : 'top'}
          >
            <div>
              <LinkText onClick={onClick} className={styles.link}>
                <div
                  className={cx(styles.linkContainer, {
                    [styles.active]: active,
                    [styles.withDescription]: !!description && !isMinified,
                    [styles.minifiedContainer]: isMinified,
                  })}
                >
                  {activeIcon && <div className={styles.icon}>{activeIcon}</div>}
                  <div
                    className={cx(styles.label, {
                      [styles.labelMinified]: isMinified,
                    })}
                  >
                    <div>{label}</div>
                    {description && <div className={styles.description}>{description}</div>}
                  </div>
                </div>
              </LinkText>
            </div>
          </Tooltip>
        )}
        {children?.({ containerRef })}
        {variant === NavLinkVariant.enum.action && (
          <div
            className={cx(styles.wrapper, {
              [styles.wrapperMinified]: isMinified,
            })}
          >
            <Tooltip
              title={
                <Text color={TextColor.WHITE} bold>
                  {tooltipText}
                </Text>
              }
              disabled={!tooltipText}
              placement={isMinified ? 'right' : 'top'}
            >
              <div>
                <Button
                  onClick={onClick}
                  icon={
                    <div
                      className={cx(styles.buttonLabelIconWrapper, {
                        [styles.buttonLabelIconWrapperMinified]: isMinified,
                      })}
                    >
                      {icon}
                    </div>
                  }
                  type={ButtonTypes.SECONDARY}
                  className={cx(styles.button, {
                    [styles.buttonMinified]: isMinified,
                  })}
                >
                  {label}
                </Button>
              </div>
            </Tooltip>
            {onActionClick && (
              <Tooltip
                title={
                  <Text color={TextColor.WHITE} bold>
                    {actionTooltipText}
                  </Text>
                }
                disabled={!actionTooltipText}
                placement={isMinified ? 'right' : 'top'}
              >
                <Button
                  round
                  isLoading={actionLoading}
                  onClick={onActionClick}
                  type={ButtonTypes.SECONDARY}
                  icon={<Icon name={IconVariant.ADD} />}
                  className={cx(styles.buttonAction, {
                    [styles.buttonActionMinified]: isMinified,
                  })}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
