
const digest = '11bad298422b6f33a64971d491f9a2c0b26a33c753768f6926c978c5936dd6f6';
const css = `@keyframes styles-module__yERxd-X {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes styles-module__ywPjB-K {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
`;

export default {"fadeIn":"styles-module__yERxd-X","fadeOut":"styles-module__ywPjB-K"};
export { css, digest };
  