import type { ISidebarCategory, CategoryType, IssueCategory } from '@writercolab/types';

export enum SidebarSection {
  ISSUES = 'issues',
  SNIPPETS = 'snippets',
}

/**
 * Represents the model for categories in the sidebar.
 */
export interface ICategoriesModel {
  /**
   * The type of the category.
   */
  readonly categoryType: CategoryType;

  /**
   * The currently selected category in the sidebar.
   */
  readonly selectedCategory: ISidebarCategory | undefined;

  /**
   * The active section in the sidebar.
   */
  readonly activeSection: SidebarSection;

  /**
   * A map of category types to issue colors.
   */
  readonly issueColorMap: Record<CategoryType, string> | undefined;

  /**
   * The visible categories in the sidebar.
   */
  readonly visibleCategories: ISidebarCategory[] | undefined;

  /**
   * A map of disabled sections.
   */
  readonly disabledSectionsMap: Record<string, boolean>;

  /**
   * The list of all categories.
   */
  readonly categoriesList: ISidebarCategory[];

  /**
   * The list of all issue categories.
   */
  readonly allIssuesCategories: IssueCategory[];

  /**
   * The list of beta issue categories.
   */
  readonly betaIssueCategories: IssueCategory[];

  /**
   * Indicates whether the plagiarism category is selected.
   */
  readonly isPlagiarismCategorySelected: boolean;

  /**
   * Indicates whether the snippets section is active.
   */
  readonly isSnippetsSection: boolean;

  /**
   * A map of styleguide URLs.
   */
  readonly styleguideUrls: Record<string, string>;

  /**
   * Sets the category type.
   * @param categoryType The category type to set.
   */
  setCategory(categoryType: CategoryType): void;

  /**
   * Gets the sidebar category for the given issue category.
   * @param categoryName The name of the issue category.
   * @returns The sidebar category, if found; otherwise, undefined.
   */
  getSidebarCategory(categoryName: IssueCategory): ISidebarCategory | undefined;
}
