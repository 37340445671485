
const digest = '73549f0dd6f0202a6350a85a84089b16c4b5c321a19798a58f6bae36ec1d3921';
const css = `/* --- COMMON --- */
.styles-module__5SqOqfr {
  display: flex;
  align-items: center;
  background-color: var(--white);
  line-height: 0;
}

.styles-module__kdcGZpV {
  margin-top: 3px !important;
  margin-left: 11px !important;
}

.styles-module__IHeJObf {
  display: flex;
  align-items: center;
}

.styles-module__ZNBO1kV {
  display: flex;
  justify-content: space-between;
}

/* ISSUECARD */
.styles-module__SVH4U26 {
  height: var(--sidebar-issuecard-height);
  padding: 18px 11px !important;
  border-radius: 8px;
  margin-bottom: 9px !important;
  box-shadow: var(--issue-card-collapsed-shadow);
}

/* CATEGORY */
.styles-module__VHRqOSM {
  width: 100%;
  max-width: var(--sidebar-category-width);
  height: 41px;
  padding: 0 8px !important;
}

.styles-module__IuCuVj0 {
  margin-right: 2px !important;
}

/* TEAM_STATS_SECTION */
.styles-module__X0Biuq4 {
  display: grid;
  margin-bottom: 60px;
  grid-gap: 10px;
}

.styles-module__tfR-t-K {
  display: grid;
  height: 87px;
  align-items: center;
  padding: 14px 0;
  border: var(--gray-border);
  border-radius: 4px;
  box-shadow: var(--writer-section-shadow);
  grid-template-columns: repeat(6, 1fr);
  text-align: center;
}

.styles-module__tfR-t-K > span:not(:last-of-type) {
  border-right: var(--gray-border);
}

/* YOUR_BILLING_PLAN_WIDGET */
.styles-module__XVUg9a- {
  max-width: 417px;
}

.styles-module__F64Jex3 {
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: var(--writer-light-gray);
}

.styles-module__F64Jex3 .styles-module__kdcGZpV {
  margin-left: 0 !important;
}

.styles-module__F64Jex3 .styles-module__ZNBO1kV > div {
  width: 100%;
  padding-top: 2px;
}

.styles-module__S8zOJ7M {
  float: right;
}

/* GLOBAL_NAV */
.styles-module__-DxGXII {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 12px;
}

.styles-module__ZgNqo-2 {
  display: flex;
  margin: 15px;
}

.styles-module__7OBxYnD {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 15px;
}

.styles-module__lvMZAg0 {
  display: flex;
  flex-direction: column;
}

.styles-module__I9iiHdT {
  margin: 15px 0;
}

/* DOCS_LIST */
.styles-module__QjBaeGm > td > span {
  display: block;
  margin-top: 4px;
}

/* SINGLE_SIGN_ON */
.styles-module__h28cVVf {
  display: flex;
  flex-direction: column;
}

.styles-module__GCsIV-X {
  margin-top: 15px;
}

.styles-module__a6hbTka {
  display: flex;
  flex-direction: column;
  padding: 32px 8%;
  margin-top: 20px;
}

.styles-module__muCcgV8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  gap: 20px;
}

.styles-module__SHJkiT3 {
  display: flex;
  padding: 32px;
}

.styles-module__ZpFN1Vm {
  display: flex;
}

.styles-module__acpBJ7Z {
  display: flex;
  flex-direction: column;
}

.styles-module__RaOTExA {
  margin-top: 16px;
  margin-left: 18px;
}

.styles-module__15Pb1Fl {
  margin-top: 19px;
  margin-left: 18px;
  background-color: var(--writer-blue-2) !important;
  background-image: linear-gradient(90deg, var(--writer-blue-2), var(--writer-blue-1), var(--writer-blue-2)) !important;
}

.styles-module__7OTeKfu {
  display: flex;
  width: 96.5%;
  padding: 17px 22px 12px 0;
  border-bottom: 1px solid var(--classic-grey-1);
  margin-left: 64px;
  column-gap: 33px;
}

.styles-module__QrbVGij {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60% 60%;
  pointer-events: none;
}

.styles-module__1LeiGVO {
  display: flex;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  margin: -9px 0 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60% 60%;
  pointer-events: none;
}

.styles-module__8BDQvXr {
  overflow: hidden;
  width: 87px;
  height: 87px;
  border-radius: 100%;
  margin: auto;
  background-color: var(--writer-blue-4);
}

.styles-module__8BDQvXr > div {
  width: 87px;
  height: 87px;
  border-radius: 100px;
  margin: auto;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: styles-module__mBiZvak;
  animation-timing-function: linear;
  background-color: var(--writer-blue-3);
}

@keyframes styles-module__mBiZvak {
  from {
    transform: translateY(-150%);
  }

  to {
    transform: translateY(-50%);
  }
}

.styles-module__N60UV6s {
  display: flex;
  flex-direction: column;

  .styles-module__YDiM6OY {
    width: 500px;
    max-width: 100%;
    display: flex;
    gap: 8px;

    & + & {
      margin-top: 16px;
    }
  }

  .styles-module__OKM7Gil {
    margin-top: 6px;
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #d2d4d7;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .styles-module__-Q6777y {
    width: 80%;
    height: 46px;
    padding: 12px 16px;
    border-radius: 8px;
    background: var(--writer-graynu-1, #f5f5f9);

    span > span {
      background-image: linear-gradient(90deg, #eee, #d2d4d7, #eee);
    }
  }

  .styles-module__giTM8dA {
    width: 100%;
    height: auto;
    background: linear-gradient(264deg, #f6effd 0.71%, #f3f5ff 100%);

    span > span {
      background-image: linear-gradient(90deg, #eee, #dcdaec, #eee);
    }
  }
}
`;

export default {"mainAnimationBox":"styles-module__5SqOqfr","animatingLine":"styles-module__kdcGZpV","centeredLine":"styles-module__IHeJObf","spaceBetween":"styles-module__ZNBO1kV","issuecardMainBox":"styles-module__SVH4U26 styles-module__5SqOqfr","categoryMainBox":"styles-module__VHRqOSM styles-module__5SqOqfr","score":"styles-module__IuCuVj0 styles-module__kdcGZpV","teamStatsMainBox":"styles-module__X0Biuq4","statsBox":"styles-module__tfR-t-K","billingPlanMainBox":"styles-module__XVUg9a-","billingContainer":"styles-module__F64Jex3","managePlanButton":"styles-module__S8zOJ7M","menuItemMainBox":"styles-module__-DxGXII","menuLogos":"styles-module__ZgNqo-2","menuSwitcher":"styles-module__7OBxYnD","menuItems":"styles-module__lvMZAg0","menuItem":"styles-module__I9iiHdT","docsRow":"styles-module__QjBaeGm","singleSignOnContainer":"styles-module__h28cVVf","headerLine":"styles-module__GCsIV-X","identityWrapper":"styles-module__a6hbTka","identityColumn":"styles-module__muCcgV8","identitySectionMain":"styles-module__SHJkiT3","identitySection":"styles-module__ZpFN1Vm","magicLinks":"styles-module__acpBJ7Z","magicLinksText":"styles-module__RaOTExA","magicLinksEnd":"styles-module__15Pb1Fl","draftsRow":"styles-module__7OTeKfu","circleLoader":"styles-module__QrbVGij","loaderContainerWrapper":"styles-module__1LeiGVO","loaderContainer":"styles-module__8BDQvXr","circleSlide":"styles-module__mBiZvak","chatMessagesLoader":"styles-module__N60UV6s","chatMessageLoaderContainer":"styles-module__YDiM6OY","chatMessageLoaderAvatar":"styles-module__OKM7Gil","chatMessageLoaderContent":"styles-module__-Q6777y","chatMessageLoaderContentLarge":"styles-module__giTM8dA"};
export { css, digest };
  