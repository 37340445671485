import isEmpty from 'lodash/isEmpty';
import { getParamValue } from './locationUtils';
import { getLogger } from '../logger';
import { SharedUtmQueryParam } from '../types';
import { getCookieValue, unsetCookie } from '../services/request';

const LOG = getLogger('utmUtils');

/**
 * @deprecated A legacy feature type
 */
export type UtmParams = { [key in SharedUtmQueryParam]?: string };

/**
 * @deprecated A legacy enum
 */
export enum utmStorageParamsKey {
  COOKIE_UTM_KEY = 'qUtm',
  LOCAL_STORAGE_UTM_KEY = 'utmData',
}

/**
 * @deprecated A legacy list
 */
export const trackableQueryParams = [
  SharedUtmQueryParam.CAMPAIGN,
  SharedUtmQueryParam.CONTENT,
  SharedUtmQueryParam.MEDIUM,
  SharedUtmQueryParam.SOURCE,
  SharedUtmQueryParam.TERM,
  SharedUtmQueryParam.UTM_REFERRER,
  SharedUtmQueryParam.REFERRER,
  SharedUtmQueryParam.REF,
  SharedUtmQueryParam.PAGE_LABEL,
  SharedUtmQueryParam.GCLID,
];

/**
 * @deprecated A legacy implementation for filtering utm params
 */
export const filterTrackableUtmParams = (utmParams: UtmParams): UtmParams => {
  const _utmParams = {};
  trackableQueryParams.forEach(p => {
    if (utmParams[p] && !isEmpty(utmParams[p])) {
      _utmParams[p] = utmParams[p];
    }
  });

  return _utmParams;
};

/**
 * @deprecated A legacy feature for collecting utm params from cookies.
 */
export const collectCookieUtmParams = (): UtmParams => {
  let parsedCookieUtm = {};
  const cookieUtm = getCookieValue(utmStorageParamsKey.COOKIE_UTM_KEY);
  LOG.debug('Collected utm params from cookie', cookieUtm);
  try {
    parsedCookieUtm = JSON.parse(cookieUtm);
  } catch (e) {
    LOG.warn(e);
  }

  return filterTrackableUtmParams(parsedCookieUtm);
};

/**
 * @deprecated A legacy feature for browser storing utm params in local storage. Use LocalStorageService instead.
 */
export const collectStorageUtmParams = (): UtmParams => {
  let parsedLocalStorageUtm = {};
  try {
    const localStorageUtm = localStorage.getItem(utmStorageParamsKey.LOCAL_STORAGE_UTM_KEY) || '{}';
    parsedLocalStorageUtm = JSON.parse(localStorageUtm);
    LOG.debug('Collected utm params from local storage', parsedLocalStorageUtm);
  } catch (e) {
    LOG.warn(e);
  }

  return filterTrackableUtmParams(parsedLocalStorageUtm);
};

/**
 * @deprecated A legacy feature for collecting utm params from request query.
 */
export const collectRequestQueryUtmParams = (locationSearch?: string): UtmParams => {
  const _utmParams = {};
  trackableQueryParams.forEach(p => {
    const paramValue = getParamValue(p, locationSearch);

    if (paramValue && !isEmpty(paramValue)) {
      _utmParams[p] = paramValue;
    }
  });

  LOG.debug('Collected utm params from request query', _utmParams);

  return _utmParams;
};

/**
 * @deprecated A legacy feature for collecting utm params from all sources.
 */
export const collectUtmParams = (locationSearch?: string): UtmParams => {
  const cookieUtmParams = collectCookieUtmParams();
  const storageUtmParams = collectStorageUtmParams();
  const requestQueryUtmParams = collectRequestQueryUtmParams(locationSearch);

  return { ...cookieUtmParams, ...storageUtmParams, ...requestQueryUtmParams };
};

/**
 * @deprecated A legacy feature for purging utm params from all sources. Use LocalStorageService instead.
 */
export const truncateUtmStorage = () => {
  localStorage.removeItem(utmStorageParamsKey.LOCAL_STORAGE_UTM_KEY);
  unsetCookie(utmStorageParamsKey.COOKIE_UTM_KEY);
};
