import type { IOrgPermission } from './permission.types';
import type { SharedQueryParam } from './shared.types';
import type { IUserProfile } from './user.types';

export type LogoutParams = {
  [key in SharedQueryParam]?: boolean | string;
};

export interface ILoginResponse {
  loggedUser: IUserProfile;
  remember_me: boolean | null;
  result: string;
  token: string;
}

export enum UserTeamRole {
  ORG_ADMIN = 'organization-admin',
  ADMIN = 'admin',
  MEMBER = 'member',
}

export interface IPermission {
  organizations: Record<string, IOrgPermission>;
}

export interface ICheckRegistration {
  valid: boolean;
  exists: boolean;
  confirmed: boolean;
  invited: boolean;
  passwordSet: boolean;
  hasSaml: boolean;
  allowUserCreation: boolean;
  allowDirectPasswordLogin: boolean;
}

export enum SignupType {
  email = 'email',
  saml = 'saml',
  google = 'google',
}

export const MFA_CODE_LENGTH = 6;

export enum MfaChallengeType {
  PHONE = 'Phone',
  EMAIL = 'Email',
}
