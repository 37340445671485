
const digest = '1653c82caabea0d59e3114482d8a917734fb45143c329e9376a144a4c8c88dfb';
const css = `.styles-module__DVA-L3w {
  position: relative;
  overflow: hidden;

  /* height: auto; */
  min-width: 300px;
  border-radius: 8px;
  background-color: var(--writer-graynu-1);
}

.styles-module__XZXVno8 {
  position: absolute;
  bottom: -50px;
  left: 14px;
  width: 66px;
  height: 66px;
  border-radius: 100%;
  background-color: black;
  opacity: 0;
  transition:
    transform 0.2s ease-in,
    opacity 0.2s ease-in;
}

.styles-module__83iQd4H {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 22px;
  border: 0;
  border-width: 12px 0 12px 22px;
  border-style: solid;
  border-color: transparent transparent transparent white;
  background: transparent;
  transform: translate(-38%, -50%);
}

.styles-module__pQHn4be {
  position: absolute;
  right: 14px;
  bottom: 15px;
  padding: 5px;
  border-radius: 8px;
  background-color: black;
  color: white;
}

.styles-module__v-nVOrK {
  width: auto;
  max-width: 400px;
  height: auto;
  min-height: 200px;
  max-height: 200px;
  background-color: gray;
  transition: transform 0.2s ease-in;
}

.styles-module__nd0NFNb {
  margin-top: 12px;
}

.styles-module__RdxKMkU {
  margin-left: 5px;
}

.styles-module__z0-bnAV {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.styles-module__iBqykdD {
  margin-left: 10px;
}

.styles-module__iBqykdD:hover {
  text-decoration: underline;
}

.styles-module__RB2qrtX {
  margin-right: 10px;
}

.styles-module__trDP-Ae:hover {
  cursor: pointer;
}

.styles-module__trDP-Ae:hover .styles-module__DVA-L3w {
  outline: 2px solid var(--writer-blue-5);
}

.styles-module__trDP-Ae:hover .styles-module__v-nVOrK {
  transform: scale(1.06);
}

.styles-module__trDP-Ae:hover .styles-module__XZXVno8 {
  opacity: 1;
  transform: translateY(-65px);
}
`;

export default {"imgWrapper":"styles-module__DVA-L3w","playButton":"styles-module__XZXVno8","triangle":"styles-module__83iQd4H","duration":"styles-module__pQHn4be","cover":"styles-module__v-nVOrK","title":"styles-module__nd0NFNb","isViewedIcon":"styles-module__RdxKMkU","article":"styles-module__z0-bnAV","articleLink":"styles-module__iBqykdD","articleIcon":"styles-module__RB2qrtX","container":"styles-module__trDP-Ae"};
export { css, digest };
  