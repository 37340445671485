import { Quill } from 'react-quill';
import {
  QL_SNIPPET_HIGHLIGHT_FORMAT_NAME,
  QL_SNIPPET_CLASS_PREFIX,
} from '@writercolab/quill-delta-utils';

const Inline = Quill.import('blots/inline');

export class SnippetFormat extends Inline {
  static tagName = 'span';
  static blotName = QL_SNIPPET_HIGHLIGHT_FORMAT_NAME;
  static className = QL_SNIPPET_CLASS_PREFIX;
  snippet = '';
  shortcut = '';

  constructor(scroll, domNode, snippet) {
    if (!snippet) {
      // @ts-expect-error
      super(scroll, domNode, false);

      return;
    }

    super(scroll, domNode);

    this.snippet = snippet.snippet;
    this.shortcut = snippet.shortcut;
  }

  static create(snippet) {
    if (!snippet) {
      return super.create(false);
    }

    const node = super.create(snippet);

    node.classList.add(QL_SNIPPET_CLASS_PREFIX);

    return node;
  }

  static formats(domNode) {
    return domNode.classList.contains(QL_SNIPPET_CLASS_PREFIX);
  }

  formats() {
    const formats = super.formats();
    const value = SnippetFormat.formats(this.domNode);

    if (value) {
      formats[QL_SNIPPET_HIGHLIGHT_FORMAT_NAME] = SnippetFormat.formats(this.domNode);
    }

    // Return this blot's formats
    return formats;
  }
}
