import React, { useMemo } from 'react';
import type { DropdownOption } from '@writercolab/ui-atoms';
import { PartOfSpeech, TermsPopupFields } from '@writercolab/types';
import { Icon, IconVariant, Text, TextSize, Tooltip } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

import { InputGroup, InputTypes } from '../InputGroup';

export const partOfSpeechOptions: DropdownOption[] = [
  {
    name: 'none',
    id: PartOfSpeech.DEFAULT,
    active: true,
  },
  {
    name: PartOfSpeech.NOUN,
    id: PartOfSpeech.NOUN,
    active: false,
  },
  {
    name: PartOfSpeech.ADJECTIVE,
    id: PartOfSpeech.ADJECTIVE,
    active: false,
  },
  {
    name: PartOfSpeech.VERB,
    id: PartOfSpeech.VERB,
    active: false,
  },
  {
    name: PartOfSpeech.ADVERB,
    id: PartOfSpeech.ADVERB,
    active: false,
  },
];

interface IPartOfSpeechDropdown {
  value?: string;
  showLabel?: boolean;
  onUpdate?: (val: string, fieldType?: TermsPopupFields.POS) => void;
}

export const PartOfSpeechDropdown: React.FC<IPartOfSpeechDropdown> = ({ showLabel, value, onUpdate }) => {
  const dropdownOptions = useMemo(
    () => partOfSpeechOptions.map(d => ({ ...d, active: d.id === value, name: d.name })),
    [value],
  );

  const onChangeDropdown = (val: string) => {
    onUpdate?.(val, TermsPopupFields.POS);
  };

  return (
    <InputGroup
      name="partOfSpeech"
      id="partOfSpeech"
      className={styles.posInputGroup}
      inputType={InputTypes.SELECT}
      dropdownOptions={dropdownOptions}
      label={
        showLabel ? (
          <Text caps variant={TextSize.XS}>
            POS
            <Tooltip
              // TODO: remove the tooltip text wrapper when tooltipWidth parameter starts working again
              title={
                <p className={styles.posTooltipTextWrapper}>
                  Select a part of speech (POS) if you'd like Writer to highlight this term only when used as this POS.
                  Otherwise, leave this blank.
                </p>
              }
              placement="top"
              tooltipWidth={220}
            >
              <span>
                <Icon name={IconVariant.INFO_OUTLINED} />
              </span>
            </Tooltip>
          </Text>
        ) : (
          ''
        )
      }
      value={value}
      placeholder="Select..."
      handleChangeInput={onChangeDropdown}
      disableHelperText
    />
  );
};

export default React.memo(PartOfSpeechDropdown);
