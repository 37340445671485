
const digest = 'ac93b2e69284a2d4f6a13b22530ae6e9fc7a420becfde8786ef1abdcd499547e';
const css = `.WrongSuggestionComment-module__PhktoRP {
  position: absolute;
  width: 247px;
  padding: 16px;
  border: 1px solid var(--classic-grey-1);
  border-radius: 8px;
  background: var(--classic-white);
  box-shadow: 0 1px 8px 0 rgb(191 203 255 / 25%);
}

.WrongSuggestionComment-module__OT6TIM1 {
  color: var(--classic-white);
}

.WrongSuggestionComment-module__jbMM7q6 {
  margin-bottom: 9px;
}

.WrongSuggestionComment-module__ssBVGkX {
  min-height: 80px;

  & textarea {
    font-size: var(--font-size-s);
    font-weight: var(--font-default-weight);
    min-height: 80px;
    max-height: 80px;
  }
}

.WrongSuggestionComment-module__0JNtdgC {
  font-weight: var(--font-default-weight);
}

.WrongSuggestionComment-module__72p3OwL {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: -10px 0 0;
}

.WrongSuggestionComment-module__XgNHa5W {
  margin: 6px 0 0;
}

.WrongSuggestionComment-module__wkPYOMQ {
  position: absolute;
  top: 9px;
  right: 7px;
}

.WrongSuggestionComment-module__SIS-J-7 {
  width: 18px !important;
  height: 18px !important;
}

@media (min-width: 360px) and (max-width: 367px) {
  .WrongSuggestionComment-module__PhktoRP {
    margin: 4px -2px 0 0;
    width: 216px;

    & .WrongSuggestionComment-module__0JNtdgC {
      font-size: var(--font-size-xxs);
    }
  }
}

@media (min-width: 368px) and (max-width: 382px) {
  .WrongSuggestionComment-module__PhktoRP {
    margin: 4px -16px 0 0;
    width: 216px;

    & .WrongSuggestionComment-module__0JNtdgC {
      font-size: var(--font-size-xxs);
    }
  }
}

@media (min-width: 383px) and (max-width: 420px) {
  .WrongSuggestionComment-module__PhktoRP {
    width: 230px;
    margin: 4px -20px 0 0;
  }
}
`;

export default {"container":"WrongSuggestionComment-module__PhktoRP","flagIcon":"WrongSuggestionComment-module__OT6TIM1","heading":"WrongSuggestionComment-module__jbMM7q6","textarea":"WrongSuggestionComment-module__ssBVGkX","counterText":"WrongSuggestionComment-module__0JNtdgC","actions":"WrongSuggestionComment-module__72p3OwL","counter":"WrongSuggestionComment-module__XgNHa5W","actionCancel":"WrongSuggestionComment-module__wkPYOMQ","cancelButton":"WrongSuggestionComment-module__SIS-J-7"};
export { css, digest };
  