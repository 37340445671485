
const digest = '9993e7ed60720487141da684aa1e4f242747341b26de67ed91a3535c2d91c7ae';
const css = `.styles-module__wuEqtHS {
  width: 100%;
}

.styles-module__qNlOf96 {
  width: 100%;
}

.styles-module__cLxIoDp {
  height: 18px;
  margin-top: 2px;
}

.styles-module__9XH3O7X {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  border: 1px solid var(--writer-graynu-2);
  border-radius: 4px;
  font-size: 11px;
  line-height: 28px;
}

.styles-module__9XH3O7X:hover.styles-module__59-s-Ms,
.styles-module__9XH3O7X.styles-module__59-s-Ms {
  border: 1px solid var(--classic-orange-1) !important;
  box-shadow: var(--menu-box-shadow);
}

.styles-module__LrcI55t {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
}

.styles-module__9XH3O7X:hover,
.styles-module__oyXCi2z[data-open='true'] > div {
  border-color: var(--writer-blue-3);
}

.styles-module__9XH3O7X:hover .styles-module__LrcI55t {
  background-color: var(--writer-graynu-2);
}

.styles-module__DTUOhjw {
  overflow: auto;
  width: 100%;
  max-height: 164px;
}

.styles-module__DTUOhjw.styles-module__59-s-Ms {
  border-color: red;
}

.styles-module__ehf7RgE {
  overflow: hidden;
  max-width: calc(100% - 46px);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__YA--uN5 {
  margin-left: 5px;
}

.styles-module__UqjFNUc p {
  display: flex;
  align-items: center;
}

.styles-module__qzxjLjt {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 42px;
}

.styles-module__QYoCHlh {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 42px;
  max-height: 100%;
  border: 1px solid var(--writer-graynu-2);
  border-radius: 4px;
  margin-bottom: 11px;
  background: var(--classic-white);
  font-family: var(--font-default);
}

.styles-module__QYoCHlh:hover {
  border: 1px solid var(--writer-blue-3);
}

.styles-module__QYoCHlh:focus {
  border: 1px solid var(--writer-blue-3);
  box-shadow: var(--input-box-shadow);
}

.styles-module__QYoCHlh:focus-visible {
  border: 1px solid var(--writer-blue-3) !important;
  box-shadow: var(--input-box-shadow) !important;
  outline: none;
}

.styles-module__QYoCHlh::placeholder {
  color: var(--writer-grey-3);
  font-weight: 400;
  opacity: 0.8;
}
`;

export default {"formControl":"styles-module__wuEqtHS","formControlInput":"styles-module__qNlOf96","helperText":"styles-module__cLxIoDp","selectTrigger":"styles-module__9XH3O7X","error":"styles-module__59-s-Ms","arrowIcon":"styles-module__LrcI55t","selectTriggerContainer":"styles-module__oyXCi2z","selectStyled":"styles-module__DTUOhjw","valueContainer":"styles-module__ehf7RgE","formLabelIconContainer":"styles-module__YA--uN5","labelWithIcon":"styles-module__UqjFNUc","textareaContainer":"styles-module__qzxjLjt","textareaContainerItem":"styles-module__QYoCHlh"};
export { css, digest };
  