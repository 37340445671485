import type { TAnalyticsActivityTypeBuilder } from '@writercolab/types';

export const UISidebarModelsAnalyticsActivity = {
  // Sidebar events
  refreshScore: '[Editor] Refresh Clicked', // when user clicks Refresh score

  filteredSuggestions: '[Editor] Filtered Suggestions', // when user clicks Purchase pro
  suggestionAccepted: '[Suggestions] Suggestion Accepted', // when user accepts suggestion
  suggestionIgnored: '[Suggestions] Suggestion Ignored', // when user ignores suggestion
  suggestionFlagged: '[Suggestions] Suggestion Flagged', // when user flags suggestion
  suggestionViewed: '[Suggestions] Suggestion Viewed', // when user clicks suggestion (inline or in sidebar)
  suggestionAddedToTermBank: '[Suggestions] Suggestion Added to Terms', // when user adds suggestion to Terms
  snippetClicked: '[Snippets] Clicked on a Snippet ', // when user clicks Snippet
  snippetTriggered: '[Snippets] Triggered Snippet', // when snippet is shown in editor
} as const;

export type TUISidebarModelsAnalyticsParams = TAnalyticsActivityTypeBuilder<
  typeof UISidebarModelsAnalyticsActivity,
  {
    [UISidebarModelsAnalyticsActivity.suggestionAccepted]: {
      suggestion_category: string;
      suggestion_issue_type: string;
      card_type: string;
      team_id: number;
      suggestions_count: number;
    };
    [UISidebarModelsAnalyticsActivity.suggestionIgnored]: {
      suggestion_category: string;
      suggestion_issue_type: string;
      card_type: string;
      team_id: number;
      suggestions_count: number;
    };
    [UISidebarModelsAnalyticsActivity.suggestionFlagged]: {
      suggestion_category: string;
      suggestion_issue_type: string;
      card_type: string;
      team_id: number;
      suggestions_count: number;
    };
    [UISidebarModelsAnalyticsActivity.filteredSuggestions]: {
      filter_category: string;
      team_id: number;
    };
    [UISidebarModelsAnalyticsActivity.refreshScore]: Record<string, never>;
    [UISidebarModelsAnalyticsActivity.snippetClicked]: {
      snippet: string;
      id: string;
      team_id: number;
    };
    [UISidebarModelsAnalyticsActivity.snippetTriggered]: {
      snippet: string;
      id: string;
      team_id: number;
    };

    [UISidebarModelsAnalyticsActivity.suggestionViewed]: {
      suggestion_category: string;
      suggestion_issue_type: string;
      card_type: string;
      team_id: number;
      organization_id: number;
    };
    [UISidebarModelsAnalyticsActivity.suggestionAccepted]: {
      suggestion_category: string;
      suggestion_issue_type: string;
      card_type: string;
      team_id: number;
      suggestions_count: number;
    };
    [UISidebarModelsAnalyticsActivity.suggestionIgnored]: {
      suggestion_category: string;
      suggestion_issue_type: string;
      card_type: string;
      team_id: number;
      suggestions_count: number;
    };
    [UISidebarModelsAnalyticsActivity.suggestionFlagged]: {
      suggestion_category: string;
      suggestion_issue_type: string;
      card_type: string;
      team_id: number;
      suggestions_count: number;
    };
    [UISidebarModelsAnalyticsActivity.filteredSuggestions]: {
      filter_category: string;
      team_id: number;
    };
    [UISidebarModelsAnalyticsActivity.refreshScore]: Record<string, never>;
    [UISidebarModelsAnalyticsActivity.snippetClicked]: {
      snippet: string;
      id: string;
      team_id: number;
    };
    [UISidebarModelsAnalyticsActivity.snippetTriggered]: {
      snippet: string;
      id: string;
      team_id: number;
    };
  }
>;
