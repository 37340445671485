
const digest = 'f92ca5b60d4dee9585c8a211e8727d7d6df6a0ec23214e12c4e4f231e6429030';
const css = `.styles-module__Y7WRETU {
  display: flex;
  overflow: auto;
  align-items: flex-start;
  justify-content: center;
}

.styles-module__WT-L5hZ {
  align-items: center;
}

.styles-module__JkCxrYL {
  position: relative;
  top: 80px;
  padding: 20px 25px 25px;
  border-radius: 8px;
  background: var(--classic-white);
  outline: none;
}

.styles-module__JkCxrYL .styles-module__PtIV3hZ {
  margin-right: 10px;
  margin-bottom: 15px;
  font-weight: 600;
}

.styles-module__N-F7q1V {
  color: var(--classic-orange-3);
}

.styles-module__JkCxrYL .styles-module__rxOgnCv {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.styles-module__rxOgnCv {
  padding: 4px;
  border-radius: 50%;
}

.styles-module__rxOgnCv svg {
  position: relative;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.styles-module__rxOgnCv::after {
  background: transparent;
  content: '';
  transition: background 0.3s ease;
}

.styles-module__rxOgnCv:hover::after {
  position: absolute;
  z-index: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: var(--classic-grey-1);
  content: '';
}

.styles-module__IZjgZYe {
  display: flex;
  align-items: flex-start;
  outline: 0 !important;
}

.styles-module__lLcZFK2 {
  margin-left: 10px;
}
`;

export default {"wrapperModal":"styles-module__Y7WRETU","centeredContent":"styles-module__WT-L5hZ","styledModal":"styles-module__JkCxrYL","modalTitle":"styles-module__PtIV3hZ","warning":"styles-module__N-F7q1V","closeModal":"styles-module__rxOgnCv","multiModalContainer":"styles-module__IZjgZYe","multiModalContent":"styles-module__lLcZFK2"};
export { css, digest };
  