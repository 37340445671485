import React, { memo, useEffect, useMemo } from 'react';
import { inMemoryPersistence } from 'firebase/auth';
import { reaction } from 'mobx';
import { cmudict } from '@writercolab/cmudict';
import { E_INTEGRATION_TYPE, IssueType } from '@writercolab/common-utils';
import { createFirebaseClient } from '@writercolab/firebase';
import { AiAssistantSubscriptionModel } from '@writercolab/models';
import { SidebarPure } from '@writercolab/ui-sidebar';
import { DocumentStatTypes, SidebarDataModel, SidebarModel } from '@writercolab/ui-sidebar-models';
import { FIREBASE_CONFIG } from '../../utils/constants';

export const SidebarMemo = memo(({ baseConfig, access, teamName, showEmailConfirmationLock, selectedIssue, modelRef, callbacks, requestService, analyticsService }) => {
  const dataClient = useMemo(() => {
    return createFirebaseClient({
      config: FIREBASE_CONFIG,
      settings: {
        firebaseStatePersistenceType: inMemoryPersistence,
      },
      request: requestService.api,
      params: {
        userId: () => undefined,
        organizationId: () => baseConfig.organizationId,
        teamId: () => baseConfig.workspaceId,
        documentId: () => baseConfig.documentId,
        personaId: baseConfig.personaId,
      },
    });
  }, [
    requestService.api,
    baseConfig.organizationId,
    baseConfig.workspaceId,
    baseConfig.documentId,
    baseConfig.personaId,
  ]);

  const sidebarDataModel = useMemo(() => {
    return new SidebarDataModel({
      client: dataClient,
    });
  }, [dataClient]);

  const subscriptionModel = useMemo(() => {
    return new AiAssistantSubscriptionModel({
      api: requestService.api,
      organizationId: () => baseConfig.organizationId,
      teamId: () => baseConfig.workspaceId,
    });
  }, [baseConfig.organizationId, baseConfig.workspaceId, requestService.api]);

  const model = useMemo(
    () => {
      const sidebarModel = new SidebarModel({
        getCmudict: () => cmudict,
        integrationType: E_INTEGRATION_TYPE.enum.CONTENTFUL_PLUGIN,
        appRoot: baseConfig.appRoot || '',
        analytics: analyticsService,
        requestService,
        sidebarDataModel,
        subscriptionModel,
        organizationId: () => baseConfig.organizationId,
        documentId: () => baseConfig.documentId,
        teamId: () => baseConfig.workspaceId,
        isTeamAdmin: () => !!access.isTeamAdmin,
        isOrgAdmin: () => !!access.isOrgAdmin,
        teamName: () => teamName,
        personaId: () => baseConfig.personaId,
        userId: () => baseConfig.userId ? +baseConfig.userId : undefined,
        userEmail: () => baseConfig.userEmail,
        defaultStatItem: () => DocumentStatTypes.enum.words,
      });
      modelRef.current = sidebarModel;
      return sidebarModel;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      sidebarDataModel,
      subscriptionModel,
      baseConfig.organizationId,
      baseConfig.workspaceId,
      baseConfig.documentId,
      baseConfig.personaId,
      baseConfig.userId,
      baseConfig.userEmail,
      teamName,
      access,
    ],
  );

  useEffect(
    () => model.eventBus.on('onSelectSuggestion', callbacks.onSelectSuggestion),
    [model.eventBus, callbacks.onSelectSuggestion],
  );
  useEffect(
    () => model.eventBus.on('onApplySuggestionCallback', callbacks.onApplySuggestionCallback),
    [model.eventBus, callbacks.onApplySuggestionCallback],
  );

  useEffect(() => {
    const currentIssueIdRunCancel = reaction(
      () => ({
        selected: model.issues.selectedSidebarIssue,
        list: model.issues.currentSidebarIssues,
      }),
      ({ selected, list }, { selected: prevSelected, list: prevList }) => {
        if (!selected && list.length) {
          const prevIndex = prevList.findIndex(issue => issue.issueId === prevSelected?.issueId);

          if (prevIndex === -1) {
            callbacks.onSelectSuggestion(list[0]);
          } else {
            const newIndex = prevIndex < list.length ? prevIndex : list.length - 1;
            callbacks.onSelectSuggestion(list[newIndex]);
          }
        }
      },
    );

    return () => currentIssueIdRunCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, callbacks.onSelectSuggestion]);

  useEffect(() => {
    const currentIssueIdRunCancel = reaction(
      () => ({ list: model.issues.currentIssues }),
      ({ list }) => {
        callbacks.onIssuesUpdated(list, model.dataModel.documentContent?.data || '', model.categories.selectedCategory?.id);
      },
    );

    return () => currentIssueIdRunCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, callbacks.onIssuesUpdated]);

  useEffect(() => {
    if (selectedIssue && selectedIssue.issueType !== IssueType.DICTIONARY) {
      model.issues.setSelectedIssue(selectedIssue, 'editor');
    }
  }, [model, selectedIssue]);

  return (
    <SidebarPure model={model} />
  );
});
