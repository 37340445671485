import type { ISidebarCategory } from '@writercolab/types';
import { CategoryType, IssueCategory, IssueType } from '@writercolab/types';
import { makeAutoObservable } from 'mobx';

export class UIInlineIssueCardModel {
  correction?: string[];
  category: IssueCategory;
  sidebarCategory?: ISidebarCategory;
  issueType: string;

  constructor(
    category: IssueCategory,
    issueType: IssueType,
    sidebarCategory?: ISidebarCategory,
    correction?: string[],
  ) {
    this.correction = correction;
    this.category = category;
    this.sidebarCategory = sidebarCategory;
    this.issueType = issueType;
    makeAutoObservable(this);
  }

  get isBannedWord() {
    return this.category === IssueCategory.BannedWords;
  }

  get correctionsList() {
    return !this.correction?.length && this.isBannedWord ? [''] : this.correction || [];
  }

  get isUseCarefully() {
    return this.category === IssueCategory.UseCarefully;
  }

  get isMultilineCard() {
    return this.issueType === IssueType.READABILITY || this.issueType === IssueType.PASSIVE_VOICE;
  }

  get isPassiveVoice() {
    return this.issueType === IssueType.PASSIVE_VOICE;
  }

  get isReadability() {
    return this.issueType === IssueType.READABILITY;
  }

  get isCompactMode() {
    if (this.isMultilineCard) {
      return false;
    }

    return (
      (this.sidebarCategory?.id === CategoryType.SPELLING_AND_GRAMMAR && !this.isMlOrInfinitiveCategory) ||
      this.isClarityCategory ||
      this.isGecOrSpellingOrHateSpeechCategory
    );
  }

  get hideReplacement() {
    return !this.correction?.length && !this.isBannedWord;
  }

  get showCategory() {
    return this.isGecOrSpellingOrHateSpeechCategory || this.isClarityCategory;
  }

  get isDescriptionActive() {
    return this.correction || this.isBannedWord || this.isMlOrInfinitiveCategory;
  }

  get isMultipleSuggestions() {
    return (this.correction && this.correction.length > 1) || this.isBannedWord || this.isMlOrInfinitiveCategory;
  }

  get isMlOrInfinitiveCategory() {
    return [IssueCategory.MlPunctuation, IssueCategory.Infinitive].includes(this.category);
  }

  get isGecOrSpellingOrHateSpeechCategory() {
    return [IssueCategory.Gec, IssueCategory.GecGpt3, IssueCategory.Spelling, IssueCategory.HateSpeech].includes(
      this.category,
    );
  }

  get isClarityCategory() {
    return this.sidebarCategory?.id === CategoryType.CLARITY && this.issueType !== IssueType.PASSIVE_VOICE;
  }
}
