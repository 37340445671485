import React from 'react';
import { observer } from 'mobx-react-lite';
import { Heading, HeadingColor, HeadingVariant } from '@writercolab/ui-atoms';
import type { UIVisibleIssuesListModel } from './UIVisibleIssuesListModel';
import styles from './VisibleIssuesList.module.css';
import { VisibleIssuesListContent } from './VisibleIssuesListContent';
import { VisibleIssuesScrollNoticeCard } from '../../molecules/VisibleIssuesScrollNoticeCard';

export const VisibleIssuesList = observer<{ model: UIVisibleIssuesListModel; isSmallPadding?: boolean }>(
  ({ model, isSmallPadding = false }) => {
    const { list, scrollNoticedMarkers } = model;

    const { hasPrev, hasNext } = scrollNoticedMarkers;

    return (
      <>
        {hasPrev && <VisibleIssuesScrollNoticeCard isSmallPadding={isSmallPadding} />}

        {list && list.length > 0 ? (
          <VisibleIssuesListContent model={model} isSmallPadding={isSmallPadding} />
        ) : (
          <div className={styles.listScrollEmptySection}>
            <Heading
              variant={HeadingVariant.H3}
              color={HeadingColor.GREY2}
              className={styles.listScrollEmptySectionText}
            >
              Looking good
              <br />
              in this section!
            </Heading>
          </div>
        )}

        {hasNext && <VisibleIssuesScrollNoticeCard isSmallPadding={isSmallPadding} isBottom />}
      </>
    );
  },
);
