import type { ITemplateDraft } from './Templates';

export interface ICoWriteKeyPointSection {
  outline: string;
  points: string[];
}

export interface ICoWriteGenerateTitlesResponse {
  titles: string[];
}

export interface ICoWriteGenerateOutlineResponse {
  outline: string[];
}

export interface ICoWriteGenerateKeyPointsResponse {
  sections: ICoWriteKeyPointSection[];
}

export interface ICoWriteGenerateContentResponse {
  draft: ITemplateDraft;
}

export enum ContentEditorQueryParams {
  mode = 'mode',
  currentTemplateId = 'currentTemplateId',
}
