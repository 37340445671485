import head from 'lodash/head';
import maxBy from 'lodash/maxBy';

import type { IOrganization } from '../types/Organization';
import { OrganizationType } from '../types/Organization';
import { isLocalEnv } from '../services/environment/environment';

export const isNewOrg = (org: IOrganization): boolean => org.type === OrganizationType.NEW;

export const extractCurrentOrg = (organizations: IOrganization[]): IOrganization => {
  let currentOrg: IOrganization | undefined;

  if (isLocalEnv()) {
    // locally we have to be redirected to the first new org
    currentOrg = head<IOrganization>(organizations.filter(org => isNewOrg(org)));
  } else {
    currentOrg = maxBy(organizations, 'id');
  }

  return currentOrg as IOrganization;
};
