export enum ContentEditorPageMode {
  PURE_EDITOR = 'pureEditor',
  CO_WRITE = 'coWrite',
  LIBRARY = 'library',
  START_WITH_LIBRARY = 'startWithLibrary',
  BLOG = 'blog',
  EVENT_TAKEAWAYS = 'eventTakeaways',
  HIGHLIGHTS = 'highlights',
  MAGIC_LINKS = 'magicLinks',
  CLAIMS_DETECTION = 'claimsDetection',
}
