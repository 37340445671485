import type { TeamId } from '@writercolab/types';
import type { OrganizationId } from './Organization';
import type { SelectionArea } from './RewriteWidget';

export const SUGGESTION_DESCRIPTION_MAX_LENGTH = 110;
export const SUGGESTION_LINK_MAX_LENGTH = 38;

export interface MagicLinksWebsite {
  id: string;
  organizationId: OrganizationId;
  teamId: TeamId;
  url: string;
  creationTime: string;
  modificationTime: string;
  createdUserId: number;
  modifiedUserId: number;
  deleted: boolean;
}

export interface IWebsiteRequestProps {
  orgId: string | OrganizationId;
  teamId: string | TeamId;
}

export type IGetWebsitesBERequest = IWebsiteRequestProps;

export interface IGetWebsitesBEResponse {
  websites: MagicLinksWebsite[];
}

export interface IAddWebsiteBERequest extends IWebsiteRequestProps {
  websiteUrl: string;
}

export interface IAddDomainBEResponse {
  domain: string;
}

export interface IDeleteWebsiteBERequest extends IWebsiteRequestProps {
  websiteId: string;
}

export interface IGetSuggestionsRequestParams {
  offset: number;
  limit: number;
  text: string;
}

export interface IGetSuggestionsBERequest {
  orgId: string;
  teamId: string;
  params: IGetSuggestionsRequestParams;
}

export interface IGetSuggestionResponseMeta {
  offset: number;
  limit: number;
}

export interface IGetSuggestion {
  title: string;
  link: string;
  snippet: string;
}

export interface IGetSuggestionsSearchResponse {
  pagination: IGetSuggestionResponseMeta;
  result: IGetSuggestion[];
  totalCount: number;
}

export type MagicModuleState = {
  selectedText: string;
  selectedArea: SelectionArea;
  selectedRange: { index: number; length: number } | null;
  amountMagicLinksFound: number[];
};

export enum MagicModuleAction {
  RESET = 'RESET',
  DEFAULT = 'DEFAULT',
}

export type DefaultMagicModuleAction = {
  type: MagicModuleAction;
  payload?: Partial<MagicModuleState>;
};
