/**
 * Builds a comparator function for use in binarySearch.
 *
 * @template T, V
 * @param {Object} options
 * @param {function(T, V): boolean} [options.cmp=(t, v) => (t as unknown) === v] - The function to use for equality comparison.
 * @param {function(T, V): boolean} options.gt - The function to use to determine if a given element in the list is greater than the target.
 * @param {'asc' | 'desc'}: order - direction of ordering
 * @returns {(T, V) => -1 | 0 | 1} A function that compares two values and returns -1, 0, or 1 to indicate which one is smaller, equal, or greater.
 */
export const buildComparator =
  <T, V>({
    cmp = (t, v) => (t as unknown) === v,
    gt,
    order,
  }: {
    cmp?: (v: V, t: T) => boolean;
    gt: (v: V, t: T) => boolean;
    order?: 'asc' | 'desc';
  }) =>
  (t: T, v: V): 0 | -1 | 1 => {
    if (cmp(v, t)) {
      return 0;
    }

    const gtValue = gt(v, t);

    if (order === 'desc') {
      return gtValue ? 1 : -1;
    } else {
      // asc
      return gtValue ? -1 : 1;
    }
  };
