import { Enum } from '@writercolab/utils';
import type { components } from '@writercolab/network';

export const TOrganizationPlan = new Enum(
  'Free',
  'Pro',
  'Team',
  'Enterprise',
  'Legacy',
  'AIStudioPayg',
  'AIStudioEnterprise',
);

export enum BillingProduct {
  FREE = 'free',
  PRO = 'pro',
  TEAM = 'team',
  ENTERPRISE = 'enterprise',
  AI_STUDIO_PAYG = 'ai_studio_payg',
  AI_STUDIO_PAYG_ENTERPRISE = 'ai_studio_enterprise',
}

export enum BillingInformationFormElement {
  NAME = 'name',
  CITY = 'city',
  COUNTRY = 'country',
  ADDRESS_LINE1 = 'line1',
  ADDRESS_LINE2 = 'line2',
  POSTAL_CODE = 'postalCode',
  STATE = 'state',
}

export enum CustomerType {
  SELF_SERVE = 'self_service',
  ENTERPRISE = 'enterprise',
}

export enum BillingStatus {
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
}

export enum BillingInterval {
  MONTH = 'month',
  YEAR = 'year',
}

export enum BlockedLimitType {
  MONTHLY = 'MonthlyAggregation',
  DAILY = 'DailyAggregation',
  HOURLY = 'HourlyAggregation',
}

export enum CheckoutPopupType {
  CHECKOUT = 'CHECKOUT',
  MANAGE_PLAN = 'MANAGE_PLAN',
}

export enum CancellationPopupType {
  CANCEL_PLAN = 'CANCEL_PLAN',
  ONE_TIME_OFFER = 'ONE_TIME_OFFER',
  ONE_TIME_OFFER_ACCEPTED = 'ONE_TIME_OFFER_ACCEPTED',
  CANCELLATION_SURVEY = 'CANCELLATION_SURVEY',
  CANCELLATION_CONFIRM = 'CANCELLATION_CONFIRM',
  PLAN_CANCELLED = 'PLAN_CANCELLED',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
}

export enum BillingAction {
  // BE actions
  BUY = 'buy',
  UPGRADE = 'upgrade',
  TRIAL = 'trial',

  // FE actions
  ACTIVATE_FREE = 'activate_free', // special case for free plan activation
  SWITCH = 'switch', // for switch to monthly\yearly
  CONTACT = 'contact', // for enterprise
  NONE = 'none', // for selected plan
  RESUBSCRIBE = 'resubscribe', // for cancelled plan
  MANAGE_PLAN = 'manage_plan', // for active states
  TRIAL_HIGHLIGHTED = 'trial_highlighted',
}

export enum CreatePaymentMethodIntentResult {
  SUCCEEDED = 'succeeded',
  REQUIRES_ACTION = 'requires_action',
}

export enum StripeErrorCode {
  INCOMPLETE_NUMBER = 'incomplete_number',
  INVALID_NUMBER = 'invalid_number',
  CARD_DECLINED = 'card_declined',
  INCOMPLETE_EXPIRY = 'incomplete_expiry',
  INCOMPLETE_CVC = 'incomplete_cvc',
  SETUP_INTENT_AUTHENTICATION_FAILURE = 'setup_intent_authentication_failure',
  INCOMPATIBLE_PAYMENT_METHOD = 'payment_intent_incompatible_payment_method',
}

export enum CancelledReason {
  TRIAL_EXPIRED = 'trial_ended',
  USER_CANCELLED = 'user_cancelled',
  PAYMENT_FAILED = 'payment_failed',
}

export const PlanStatusLabelColor = {
  [BillingStatus.ACTIVE]: 'var(--writer-green-5)',
  [BillingStatus.TRIALING]: 'var(--writer-blue-3)',
  [BillingStatus.CANCELED]: 'var(--classic-orange-1)',
  [BillingStatus.PAST_DUE]: 'var(--classic-orange-1)',
};

export const PlanStatusReadable = {
  [BillingStatus.ACTIVE]: 'ACTIVE',
  [BillingStatus.TRIALING]: 'TRIALING',
  [BillingStatus.CANCELED]: 'CANCELLED',
  [BillingStatus.PAST_DUE]: 'PAST DUE',
  _FE_TRIAL_EXPIRED: 'TRIAL EXPIRED',
};

export const secondaryBillingInformationElements = [
  BillingInformationFormElement.CITY,
  BillingInformationFormElement.COUNTRY,
  BillingInformationFormElement.STATE,
  BillingInformationFormElement.ADDRESS_LINE1,
  BillingInformationFormElement.ADDRESS_LINE2,
  BillingInformationFormElement.POSTAL_CODE,
];

export const mandatoryBillingInformationElements = [
  BillingInformationFormElement.NAME,
  BillingInformationFormElement.CITY,
  BillingInformationFormElement.ADDRESS_LINE1,
  BillingInformationFormElement.POSTAL_CODE,
];

export const primaryBillingInformationElements = [BillingInformationFormElement.NAME];

export interface IUserTier {
  price: number;
  upTo: number;
}

export interface IUserTiers {
  subscriptionStatus: string;
  planName: string;
  customerType: string;
  currentUsers: number;
  userTiers: IUserTier[];
}

export enum BillingCreditCardBrand {
  VISA = 'visa',
  AMERICAN_EXPRESS = 'amex',
  DINERS_CLUB = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MASTERCARD = 'mastercard',
  UNION_PAY = 'unionpay',
  UNKNOWN = 'Unknown',
}

export enum PaymentIntentStatus {
  succeeded = 'succeeded',
  error = 'error',
}

export interface IPublisherKey {
  publisher_key: string;
}

export interface IBillingInviteeCost {
  cost: number;
  inviteeUsers: number;
  currentUsers: number;
  balance: number;
  customerType: string;
  planName: string;
  subscriptionStatus: string;
  userTiers: IUserTier[];
}

// old end

// new start

export interface IPaymentMethodDetails {
  name?: string;
  address?: IPaymentMethodAddress;
}

export interface IPaymentMethodAddress {
  [BillingInformationFormElement.CITY]?: string;
  [BillingInformationFormElement.COUNTRY]?: string;
  [BillingInformationFormElement.ADDRESS_LINE1]?: string;
  [BillingInformationFormElement.ADDRESS_LINE2]?: string;
  [BillingInformationFormElement.NAME]?: string;
  [BillingInformationFormElement.POSTAL_CODE]?: string;
  [BillingInformationFormElement.STATE]?: string;
}

export interface IPaymentMethod {
  id: string;
  expireMonth: number;
  expireYear: number;
  lastDigits: string;
  cardBrand: BillingCreditCardBrand;
  billingAddress: IPaymentMethodAddress;
}

export interface IBillingContact {
  balance: number;
  currency: string;
  defaultPaymentMethodId: string;
  email: string;
  id: string;
}

export interface IBillingInfo {
  [key: string]: string;
}

export interface IBillingAddressValid {
  [key: string]: boolean;
}

export interface IBillingPaymentMethod {
  paymentMethodId: string;
}

export interface IPaymentIntent {
  id: string;
  amount: number;
  clientSecret: string;
  customerId: string;
  paymentMethodId: string;
  status?: PaymentIntentStatus;
}

export interface ISetSubscription {
  paymentRequired: boolean;
  paymentIntent: IPaymentIntent;
  publisherKey: string;
}

export interface IInvoice {
  date: string;
  activity: string;
  charge: number;
  link: string;
}

export interface IBillingCoupon {
  id: string;
  name: string;
  currency: string;
  duration: string;
  durationInMonths: number;
  valid: boolean;
  percentOff?: number;
  amountOff?: number;
}

export interface IBillingInvoiceDiscount {
  id: string;
  coupon: IBillingCoupon;
}

export interface IBillingInvoiceDataLine {
  amount: number;
  description: string;
  periodEnd: string;
  periodStart: string;
  priceId: string;
  priceName: string;
  quantity: number;
  proration: boolean;
}

export interface IBillingCalculationInvoice {
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  attemptCount: number;
  attempted: boolean;
  created: string;
  description: string;
  discount: IBillingInvoiceDiscount | null;
  link: string | null;
  nextPaymentAttempt: string;
  paymentIntent: string | null;
  status: string;
  lines: IBillingInvoiceDataLine[];
  startingBalance: number;
  endingBalance: number;
}

export interface IBillingCalculation {
  invoice: IBillingCalculationInvoice;
  paymentAt: string;
  _isLoading: boolean;
}

export interface BillingCostDetail {
  description: string;
  cost: string;
  warningText?: string;
  discount?: boolean;
  isTotal?: boolean;
  debit?: boolean;
}

export interface CreatePaymentMethodIntent {
  clientSecret: string;
  description: string | null;
  id: string;
  status: CreatePaymentMethodIntentResult;
}

export interface CreatePaymentMethodResponse {
  result?: CreatePaymentMethodIntent;
  error?: Error;
}

export interface ICancellationSurveyQuestion {
  title: string;
  checked?: boolean;
}

export const creditCardErrorCodes = [
  StripeErrorCode.INCOMPLETE_NUMBER,
  StripeErrorCode.INVALID_NUMBER,
  StripeErrorCode.CARD_DECLINED,
];
export const setupIntentErrorCodes = [StripeErrorCode.SETUP_INTENT_AUTHENTICATION_FAILURE];
export const creditCardExpiryErrorCodes = [StripeErrorCode.INCOMPLETE_EXPIRY];
export const creditCardCvcErrorCodes = [StripeErrorCode.INCOMPLETE_CVC];

export const teamSurveyQuestions = [
  { title: "I'm not using Writer's premium features" },
  { title: 'Writer is too expensive' },
  { title: 'Writer is missing features' },
  { title: 'I found a better solution' },
  { title: "I don't like Writer's suggestions" },
  { title: 'Writer is buggy or slow' },
  { title: "Writer doesn't work on the websites I use" },
] as ICancellationSurveyQuestion[];

export const proSurveyQuestions = [
  { title: "I'm not using Writer's advanced suggestions" },
  { title: 'Writer is too expensive' },
  { title: 'Writer is missing features' },
  { title: 'I found a better solution' },
  { title: "I don't like Writer's suggestions" },
  { title: 'Writer is buggy or slow' },
  { title: "Writer doesn't work on the websites I use" },
] as ICancellationSurveyQuestion[];

/**
 * Filters Usage Stats to include/remove stats based on the Product being pro/free/team
 * @param usage
 * @param billingProduct
 */
export const filterUsage = (usage: IApplicationUsageStats, billingProduct: BillingProduct) => {
  const { team, words, coWriteWords } = usage;

  if ([BillingProduct.PRO, BillingProduct.FREE].includes(billingProduct)) {
    return {
      team,
      words,
      user: undefined,
      coWriteWords,
    } as IApplicationUsageStats;
  }

  return usage;
};

export interface IBillingPlan {
  id: string;
  productId: string;
  productName: BillingProduct;
  name: string;
  interval: BillingInterval;
  amount: number;
  key: string;
  action: BillingAction;
}

/**
 * @deprecated Use TApplicationUsageStats instead
 */
export interface IApplicationUsageStatsValue {
  value: number;
  limit: number;
}

/**
 * @deprecated Use TApplicationUsageStats instead
 */
export interface IApplicationUsageStats {
  team: IApplicationUsageStatsValue;
  user?: IApplicationUsageStatsValue;
  words: IApplicationUsageStatsValue;
  coWriteWords: IApplicationUsageStatsValue;
  reWriteWords: IApplicationUsageStatsValue;
  domains: IApplicationUsageStatsValue;
  transcripts: IApplicationUsageStatsValue;
}

export type TApplicationUsageStats = components['schemas']['billing_model_UsageItem'];

export enum SubscriptionFeatureType {
  SEO_BLOG = 'seoBlogBuilder',
  CUSTOM_TEMPLATES = 'customTemplates',
  CO_WRITE = 'coWrite',
  RE_WRITE = 'reWrite',
  SCIM = 'scim',
  HIGHLIGHTS = 'highlight',
  SUGGESTIONS_FOUND_REPORT = 'suggestionsFoundReport',
  USER_ACTIVITY_REPORT = 'userActivityReport',
  MAGIC_LINKS = 'magicLinks',
  AUTO_WRITE_BUTTON = 'autoWriteButton',
  AUTO_WRITE_COMMANDS = 'autoWriteCommands',
  CLAIM_DETECTION = 'claimDetection',
  EVENT_TAKEAWAYS = 'eventTakeaways',
  ASK_WRITER = 'askWriter',
  KNOWLEDGE_GRAPH = 'knowledgeGraph',
  DATA_CONNECTOR = 'dataConnector',
  VOICE = 'voice',
  DATA_RETENTION = 'retention',
}

export enum SubscriptionFlagType {
  TRIAL_EXTENSION = 'trialExtensionOffer',
}

export interface ISubscriptionFeatureLock {
  featureType: SubscriptionFeatureType;
  active: boolean;
}

export interface ISubscriptionFlag {
  flag: SubscriptionFlagType;
  active: boolean;
}

/**
 * @deprecated Use TSubscription instead
 */
export interface ISubscription {
  organizationId: number;
  subscriptionId: string;
  productName: IBillingPlan['productName'];
  price?: IBillingPlan;
  cancelledReason: CancelledReason;
  customerType: CustomerType;
  status: BillingStatus;
  quantity: number;
  trialStart: string;
  trialEnd: string;
  createdAt: string;
  startDate: string;
  startPeriodDate: string;
  endPeriodDate: string;
  scheduledToCancelAt: string | null;
  canceledAt: string | null;
  blockadeLimitType: BlockedLimitType | null;
  blockedUntil?: string | null;
  meta: ISubscriptionMeta;
  featureLocks?: ISubscriptionFeatureLock[];
  flags?: ISubscriptionFlag[];
}

/**
 * @deprecated Use TSubscription instead
 */
export interface ISubscriptionMeta {
  userCount: number;
  teamCount: number;
  termsCount: number;
  snippetsCount: number;
  ssoAccess: boolean;
  reporting: {
    suggestionsFoundReport: boolean;
    userActivityReport: boolean;
  };
  portal: {
    enabled: boolean;
    customDomain: boolean;
    customCss: boolean;
  };
  styleguide: {
    compliance: boolean;
    style: boolean;
    inclusivity: boolean;
    clarity: boolean;
    'grammar-spelling': boolean;
    terms: boolean;
    delivery: boolean;
    plagiarism: boolean;
  };
  wordLimit: {
    hour: number;
    day: number;
    month: number;
  };
  tier: 'enterprise-1' | 'enterprise-2' | 'enterprise-3' | 'enterprise-4';
  template: boolean;
  rephrase: boolean;
  seoBlogBuilder?: boolean;
  customTemplates?: boolean;
}

export enum SubscriptionLimitType {
  CONTENT_CHECK = 'contentCheck',
  CO_WRITE = 'coWrite',
  TRANSCRIPT = 'transcript',
  RE_WRITE = 'reWrite',
  DOMAIN = 'domain',
  TEAM = 'team',
  USER = 'user',
  WORDS = 'words',
}

/**
 * @deprecated Use TSubscriptionLimit instead
 */
export interface ISubscriptionLimit {
  id: number;
  organizationId: number;
  subscriptionId: string;
  type: SubscriptionLimitType;
  createdAt: string;
  blockedUntil: string;
  updatedAt: string;
  deleted: boolean;
}

export type TSubscriptionLimit = components['schemas']['billing_model_ApiSubscriptionLimit'];

export interface ISubscriptionStatusLimit extends IApplicationUsageStatsValue {
  exceeded: boolean;
  blockedUntil?: string;
}

export interface WordLimit {
  maxPerHour: number;
  maxPerHourNormalized: number;
  maxPerDay: number;
  maxPerDayNormalized: number;
  maxPerMonth: number;
  maxPerMonthNormalized: number;
}

export const TOrganizationPlanId = new Enum(
  'free',
  'pro',
  'team',
  'enterprise',
  'legacy',
  'ai_studio_payg',
  'ai_studio_enterprise',
);

export type IBillingGroup = components['schemas']['com_qordoba_user_dto_BillingGroupResponse'];
