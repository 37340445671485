import React from 'react';
import { Text, TextSize, TextColor } from '@writercolab/ui-atoms';
import styles from './styles.module.css';

export const CategoryDisabledTooltipContent: React.FC<{
  styleguideSectionUrl?: string | undefined;
  canGoToSettings: boolean;
}> = ({ styleguideSectionUrl, canGoToSettings }) => {
  if (!canGoToSettings) {
    return <>Disabled for your team</>;
  }

  return (
    <Text variant={TextSize.S} color={TextColor.WHITE} className={styles.tooltipLink}>
      Go to{' '}
      <a href={styleguideSectionUrl} target="_blank">
        Setup
      </a>{' '}
      to enable <br /> this category.
    </Text>
  );
};
