import React, { useCallback, useEffect, useState } from 'react';
import cx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import {
  Text,
  TextColor,
  TextSize,
  Input,
  Icon,
  IconVariant,
  Tooltip,
  TermLabel,
  MuiFormControl,
  useVisible,
} from '@writercolab/ui-atoms';
import type {
  CommonMistakeDataItem,
  ILinkedTermBE,
  ITermExampleBase,
  ITermMistakeBase,
  ITermTag,
  ILinkedTerm,
  ITerm,
  ITermCreateAndUpdate,
  MultiFieldError,
  TAddTermsState,
} from '@writercolab/types';
import { PartOfSpeech, TermType } from '@writercolab/types';
import { stopEventPropagation } from '@writercolab/dom';
import styles from './styles.module.css';
import { AddNewRowButton } from '../AddNewRowButton';
import { ThreeDotsLoader } from '../ThreeDotsLoader';

export interface ITermSuggestionsGroupProps {
  termId: number;
  linkedTerms: ILinkedTerm[];
  terms: ITerm[];
  searchTerm: string;
  handleSearch: (term: string) => void;
  onTermClick: (termId: ITerm['id']) => void;
  onChange: (approvedTerms: ILinkedTerm[]) => void;
  onTermCreated: (terms: ITermCreateAndUpdate) => Promise<ITerm>;
}

export const suggestionsDefaultTooltips = {
  banned: `When checking content, we'll underline don't use terms in yellow.`,
  enableApprovedTerm: `When Writer finds the approved term in your content, we'll highlight the term in blue and show a definition card on hover.`,
  enableCommonMistakeTerm: `When Writer finds a common mistake in your content, we'll underline it in yellow and show a suggestion on hover.`,
  correctCaps: `If Writer finds this term in any other casing combination, we'll correct it to the specific casing you've written here.`,
  capsAtStart: `If Writer finds this term at the start of a sentence and uncapitalized, we'll suggest that you capitalize it.`,
  suggestedApprovedTerms: `Select an approved term that you'd like your team to use instead. The approved term will appear as a suggested replacement when we find the don't use term in your content. You can select up to three.`,
  disabledSuggestionsTooltip: `Add a description to your term to enable approved term highlighting.`,
};

const termToLinkedTerm = (termId: number, linkedTerm: ITerm): ILinkedTerm => ({
  id: linkedTerm.id,
  termId,
  type: linkedTerm.type,
  linkedTermId: linkedTerm.id,
  term: linkedTerm.term,
  pos: linkedTerm.pos,
});

export const createTermMapper = (state: TAddTermsState): ITermCreateAndUpdate => ({
  id: state.id,
  term: state.term.trim(),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: state.termType as any,
  caseSensitive: state.caseSensitive,
  tags: state.tags.map(d => ({ ...d, tag: d.tag.trim() }) as ITermTag),
  // remove empty description
  ...(state.description?.length && { description: state.description }),
  // remove pos=PartOfSpeech.DEFAULT
  ...(state.partOfSpeech !== PartOfSpeech.DEFAULT && { pos: state.partOfSpeech }),
  // filter objects with empty `example`
  examples: state.example
    .filter(d => !!d.example.length)
    .map(d => ({ ...d, example: d.example.trim() }) as ITermExampleBase),
  // filter objects with empty `mistake` & empty pos:'-' values
  mistakes: state.commonMistake
    .map(({ mistake, pos, caseSensitive }, i) =>
      pos !== PartOfSpeech.DEFAULT ? state.commonMistake[i] : ({ mistake, caseSensitive } as ITermMistakeBase),
    )
    .map(d => ({ ...d, mistake: d.mistake.trim() }) as ITermMistakeBase)
    .filter(d => !!d.mistake.length),
  highlight: state.enableSuggestions,
  approvedTermExtension: {
    fixCase: state.fixCase === true,
    fixCommonMistakes: state.fixCommonMistakes === true,
    capitalize: state.capitalize === true,
  },
  // when update\create term, we need to prefix linked term with:
  // - # if we refer to ID of a term
  // - @ if we refer to reference (used in terms upload)
  // - no-prefix if we refer to the term itself
  linkedTerms: state.linkedTerms.map(term => ({ reference: `#${term.linkedTermId.toString()}` })) as ILinkedTermBE[],
  backlinkedTerms: [],
});

export const initMultiFieldError: MultiFieldError = {
  message: '',
  term: '',
  fieldPosition: -1,
};

export const initAddTermState: TAddTermsState = {
  id: 0,
  term: '',
  termType: TermType.APPROVED,
  partOfSpeech: PartOfSpeech.DEFAULT,
  caseSensitive: true,
  description: '',
  example: [],
  commonMistake: [],
  tags: [],
  isLoading: false,
  termError: '',
  descriptionError: '',
  examplesError: initMultiFieldError,
  mistakesErrors: [],
  tagsError: '',
  enableSuggestions: true,
  capitalize: true,
  fixCase: true,
  fixCommonMistakes: true,
  linkedTerms: [],
  backlinkedTerms: [],
};

export const SuggestionsGroup = ({
  termId,
  linkedTerms,
  terms,
  searchTerm,
  handleSearch,
  onTermClick,
  onChange,
  onTermCreated,
}: ITermSuggestionsGroupProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [isCreateShown, setIsCreateShown] = useState(false);
  const [newTerm, setNewTerm] = useState<ITerm | undefined>();
  const { triggerRef, dropdownRef, isVisible, setIsVisible } = useVisible(false);

  const toggleDropDown = useCallback(() => setIsVisible(prev => !prev), [setIsVisible]);

  const onAddNewRowClick = () => {
    handleSearch('');
  };

  const onTermChange = (term: string) => {
    setIsLoading(true);
    handleSearch(term);
  };

  const onCreateTerm = async () => {
    if (isCreateLoading) {
      return;
    }

    setIsCreateLoading(true);

    const newTerm = {
      ...initAddTermState,
      term: searchTerm,
    };

    const createdTerm = await onTermCreated(createTermMapper(newTerm));
    setNewTerm(createdTerm);
  };

  const onTermAdded = (term: ITerm) => {
    onChange([...linkedTerms, termToLinkedTerm(termId, term)]);
  };

  const handleRemoveTerm = (removedTerm: ILinkedTerm) => {
    onChange(linkedTerms.filter(term => term.linkedTermId !== removedTerm.linkedTermId));
  };

  useEffect(() => {
    const isTermInTheTermsList = terms.some(term => term.term === searchTerm);

    setIsCreateShown(!isTermInTheTermsList);
    setIsLoading(false);
  }, [terms]);

  useEffect(() => {
    if (!isEmpty(newTerm)) {
      setIsCreateLoading(false);
      onTermAdded(newTerm);
    }
  }, [newTerm]);

  return (
    <MuiFormControl className={cx(styles.formControl)}>
      <div className={styles.header}>
        <Text variant={TextSize.XL} bold>
          Replace with
        </Text>
        <Tooltip
          title={
            <div className={styles.infoTooltipContainer}>
              <Text variant={TextSize.S} color={TextColor.WHITE}>
                {suggestionsDefaultTooltips.suggestedApprovedTerms}
              </Text>
            </div>
          }
          placement="top"
          tooltipWidth={220}
        >
          <span>
            <Icon name={IconVariant.INFO_OUTLINED} />
          </span>
        </Tooltip>
      </div>

      <div className={styles.termsWrapper}>
        {linkedTerms.map(linkedTerm => (
          <div key={linkedTerm.linkedTermId}>
            <TermLabel
              className={styles.termOption}
              term={linkedTerm.term}
              id={linkedTerm.id}
              pos={linkedTerm.pos}
              onClick={() => onTermClick(linkedTerm.linkedTermId)}
            />

            <div aria-hidden onClick={() => handleRemoveTerm(linkedTerm)}>
              <Icon name={IconVariant.REMOVE_BLACK} width={12} height={12} />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.dropDownWrapper}>
        <div aria-hidden ref={triggerRef} onClick={toggleDropDown}>
          {linkedTerms.length < 3 && <AddNewRowButton onClick={onAddNewRowClick} label="Select an approved term" />}
        </div>

        {isVisible && (
          <div aria-hidden className={styles.menuItemsContainer} ref={dropdownRef} onClick={toggleDropDown}>
            <div aria-hidden onClick={stopEventPropagation}>
              <Input
                className={styles.input}
                value={searchTerm}
                onChange={e => {
                  onTermChange(e.target.value);
                }}
              />

              <div>
                <Icon name={IconVariant.CLOSE} width={16} height={16} />
              </div>
            </div>

            {!isLoading && isCreateShown && searchTerm.length > 1 && (
              <div className={styles.createTerm}>
                <Text smallCaps>Create approved term</Text>

                <div>
                  <Text className={styles.termBlue} variant={TextSize.XL}>
                    {searchTerm}
                  </Text>

                  <AddNewRowButton className={styles.noPaddings} onClick={onCreateTerm} label="" />

                  {isCreateLoading && <ThreeDotsLoader className={styles.loadingCreatedTerm} />}
                </div>
              </div>
            )}

            <div className={cx(styles.showTerms, { [styles.showTermsSmaller]: isCreateShown })}>
              {isLoading ? (
                <ThreeDotsLoader className={styles.loading} />
              ) : (
                <div className={styles.terms}>
                  {terms
                    .filter(term => !linkedTerms.some(lTerm => lTerm.linkedTermId === term.id))
                    .map(term => (
                      <TermLabel
                        key={term.id}
                        term={term.term}
                        id={term.id}
                        pos={term.pos}
                        onClick={() => onTermAdded(term)}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </MuiFormControl>
  );
};

export default React.memo(SuggestionsGroup);
