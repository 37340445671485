import { Quill } from 'react-quill';
import QuillCursors from 'quill-cursors';
import { QL_SNIPPET_HIGHLIGHT_FORMAT_NAME } from '@writercolab/quill-delta-utils';

import { Embed } from "./modules/Embed";
import { TextHighlightFormat } from "./modules/TextHighlightFormat";
import { SnippetsDetector } from "./modules/SnippetsDetector";
import { AutoWriteModule } from "./modules/autowrite";
import { BetterTable } from "./modules/better-table";
import { Clipboard } from "./modules/clipboard";
import { CustomEmojiFormat } from "./formats/emoji";
import { Link } from "./formats/link";
import { SnippetFormat } from "./formats/snippets";

const initQuill = () => {
  Quill.register(Embed, true);
  Quill.register(TextHighlightFormat, true);
  Quill.register("modules/cursors", QuillCursors, true);
  Quill.register("modules/autowrite", AutoWriteModule, true);
  Quill.register("modules/better-table", BetterTable, true);
  Quill.register("modules/clipboard", Clipboard, true);
  Quill.register("modules/snippets", SnippetsDetector, true);
  Quill.register("formats/emoji", CustomEmojiFormat, true);
  Quill.register("formats/link", Link, true);
  Quill.register(`formats/${QL_SNIPPET_HIGHLIGHT_FORMAT_NAME}`, SnippetFormat, true);
}

export default initQuill;
