import { makeObservable, computed } from 'mobx';
import type Delta from 'quill-delta';
import type {
  IFirebaseIssue,
  ICollaborationUserWithMeta,
  IStyleguide,
  SidebarDataClient,
  ISidebarDataApi,
  IPipelineContentHashes,
} from '@writercolab/types';
import { Subscriber } from '@writercolab/mobx';
import { getLogger } from '../logger';

const LOG = getLogger('FirebaseModel');

interface ISidebarDataModelOpts {
  client: SidebarDataClient;
  getLocalContent?: () => string | undefined;
}

export class SidebarDataModel {
  private readonly client: ISidebarDataModelOpts['client'];
  private readonly getLocalContent: undefined | (() => string | undefined);

  constructor({ client, getLocalContent }: ISidebarDataModelOpts) {
    LOG.debug('constructor');

    this.client = client;
    this.getLocalContent = getLocalContent;

    makeObservable(this, {
      documentViewers: computed,
      currentUser: computed,
      documentContentData: computed,
      issuesData: computed,
    });
  }

  private readonly firebaseIssuesInternal = new Subscriber<
    Exclude<ISidebarDataApi['onIssuesSubscriber'], undefined>,
    IFirebaseIssue[]
  >({
    name: 'issuesSubscriber',
    autoclear: true,
    getId: () => this.client.api?.onIssuesSubscriber,
    subscribe: (onIssuesSubscriber, onDataUpdate) => onIssuesSubscriber(onDataUpdate),
  });

  readonly issues = new Subscriber<
    {
      list: IFirebaseIssue[] | undefined;
      content: string | undefined;
    },
    IFirebaseIssue[]
  >({
    getId: () => {
      const list = this.firebaseIssuesInternal.data;
      const content = this.documentContent.data;

      return { list, content };
    },
    subscribe: ({ list, content }, push) => {
      if (content === undefined || list === undefined) {
        return;
      }

      const { getLocalContent } = this;

      // content exists, issues is real
      // or we don't have localContent management
      if (!getLocalContent) {
        push(list);

        return;
      }

      const localContent = getLocalContent();

      // if localContent missing, add
      if (localContent === content || list.length) {
        push(list);
      }
    },
  });

  readonly viewers = new Subscriber<
    Exclude<ISidebarDataApi['onViewerSubscriber'], undefined>,
    ICollaborationUserWithMeta[]
  >({
    name: 'viewerSubscriber',
    autoclear: true,
    getId: () => this.client.api?.onViewerSubscriber,
    subscribe: (onViewerSubscriber, onDataUpdate) => onViewerSubscriber(onDataUpdate),
  });

  readonly documentContent = new Subscriber<Exclude<ISidebarDataApi['onDocumentContentSubscriber'], undefined>, string>(
    {
      name: 'documentContentSubscriber',
      autoclear: true,
      getId: () => this.client.api?.onDocumentContentSubscriber,
      subscribe: (onDocumentContentSubscriber, onDataUpdate) => onDocumentContentSubscriber(onDataUpdate),
    },
  );

  readonly styleguide = new Subscriber<Exclude<ISidebarDataApi['onStyleguideSubscriber'], undefined>, IStyleguide>({
    name: 'styleguideSubscriber',
    autoclear: true,
    getId: () => this.client.api?.onStyleguideSubscriber,
    subscribe: (onStyleguideSubscriber, onDataUpdate) => onStyleguideSubscriber(onDataUpdate),
  });

  readonly contentHashes = new Subscriber<
    Exclude<ISidebarDataApi['onContentHashes'], undefined>,
    IPipelineContentHashes
  >({
    name: 'contentHashes',
    autoclear: true,
    getId: () => this.client.api?.onContentHashes,
    subscribe: (onContentHashes, onDataUpdate) => onContentHashes(onDataUpdate),
  });

  readonly documentDelta = new Subscriber<Exclude<ISidebarDataApi['onDocumentDelta'], undefined>, Delta>({
    name: 'documentDelta',
    autoclear: true,
    getId: () => this.client.api?.onDocumentDelta,
    subscribe: (onDocumentDelta, onDataUpdate) => onDocumentDelta(onDataUpdate),
  });

  get styleguideData() {
    return this.styleguide.data;
  }

  get documentViewers() {
    return this.viewers.data || [];
  }

  get currentUser() {
    return this.client.user;
  }

  get api(): Pick<ISidebarDataApi, 'getDocumentDelta' | 'updateDocumentUser' | 'getDocumentPipeline'> | undefined {
    return this.client.api;
  }

  get documentContentData() {
    const documentId = this.client.params.documentId();

    if (!documentId) {
      return undefined;
    }

    return this.documentContent.data;
  }

  get issuesData() {
    const documentId = this.client.params.documentId();

    if (!documentId) {
      return undefined;
    }

    return this.issues.data;
  }
}
