import React, { useCallback, useState } from 'react';
import cx from 'clsx';
import { stopEventPropagation } from '@writercolab/dom';
import { ItemLabel, Button, ButtonTypes, Text, TextSize, useVisible } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

import { InputGroup, InputTypes } from '../InputGroup';

export interface ISelectSuggestion {
  value: number | string;
  label: string;
  id: number;
}

type IGroupItemParam = Pick<ISelectSuggestion, 'value' | 'label' | 'id'> & Partial<unknown>;

export interface ISuggestionGroupProps {
  className?: string;
  items: IGroupItemParam[];
  disabled?: boolean;
  isLoading?: boolean;
  isClosedOnSelect?: boolean;
  autoFocus?: boolean;
  selectLabel: string;
  selectedValue: IGroupItemParam[];
  selectPlaceholder: string;
  selectSearchPlaceholder: string;
  handleSearch: (searchElement: string) => void;
  onSelect: (item: IGroupItemParam) => void;
  onRemove: (item: IGroupItemParam) => void;
}

export const SelectSuggestionGroup = ({
  items,
  disabled = false,
  isLoading = false,
  autoFocus = true,
  isClosedOnSelect = true,
  selectLabel,
  selectedValue,
  selectPlaceholder,
  selectSearchPlaceholder,
  handleSearch,
  onSelect,
  onRemove,
  className,
}: ISuggestionGroupProps) => {
  const [searchItem, setSearchItem] = useState('');
  const { triggerRef, dropdownRef, isVisible, setIsVisible } = useVisible(false);

  const toggleDropDown = useCallback(() => {
    if (disabled) {
      setIsVisible(false);
    } else {
      setIsVisible(prev => !prev);
    }
  }, [setIsVisible, disabled]);

  const onItemChange = (searchElement: string) => {
    setSearchItem(searchElement);

    setTimeout(() => handleSearch(searchElement), 100);
  };

  const handleAddItem = (item: IGroupItemParam) => {
    if (!selectedValue.find(str => str.id === item.id)) {
      onSelect(item);
      setSearchItem('');

      if (isClosedOnSelect) {
        setIsVisible(false);
      }
    }
  };

  return (
    <div className={cx(className, styles.suggestionWrapper)}>
      <div aria-hidden className={styles.dropDownWrapper} onClick={toggleDropDown}>
        <div ref={triggerRef}>
          <InputGroup
            name="selectGroup"
            id="selectGroup"
            className={styles.selectInputGroup}
            inputType={InputTypes.INPUT}
            value={selectedValue.map(val => val.label).join(', ') || selectPlaceholder}
            label={
              <Text caps variant={TextSize.XS}>
                {selectLabel}
              </Text>
            }
            disabled={disabled}
            disableHelperText
          />
        </div>

        {isVisible && (
          <div aria-hidden className={styles.menuItemsContainer} ref={dropdownRef} onClick={toggleDropDown}>
            <div
              aria-hidden
              onClick={stopEventPropagation}
              className={cx(styles.inputWrapper, {
                [styles.emptyText]: selectedValue.length === 0,
                [styles.disabled]: disabled,
              })}
            >
              {selectedValue.map((item: IGroupItemParam) => (
                <div key={item.id}>
                  <ItemLabel
                    label={item.label}
                    id={item.id}
                    onClick={() => handleAddItem(item)}
                    onClose={() => onRemove(item)}
                  />
                </div>
              ))}

              <input
                placeholder={selectSearchPlaceholder || 'search'}
                className={styles.input}
                value={searchItem}
                disabled={disabled}
                onChange={e => {
                  onItemChange(e.target.value);
                }}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
              />
            </div>

            <div className={cx(styles.showTerms)}>
              {isLoading ? (
                <Button className={styles.loading} isLoading type={ButtonTypes.TRANSPARENT} content="" />
              ) : (
                <div className={styles.terms}>
                  {items.length === 0 ? (
                    <Text variant={TextSize.L}>No orgs found</Text>
                  ) : (
                    items.map((item: ISelectSuggestion) => (
                      <div key={item.id}>
                        <ItemLabel label={item.label} id={item.id} onClick={() => handleAddItem(item)} />
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(SelectSuggestionGroup);
