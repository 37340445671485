import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { GradeScoreColor, DocumentStatTypes } from '@writercolab/ui-sidebar-models';
import { Text, TextSize, Icon, IconVariant } from '@writercolab/ui-atoms';
import styles from './styles.module.css';

import { FleschKincaidScoreTooltip } from '../FleschKincaidScoreTooltip';
import type { TStatsDropdownOption } from '../StatsDropdown';
import { StatsDropdown } from '../StatsDropdown';
import type { UIDocumentStatsModel } from '../../organisms/DocumentStats';

interface DocumentStatProps {
  className?: string;
  classNameSelect?: string;
  model: Pick<UIDocumentStatsModel, keyof UIDocumentStatsModel>;
  withGradeLevelOption?: boolean;
}

export const DocumentStatsDropdown = observer<DocumentStatProps>(
  ({ className, classNameSelect, model, withGradeLevelOption = false }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const {
      characterCount,
      wordCount,
      defaultOption,
      avgSentenceLength,
      readingTimeReadable,
      isFleschKincaidScoreAvailable,
      fleschKincaidScoreValueReadable,
      gradeColor,
      onDocumentStatOptionChange,
    } = model;

    const [currentOptionId, setCurrentOptionId] = useState(defaultOption);

    const dropdownOptions = useMemo(() => {
      const commonOptions: TStatsDropdownOption[] = [
        {
          label: 'Words',
          value: wordCount,
          id: DocumentStatTypes.enum.words,
        },
        {
          label: 'Characters',
          value: characterCount,
          id: DocumentStatTypes.enum.characters,
        },
        {
          label: 'Sentence length',
          value: avgSentenceLength,
          id: DocumentStatTypes.enum.sentenceLength,
        },
        {
          label: 'Reading time',
          value: readingTimeReadable,
          id: DocumentStatTypes.enum.readingTime,
        },
      ];

      if (withGradeLevelOption && fleschKincaidScoreValueReadable) {
        commonOptions.push({
          label: 'Grade level',
          value: fleschKincaidScoreValueReadable,
          id: DocumentStatTypes.enum.gradeLevel,
        });
      }

      return commonOptions;
    }, [
      wordCount,
      characterCount,
      avgSentenceLength,
      readingTimeReadable,
      fleschKincaidScoreValueReadable,
      withGradeLevelOption,
    ]);

    const currentOption = dropdownOptions.find(item => item.id === currentOptionId);
    const isGradeLevelOptionSelected = currentOptionId === DocumentStatTypes.enum.gradeLevel;
    const valueBackgroundColorClassName = {
      [styles.statValueGreen]: gradeColor === GradeScoreColor.enum.green,
      [styles.statValueBlue]: gradeColor === GradeScoreColor.enum.blue,
      [styles.statValuePurple]: gradeColor === GradeScoreColor.enum.purple,
      [styles.statValueGray]: gradeColor === GradeScoreColor.enum.gray,
    };

    return (
      <div className={cx(className, styles.main)}>
        <div
          className={cx(styles.selectedOption, classNameSelect)}
          onClick={() => setDropdownVisible(!dropdownVisible)}
          onKeyDown={() => setDropdownVisible(!dropdownVisible)}
          role="switch"
          tabIndex={-1}
          aria-checked={dropdownVisible}
        >
          <Text variant={TextSize.XL}>{currentOption?.label}</Text>
          <FleschKincaidScoreTooltip isEmpty={!isFleschKincaidScoreAvailable} disabled={!isGradeLevelOptionSelected}>
            <Text
              className={cx(
                styles.selectedOptionValue,
                isGradeLevelOptionSelected && [styles.gradeLevelValue, valueBackgroundColorClassName],
              )}
              variant={TextSize.XL}
            >
              {currentOption?.value}
            </Text>
          </FleschKincaidScoreTooltip>
          <Icon
            name={IconVariant.BREADCRUMB_ARROW}
            className={cx(styles.arrow, { [styles.arrowUp]: dropdownVisible })}
          />
        </div>
        {dropdownVisible && (
          <StatsDropdown
            className={cx(styles.dropdown, className)}
            dropdownOptions={dropdownOptions}
            currentOptionId={currentOptionId}
            onChange={id => {
              setCurrentOptionId(id);
              setDropdownVisible(false);
              onDocumentStatOptionChange?.(id);
            }}
          />
        )}
      </div>
    );
  },
);
