
const digest = '5fc475b0ac55b78e922eb6b60aa7fde778cbad138b705877e62801f61bf569db';
const css = `.styles-module__phjdUhF {
  display: block;
  overflow: hidden;
  border-radius: 50%;
  cursor: default;
}

.styles-module__fj4TSlu {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.styles-module__fj4TSlu p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: var(--classic-white);
  text-align: center;
}

.styles-module__MekcLg0 {
  width: 24px;
  height: 24px;
}

.styles-module__bo9rwIL {
  width: 40px;
  height: 40px;
}

.styles-module__htAZnZ0 {
  width: 100px;
  height: 100px;
}

.styles-module__UQ3THW- {
  width: 30px;
  height: 30px;
}

.styles-module__v0cZE9l {
  width: 28px;
  height: 28px;
}

.styles-module__V-Q-oqC {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.styles-module__V-Q-oqC img {
  width: 100%;
  height: 100%;
}

.styles-module__V-Q-oqC p {
  margin: 0;
  color: var(--classic-white);
  line-height: 12px;
}

.styles-module__YNzNRxt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
}

.styles-module__cuVghld {
  cursor: pointer;
}
`;

export default {"container":"styles-module__phjdUhF","containerInitials":"styles-module__fj4TSlu","sizeXs":"styles-module__MekcLg0","sizeXl":"styles-module__bo9rwIL","sizeXxxl":"styles-module__htAZnZ0","sizeL":"styles-module__UQ3THW-","sizeM":"styles-module__v0cZE9l","containerIcon":"styles-module__V-Q-oqC","default":"styles-module__YNzNRxt","clickable":"styles-module__cuVghld"};
export { css, digest };
  