
const digest = '637533db0561566e9c0a540fb494b7b503fddfd572c4f59618ca8710bc016265';
const css = `.styles-module__1AqTstk {
  font-family: var(--font-default) !important;
}

.styles-module__rm09unK {
  background-color: #d6fff3;
  font-weight: var(--font-medium-weight);
}

.styles-module__1RH7a91 {
  display: none;
}
`;

export default {"container":"styles-module__1AqTstk","added":"styles-module__rm09unK","removed":"styles-module__1RH7a91"};
export { css, digest };
  