import { Adapter } from './Adapter';
import type { IAnalyticsGtm } from '../types';

/**
 * Adapter class for Google Tag Manager (GTM) integration.
 *
 * @template TContext - The context type extending a record with string keys and unknown values.
 * @extends Adapter
 * @implements Partial<IAnalytics<TContext>>
 */
export class GtmAdapter<TGtmActivityParams> extends Adapter implements IAnalyticsGtm<TGtmActivityParams> {
  /**
   * Creates an instance of GtmAdapter.
   *
   * @param dataLayer - The GTM data layer, defaults to `window.dataLayer` if available.
   */
  constructor(private readonly dataLayer = typeof window !== 'undefined' ? window.dataLayer : undefined) {
    super();
  }

  /**
   * Tracks an activity in GTM by pushing the provided parameters to the data layer.
   *
   * @param params - The parameters for the GTM activity.
   * @returns A promise that resolves when the activity has been tracked.
   */
  async trackGtmActivity(params: TGtmActivityParams): Promise<void> {
    this.dataLayer?.push(params);
  }
}

declare global {
  interface Window {
    dataLayer?: { push: (e: unknown) => void };
  }
}
