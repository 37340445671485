import { formatError } from './formatError';

/**
 * The BaseError class is a custom error class that extends the built-in Error class.
 * It provides additional properties to store information about the error and its cause.
 */
export class BaseError extends Error {
  /**
   * The error that caused this error.
   * @readonly
   */
  public readonly error: unknown;

  /**
   * The original error that was thrown.
   * If this error is a BaseError, the error property will be extracted from it.
   * @readonly
   */
  public override readonly cause: unknown;

  /**
   * Creates a new instance of the BaseError class.
   * @param err - The original error that was thrown.
   * @param cause - The error that caused this error.
   */
  constructor(err: unknown, cause?: unknown) {
    super('BaseError');
    this.name = 'BaseError';
    this.message = `${formatError(err)} ${cause ?? ''}`;

    if (cause) {
      this.cause = cause;
    }

    this.error = err instanceof BaseError ? err.error : err;
    Object.setPrototypeOf(this, BaseError.prototype);
  }
}
