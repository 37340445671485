/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeObservable, computed } from 'mobx';
import { PaginatedModel, PromisedError } from '@writercolab/mobx';
import type { components, operations, RequestServiceInitialize } from '@writercolab/network';

export interface ISnippetsModelParams {
  api: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: number;
  defaultLimit?: number;
  defaultPrefix?: string;
}

export interface ISnippetSearchParams {
  search: string | undefined;
  sortField: Exclude<Exclude<operations['findAllSnippets']['parameters']['query'], undefined>['sortField'], undefined>;
  sortOrder: Exclude<Exclude<operations['findAllSnippets']['parameters']['query'], undefined>['sortOrder'], undefined>;
}

/**
 * Implement loading snippets data
 */
export class SnippetsModel extends PaginatedModel<
  components['schemas']['model_PaginatedResult_terminology_dto_FullSnippetWithUser_scala_Tuple2'],
  components['schemas']['terminology_dto_FullSnippetWithUser_scala_Tuple2'],
  { limit: number; offset: number },
  ISnippetSearchParams
> {
  constructor({ teamId, organizationId, api, defaultLimit = 50, defaultPrefix = 'w.' }: ISnippetsModelParams) {
    super({
      argsExtra: {
        search: undefined,
        sortField: 'creationTime',
        sortOrder: 'desc',
      },
      argsDefault: { offset: 0, limit: defaultLimit },
      extractMeta: obj => {
        const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

        if (offset >= obj.totalCount) {
          return this.args;
        }

        return {
          offset,
          limit: defaultLimit,
        };
      },
      extract: obj => obj.result ?? [],
      load: async ({ args: { limit, offset }, extra: { sortField, sortOrder, search } }) => {
        const { data } = await api.get('/api/terminology/organization/{organizationId}/team/{teamId}/snippet', {
          params: {
            path: {
              organizationId,
              teamId,
            },
            query: {
              offset,
              limit,
              sortField,
              sortOrder,
              search,
            },
          },
        });

        return data;
      },
    });

    const { setExtra } = this;

    this.setExtra = ({ search, ...params }, replace) =>
      setExtra.call(
        this,
        {
          ...this.extra,
          ...params,
          search: search ? search.toLowerCase().replace(defaultPrefix, '') : undefined,
        },
        replace as any,
      );

    makeObservable(this, {
      progress: computed,
    });
  }

  /**
   * Gets the progress of the operation.
   * @returns {string} The progress status ('pending', 'error', 'empty', or 'data').
   */
  get progress() {
    if (this.status === 'pending') {
      return 'pending';
    }

    const { valueWithError } = this;

    if (valueWithError instanceof PromisedError) {
      return 'error';
    }

    if (!valueWithError?.length && !this.extra.search?.length) {
      return 'empty';
    }

    return 'data';
  }
}
