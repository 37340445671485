import React from 'react';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Category as CategoryBase } from '@writercolab/ui-molecules';
import type { UICategoryModel } from './UICategoryModel';
import styles from './styles.module.css';
import { CategoryDisabledTooltipContent } from '../../molecules/CategoryDisabledTooltipContent';

export const Category = observer<{
  className?: string;
  model: UICategoryModel;
}>(({ className, model }) => {
  const { category, canGoToSettings, value, hideCounter, selected, counterUnavailable, isSmall, onClick } = model;

  const disabledTooltipContent = (
    <CategoryDisabledTooltipContent
      styleguideSectionUrl={category.styleguideSectionUrl}
      canGoToSettings={canGoToSettings}
    />
  );

  return (
    <CategoryBase
      className={cx(styles.fadeIn, className)}
      value={value}
      label={category.label}
      hideCounter={hideCounter}
      selected={selected}
      color={category.color}
      status={category.status}
      counterUnavailable={counterUnavailable}
      onClick={onClick}
      isSmall={isSmall}
      disabledTooltipContent={disabledTooltipContent}
    />
  );
});
