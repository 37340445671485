import React, { useCallback } from 'react';

import { LearnMoreModalType } from '@writercolab/types';

import { PassiveVoiceInfo, PeopleFirstInfo, NeutralLangInfo, Icon, IconVariant } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

export interface ILearnMoreContent {
  modalType?: LearnMoreModalType;
  onCloseModal?: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
  hideCloseButton?: boolean;
}

export const LearnMoreContent: React.FC<ILearnMoreContent> = ({ onCloseModal, modalType, hideCloseButton }) => {
  const getLearnMoreModal = useCallback(() => {
    switch (modalType) {
      case LearnMoreModalType.PASSIVE_VOICE:
        return <PassiveVoiceInfo />;
      case LearnMoreModalType.DISABILITY_PEOPLE_FIRST:
        return <PeopleFirstInfo />;
      case LearnMoreModalType.DISABILITY_NEUTRAL_LANGUAGE:
        return <NeutralLangInfo />;
      default:
        return null;
    }
  }, [modalType]);

  return (
    <div>
      {!hideCloseButton ? (
        <div
          aria-label="Close"
          className={styles.closeIcon}
          onClick={onCloseModal}
          onKeyDown={onCloseModal}
          role="button"
          tabIndex={-1}
        >
          <Icon name={IconVariant.CLOSE} />
        </div>
      ) : null}
      <div className={styles.modalContainer}>{getLearnMoreModal()}</div>
    </div>
  );
};
