
const digest = '846b7d31a9c1be31c1742b6752cf457465b59f6b86880cc52ba6362d81780af4';
const css = `/* Base styles for input fields */
.Input-module__tgVCZp6 {
  width: 100%;
  border: 1px solid var(--writer-graynu-2);
  border-radius: 8px;
  background: var(--classic-white);
  color: var(--classic-black);
  font-size: var(--font-size-l);
  font-weight: var(--font-default-weight);
  outline: none;

  &.Input-module__DqCMlD3 {
    padding: 8px 16px;
  }

  &.Input-module__M12v-V9 {
    height: 48px;
    padding: 10px 16px;
    font-size: var(--font-size-xl);
  }

  &.Input-module__LCVENKx {
    border: 1px solid var(--writer-graynu-2);

    &:focus,
    &:hover {
      border: 1px solid var(--writer-blue-3);
      box-shadow: var(--input-box-shadow);
    }

    &:disabled {
      border: 1px solid var(--writer-graynu-1) !important;
      background: var(--writer-graynu-1) !important;
      box-shadow: none;
      color: var(--writer-grey-2) !important;
      cursor: default;
    }
  }

  &.Input-module__wmd-Cl- {
    border: 1px solid var(--classic-orange-1) !important;
    box-shadow: none !important;

    &:focus {
      border: 1px solid var(--classic-orange-1) !important;
    }
  }

  &:hover,
  &:active {
    border: 1px solid var(--writer-blue-3);
  }

  &::placeholder {
    color: var(--writer-grey-3);
    font-weight: var(--font-default-weight);
    opacity: 0.8;
  }

  &:focus-visible {
    border: 1px solid var(--writer-blue-3);
    box-shadow: var(--input-box-shadow);
    outline: none;
  }

  &:focus {
    border: 1px solid var(--writer-blue-3);
    box-shadow: var(--input-box-shadow);
  }

  &:disabled {
    border: 1px solid var(--writer-graynu-1) !important;
    background: var(--writer-graynu-1) !important;
    color: var(--writer-grey-2) !important;
    cursor: default;
  }
}

.Input-module__GnT3KCJ {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  user-select: none;

  &.Input-module__SJwxlue {
    color: var(--writer-grey-1);
    pointer-events: none;
  }

  &:hover {
    .Input-module__qNq5sGo {
      border-color: var(--classic-grey-3);
      color: var(--classic-grey-1-5);
    }
  }

  & input[type='checkbox'] {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
  }
}
`;

export default {"inputText":"Input-module__tgVCZp6","small":"Input-module__DqCMlD3","medium":"Input-module__M12v-V9","empty":"Input-module__LCVENKx","hasError":"Input-module__wmd-Cl-","containerCheckbox":"Input-module__GnT3KCJ","disabled":"Input-module__SJwxlue","checkbox":"Input-module__qNq5sGo"};
export { css, digest };
  