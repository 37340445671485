export interface ITeamKnowledgeMediaCreatedBy {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  avatar: string;
}

export enum TeamKnowledgeMediaStatus {
  QUEUED = 'queued',
  processing = 'processing',
  COMPLETED = 'completed',
  ERROR = 'error',
}

export enum TeamKnowledgeAnswerSource {
  DB = 'db',
}

export interface ITeamKnowledgeMedia {
  fileId: string;
  organizationId: number;
  teamId: number;
  name: string;
  path: string;
  url: string;
  size: number;
  createdBy: ITeamKnowledgeMediaCreatedBy;
  createdAt: string;
  status: TeamKnowledgeMediaStatus;
}

export interface ITeamKnowledgeReferenceDoc {
  fileName: string;
  fileId: string;
}

export interface ITeamKnowledgeAnswer {
  id: string;
  question: string;
  answer: string;
  source: TeamKnowledgeAnswerSource;
  references: ITeamKnowledgeReferenceDoc[];
  createdAt: string;
  createdBy: number;
  teamId: number;
}

export interface ITeamKnowledgeWebsite {
  websiteId: number;
  organizationId: number;
  teamId: number;
  url: string;
  pageCount: number;
  createdBy: ITeamKnowledgeMediaCreatedBy;
  createdAt: string;
  updatedBy: ITeamKnowledgeMediaCreatedBy;
  updatedAt: string;
}
