import isEmpty from 'lodash/isEmpty';
import { getSentences } from './getSentences';

/**
 * Returns the number of sentences in the given text.
 * @param text - The text to count the sentences in.
 * @returns The number of sentences in the given text.
 */
export function getSentenceCount(text: string): number {
  return getSentences(text).filter(s => !isEmpty(s.text.trim())).length;
}
