import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import cx from 'clsx';
import { Enum } from '@writercolab/utils';
import styles from './RTooltip.module.css';

export const RTooltipPlacement = new Enum('top', 'bottom', 'right', 'left');

interface RTooltipProps {
  className?: string;
  children: React.ReactNode;
  placement?: typeof RTooltipPlacement.type;
  content?: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  disabled?: boolean;
  disabledCursorStyle?: string;
  delay?: number;
  indent?: number;
  arrowWidth?: number;
  arrowHeight?: number;
  arrowColor?: string;
  onOpenChange?: (open: boolean) => void;
}

/**
 * @deprecated use WDS
 */
export const RTooltip = ({
  open,
  defaultOpen,
  onOpenChange,
  children,
  content,
  placement = RTooltipPlacement.enum.top,
  delay = 250,
  arrowWidth = 12,
  arrowHeight = 6,
  arrowColor = 'black',
  className,
  disabled = false,
  indent = 5,
  disabledCursorStyle = 'not-allowed',
}: RTooltipProps) => (
  <Tooltip.Provider delayDuration={delay}>
    <Tooltip.Root open={disabled ? false : open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <Tooltip.Trigger
        asChild
        disabled={disabled}
        style={{
          cursor: disabled ? disabledCursorStyle : 'pointer',
        }}
      >
        {children}
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className={cx(styles.tooltipContent, className)} sideOffset={indent} side={placement}>
          {content}
          <Tooltip.Arrow width={arrowWidth} height={arrowHeight} fill={arrowColor} />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
);
