
const digest = 'ec0d08073fd1f65b59487fb93bce0cced68ea3d4e13482b2b9e9168bec7f64fb';
const css = `.FkChangeIndicator-module__kplEOu- {
  display: flex;
  align-items: center;
}

.FkChangeIndicator-module__MBh0RAr {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.FkChangeIndicator-module__RUXCOPv {
  background: var(--classic-orange-0);
}

.FkChangeIndicator-module__tc7O1xi {
  background: var(--writer-blue-2);
}
`;

export default {"fkChangeIndicator":"FkChangeIndicator-module__kplEOu-","label":"FkChangeIndicator-module__MBh0RAr","from":"FkChangeIndicator-module__RUXCOPv","to":"FkChangeIndicator-module__tc7O1xi"};
export { css, digest };
  