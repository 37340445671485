import type { ISidebarCategory } from '@writercolab/types';
import { CategoryType } from '@writercolab/types';
import { createFactory } from '@writercolab/utils';
import { computed, makeObservable, comparer } from 'mobx';
import type { SidebarModel } from '@writercolab/ui-sidebar-models';
import { SidebarSection } from '@writercolab/ui-sidebar-models';
import { UICategoryModel } from '../Category/UICategoryModel';

export class UICategoriesPanelModel {
  constructor(readonly model: SidebarModel) {
    makeObservable(this, {
      categoriesList: computed({ equals: comparer.shallow }),
    });
  }

  private readonly factoryCategories = createFactory<UICategoryModel, ISidebarCategory>({
    create: category => new UICategoryModel(this.model, category),
    recreate: (inst, category) => {
      inst.setCategory(category);
    },
  });

  get categoriesList() {
    return this.model.categories?.categoriesList.map(category => this.factoryCategories.create(category));
  }

  get isSnippetsSection() {
    return this.model.categories?.isSnippetsSection;
  }

  get totalSidebarIssuesCount() {
    return this.model.issues?.totalSidebarIssuesCount;
  }

  get isLoading() {
    return this.model.sidebarState.isLoading;
  }

  get isStyleguideLoading() {
    return this.model.sidebarState.isStyleguideLoading;
  }

  get hideCategoriesHeader() {
    return this.model.sidebarState.hideCategoriesHeader;
  }

  get hideSnippets() {
    return this.model.sidebarState.hideSnippets || this.model.isSnippetsCategoryHidden;
  }

  get isStandard() {
    return this.model.size.isStandard;
  }

  get isSmall() {
    return this.model.size.isSmall;
  }

  get onRefreshScoreClick(): SidebarModel['onRefreshScoreClick'] {
    return this.model.onRefreshScoreClick;
  }

  get isSelected() {
    return !this.model.categories?.selectedCategory && this.model.categories?.activeSection === SidebarSection.ISSUES;
  }

  get hideScoreValue() {
    return this.model.issues?.isEmptyState || !this.model.documentStats.score.available;
  }

  get hideCounter() {
    if (this.isLoading) {
      return true;
    }

    return this.model.issues?.isEmptyState ?? true;
  }

  get scoreValue() {
    return this.model.documentStats.score.value;
  }

  readonly onSelectSnippets = () => this.model.onSelectCategory(CategoryType.SNIPPETS);

  readonly onSelectAll = () => this.model.onSelectCategory(CategoryType.ALL);
}
