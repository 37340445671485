export enum UserAccountStatus {
  INVITED = 'invited',
  SIGNED_UP = 'signed_up',
}

export enum CustomerType {
  SELF_SERVE = 'self_service',
  ENTERPRISE = 'enterprise',
}

export enum UserAuthType {
  PRIVATE = 'private',
  SAML = 'saml',
  SOCIAL = 'social',
  SCIM = 'SCIM',
}
