import type { TApplicationUserType } from '@writercolab/types';
import type { SortFieldTypes, SortOrders } from '@writercolab/validation-utils';
import type { TUserStatusFilter } from './shared';

export enum SearchQueryParam {
  search = 'search',
  sortField = 'sortField',
  sortOrder = 'sortOrder',
  offset = 'offset',
  limit = 'limit',
  scope = 'scope',
  entityId = 'entityId',
  hideTrialBanner = 'hideTrialBanner',
  create = 'create',
  creationTime = 'creationTime',
  status = 'status',
  error = 'error',
  draftsPreview = 'draftsPreview',
  id = 'id',
  createOrganization = 'create-organization',
}

export interface RequestQueryParams {
  search?: string;
  sortField?: SortFieldTypes | TermsSortingTypes | null;
  sortOrder?: string | SortOrders | null;
  offset?: number;
  limit?: number;
  id?: number | string;
  searchFields?: string;
  scope?: DocsRequestTypes;
  type?: TermsRequestTypes;
  tags?: string[];
  status?: string[];
  approvedByInviter?: boolean;
  createOrganization?: string;
  userType?: typeof TApplicationUserType.type;
  userStatus?: (typeof TUserStatusFilter.type)[];
  /**
   * @deprecated use `userStatus` instead
   */
  accountStatus?: typeof TUserStatusFilter.type;
  /**
   * @deprecated temporary solution for the People page implementation
   */
  hasConsoleRole?: boolean;
}

export interface Pagination {
  limit: number;
  offset: number;
}

export enum TermsSortingTypes {
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  TYPE_FILTER = 'type_filter',
  MODIFICATION_DATE_ASC = 'modificationTime_asc',
  MODIFICATION_DATE_DESC = 'modificationTime_desc',
  ADDED_DATE_ASC = 'creationTime_asc',
  ADDED_DATE_DESC = 'creationTime_desc',
  LAST_SEEN_ASC = 'lastSeen_asc',
  LAST_SEEN_DESC = 'lastSeen_desc',
}

export enum DocsRequestTypes {
  MY = 'mine',
  ALL = 'all',
  FAVORITES = 'favorite',
  MODIFIED = 'modified',
  NOT_OWNED_BY_ME = 'notOwnedByMe',
}

export enum TermsRequestTypes {
  BANNED = 'banned',
  APPROVED = 'approved',
  PENDING = 'pending',
  USE_CAREFULLY = 'useCarefully',
}

export enum BatchSubmitFailHandlingTypes {
  ACCUMULATE = 'accumulate',
}
