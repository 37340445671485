import { getLogger } from '@writercolab/utils';
import { _isComputingDerivation, runInAction, untracked } from 'mobx';
import type { IPromisedModelParams } from './PromisedModel';
import { PromisedModel } from './PromisedModel';

const logger = getLogger('MutatePromisedModel');

export interface IMutatePromisedModelParams<T, TArgs = void> extends IPromisedModelParams<T> {
  mutate(args: TArgs): Promise<void | false> | void | false;
}

export class MutatePromisedModel<T, TArgs = void> extends PromisedModel<T> {
  readonly mutate: (args: TArgs) => Promise<void>;

  constructor({ mutate, load, ...options }: IMutatePromisedModelParams<T, TArgs>) {
    super({
      ...options,
      load: params => {
        logger.debug(this.name, 'load:overwrite');

        return load(params);
      },
    });

    this.mutate = async (args: TArgs) => {
      logger.debug(this.name, 'mutate', args);
      runInAction(() => {
        const ret = untracked(() => mutate(args));

        if (!_isComputingDerivation()) {
          // if we outside reactive context we should clear cache
          this.clearSubscription();
        }

        if (ret === undefined || ret === false) {
          if (ret !== false) {
            this.reload();
          }
        } else {
          ret.then(data => {
            if (data !== false) {
              this.reload();
            }
          });
        }
      });
    };
  }
}
