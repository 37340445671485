
const digest = '09271f9df892f5bd385187ac46e421de2221ea1fa0219414a63ba15f95c73e89';
const css = `.styles-module__pWr3UPC {
  display: flex;
  align-items: baseline;
  padding: 0 var(--sidebar-horizontal-spacing);
}

.styles-module__aNCokTE {
  position: absolute;
  top: 11px;
  left: 0;
}

.styles-module__Hl79SRv {
  display: flex;
  align-items: baseline;
  font-weight: 500;
}

.styles-module__CO0uTrW {
  font-size: 11px;
  line-height: 16px;
}

.styles-module__I-BZxC9 {
  position: relative;
  padding-top: 4px;
  margin-right: 7px;
}

.styles-module__E4iHuNK {
  color: var(--classic-grey-3);
  font-size: 13px;
  line-height: 1;
}
`;

export default {"container":"styles-module__pWr3UPC","icon":"styles-module__aNCokTE","titleContainer":"styles-module__Hl79SRv","subTitle":"styles-module__CO0uTrW","header":"styles-module__I-BZxC9","issueCount":"styles-module__E4iHuNK"};
export { css, digest };
  