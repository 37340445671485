import { makeObservable, computed } from 'mobx';
import { PaginatedModel } from '@writercolab/mobx';
import type { TGetDraftsPaginatedRequest, TGetDraftsPaginatedResponse, TGetDraftsResponse } from '@writercolab/types';
import type { DraftsApiModel } from '@writercolab/models';

export interface IDraftsPaginatedApiModelOpts {
  draftsApiModel: Pick<DraftsApiModel, 'loadDrafts'>;
  initialPaginationArgs?: TGetDraftsPaginatedRequest;
  organizationId: () => number | undefined;
  teamId: () => number | undefined;
}

export class DraftsPaginatedApiModel {
  static defaultDraftPaginationLimit = 25;

  constructor(private opts: IDraftsPaginatedApiModelOpts) {
    this.$paginatedDrafts = new PaginatedModel<
      TGetDraftsPaginatedResponse | undefined,
      TGetDraftsResponse,
      { offset: number; limit: number },
      TGetDraftsPaginatedRequest
    >({
      argsExtra: opts.initialPaginationArgs ?? {
        templateIds: [],
      },

      argsDefault: {
        offset: 0,
        limit: DraftsPaginatedApiModel.defaultDraftPaginationLimit,
      },

      extractMeta: obj => {
        if (!obj) {
          return undefined;
        }

        const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

        if (offset >= obj.totalCount) {
          return undefined;
        }

        return {
          offset,
          limit: DraftsPaginatedApiModel.defaultDraftPaginationLimit,
        };
      },
      extract: obj => obj?.result ?? [],

      load: async ({ args, extra }) => {
        const query: TGetDraftsPaginatedRequest = {
          ...args,
          ...extra,
        };

        const { organizationId, teamId } = this;

        if (!organizationId || !teamId) {
          return undefined;
        }

        return this.opts.draftsApiModel.loadDrafts(organizationId, teamId, query);
      },
    });
    makeObservable(this, {
      organizationId: computed,
      teamId: computed,
    });
  }

  get organizationId() {
    return this.opts.organizationId();
  }

  get teamId() {
    return this.opts.teamId();
  }

  readonly $paginatedDrafts: PaginatedModel<
    TGetDraftsPaginatedResponse | undefined,
    TGetDraftsResponse,
    { offset: number; limit: number },
    TGetDraftsPaginatedRequest
  >;

  setExtra(extra: Partial<TGetDraftsPaginatedRequest>) {
    this.$paginatedDrafts.setExtra(extra);
  }

  reload() {
    this.$paginatedDrafts.reload();
  }
}
