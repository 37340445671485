import React, { useEffect, useState } from 'react';
import cx from 'clsx';

import { Text, TextColor, TextSize, Heading, Logo, Tag, TagColor, TagTextAlign } from '@writercolab/ui-atoms';
import styles from './styles.module.css';
import { getLogger } from '../../utils/logger';
import ConfirmEmailImg from '../../assets/icons/confirmEmail.svg';
import { EMAIL_CONFIRMATION_SEND, useSessionStorage } from '../../hooks/useSessionStorage';

const LOG = getLogger('EmailConfirmationBanner');
const NOTIFICATION_TAG_SHOW_DELAY = 5000;

export interface IEmailConfirmationBannerProps {
  onResendButtonClick?: () => Promise<void>;
  notificationTagShowDelay?: number;
}

const delay = (ms: number): Promise<unknown> => new Promise(res => setTimeout(res, ms));

export const EmailConfirmationBanner: React.FC<IEmailConfirmationBannerProps> = ({
  onResendButtonClick,
  notificationTagShowDelay = NOTIFICATION_TAG_SHOW_DELAY,
}) => {
  const [confirmationSend, setConfirmationSend] = useSessionStorage<boolean | null>(EMAIL_CONFIRMATION_SEND, null);
  const [actionSubmitted, setActionSubmitted] = useState(false);
  const [actionError, setActionError] = useState(false);

  const _onResendButtonClick = () => {
    onResendButtonClick &&
      onResendButtonClick()
        .then(() => {
          setActionError(false);
          setActionSubmitted(true);
          delay(notificationTagShowDelay).then(() => setActionSubmitted(false));
        })
        .catch(e => {
          setActionError(true);
          delay(notificationTagShowDelay).then(() => setActionError(false));
          LOG.error(e);
        });
  };

  useEffect(() => {
    if (!confirmationSend && onResendButtonClick) {
      onResendButtonClick()
        .then(() => setConfirmationSend(true))
        .catch(e => {
          setActionError(true);
          delay(notificationTagShowDelay).then(() => setActionError(false));
          LOG.error(e);
        });
    }
  }, [confirmationSend]);

  return (
    <div className={styles.container}>
      <div className={styles.containerHero}>
        <div className={styles.containerHeroIconContainer}>
          <ConfirmEmailImg />
        </div>
        <div className={styles.containerHeroHeading}>
          <Heading>
            Looks like you still need to
            <br /> confirm your email address.
          </Heading>
        </div>
        <div className={styles.containerHeroHeadingDescriptionTop}>
          <Text variant={TextSize.M} className={styles.containerHeadingDescriptionTextTop}>
            We sent you a confirmation email.
          </Text>
          <Text variant={TextSize.M} className={styles.containerHeadingDescriptionTextTop}>
            Find it in your inbox and click Confirm.
          </Text>
        </div>
        <div className={styles.containerHeroLogo}>
          <Logo />
        </div>
        <div className={styles.containerHeroHeadingDescriptionBottom}>
          <Text variant={TextSize.L} bold>
            Don’t see an email?{' '}
            <span aria-hidden className={cx(styles.link, styles.clickable)} onClick={_onResendButtonClick}>
              Resend the link
            </span>
          </Text>
          <div
            className={cx(styles.actionTagContainer, {
              [styles.actionTagContainerShort]: actionSubmitted,
              [styles.actionTagContainerLong]: actionError,
            })}
          >
            {actionSubmitted && (
              <Tag color={TagColor.LIGHT_GREEN} textAlign={TagTextAlign.CENTER}>
                <Text variant={TextSize.XS} extraSmallCaps>
                  Email resent
                </Text>
              </Tag>
            )}
            {actionError && (
              <Tag color={TagColor.LIGHT_ORANGE} textAlign={TagTextAlign.CENTER}>
                <Text variant={TextSize.XS} extraSmallCaps>
                  Resend confirmation email error
                </Text>
              </Tag>
            )}
          </div>
        </div>
      </div>
      <div className={styles.containerDescription}>
        <Text variant={TextSize.M} color={TextColor.GREY2} className={styles.containerText}>
          Add <span className={styles.boldText}>noreply@writer.com</span> to your Safe Sender
          <br /> list to make sure you receive our emails.
        </Text>
      </div>
    </div>
  );
};
